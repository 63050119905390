import { useState } from 'react';
import { createDownAnimation, createFloatAnimation, createUpAnimation } from '../../utils/Animator';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [titleStatus, setTitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [descriptionStatus, setDescriptionStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [inputStatus, setInputStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [joinButtonStatus, setJoinButtonStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [backStatus, setBackStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');

    const titleStatusAnimation = createUpAnimation(0)
    const descriptionAnimation = createUpAnimation(0.05)
    const InputStatusAnimation = createUpAnimation(0.1)
    const joinButtonAnimation = createUpAnimation(0.15)
    const backStatusAnimation = createDownAnimation(0.2)

    titleStatusAnimation.idle = createFloatAnimation(0)
    descriptionAnimation.idle = createFloatAnimation(0.1)
    InputStatusAnimation.idle = createFloatAnimation(0.2)
    joinButtonAnimation.idle = createFloatAnimation(0.3)
    backStatusAnimation.idle = createFloatAnimation(0.05)

    function TransitionOut() {
        setTitleStatus('exit')
        setDescriptionStatus('exit')
        setJoinButtonStatus('exit')
        setBackStatus('exit')
        setInputStatus('exit')
    }

    return {
        titleStatus, setTitleStatus, titleStatusAnimation,
        descriptionStatus, setDescriptionStatus, descriptionAnimation,
        joinButtonStatus, setJoinButtonStatus, joinButtonAnimation,
        inputStatus, setInputStatus, InputStatusAnimation,
        backStatus, setBackStatus, backStatusAnimation,
        TransitionOut
    };
};