import React from 'react';
import ParticleComponent from './Particle';

interface Particle {
    id: number;
    x: number;
    y: number;
    speed: number;
    direction: number;
}

interface ParticleGeneratorProps {
    particles: Particle[];
}

const ParticleGenerator: React.FC<ParticleGeneratorProps> = ({ particles }) => {
    return (
        <div style={{ position: 'absolute', width: '100%', height: '100%', pointerEvents: 'none' }}>
            {particles.map(particle => (
                <ParticleComponent key={particle.id} {...particle} />
            ))}
        </div>
    );
};

export default ParticleGenerator;