import { useState } from 'react';
import createFadeAnimation, { createDownAnimation, createFloatAnimation, createUpAnimation } from '../../utils/Animator';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [fadeStatus, setFadeStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [titleStatus, setTitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [inputStatus, setInputStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [joinButtonStatus, setJoinButtonStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [backStatus, setBackStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');

    const fadeStatusAnimation = createFadeAnimation(0)
    const titleStatusAnimation = createUpAnimation(0)
    const InputStatusAnimation = createUpAnimation(0.05)
    const joinButtonAnimation = createUpAnimation(0.1)
    const backStatusAnimation = createDownAnimation(0.15)

    titleStatusAnimation.idle = createFloatAnimation(0)
    InputStatusAnimation.idle = createFloatAnimation(0.1)
    joinButtonAnimation.idle = createFloatAnimation(0.2)
    backStatusAnimation.idle = createFloatAnimation(0.05)

    function TransitionOut() {
        setFadeStatus('exit')
        setTitleStatus('exit')
        setJoinButtonStatus('exit')
        setBackStatus('exit')
        setInputStatus('exit')
    }

    return {
        fadeStatus, setFadeStatus, fadeStatusAnimation,
        titleStatus, setTitleStatus, titleStatusAnimation,
        joinButtonStatus, setJoinButtonStatus, joinButtonAnimation,
        inputStatus, setInputStatus, InputStatusAnimation,
        backStatus, setBackStatus, backStatusAnimation,
        TransitionOut
    };
};