import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useAudio } from './AudioContext';

import '../Common.css';
import './AudioSetting.css';
import CwgButton from '../CwgButton';
import sounds from '../../sounds';
import { createUpAnimation } from '../Animator';

interface Props {
    volumeSettingOn: boolean;
    setVolumeSettingOn: (status: boolean) => void;
}

const AudioSetting: React.FC<Props> = ({volumeSettingOn, setVolumeSettingOn}) => {
    const { volumeSettings, updateVolume } = useAudio();
    const [localSettings, setLocalSettings] = useState(volumeSettings);
    const [animationStatus, setAnimationStatus] = useState<string>('initial');
    const animation = createUpAnimation()

    useEffect(() => {
        if (!volumeSettingOn) {
            setAnimationStatus('exit')
        } else {
            setAnimationStatus('enter')
        }
    }, [volumeSettingOn]);

    useEffect(() => {
        setLocalSettings(volumeSettings);
    }, [volumeSettings]);

    const handleVolumeChange = (type: keyof typeof volumeSettings, event: React.ChangeEvent<HTMLInputElement>) => {
        const newVolume = parseFloat(event.target.value);
        setLocalSettings({
            ...localSettings,
            [type]: newVolume
        });
    };

    const handleVolumeCommit = (type: keyof typeof volumeSettings, event: React.MouseEvent<HTMLInputElement>) => {
        const newVolume = parseFloat((event.target as HTMLInputElement).value);
        updateVolume(type, newVolume);
    };
    return (
        <motion.div className="SettingFrame"
            style={{pointerEvents: volumeSettingOn ? 'auto' : 'none'}}
            initial='initial' 
            animate={animationStatus}
            variants={animation}>
            <div className='LargeText'>
                Audio Setting
            </div>
            <div style={{marginBottom: '3vh'}}/>
            <div className='NormalText'>
                Master Volume
            </div>

            <div style={{marginBottom: '0.5vh'}}/>
            <motion.input
                whileHover={{scale: volumeSettingOn ? 1.05 : 1}}
                onMouseEnter={() => {
                    if (!volumeSettingOn) return
                    sounds['hover'].play()
                }}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={localSettings.masterVolume}
                onChange={(e) => handleVolumeChange('masterVolume', e)}
                onMouseUp={(e) => handleVolumeCommit('masterVolume', e)}
            />
            <div style={{marginBottom: '1.5vh'}}/>
            <div className='NormalText'>
                Music Volume
            </div>

            <div style={{marginBottom: '0.5vh'}}/>
            <motion.input
                whileHover={{scale: volumeSettingOn ? 1.05 : 1}}
                onMouseEnter={() => {
                    if (!volumeSettingOn) return
                    sounds['hover'].play()
                }}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={localSettings.musicVolume}
                onChange={(e) => handleVolumeChange('musicVolume', e)}
                onMouseUp={(e) => handleVolumeCommit('musicVolume', e)}
            />
            <div style={{marginBottom: '1.5vh'}}/>
            <div className='NormalText'>
                Effects Volume
            </div>

            <div style={{marginBottom: '0.5vh'}}/>
            <motion.input
                whileHover={{scale: volumeSettingOn ? 1.05 : 1}}
                onMouseEnter={() => {
                    if (!volumeSettingOn) return
                    sounds['hover'].play()
                }}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={localSettings.soundEffectVolume}
                onChange={(e) => handleVolumeChange('soundEffectVolume', e)}
                onMouseUp={(e) => handleVolumeCommit('soundEffectVolume', e)}
            />
            <div style={{marginBottom: '3vh'}}/>
            <CwgButton 
                hasIdle={true}
                text={'Finish'} 
                recoverDuration={500}
                onClick={() => { setVolumeSettingOn(false) }} 
                allowClick={ true }/>
            <div style={{marginBottom: '-1.5vh'}}/>
        </motion.div>
    );
}

export default AudioSetting;