import { useEffect, useState } from 'react';
import { getValidatedSkinName } from '../../utils/SkinMapper'
import LayoutEditorEngine from '../../backend/LayoutEditorEngine';

import { getAuth } from "firebase/auth";

export const MAX_LAYOUT = 8

interface Loadout {
    [key: string]: string;
}

export const getDefaultLoadout = () => {
    return {
        "pawn1": "Grunt",
        "pawn2": "Grunt",
        "pawn3": "Grunt",
        "pawn4": "Grunt",
        "pawn5": "Grunt",
        "pawn6": "Grunt",
        "pawn7": "Grunt",
        "pawn8": "Grunt",
    
        "rook1": "Sailor", 
        "rook2": "Sailor", 
        
        "knight1": "Cavalry",
        "knight2": "Cavalry",  
    
        "bishop1": "Cadet",
        "bishop2": "Cadet",
    
        "queen": "The Lieutenant",
        "king": "The General",

        "sniper": "Sniper",
        "rifle": "Rifle",
        "shotgun": "Shotgun",
        "pistol": "Pistol",
        "knife": "Knife",
        "shield": "Shield",
    }
}

export const useLayout = () => {
    const [isLoadoutLoading, setIsLoadoutLoading] = useState(true);
    // layouts in front end will be an array, but stored as a mapping
    const [layouts, setLayouts] = useState<Loadout[]>([]);
    const [originalLayouts, setOriginalLayouts] = useState<Loadout[]>([]);
    const [layoutNames, setLayoutNames] = useState<string[]>([]);
    const [selectedLayout, setSelectedLayout] = useState<number>(0);

    const [ layoutEditorEngine, setLayoutEditorEngine ] = useState<LayoutEditorEngine | null>(null);

    useEffect(() => {
        const fetchLayout = async () => {
            setIsLoadoutLoading(true);
            try {
                // initializing the skin collection engine
                setLayoutEditorEngine(new LayoutEditorEngine(getAuth().currentUser?.uid, (layouts: any) => {
                    const fetchedLayouts = [];
                    for (let i = 1; i <= MAX_LAYOUT; i++) {
                        fetchedLayouts.push(layouts[`layout${i}`]);
                        // client side validation for each skin piece to make sure that they exist as an actual skin
                        for (let pieceAlias in fetchedLayouts[i - 1]) {
                            fetchedLayouts[i - 1][pieceAlias] = getValidatedSkinName(pieceAlias, fetchedLayouts[i - 1][pieceAlias])
                        }
                    }
                    setLayouts(fetchedLayouts);
                    setOriginalLayouts(fetchedLayouts)
                    setSelectedLayout(layouts['selectedLayout'])
                    setLayoutNames(layouts['layoutNames'])
                }));
            } catch (error) {
                // console.error("Error fetching layout", error);
                console.log('error')
            } finally {
                setIsLoadoutLoading(false);
            }
        };

        fetchLayout();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const updateLoadout = (index: number, newLoadout: Loadout) => {
        setLayouts(prevlayouts => {
            const updatedlayout = [...prevlayouts];
            updatedlayout[index] = newLoadout;
            return updatedlayout;
        });
    };

    const updateLayoutName = (index: number, newLayoutName: string) => {
        setLayoutNames(prevlayout => {
            const updatedlayout = [...prevlayout];
            updatedlayout[index] = newLayoutName;
            return updatedlayout;
        });
    };

    const saveLayouts = () => {
        if (layoutEditorEngine) {
            const layoutMapping: any = {};
            for (let i = 1; i <= MAX_LAYOUT; i++) {
                layoutMapping[`layout${i}`] = layouts[i - 1];
            }
            layoutEditorEngine.saveLayouts(layoutMapping, selectedLayout, layoutNames)
        }
    }

    return { 
        isLoadoutLoading, 
        layouts, updateLoadout,
        originalLayouts, 
        layoutNames, updateLayoutName,
        selectedLayout, setSelectedLayout,
        saveLayouts
    };
};

export const countSkinNameInLoadout = (skinName: string, loadout: Loadout) => {
    // Check if loadout exists
    if(!loadout) return 0; 
    
    let count = 0;
    for(const piece in loadout) {
        if(loadout[piece] === skinName) count++;
    }

    return count;
}

export function areLoadoutsDifferent(arr1: Loadout[], arr2: Loadout[]): boolean {
    if (arr1.length !== arr2.length) {
        return true; 
    }

    for (let i = 0; i < arr1.length; i++) {
        if (isObjectDifferent(arr1[i], arr2[i])) {
            return true; 
        }
    }

    return false; // No differences found
}

function isObjectDifferent(obj1: Loadout, obj2: Loadout): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return true;
    }

    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return true;
        }
    }

    return false; 
}

export default useLayout;