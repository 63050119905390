import { useState } from 'react';
import createFadeAnimation, { createLeftAnimation, createRightAnimation, createFloatAnimation, createUpAnimation, createDownAnimation } from '../../utils/Animator';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [fadeStatus, setFadeStatus] = useState<'initial' | 'enter' | 'exit'>('enter');
    const [titleStatus, setTitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [cancelStatus, setCancelStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');

    const [readyButtonStatus, setReadyButtonStatus] = useState<'initial' | 'enter' | 'exit'>('initial');
    const [infoPanelStatus, setInfoPanelStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [hostPanelStatus, setHostPanelStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('initial');
    const [opponentPanelStatus, setOpponentPanelStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('initial');

    const fadeStatusAnimation = createFadeAnimation(0)
    const titleStatusAnimation = createUpAnimation(0)
    const cancelStatusAnimation = createDownAnimation(0.15)
    const readyButtonStatusAnimation = createLeftAnimation(0.2)

    const infoPanelAnimation = createUpAnimation(0.05)
    const hostPanelAnimation = createLeftAnimation(0.25, true)
    hostPanelAnimation.exit.transition.delay = 0
    const opponentPanelAnimation = createRightAnimation(0, true)

    cancelStatusAnimation.idle = createFloatAnimation(0)
    titleStatusAnimation.idle = createFloatAnimation(0.1)
    infoPanelAnimation.idle = createFloatAnimation(0.1)
    hostPanelAnimation.idle = createFloatAnimation(0.2)
    opponentPanelAnimation.idle = createFloatAnimation(0.2)

    function TransitionOut() {
        setFadeStatus('exit')
        setTitleStatus('exit')
        setCancelStatus('exit')

        setReadyButtonStatus('exit')
        setInfoPanelStatus('exit')

        setHostPanelStatus('exit')
        setOpponentPanelStatus('exit')
    }

    function OpponentFound() {
        setReadyButtonStatus('enter')
        setOpponentPanelStatus('enter')
    }

    function OpponentLeaves() {
        setReadyButtonStatus('exit')
        setOpponentPanelStatus('exit')
    }

    return {
        fadeStatus, setFadeStatus, fadeStatusAnimation,
        titleStatus, setTitleStatus, titleStatusAnimation,
        cancelStatus, setCancelStatus, cancelStatusAnimation, 
        readyButtonStatus, setReadyButtonStatus, readyButtonStatusAnimation,
        infoPanelStatus, setInfoPanelStatus, infoPanelAnimation,
        hostPanelStatus, setHostPanelStatus, hostPanelAnimation,
        opponentPanelStatus, setOpponentPanelStatus, opponentPanelAnimation,
        TransitionOut, OpponentFound, OpponentLeaves
    };
};