import { Howl } from 'howler';

const sounds: Record<string, Howl> = {
    pieceSelect: new Howl({
        src: [require('./piece/piece_select.wav')]
    }),
    pieceHover: new Howl({
        src: [require('./piece/piece_hover.wav')]
    }),
    pieceLift: new Howl({
        src: [require('./utils/piece_lift.wav')]
    }),
    piecePlace: new Howl({
        src: [require('./utils/piece_place.wav')]
    }), 
    pieceDrop: new Howl({
        src: [require('./utils/piece_drop.wav')]
    }),
    drop1: new Howl({
        src: [require('./piece/drop1.wav')]
    }),
    drop2: new Howl({
        src: [require('./piece/drop2.wav')]
    }),
    drop3: new Howl({
        src: [require('./piece/drop3.wav')]
    }),
    drop4: new Howl({
        src: [require('./piece/drop4.wav')]
    }),
    equip: new Howl({
        src: [require('./piece/equip.wav')]
    }),
    purchaseEquipment: new Howl({
        src: [require('./utils/purchase_equipment.wav')]
    }),
    bulletHit1: new Howl({
        src: [require('./utils/bullet_hit1.wav')]
    }),
    bulletHit2: new Howl({
        src: [require('./utils/bullet_hit2.wav')]
    }),
    bulletHit3: new Howl({
        src: [require('./utils/bullet_hit3.wav')]
    }),
    bulletHit4: new Howl({
        src: [require('./utils/bullet_hit4.wav')]
    }),
    knifeStab: new Howl({
        src: [require('./utils/knife_stab.wav')]
    }),

    pistolReload: new Howl({
        src: [require('./reload/pistol_default.wav')]
    }),
    shotgunReload: new Howl({
        src: [require('./reload/shotgun_default.wav')]
    }),
    rifleReload: new Howl({
        src: [require('./reload/rifle_default.wav')]
    }),
    tommyGunReload: new Howl({
        src: [require('./reload/rifle_mafia.wav')]
    }),
    sniperReload: new Howl({
        src: [require('./reload/sniper_default.wav')]
    }),

    pistolFire: new Howl({
        src: [require('./fire/pistol_default.wav')]
    }),
    shotgunFire: new Howl({
        src: [require('./fire/shotgun_default.wav')]
    }),
    rifleFire: new Howl({
        src: [require('./fire/rifle_default.wav')]
    }),
    tommyGunFire: new Howl({
        src: [require('./fire/rifle_mafia.wav')]
    }),
    sniperFire: new Howl({
        src: [require('./fire/sniper_default.wav')]
    }),

    gameover: new Howl({
        src: [require('./utils/gameover.wav')]
    }),
    credit: new Howl({
        src: [require('./utils/credit.wav')]
    }),
    hover: new Howl({
        src: [require('./utils/hover.wav')]
    }),
    hover2: new Howl({
        src: [require('./utils/hover2.wav')]
    }),
    select: new Howl({
        src: [require('./utils/select.wav')]
    }),
    select2: new Howl({
        src: [require('./utils/select2.wav')]
    }),
    select3: new Howl({
        src: [require('./utils/select3.wav')]
    }),
    slide: new Howl({
        src: [require('./utils/slide.wav')]
    }),
    coins: new Howl({
        src: [require('./utils/coins.wav')]
    }),
    countdown: new Howl({
        src: [require('./utils/countdown.wav')]
    }),

    rollout1: new Howl({
        src: [require('./gatcha/rollout1.wav')]
    }),
    rollout2: new Howl({
        src: [require('./gatcha/rollout2.wav')]
    }),
    rollout3: new Howl({
        src: [require('./gatcha/rollout3.wav')]
    }),

    default_guy1: new Howl({
        src: [require('./character/default_guy1.wav')]
    }),
    default_guy2: new Howl({
        src: [require('./character/default_guy2.wav')]
    }),
};

export default sounds;