import React from 'react';
import { motion } from "framer-motion";
import images from '../../images';

export interface Capsule {
    id: number;
    startX: string;   
    startY: string;  
    endX: string;    
    endY: string;  
    peakHeight: string; 
}

interface CapsuleGeneratorProps {
    capsules: Capsule[];
}

const CapsuleGenerator: React.FC<CapsuleGeneratorProps> = ({ capsules }) => {
    return (
        <div style={{ position: 'absolute', right: '10vh', width: '100%', height: '100%', pointerEvents: 'none' }}>
            {capsules.map((capsule) => (
                <motion.img
                    key={capsule.id}
                    initial={{ x: capsule.startX, y: capsule.startY, rotate: 0, scale: 1 }}
                    animate={{
                        x: [capsule.startX, capsule.endX],
                        y: [capsule.startY, `${parseFloat(capsule.startY) - parseFloat(capsule.peakHeight)}vh`, capsule.endY],
                        rotate: [0, 360], 
                        scale: 1,
                    }}
                    transition={{
                        x: { duration: 1, ease: "easeOut" },
                        y: { duration: 1, ease: "easeOut" },
                        rotate: { duration: 1, ease: "easeOut" }
                    }}
                    style={{
                        position: 'absolute',
                        width: '10vh', 
                        height: '10vh',  
                    }}
                    src={images['ball']}
                />
            ))}
        </div>
    );
};

export default CapsuleGenerator;