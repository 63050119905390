import { useEffect, useState } from "react";
import { createDownAnimation, createLeftAnimation, createRightAnimation, createUpAnimation } from "../../utils/Animator";
import { triggerCleanupEvent } from "../../utils/GlobalUtils";
import { ShakeData } from "./Game"

export const createPieceAnimations = (
    delay: number = 0, posX: number = 0, posY: number = 0, 
    launchX: number, launchY: number, 
    gunLaunchX: number, gunLaunchY: number, 
    shieldLaunchX: number, shieldLaunchY: number, 
    recoilX: number, recoilY: number, 
    xRotate: number, xRotateShot: number,
) => ({
    initial: {
        x: [`${posX * 50}%`],
        y: [`${(posY - 3) * 50}%`],
        opacity: 0
    },

    none: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1, 
        opacity: 1, 
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        }
    },

    // for after recoil
    none2: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1, 
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeInOut",
        }
    },

    enter: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        opacity: 1, 
        scale: 1, 
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        }
    },

    specialEnter: {
        x: `${posX * 50}%`,
        y: [`${(posY - 3) * 50}%`, `${posY * 50}%`],
        opacity: 1, 
        scale: 1, 
        transition: {
            duration: 0.2,
            ease: "easeOut",
            delay: delay + 0.4
        }
    },

    exit: {
        x: `${posX * 50}%`,
        y: `${(posY - 1) * 50}%`,
        opacity: 0,
        scale: 1, 
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        }
    }, 

    hover: { 
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1.2,
        opacity: 1,
        transition: { 
            duration: 0.15,
            ease: "easeInOut"
        }
    },

    select: {
        x: `${posX * 50}%`,
        y: `${(posY - 0.3) * 50}%`,
        opacity: 1,
        scale: 1, 
        transition: { 
            duration: 0.15,
            ease: "easeInOut"
        }
    },

    selecthover: {
        scale: 1.2, 
        x: `${posX * 50}%`,
        y: `${(posY - 0.3) * 50}%`,
        opacity: 1,
        transition: { 
            duration: 0.15,
            ease: "easeInOut"
        } 
    },
    
    lift: { 
        scale: 1.2, 
        opacity: 1,
        x: `${posX * 50}%`,
        y: `${(posY - 1.60) * 50}%`,
        transition: {
            duration: 0.15,
            ease: "easeIn"
        }
    },

    drop: { 
        scale: 1, 
        opacity: 1,
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        transition: {
            duration: 0.10, 
            ease: "easeIn"
        }
    },

    stabbed: {
        x: `${(posX + launchX) * 50}%`,
        y: `${(posY + launchY) * 50}%`,
        opacity: 0,
        rotate: xRotate * 125, 
        scale: 1, 
        transition: {
            type: "spring",
            damping: 50,
            stiffness: 90,
            delay: 0.4,
            opacity: { delay: 1, duration: 1 },
            rotate: { delay: 0.5, duration: 1.5 }, 
        }, 
    },

    shot: {
        x: `${(posX + gunLaunchX) * 50}%`,
        y: `${(posY + gunLaunchY) * 50}%`,
        opacity: 0, 
        rotate: xRotateShot * 1500, 
        scale: 1, 
        transition: {
            type: "spring",
            damping: 50,
            stiffness: 70,
            opacity: { delay: 0.5, duration: 1 },
            rotate: { delay: 0.05, duration: 1.5 }, 
        }
    },

    knockback: {
        x: `${posX * 50 + shieldLaunchX * 2}%`, 
        y: `${posY * 50 + shieldLaunchY * 2}%`, 
        scale: 1, 
        transition: {
            duration: 0.025,
            ease: "easeIn"
        }
    },

    recoil: {
        x: `${posX * 50 + recoilX * 4}%`, 
        y: `${posY * 50 + recoilY * 4}%`, 
        scale: 1, 
        opacity: 1, 
        transition: {
            duration: 0.025,
            ease: "easeIn"
        }
    },

    noShiver: {
        x: `${0}%`, 
        y: `${0}%`, 
        scale: 1, 
        opacity: 1, 
    }, 

    shiver: {
        x: [`${0}%`, 
            `${0 + (Math.random()*1 - 0.5)}%`, 
            `${0}%` , 
            `${0 - (Math.random()*1 - 0.5)}%`, 
            `${0}%`],
        y: `${0}%`, 
        rotate: [0, -2, 0, 2, 0], 
        scale: 1, 
        opacity: 1,
        transition: {
            x: {
                repeat: Infinity,
                duration: 0.2,
                ease: "easeInOut"
            },
            rotate: {
                repeat: Infinity,
                duration: 0.2,
                ease: "easeInOut"
            }, 
            default: { 
                duration: 0.15, 
                ease: "easeInOut" 
            }
        }
    }, 

    bump: {
        x: `${0}%`,
        y: [`${0}%`, `${-10}%`, `${0}%`], 
        scale: 1, 
        opacity: 1,
        transition: {
            duration: 0.15, 
            ease: "easeInOut" 
        }
    },
});

export const createShadowAnimations = (
    delay: number = 0, posX: number = 0, posY: number = 0, 
    shieldLaunchX: number, shieldLaunchY: number,
    recoilX: number, recoilY: number
) => ({
    initial: {
        x: [`${posX * 50}%`],
        y: [`${posY * 50}%`],
        scale: 1.5,
        opacity: 0
    },

    none: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1, 
        opacity: 0.3,
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        }
    },

    // for after recoil
    none2: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1, 
        opacity: 0.3,
        transition: {
            duration: 0.5,
            ease: "easeInOut",
        }
    },

    enter: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1, 
        opacity: 0.3,
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        }
    },

    specialEnter: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1,
        opacity: 0.3,
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            delay: delay + 0.2
        }
    },

    exit: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1.25, 
        opacity: 0,
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        }
    }, 

    hover: { 
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1,
        opacity: 0.15,
        transition: { 
            duration: 0.25,
            ease: "easeInOut"
        }
    },

    select: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1,
        opacity: 0.15,
        transition: { 
            duration: 0.25,
            ease: "easeInOut"
        }
    },

    selecthover: {
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1,
        opacity: 0.15,
        transition: { 
            duration: 0.25,
            ease: "easeInOut"
        } 
    },

    lift: { 
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1.2, 
        opacity: 0.15, 
        transition: {
            duration: 0.15,
            ease: "easeIn"
        }
    },

    drop: { 
        x: `${posX * 50}%`,
        y: `${posY * 50}%`,
        scale: 1,
        opacity: 0.3,
        transition: {
            duration: 0.10, 
            ease: "easeIn"
        }
    },

    stabbed: {
        opacity: [0],
    }, 

    shot: {
        opacity: [0],
    },

    knockback: {
        x: `${posX * 50 + shieldLaunchX * 2}%`, 
        y: `${posY * 50 + shieldLaunchY * 2}%`, 
        opacity: 0.3,
        transition: {
            duration: 0.025,
            ease: "easeIn"
        }
    },

    recoil: {
        x: `${posX * 50 + recoilX * 4}%`, 
        y: `${posY * 50 + recoilY * 4}%`,
        opacity: 0.3, 
        transition: {
            duration: 0.025,
            ease: "easeIn"
        }
    },
})

export const createWeaponDestroyAnimations = (
    posX: number = 0, posY: number = 0, launchX: number, launchY: number, xRotateShot: number, gunHit: boolean
) => ({
    none: {
        opacity: [1]
    }, 
    shot: {
        x: `${(posX + launchX) * 50}%`,
        y: `${(posY + launchY) * 50}%`,
        opacity: [1, 0], 
        rotate: gunHit ? xRotateShot * 1250 : xRotateShot * 125, 
        transition: {
            type: "spring",
            damping: 70,
            stiffness: 90,
            opacity: { delay: 0.5, duration: 1 },
            rotate: { delay: 0.05, duration: 1.5 }, 
        }
    },
})

export const createShieldDestroyAnimations = (
    posX: number = 0, posY: number = 0, launchX: number, launchY: number, gunHit: boolean 
) => ({
    none: {
        opacity: [1]
    }, 
    shot: {
        x: `${(posX + launchX) * 50}%`,
        y: `${(posY + launchY) * 50}%`,
        opacity: [1, 0], 
        rotate: gunHit ? 1250 : 125, 
        transition: {
            type: "spring",
            damping: 50,
            stiffness: 90,
            opacity: { delay: 0.5, duration: 0.8 },
            rotate: { delay: 0.05, duration: 1 }, 
        }
    },
})

export const createFastRightAnimation = (delay = 0) => ({
    initial: {
        x: '-35vh',
        opacity: 0
    },
    enter: {
        x: 0,
        opacity: 1, 
        transition: {
            duration: 0.25,
            ease: "easeInOut",
            delay
        }
    },
    exit: {
        x: '-35vh',
        opacity: 0,
        transition: {
            duration: 0.25,
            ease: "easeInOut",
            delay
        }
    }
});

export const createFastUpAnimation = (delay = 0) => ({
    initial: {
        y: '-35vh',
        opacity: 0
    },
    enter: {
        y: 0,
        opacity: 1, 
        transition: {
            duration: 0.25,
            ease: "easeInOut",
            delay
        }
    },
    exit: {
        y: '-35vh',
        opacity: 0,
        transition: {
            duration: 0.25,
            ease: "easeInOut",
            delay
        }
    }
});

export const useBoardShakeAnimation = (shakeInfo: ShakeData) => {
    const [boardShakeStatus, setBoardShakeStatus] = useState('initial');

    useEffect(() => {
        setTimeout(() => setBoardShakeStatus('idle'), 250);
        if (shakeInfo.force !== 0) {
            setBoardShakeStatus('shake');
        }
    }, [shakeInfo]);

    const boardShakeAnimation = {
        initial: {
            x: 0,
            y: 0,
        },
        shake: {
            opacity: [1],
            scale: [1],
            x: ['0%', `${Math.floor(Math.cos(shakeInfo.angle) * (shakeInfo.force - 3))}%`, '0%'],
            y: ['0%', `${Math.floor(Math.sin(shakeInfo.angle) * (shakeInfo.force - 3))}%`, '0%'],
            transition: { duration: 0.25, ease: 'easeIn' },
        },
        idle: {
            opacity: 1, 
            scale: 1,
            x: 0,
            y: 0,
            transition: { duration: 0.25 },
        },
    };

    return { boardShakeStatus, setBoardShakeStatus, boardShakeAnimation };
};

export const useScreenShakeAnimation = (shakeInfo: ShakeData) => {
    const [screenShakeStatus, setScreenShakeStatus] = useState<string>("idle");

    const screenShakeAnimation = {
        shake: {
            transform: `translate(${Math.floor(Math.cos(shakeInfo.angle) * (shakeInfo.force))}%, 
                        ${Math.floor(Math.sin(shakeInfo.angle) * (shakeInfo.force))}%)`,
            transition: {
                duration: 0.04,
                ease: 'easeIn'
            },
        },
        idle: {
            transform: `translate(0%, 0%)`,
            transition: {
                duration: 1,
                ease: 'easeOut'
            },
        },
    }

    return {
        screenShakeStatus, setScreenShakeStatus, screenShakeAnimation,
    };
}

export const useSecondaryScreenShakeAnimation = (shakeInfo: ShakeData) => {
    const [secondaryShakeStatus, setSecondaryShakeStatus] = useState<string>("idle");

    const secondaryShakeAnimation = {
        shake: {
            transform: `translate(${Math.floor(Math.cos(shakeInfo.angle) * (shakeInfo.force))}%, 
                        ${Math.floor(Math.sin(shakeInfo.angle) * (shakeInfo.force))}%)`,
            transition: {
                duration: 0.05,
                ease: 'easeIn'
            },
        },
        idle: {
            transform: `translate(0%, 0%)`,
            transition: {
                duration: 0.5,
                ease: 'easeOut'
            },
        },
    }

    return {
        secondaryShakeStatus, setSecondaryShakeStatus, secondaryShakeAnimation,
    };
}

export const useShopAnimationStatus = (isBasic: boolean) => {
    const [status, setStatus] = useState<string>("initial");

    const toRightAnim = {
        initial: {
            x: '-35vh',
            opacity: 0,
        },
        enter: {
            x: 0,
            opacity: 1, 
            transition: {
                duration: 0.25,
                ease: "easeInOut",
                delay: 1.8
            }
        },
        exit: {
            x: '-35vh',
            opacity: 0,
            transition: {
                duration: 0.25,
                ease: "easeInOut",
                delay: 0
            }
        }
    }
    const toLeftAnim = {
        initial: {
            x: '35vh',
            opacity: 0
        },
        enter: {
            x: 0,
            opacity: 1, 
            transition: {
                duration: 0.25,
                ease: "easeInOut",
                delay: 1.8
            }
        },
        exit: {
            x: '35vh',
            opacity: 0,
            transition: {
                duration: 0.25,
                ease: "easeInOut",
                delay: 0
            }
        }
    }
    
    const statusAnimation = isBasic ? toLeftAnim : toRightAnim;
    
    function TransitionOut() {
        setStatus('exit')
    }

    return {
        status, setStatus, statusAnimation,
        TransitionOut
    };
};

export const EndTurnTimerStatus = () => {
    const [status, setStatus] = useState<string>("initialEnter");
    
    const statusAnimation = {
        initial: {
            scale: 2.25, 
            opacity: 0,
        },
        update: {
            scale: [2.25, 1, 1, 1],
            opacity: [0, 1, 1, 1],
            transition: {
                duration: 0.5,
                ease: "easeIn"
            }
        },
        exit: {
            opacity: 0,
            scale: 1, 
            transition: {
                duration: 0.25,
                ease: "easeIn"
            }
        }
    };
    return {
        status, setStatus, statusAnimation
    };
};

export const useStatusDisplayAnimationStatus = () => {
    const [status, setStatus] = useState<string>("initialEnter");
    
    const statusAnimation = {
        initial: {
            scale: 1, 
            opacity: 0,
            y: '200%'
        },
        initialEnter: {
            scale: 1, 
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.25,
                ease: "easeInOut",
                delay: 1.8
            }
        },
        enter: {
            scale: 1, 
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.25,
                ease: "easeInOut",
            }
        },
        update: {
            scale: [1.35, 1],
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.25,
                ease: "easeInOut",
            }
        },
        exit: {
            scale: 1, 
            opacity: 0,
            y: '200%', 
            transition: {
                duration: 0.25,
                ease: "easeInOut",
            }
        }
    };
    
    function TransitionOut() {
        setStatus('exit')
    }

    return {
        status, setStatus, statusAnimation,
        TransitionOut
    };
};

export const usePromoteAnimationStatus = () => {
    const [status, setStatus] = useState<string>("initial");
    const [option1Status, setOption1Status] = useState<string>("initial");
    const [option2Status, setOption2Status] = useState<string>("initial");
    const [option3Status, setOption3Status] = useState<string>("initial");
    const [option4Status, setOption4Status] = useState<string>("initial");
    
    const statusAnimation = {
        initial: {
            opacity: 0
        },
        enter: {
            opacity: 0.6,
            transition: {
                duration: 0.4,
                ease: "easeInOut",
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.5,
                ease: "easeInOut",
            }
        }
    }
    const option1StatusAnimation = createUpAnimation(0.2)
    const option2StatusAnimation = createUpAnimation(0.3)
    const option3StatusAnimation = createUpAnimation(0.4)
    const option4StatusAnimation = createUpAnimation(0.5)
    option1StatusAnimation.exit.transition.delay = 0
    option2StatusAnimation.exit.transition.delay = 0
    option3StatusAnimation.exit.transition.delay = 0
    option4StatusAnimation.exit.transition.delay = 0
    
    function TransitionOut() {
        setStatus('exit')
        setOption1Status('exit')
        setOption2Status('exit')
        setOption3Status('exit')
        setOption4Status('exit')
    }

    function TransitionIn() {
        setStatus('enter')
        setOption1Status('enter')
        setOption2Status('enter')
        setOption3Status('enter')
        setOption4Status('enter')
    }

    return {
        status, setStatus, statusAnimation,
        option1Status, option2Status, option3Status, option4Status,
        setOption1Status, setOption2Status, setOption3Status, setOption4Status, 
        option1StatusAnimation, option2StatusAnimation, option3StatusAnimation, option4StatusAnimation, 
        TransitionOut, TransitionIn
    };
};

export const useBoardAnimationStatus = () => {
    const [boardStatus, setBoardStatus] = useState<string>("initial");
    const [settingsStatus, setSettingsStatus] = useState<string>("initial");
    const [surrenderStatus, setSurrenderStatus] = useState<string>("initial");
    const [drawStatus, setDrawStatus] = useState<string>("initial");
    const [gameStatus, setGameStatus] = useState<string>("initial");

    const [myAvatarStatus, setMyAvatarStatus] = useState<string>("initial");
    const [myUsernameStatus, setMyUsernameStatus] = useState<string>("initial");
    const [myRankStatus, setMyRankStatus] = useState<string>("initial");
    const [myIncomeStatus, setMyIncomeStatus] = useState<string>("initial");

    const [opponentAvatarStatus, setOpponentAvatarStatus] = useState<string>("initial");
    const [opponentUsernameStatus, setOpponentUsernameStatus] = useState<string>("initial");
    const [opponentRankStatus, setOpponentRankStatus] = useState<string>("initial");
    
    const boardAnimation = createUpAnimation(0, true)
    boardAnimation.exit.transition.delay = 0.3
    const settingsAnimation = createRightAnimation(0.40, true);
    const surrenderAnimation = createRightAnimation(0.45, true);
    const drawAnimation = createRightAnimation(0.5, true);
    const gameAnimation = createDownAnimation(0.55);

    const myAvatarAnimation = createRightAnimation(0, true);
    myAvatarAnimation.exit.transition.delay = 0.3
    const myUsernameAnimation = createRightAnimation(0.05, true);
    myUsernameAnimation.exit.transition.delay = 0.35
    const myRankAnimation = createRightAnimation(0.25, true); // legacy
    const myIncomeAnimation = createRightAnimation(0.1, true);
    myIncomeAnimation.exit.transition.delay = 0.4

    const opponentAvatarAnimation = createLeftAnimation(0, true);
    opponentAvatarAnimation.exit.transition.delay = 0.3
    const opponentUsernameAnimation = createLeftAnimation(0.05, true);
    opponentUsernameAnimation.exit.transition.delay = 0.35
    const opponentRankAnimation = createLeftAnimation(0.25, true); // legacy
    
    function TransitionIn() {
        setBoardStatus('enter')
        setGameStatus('enter')
        setSettingsStatus('enter')
        setSurrenderStatus('enter')
        setDrawStatus('enter')
        setMyAvatarStatus('enter')
        setMyUsernameStatus('enter')
        setMyRankStatus('enter')
        setMyIncomeStatus('enter')
        setOpponentAvatarStatus('enter')
        setOpponentUsernameStatus('enter')
        setOpponentRankStatus('enter')
    }

    function TransitionOut() {
        triggerCleanupEvent()
        setBoardStatus('exit')
        setGameStatus('exit')
        setSettingsStatus('exit')
        setSurrenderStatus('exit')
        setDrawStatus('exit')
        setMyAvatarStatus('exit')
        setMyUsernameStatus('exit')
        setMyRankStatus('exit')
        setMyIncomeStatus('exit')
        setOpponentAvatarStatus('exit')
        setOpponentUsernameStatus('exit')
        setOpponentRankStatus('exit')
    }

    return {
        boardStatus, boardAnimation,
        gameStatus, gameAnimation,
        settingsStatus, settingsAnimation,
        surrenderStatus, surrenderAnimation,
        drawStatus, drawAnimation,
        myAvatarStatus, myAvatarAnimation,
        myUsernameStatus, myUsernameAnimation,
        myRankStatus, myRankAnimation,
        myIncomeStatus, myIncomeAnimation,
        opponentAvatarStatus, opponentAvatarAnimation,
        opponentUsernameStatus, opponentUsernameAnimation, 
        opponentRankStatus, opponentRankAnimation,
        TransitionIn, TransitionOut
    };
};

export const useGameOverAnimationStatus = () => {
    const [backgroundStatus, setBackgroundStatus] = useState<string>("initial");
    const [panelStatus, setPanelStatus] = useState<string>("initial");
    
    const panelStatusAnimation = {
        initial: {
            opacity: 0,
            scale: 2,
            y: '-10vh',
        },
        enter: {
            opacity: 1,
            scale: 1, 
            y: '0vh',
            transition: {
                duration: 0.3,
                ease: "easeInOut",
                delay: 0.8
            }
        },
        exit: {
            opacity: 0,
            scale: 1.5, 
            y: '-10vh',
            transition: {
                duration: 0.2,
                ease: "easeInOut",
            }
        }
    }
    const backgroundStatusAnimation = {
        initial: {
            opacity: 0
        },
        enter: {
            opacity: 0.6,
            transition: {
                duration: 0.8,
                ease: "easeInOut",
                delay: 0.5
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.3,
                ease: "easeInOut",
            }
        }
    };
    
    function TransitionOut() {
        setPanelStatus('exit')
        setBackgroundStatus('exit')
    }

    return {
        panelStatus, setPanelStatus, panelStatusAnimation,
        backgroundStatus, setBackgroundStatus, backgroundStatusAnimation,
        TransitionOut
    };
};


export const useUIOverlayAnimationStatus = () => {
    const [backgroundStatus, setBackgroundStatus] = useState<string>("initial");
    const [panelStatus, setPanelStatus] = useState<string>("initial");
    
    const panelStatusAnimation = {
        initial: {
            opacity: 0,
            scale: 2,
            y: '-10vh',
        },
        enter: {
            opacity: 1,
            scale: 1, 
            y: '0vh',
            transition: {
                duration: 0.2,
                ease: "easeInOut",
                delay: 0.1
            }
        },
        exit: {
            opacity: 0,
            scale: 1.5, 
            y: '-10vh',
            transition: {
                duration: 0.1,
                ease: "easeInOut",
            }
        }
    }
    const backgroundStatusAnimation = {
        initial: {
            opacity: 0
        },
        enter: {
            opacity: 0.6,
            transition: {
                duration: 0.3,
                ease: "easeInOut",
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.2,
                ease: "easeInOut",
            }
        }
    };
    
    function TransitionOut() {
        setPanelStatus('exit')
        setBackgroundStatus('exit')
    }

    return {
        panelStatus, setPanelStatus, panelStatusAnimation,
        backgroundStatus, setBackgroundStatus, backgroundStatusAnimation,
        TransitionOut
    };
};

export const useSpeechBubbleAnimationStatus = (mySide: boolean) => {
    const [status, setStatus] = useState<string>("initial");
    
    const statusAnimation = {
        initial: {
            opacity: 0,
            scale: 0,
            y: mySide ? '10vh' : '-10vh',
            x: mySide ? '-2vh' : '2vh',
        },
        enter: {
            opacity: [0, 0, 1],
            scale: 1, 
            y: '0vh',
            x: '0vh',
            transition: {
                duration: 0.25,
                ease: "easeInOut",
                delay: 0.1
            }
        },
        exit: {
            opacity: [1, 0, 0],
            scale: 0,
            y: mySide ? '10vh' : '-10vh',
            x: mySide ? '-2vh' : '2vh',
            transition: {
                duration: 0.25,
                ease: "easeInOut",
            }
        }
    }
    
    function TransitionOut() {
        setStatus('exit')
    }

    return {
        status, setStatus, statusAnimation,
        TransitionOut
    };
};
