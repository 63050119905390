import React from 'react';
import { motion } from 'framer-motion';

import './Layout.css';

import { Colors } from '../../utils/Enums'
import skins from '../../utils/SkinMapper'

interface Props {
    cellId: number;
    hoveredCellId: number;
    selectedCellId: number;
    skinName: string; 
    color: Colors;
    hidePieces: boolean;
    onClick: () => void; 
    onHover: () => void; 
}

const Cell: React.FC<Props> = ({ cellId, hoveredCellId, selectedCellId, skinName, color, hidePieces, onClick, onHover }) => {
    const isHovered = hoveredCellId === cellId
    const isSelected = selectedCellId === cellId

    const hoveredBorderStyle = hidePieces ? { outline: `2px solid #BFBFBF`, zIndex: 5, borderRadius: '0.5vh' } : {};
    const selectedBorderStyle = hidePieces ? { outline: `2px solid #A3A3A3`, zIndex: 10, borderRadius: '0.5vh' } : {};
    
    let cellStyle = { backgroundColor: color === Colors.WHITE ? '#CBCBCB' : '#D9D9D9' }
    if (isSelected) {
        cellStyle = { ...cellStyle, ...selectedBorderStyle }; 
    } else if (isHovered) {
        cellStyle = { ...cellStyle, ...hoveredBorderStyle }; 
    }

    return (
        <motion.div className="MyCell" style={cellStyle} onClick = { onClick } onMouseEnter = { onHover }>
            {skinName !== 'Default Tile' && (
                <img className='MyCellSkin' 
                    src={color === Colors.WHITE ? skins[skinName].whiteImage : skins[skinName].blackImage} 
                    alt='cell skin' draggable="false"/>
            )}
        </motion.div>
    )
}

export default Cell;