import React from 'react';
import { motion } from 'framer-motion';

import './Game.css';

interface Props {
    timePercentage: number;
}

const GameTimer: React.FC<Props> = ({ timePercentage }) => {
    return (
        <motion.div className='CountdownTimer'>
            <motion.div
                style={{
                    height: '100%',
                    width: `${Math.min(timePercentage * 100, 98)}%`,
                    backgroundColor: '#6B6B6B',
                    borderRadius: '1vh',
                }}
                initial={{ width: '100%' }} 
                animate={{ width: `${timePercentage * 100}%` }} 
                transition={{ duration: 0.25, ease: "linear" }}
            >
            </motion.div>
        </motion.div>
    )
    // return (
    //     <>
    //         <svg viewBox="-10 -10 720 720" style={{ transform: `scaleX(1.0075) scaleY(-1.0075)`, width: '72vh', height: '72vh', position: 'absolute', top: '-1vh', left: '-1vh', pointerEvents: 'none' }}>
    //             <path d="M 0,350 
    //                 V 10   
    //                 A 10 10 0 0 1 10,0   
    //                 H 690 
    //                 A 10 10 0 0 1 700,10  
    //                 V 690  
    //                 A 10 10 0 0 1 690,700 
    //                 H 10   
    //                 A 10 10 0 0 1 0,690  
    //                 Z"    // Close the path
    //             fill="none" 
    //             stroke="#A9A9A9" 
    //             strokeWidth="0.3vh"  
    //             style={{
    //                 strokeDasharray: 2800,
    //                 strokeDashoffset: 4208.5 - opponentTimePercentage * (4208.5 - 2800) // 2800 to 4208.5 is the range
    //             }} />
    //         </svg>
    //         <svg viewBox="-10 -10 720 720" style={{ transform: `scaleX(1.0075) scaleY(1.0075)`, width: '72vh', height: '72vh', position: 'absolute', top: '-1vh', left: '-1vh', pointerEvents: 'none' }}>
    //             <path d="M 0,350 
    //                 V 10   
    //                 A 10 10 0 0 1 10,0   
    //                 H 690 
    //                 A 10 10 0 0 1 700,10  
    //                 V 690  
    //                 A 10 10 0 0 1 690,700 
    //                 H 10   
    //                 A 10 10 0 0 1 0,690  
    //                 Z"    // Close the path
    //             fill="none" 
    //             stroke="#A9A9A9" 
    //             strokeWidth="0.3vh"  
    //             style={{
    //                 strokeDasharray: 2800,
    //                 strokeDashoffset: 4208.5 - myTimePercentage * (4208.5 - 2800) // 2800 to 4208.5 is the range
    //             }} />
    //         </svg>
    //     </>
    // )
}

export default GameTimer;