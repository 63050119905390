import React, { useState, useEffect, MouseEvent } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Game.css';

import Pieces, { Characters, Colors, Equipments, Gamemodes, Phases, PieceIdxes } from "../../utils/Enums"
import Cell from './Cell';
import Piece, { PieceInfo } from './Piece';
import { CoordToPos, GetCanMovePos, clickCell, getNextTurn, PosToCoord, pieceFire, updateShiver, buyEquipment, IsChecked, GenerateChessGrid, Moved } from './GameLogic'
import { useGameState } from './GameState';
import Shop from './Shop';

import { EndTurnTimerStatus, useBoardAnimationStatus, useBoardShakeAnimation, useScreenShakeAnimation, useSecondaryScreenShakeAnimation } from './Animation';
import skins from '../../utils/SkinMapper';
import Promotion from './Promotion';
import StatusDisplay from './StatusDisplay';
import GameOverDisplay from './GameOverDisplay';
import Surrender from './Surrender';
import sounds from '../../sounds';
import Draw from './Draw';
import MySpeechBubble, { OpponentSpeechBubble } from './SpeechBubbles';
import Settings from './Settings';
import ParticleGenerator from '../../utils/ParticleGenerator';
import CharacterHandler from '../../utils/CharacterHandler';
import ExpressionSpammer from './ExpressionSpammer';
import GameTimer from './GameTimer';

interface Props {
    setAlertMessage: (alert: string) => void;
    gameMode: string;
    matchId: string;
    backToMenu: () => void;
    changeMode: () => void;
    findMatch: (mode: string) => void;
    myCharacter: Characters;
    setGameStage: (gameStage: number) => void;
}

interface Particle {
    id: number;
    x: number;
    y: number;
    speed: number;
    direction: number;
}

let particleCounter = 0;

export interface ShakeData {
    angle: number;
    force: number;
}

const Game: React.FC<Props> = ({ setAlertMessage, gameMode, matchId, backToMenu, changeMode, findMatch, myCharacter, setGameStage }) => {
    // all backend functions are disabled if the mode is dummy
    const { 
        isLoading, 
        pieces, setPieces, 
        turn, setTurn,
        // turnLock, setTurnLock, 
        myUsername, myCredits, myColor, myLayout, 
        myIncome, setMyIncome, 
        opponentUsername, opponentCredits, opponentLayout,
        pieceCache, setPieceCache, 
        hoveredPos, setHoveredPos, 
        selectedPos, setSelectedPos, 
        boardShakeInfo, setBoardShakeInfo,
        screenShakeInfo, setScreenShakeInfo, 
        shootAngle, setShootAngle,
        opponentShootAngle,
        gameOverStatus, setGameOverStatus, 
        myDrawRequest, setMyDrawRequest,
        opponentDrawRequest, setOpponentDrawRequest,
        mySpeech, setMySpeech,
        opponentSpeech, setOpponentSpeech,
        opponentExpression, setOpponentExpression,
        opponentCharacter, 
        lastUpdateTime,
        gameEngine
    } = useGameState(matchId, gameMode, gameMode === Gamemodes.DUMMY_BOT);
    const [ secondaryShakeInfo, setSecondaryShakeInfo ] = useState<ShakeData>({angle: 0, force: 0})

    // animations
    const animation = useBoardAnimationStatus();
    const endTurnTimerAnimation = EndTurnTimerStatus();
    const {boardShakeStatus, setBoardShakeStatus, boardShakeAnimation} = useBoardShakeAnimation(boardShakeInfo)
    const {screenShakeStatus, setScreenShakeStatus, screenShakeAnimation} = useScreenShakeAnimation(screenShakeInfo)
    const {secondaryShakeStatus, setSecondaryShakeStatus, secondaryShakeAnimation} = useSecondaryScreenShakeAnimation(secondaryShakeInfo)
    const [promotionSet, setPromotionSet] = useState<string>('')
    const [displayedIncome, setDisplayedIncome] = useState<number>(gameMode === Gamemodes.DUMMY_BOT ? 9999 : 0);
    const [particles, setParticles] = useState<Particle[]>([]);

    // other fields
    const [selectedPiece, setSelectedPiece] = useState<PieceInfo | null>(null)
    const [draggedEquipmentAlias, setDraggedEquipmentAlias] = useState<{dragStart: number, equipment: string} | null>(null)
    const [mousePos, setMousePos] = useState<{x: number, y: number} | null>(null)
    const [allowMove, setAllowMove] = useState<boolean>(true) // used to make sure the canMove array updates before moving
    const [canMovePos, setCanMovePos] = useState<boolean[][]>(Array(8).fill(false).map(() => Array(8).fill(false)))
    const [canShoot, setCanShoot] = useState<boolean>(false);
    const [overlayUI, setOverlayUI] = useState<'none' | 'surrender' | 'draw' | 'settings'>('none');
    const [lastDrawTime, setLastDrawTime] = useState<number>(30);
    const [myExpression, setMyExpression] = useState<string>('idle')

    const [turnTimer, setTurnTimer] = useState<number>(16);

    useEffect(() => {
        if (turn.phase === Phases.MOVE || turn.phase === Phases.FIRE) {
            const maxTurnTime = turn.phase === Phases.MOVE ? 17 : 7;
            let timerId: number;  // Declare timerId as a number for browser environments
    
            const updateTimer = () => {
                const now = new Date().getTime();
                const lastUpdateMillis = lastUpdateTime ? lastUpdateTime.getTime() : now;
                const timeElapsed = Math.floor((now - lastUpdateMillis) / 1000);
                const remainingTime = Math.max(maxTurnTime - timeElapsed, 0);
                setTurnTimer(remainingTime);
    
                if (remainingTime <= 0) {
                    clearInterval(timerId);  // Use clearInterval with timerId as number
                }
            };
    
            updateTimer();  // Update immediately to set initial value
            timerId = window.setInterval(updateTimer, 1000);  // Assign and use window.setInterval
    
            return () => clearInterval(timerId);  // Cleanup function
        }
    }, [turn.color, turn.phase, lastUpdateTime]);

    useEffect(() => {
        let score = 0
        for (const piece of pieces) {
            if (!piece.active) {
                if (piece.type === Pieces.PAWN) {
                    score += 2
                } else if (piece.type === Pieces.QUEEN) {
                    score += 8
                } else {
                    score += 5
                }
            } else if (Moved(piece)) {
                score += 1
            }
        }
        
        let stage = 1 // early game
        if (score >= 55) {
            // late game
            stage = 3
        } else if (score >= 25) {
            // mid game
            stage = 2
        }

        setGameStage(stage) 
    }, [pieces, setGameStage]);

    // transition all the UIs in
    useEffect(() => { 
        animation.TransitionIn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // update income timer
    useEffect(() => {
        if (displayedIncome < myIncome) {
            const timer = setTimeout(() => {
                if (displayedIncome < myIncome - 150) {
                    setDisplayedIncome(Math.max(0, myIncome - 150))
                } else {
                    setDisplayedIncome(Math.max(0, displayedIncome + 10))
                }
                if (displayedIncome % 10 === 0) {
                    // sounds['coins'].play()
                }
                return () => {
                    clearTimeout(timer)
                };
            }, 75);
        } else if (displayedIncome > myIncome) {
            const timer = setTimeout(() => {
                if (displayedIncome > myIncome + 150) {
                    setDisplayedIncome(Math.max(0, myIncome - 150))
                } else {
                    setDisplayedIncome(Math.max(0, displayedIncome - 10))
                }
                if (displayedIncome % 10 === 0) {
                    // sounds['coins'].play()
                }
                return () => {
                    clearTimeout(timer)
                };
            }, 75);
        }
    }, [displayedIncome, myIncome])

    // countdown the lastDrawTime timer so that player can request draw
    // -1 is the terminating condition, we dont want to skip 0 on the player's screen
    useEffect(() => {
        if (lastDrawTime > -1) {
            const timer = setTimeout(() => {
                setLastDrawTime(lastDrawTime - 1)
                return () => {
                    clearTimeout(timer)
                };
            }, 1000);
        }
    }, [lastDrawTime])

    // reset game over status to default on initial render everytime we enter a game
    useEffect(() => {
        setGameOverStatus({winner: Colors.WHITE, condition: ''})
    }, [setGameOverStatus])

    // this useEffect updates the selected piece
    useEffect(() => {
        // do not reset selected piece if it is the firing phase
        setSelectedPiece(null)
        if (selectedPos) {
            const foundPiece = pieces.find(piece => piece.active && piece.position === selectedPos)
            if (foundPiece && (foundPiece.color === turn.color || gameMode === Gamemodes.DUMMY_BOT)) {
                setSelectedPiece(foundPiece)
            } else {
                setSelectedPiece(null)
            }
        }
    }, [selectedPos, pieces, turn, myColor, gameMode])

    // this useEffect generates all the possible moves
    const isClassic = gameMode.includes('classic') 
    const jsonPieces = JSON.stringify(pieces)
    useEffect(() => {
        setCanMovePos(GetCanMovePos(pieces, selectedPiece, myColor, turn.phase, isClassic))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jsonPieces, selectedPiece, myColor, turn, isClassic])
    
    useEffect(() => {
        setAllowMove(true) // make sure to allow player to move after canMove array updates
    }, [canMovePos])

    // update board shake
    useEffect(() => {
        if (boardShakeInfo.force > 0) {
            setBoardShakeStatus('shake')
        }
    }, [boardShakeInfo.angle, boardShakeInfo.force, setBoardShakeStatus])

    // update screen shake
    useEffect(() => {
        if (screenShakeInfo.force > 0) {
            setScreenShakeStatus('shake')
        }
    }, [screenShakeInfo.angle, screenShakeInfo.force, setScreenShakeStatus])

    // update secondary screen shake
    useEffect(() => {
        if (secondaryShakeInfo.force > 0) {
            setSecondaryShakeStatus('shake')
        }
    }, [secondaryShakeInfo.angle, secondaryShakeInfo.force, setSecondaryShakeStatus])

    // update pawn promotion UI
    useEffect(() => {
        if ((turn.color === myColor || gameMode === Gamemodes.DUMMY_BOT) && turn.phase === Phases.PROMOTE && selectedPiece) {
            const timer = setTimeout(() => {
                setPromotionSet(skins[myLayout[selectedPiece.name]].set)
                return () => {
                    clearTimeout(timer)
                };
            }, 250);
        } 
    }, [turn, myColor, myLayout, selectedPiece, gameMode])

    // deselect selected piece during buy phase
    useEffect(() => {
        if (turn.phase === Phases.MOVE) {
            setSelectedPos('')
            setHoveredPos('')
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [turn])

    // update shiver effect
    useEffect(() => {
        if (turn.phase === Phases.FIRE) {
            if (selectedPiece) {
                updateShiver(pieces, selectedPiece!.idx, pieceCache, setPieceCache, turn.phase, canShoot)
            }
        } else {
            const chessGrid = GenerateChessGrid(pieces, myColor)
            const checked = IsChecked(chessGrid, myColor, true) 
            const opponentChecked = IsChecked(chessGrid, myColor === Colors.WHITE ? Colors.BLACK : Colors.WHITE, true)
            for (const piece of pieces) {
                pieceCache[piece.idx].shiver = false
            }
            if (checked) {
                const myKingIdx: number = myColor === Colors.WHITE ? PieceIdxes.WHITE_KING : PieceIdxes.BLACK_KING
                pieceCache[myKingIdx].shiver = true
            } else if (opponentChecked) {
                const opponentKingIdx: number = myColor === Colors.WHITE ? PieceIdxes.BLACK_KING : PieceIdxes.WHITE_KING
                pieceCache[opponentKingIdx].shiver = true
            } 
            setPieceCache(pieceCache)
        }
    }, [canShoot, pieceCache, pieces, selectedPiece, turn.phase, myColor, setPieceCache, isClassic])
    
    const backToMenuOut = () => {
        setGameStage(1) 
        animation.TransitionOut()
        const timer = setTimeout(() => {
            backToMenu()
            return () => {
                clearTimeout(timer)
            };
        }, 600);
    }

    const changeModeOut = () => {
        setGameStage(1) 
        animation.TransitionOut()
        const timer = setTimeout(() => {
            changeMode()
            return () => {
                clearTimeout(timer)
            };
        }, 600);
    }

    const findMatchOut = (mode: string) => {
        setGameStage(1) 
        animation.TransitionOut()
        const timer = setTimeout(() => {
            findMatch(mode)
            return () => {
                clearTimeout(timer)
            };
        }, 600);
    } 

    const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
        if (draggedEquipmentAlias !== null) {
            const { clientX, clientY } = event;
            setMousePos({x: clientX - window.innerWidth / 2, y: clientY - window.innerHeight / 2})
        } else if (turn.phase === Phases.FIRE && (turn.color === myColor || gameMode === Gamemodes.DUMMY_BOT) && selectedPiece) {
            const { clientX, clientY } = event;
            
            const board = document.getElementById('board')
            const boardRect = board!.getBoundingClientRect()

            const [x, y] = PosToCoord(selectedPiece.position, myColor)

            const cellSize = boardRect.width / 8

            const selectedPieceScreenX = boardRect.left + (x + 0.5) * cellSize
            const selectedPieceScreenY = boardRect.top + y * cellSize

            const shootAngle = Math.atan2(clientY - selectedPieceScreenY, clientX - selectedPieceScreenX);
            setShootAngle(shootAngle)
        } 
    };

    // handles firing during the firing phase 
    const handleOnClick = () => {
        if (turn.phase === Phases.FIRE && canShoot && selectedPiece && (selectedPiece.color === myColor || gameMode === Gamemodes.DUMMY_BOT)) {
            // screen shake
            if ((turn.color === myColor || gameMode === Gamemodes.DUMMY_BOT) && selectedPiece) { 
                if (gameEngine) { 
                    gameEngine.queueEvent({
                        type: 'fire',
                        selectedPieceIdx: selectedPiece.idx,
                        shootAngle: shootAngle
                    })
                }
                pieceFire(pieces, setPieces, selectedPiece.idx, gameMode === Gamemodes.DUMMY_BOT && selectedPiece.color !== myColor ? selectedPiece.weapon.charAt(0).toUpperCase() + selectedPiece.weapon.slice(1) :
                    myLayout[selectedPiece.weapon], shootAngle, setScreenShakeInfo, pieceCache, setPieceCache, setGameOverStatus, myIncome, setMyIncome, gameMode, myColor)
            }
            setCanShoot(false) 
            const nextTurn = getNextTurn(turn, gameMode, false) 
            setTurn(nextTurn)
            setSelectedPos('')
            setSelectedPiece(null)
        }
    };

    const addParticle = (x: number, y: number, speed: number, direction: number) => {
        const newParticle: Particle = {
            id: particleCounter++,
            x: x,
            y: y,
            speed: speed, 
            direction: direction
        };

        setParticles(prev => [...prev, newParticle]);

        // Remove the particle after 3 seconds
        setTimeout(() => {
            setParticles(prev => prev.filter(p => p.id !== newParticle.id));
        }, 1000);
    };

    if (isLoading) {
        return (
            <div className = "Screen"/>
        )
    }

    // creating the 8 by 8 chess board
    const cellComponents = () => {
        const newCells: React.ReactNode[][] = [];
        for (let i = 0; i < 8; i++) {
            newCells.push([]);
            for (let j = 0; j < 8; j++) {
                let cellId = i * 8 + j;
                newCells[i].push(
                    <Cell
                        key={cellId}
                        skinName={'Default Tile'}
                        color={ (i + j) % 2 === 0 ? Colors.WHITE : Colors.BLACK }
                        canMovePos={canMovePos[i][j]}
                        onClick={() => {
                            if (overlayUI !== 'none') return
                            if (turn.phase === Phases.MOVE && draggedEquipmentAlias !== null) {
                                buyDraggedEquipment()
                            } else if (turn.phase !== Phases.FIRE && turn.phase !== Phases.PROMOTE && (turn.color === myColor || gameMode === Gamemodes.DUMMY_BOT)) {
                                // this timer gives checked king time to reset animation before lifting it
                                clickCell(pieces, setPieces, selectedPiece, setSelectedPos, setSelectedPiece, canMovePos, j, i, myColor,
                                    turn, setTurn, pieceCache, setPieceCache, myIncome, setMyIncome, setBoardShakeInfo, setGameOverStatus, gameMode, allowMove, gameEngine)
                            }
                        }}
                        onHover={() => {
                            if (overlayUI !== 'none') return
                            setHoveredPos('')
                            if (turn.phase !== Phases.FIRE && turn.phase !== Phases.PROMOTE && (turn.color === myColor || gameMode === Gamemodes.DUMMY_BOT)) {
                                const pos = CoordToPos(j, i, myColor)
                                if (gameMode === Gamemodes.DUMMY_BOT && selectedPiece && selectedPiece.position !== pos) {
                                    for (const piece of pieces) {
                                        if (piece.position === pos) {
                                            if (piece.color !== selectedPiece.color)
                                            return
                                        }
                                    }
                                }
                                setHoveredPos(pos)
                                for (let i = 0; i < pieces.length; ++i) {
                                    if (pieces[i].active && (pieces[i].color === myColor || gameMode === Gamemodes.DUMMY_BOT) && pieces[i].position === pos) {
                                        sounds['pieceHover'].play()
                                        return
                                    }
                                }
                            }
                        }}
                    />
                );
            }
        }
        return newCells;
    };
    
    const pieceComponents = pieces.map((piece: PieceInfo, index: number) => {
        const key = `${myColor}-${index}`;
        return (
            <Piece key={key} 
                pieceInfo={piece} 
                pieceCache={pieceCache[index]}
                hoveredCellPos={hoveredPos} 
                selectedCellPos={selectedPos} 
                myColor={myColor}
                phase={turn.phase}
                turn={turn.color}
                shootAngle={piece.color === myColor || gameMode === Gamemodes.DUMMY_BOT ? shootAngle : opponentShootAngle}
                layout={myColor === piece.color ? myLayout : opponentLayout}
                canShoot={canShoot}
                setCanShoot={setCanShoot}
                addParticle={addParticle}
                gameMode={gameMode}/>
        );
    })

    function buyDraggedEquipment() {
        if (!draggedEquipmentAlias) {
            setAllowMove(true)
            return
        }
        let found = false
        for (const piece of pieces) {
            if ((piece.color === myColor || gameMode === Gamemodes.DUMMY_BOT) && piece.active && piece.position === hoveredPos) {
                const canBuy = buyEquipment(pieces, setPieces, piece, draggedEquipmentAlias.equipment as Equipments, myIncome, setMyIncome, gameMode)
                if (gameEngine && canBuy) {
                    gameEngine.queueEvent({
                        type: 'buy',
                        selectedPieceIdx: piece.idx,
                        equipment: draggedEquipmentAlias.equipment
                    })
                }
                found = true
                break
            }
        }
        if (!found) {
            setAllowMove(true)
        }
        setDraggedEquipmentAlias(null)
        setMousePos(null)
    }
    
    const uiBlocked = overlayUI !== 'none' || turn.phase === Phases.FIRE || gameOverStatus.condition !== ''
    return (
        <motion.div className = "Screen" style={{position: 'absolute', overflow: 'hidden'}} onMouseMove={handleMouseMove} onClick={handleOnClick} onMouseUp={()=>{
            // we have 2 drag modes
            if (draggedEquipmentAlias && Date.now() - draggedEquipmentAlias.dragStart > 100) {
                buyDraggedEquipment()
            }
        }}>
            <motion.div className='ScreenShakeWrapper' 
                        initial = "idle"
                        animate = { secondaryShakeStatus }
                        variants = { secondaryShakeAnimation }
                        onAnimationComplete = {() => {
                            if (secondaryShakeStatus !== 'idle') {
                                setSecondaryShakeStatus('idle')
                                setSecondaryShakeInfo({angle: 0, force: 0})
                            }
                        }}>
            <motion.div className='ScreenShakeWrapper' 
                        initial = "idle"
                        animate = { screenShakeStatus }
                        variants = { screenShakeAnimation }
                        onAnimationComplete = {() => {
                            if (screenShakeStatus !== 'idle') {
                                setScreenShakeStatus('idle')
                                setScreenShakeInfo({angle: 0, force: 0})
                            }
                        }}>
                <motion.div style={{display: 'flex', flexDirection: 'column', position: 'absolute', scale: 0.9, alignItems: 'center', marginRight: '120vh', marginBottom: '62vh', zIndex: 0}}
                            initial = "initial"
                            animate = { animation.opponentAvatarStatus }
                            variants = { animation.opponentAvatarAnimation }>
                        <CharacterHandler name={gameMode === Gamemodes.DUMMY_BOT ? Characters.DUMMY : opponentCharacter} expression={gameMode === Gamemodes.DUMMY_BOT ? 'idle' : opponentExpression} setExpression={setOpponentExpression} allowAnnoy={false} smallBorder={false}/>
                        <motion.div className="NormalText" style={{fontSize: '3.5vh', marginTop: '0.5vh'}}>{opponentUsername}</motion.div>
                    </motion.div>
                <motion.div style={{display: 'flex', flexDirection: 'column', position: 'absolute', scale: 0.9, alignItems: 'center', marginLeft: '120vh', marginTop: '52.25vh', zIndex: 0}}
                            initial = "initial"
                            animate = { animation.myAvatarStatus }
                            variants = { animation.myAvatarAnimation }>
                    <CharacterHandler name={myCharacter} expression={myExpression} setExpression={setMyExpression} allowAnnoy={false} smallBorder={false}/>
                    <motion.div className="NormalText" style={{fontSize: '3.5vh', marginTop: '0.5vh'}}>{myUsername}</motion.div>
                    <motion.div className = "NormalText" style={{fontSize: '3vh', height: '3.5vh'}}
                                        initial = "initial"
                                        animate = { animation.myIncomeStatus }
                                        variants = { animation.myIncomeAnimation }>
                        {gameMode.includes('classic') ? '' : `$ ${displayedIncome}`}
                    </motion.div>

                    <ExpressionSpammer character={myCharacter} currentExpression={myExpression} setCurrentExpression={setMyExpression} isBot={gameMode.includes('bot')} gameEngine={gameEngine}/>
                </motion.div>
                <motion.div className = "BoardContainer">
                    <Shop 
                        myTurn={ turn.color === myColor }
                        phase={ turn.phase }
                        myIncome={ myIncome }
                        myLayout={ myLayout }
                        gameOverStatus={ gameOverStatus }
                        draggedEquipmentAlias = { draggedEquipmentAlias }
                        setDraggedEquipmentAlias = { setDraggedEquipmentAlias }
                        isClassic={ gameMode.includes('classic') }
                        isBasic={true}
                        setAllowMove={setAllowMove}/>
                    <motion.div 
                            initial = "initial"
                            animate = { boardShakeStatus }
                            variants = { boardShakeAnimation }
                            onAnimationComplete = {() => {
                                if (boardShakeStatus !== 'idle') {
                                    setBoardShakeStatus('idle')
                                    setBoardShakeInfo({angle: 0, force: 0})
                                }
                            }}>
                        <motion.div className = "Board" style={{zIndex: 1}} id='board'
                                    initial = "initial"
                                    animate = { animation.boardStatus }
                                    variants = { animation.boardAnimation }
                                    onMouseLeave = {() => {setHoveredPos('')}}>
                                    { cellComponents() }
                                    { pieceComponents }
                                    {
                                        gameMode !== Gamemodes.DUMMY_BOT && 
                                        <GameTimer timePercentage={turn.phase === Phases.FIRE ? (turnTimer - 1) / 5 : (turnTimer - 1) / 15}/>
                                    }
                            <ParticleGenerator particles={particles} />
                        </motion.div>
                    </motion.div>
                    <Shop 
                        myTurn={ turn.color === myColor }
                        phase={ turn.phase }
                        myIncome={ myIncome }
                        myLayout={ myLayout }
                        gameOverStatus={ gameOverStatus }
                        draggedEquipmentAlias = { draggedEquipmentAlias }
                        setDraggedEquipmentAlias = { setDraggedEquipmentAlias }
                        isClassic={ gameMode.includes('classic') }
                        isBasic={false}
                        setAllowMove={setAllowMove}/>
                </motion.div>

                <StatusDisplay myColor={myColor} turn={turn} gameOverStatus={gameOverStatus} endTurn={()=>{
                    if (overlayUI !== 'none') return
                    setSelectedPos('')
                    if (gameMode === Gamemodes.DUMMY_BOT) {
                        setTurn({color: turn.color, phase: Phases.MOVE})
                    } 
                }}/>
                
                <motion.div className='OverlayUIButton' style={{right: '1.5vh', top: '1.5vh'}} 
                            initial = "initial"
                            animate = { animation.settingsStatus }
                            variants = { animation.settingsAnimation }
                            onClick={()=>{
                                if (uiBlocked) return
                                sounds['select'].play()
                                if (overlayUI === 'none') {
                                    setOverlayUI('settings')
                                }
                            }}
                            onMouseEnter={()=>{if (uiBlocked) return; sounds['hover'].play()}}
                            whileHover={{ scale: uiBlocked ? 1 : 1.1 }}>
                    <motion.img className='SettingsButton'/>
                </motion.div>
                <motion.div className='OverlayUIButton' style={{right: '1.5vh', top: '9.75vh'}} 
                            initial = "initial"
                            animate = { animation.surrenderStatus }
                            variants = { animation.surrenderAnimation }
                            onClick={()=>{
                                if (uiBlocked) return
                                sounds['select'].play()
                                if (overlayUI === 'none') {
                                    setOverlayUI('surrender')
                                }
                            }}
                            onMouseEnter={()=>{if (uiBlocked) return; sounds['hover'].play()}}
                            whileHover={{ scale: uiBlocked ? 1 : 1.1 }}>
                    <motion.img className='SurrenderButton'/>
                </motion.div>
                <motion.div className='OverlayUIButton' style={{right: '1.5vh', top: '18vh'}} 
                            initial = "initial"
                            animate = { animation.drawStatus }
                            variants = { animation.drawAnimation }
                            onClick={()=>{
                                if (uiBlocked || lastDrawTime >= 0) return
                                sounds['select'].play()
                                if (overlayUI === 'none') {
                                    setOverlayUI('draw')
                                }
                            }}
                            onMouseEnter={()=>{if (uiBlocked || lastDrawTime >= 0) return; sounds['hover'].play()}}
                            whileHover={{ scale: uiBlocked || lastDrawTime >= 0 ? 1 : 1.1 }}>
                    <motion.img className='DrawButton' style={{opacity: lastDrawTime >= 0 ? 0.25 : 1}}/>
                    {lastDrawTime >= 0 && <motion.div className='CountdownTimer'>{lastDrawTime}</motion.div>}
                </motion.div>

                {
                    animation.boardStatus !== 'exit' && 
                    <MySpeechBubble myDrawRequest={myDrawRequest} setMyDrawRequest={setMyDrawRequest} mySpeech={mySpeech} setMySpeech={setMySpeech} setGameOverStatus={setGameOverStatus} gameEngine={gameEngine}/>
                }
                {
                    animation.boardStatus !== 'exit' && 
                    <OpponentSpeechBubble myDrawRequest={opponentDrawRequest} setMyDrawRequest={setOpponentDrawRequest} mySpeech={opponentSpeech} setMySpeech={setOpponentSpeech} setGameOverStatus={setGameOverStatus} gameEngine={gameEngine}/>
                }
            </motion.div>

            <motion.div className='EndTurnTimer' style={{pointerEvents: 'none'}}
                        initial="initial"
                        animate={endTurnTimerAnimation.status}
                        variants={endTurnTimerAnimation.statusAnimation}
                        onAnimationComplete={() => {
                            if (endTurnTimerAnimation.status === 'update') {
                                endTurnTimerAnimation.setStatus('exit')
                            } else if (endTurnTimerAnimation.status === 'exit') {
                                endTurnTimerAnimation.setStatus('initial')
                            }
                        }}>
                {Math.floor(turnTimer)}
            </motion.div>
            </motion.div>
            {draggedEquipmentAlias && mousePos && 
                <img className = "DraggedShopTile" 
                    style={{transform: `scale(${skins[myLayout[draggedEquipmentAlias.equipment]].equipmentDetail!.avatarScale}) 
                                        translateX(${mousePos.x / skins[myLayout[draggedEquipmentAlias.equipment]].equipmentDetail!.avatarScale}px) 
                                        translateY(${mousePos.y / skins[myLayout[draggedEquipmentAlias.equipment]].equipmentDetail!.avatarScale}px)`}}
                    alt={'missing'}
                    draggable={false}
                    src = {skins[myLayout[draggedEquipmentAlias.equipment]].equipmentDetail!.avatar}/>
            }
            {
                gameOverStatus.condition !== '' && 
                <GameOverDisplay myColor={myColor} gameOverStatus={gameOverStatus} gameMode={gameMode}
                                myCredits={myCredits} opponentCredits={opponentCredits}
                                backToMenu={backToMenuOut} changeMode={changeModeOut} findMatch={findMatchOut}/>
            }
            {
                overlayUI === 'settings' && 
                // gameMode !== Gamemodes.DUMMY_BOT && gameMode !== Gamemodes.STANDARD_BOT && gameMode !== Gamemodes.STANDARD_BOT &&
                <Settings overlayUI={ overlayUI } setOverlayUI={ setOverlayUI } gameEngine={ gameEngine } changeMode={backToMenuOut} setGameOverStatus={setGameOverStatus}/>
            }
            {
                overlayUI === 'surrender' && 
                // gameMode !== Gamemodes.DUMMY_BOT && gameMode !== Gamemodes.STANDARD_BOT && gameMode !== Gamemodes.STANDARD_BOT &&
                <Surrender myColor={ myColor } overlayUI={ overlayUI } setOverlayUI={ setOverlayUI } setGameOverStatus={ setGameOverStatus } setMySpeech={setMySpeech} gameEngine={ gameEngine }/>
            }
            {
                overlayUI === 'draw' && 
                // gameMode !== Gamemodes.DUMMY_BOT && gameMode !== Gamemodes.STANDARD_BOT && gameMode !== Gamemodes.STANDARD_BOT &&
                <Draw overlayUI={ overlayUI } setOverlayUI={ setOverlayUI } setMyDrawRequest={setMyDrawRequest} setLastDrawTime={setLastDrawTime} gameEngine={ gameEngine }/> 
            }
            <Promotion pieces={ pieces } setPieces={ setPieces } myColor={ myColor } gameMode={gameMode}
                                selectedIdx={ selectedPiece ? selectedPiece.idx : -1 } setSelectedPiece={ setSelectedPiece }
                                promotionSet={ promotionSet } setPromotionSet={ setPromotionSet } turn={turn} setTurn={ setTurn } 
                                setGameOverStatus={ setGameOverStatus } 
                                gameEngine={ gameEngine }/>
        </motion.div>
    );
}

export default Game;