import { firestore, cancelQueue, hostLobby, updateLobby, kickOpponent, startCustomMatch } from "./CreateFirebaseEngine";
import { doc, onSnapshot } from "firebase/firestore";

class MatchMakingEngine {
    #user_id;
    #unsub;
    inQueue;
    setMatchId;

    constructor(user_id, setOpponent, setOpponentUsername, setOpponentCharacter, setMatchId, setEnableEquipments, setMyColor, setStatus, isHost) {
        this.#user_id = user_id;
        this.setMatchId = setMatchId;

        this.#unsub = onSnapshot(doc(firestore, "users/" + this.#user_id), (doc) => {
            if (doc.exists) {
                const data = doc.data();
                const matchMaking = data['matchMaking']
                const matchId = matchMaking['matchId']
                const opponentId = matchMaking['opponentId']
                const opponentUsername = matchMaking['opponentUsername']
                const opponentCharacter = matchMaking['opponentAvatar']
                const customMode = matchMaking["customMode"]
                const hostColor = matchMaking["hostColor"]
                
                if (opponentId === '') {
                    setOpponent('')
                } else {
                    setStatus(data['status'])
                    setOpponent(opponentId)
                    setOpponentUsername(opponentUsername)
                    setOpponentCharacter(opponentCharacter)

                    if (!isHost) {
                        setMatchId(matchId)
                        setEnableEquipments(customMode === 'random' ? 'random' : (customMode === 'standard' ? 'enable' : 'disable'))
                        setMyColor(hostColor === 'random' ? 'random' : (hostColor === 'white' ? 'black' : 'white'))
                    }
                }
            }
        });
    }

    async exitQueue() {
        try {
            await cancelQueue();
        } catch (error) {
            throw new Error(error.code)
        }
    }

    // returns lobby id for custom game if successful
    async hostLobby(selectedColor, enableEquipments) {
        try {
            await hostLobby({
                hostColor: selectedColor,
                gamemode: enableEquipments === 'random' ? 'random' : (enableEquipments === 'enable' ? 'standard' : 'classic'), 
            }).then((result) => {
                this.setMatchId(result.data.data.matchId)
            });
        } catch (error) {
            console.log(error)
            throw new Error(error.code)
        }
    }

    async updateLobby(selectedColor, enableEquipments) {
        try {
            await updateLobby({
                hostColor: selectedColor,
                gamemode: enableEquipments === 'random' ? 'random' : (enableEquipments === 'enable' ? 'standard' : 'classic'), 
            })
        } catch (error) {
            console.log(error)
            throw new Error(error.code)
        }
    }

    async kickOpponent() {
        try {
            await kickOpponent()
        } catch (error) {
            console.log(error)
            throw new Error(error.code)
        }
    }

    async startCustomMatch() {
        try {
            await startCustomMatch()
        } catch (error) {
            console.log(error)
            throw new Error(error.code)
        }
    }

    destructor() {
        this.#unsub();
    }
}

export default MatchMakingEngine;