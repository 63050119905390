import { Colors, Equipments, Pieces } from "../../utils/Enums";
import { PieceInfo } from "./Piece";

// this is used for testing
export function initializePieces(): PieceInfo[] {
    return [
        {name: 'rook2', type: Pieces.ROOK, color: Colors.BLACK, position: 'A8', active: true, idx: 0, weapon: Equipments.KNIFE, vest: false, canCastle: true},
        {name: 'knight2', type: Pieces.KNIGHT, color: Colors.BLACK, position: 'B8', active: true, idx: 1, weapon: Equipments.KNIFE, vest: false},
        {name: 'bishop2', type: Pieces.BISHOP, color: Colors.BLACK, position: 'C8', active: true, idx: 2, weapon: Equipments.KNIFE, vest: false},
        {name: 'queen', type: Pieces.QUEEN, color: Colors.BLACK, position: 'D8', active: true, idx: 3, weapon: Equipments.KNIFE, vest: false},
        {name: 'king', type: Pieces.KING, color: Colors.BLACK, position: 'E8', active: true, idx: 4, weapon: Equipments.KNIFE, vest: false, canCastle: true},
        {name: 'bishop1', type: Pieces.BISHOP, color: Colors.BLACK, position: 'F8', active: true, idx: 5, weapon: Equipments.KNIFE, vest: false},
        {name: 'knight1', type: Pieces.KNIGHT, color: Colors.BLACK, position: 'G8', active: true, idx: 6, weapon: Equipments.KNIFE, vest: false},
        {name: 'rook1', type: Pieces.ROOK, color: Colors.BLACK, position: 'H8', active: true, idx: 7, weapon: Equipments.KNIFE, vest: false, canCastle: true},
        {name: 'pawn8', type: Pieces.PAWN, color: Colors.BLACK, position: 'A7', active: true, idx: 8, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn7', type: Pieces.PAWN, color: Colors.BLACK, position: 'B7', active: true, idx: 9, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn6', type: Pieces.PAWN, color: Colors.BLACK, position: 'C7', active: true, idx: 10, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn5', type: Pieces.PAWN, color: Colors.BLACK, position: 'D7', active: true, idx: 11, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn4', type: Pieces.PAWN, color: Colors.BLACK, position: 'E7', active: true, idx: 12, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn3', type: Pieces.PAWN, color: Colors.BLACK, position: 'F7', active: true, idx: 13, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn2', type: Pieces.PAWN, color: Colors.BLACK, position: 'G7', active: true, idx: 14, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn1', type: Pieces.PAWN, color: Colors.BLACK, position: 'H7', active: true, idx: 15, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'rook1', type: Pieces.ROOK, color: Colors.WHITE, position: 'A1', active: true, idx: 16, weapon: Equipments.KNIFE, vest: false, canCastle: true},
        {name: 'knight1', type: Pieces.KNIGHT, color: Colors.WHITE, position: 'B1', active: true, idx: 17, weapon: Equipments.KNIFE, vest: false},
        {name: 'bishop1', type: Pieces.BISHOP, color: Colors.WHITE, position: 'C1', active: true, idx: 18, weapon: Equipments.KNIFE, vest: false},
        {name: 'queen', type: Pieces.QUEEN, color: Colors.WHITE, position: 'D1', active: true, idx: 19, weapon: Equipments.KNIFE, vest: false},
        {name: 'king', type: Pieces.KING, color: Colors.WHITE, position: 'E1', active: true, idx: 20, weapon: Equipments.KNIFE, vest: false, canCastle: true},
        {name: 'bishop2', type: Pieces.BISHOP, color: Colors.WHITE, position: 'F1', active: true, idx: 21, weapon: Equipments.KNIFE, vest: false},
        {name: 'knight2', type: Pieces.KNIGHT, color: Colors.WHITE, position: 'G1', active: true, idx: 22, weapon: Equipments.KNIFE, vest: false},
        {name: 'rook2', type: Pieces.ROOK, color: Colors.WHITE, position: 'H1', active: true, idx: 23, weapon: Equipments.KNIFE, vest: false, canCastle: true},
        {name: 'pawn1', type: Pieces.PAWN, color: Colors.WHITE, position: 'A2', active: true, idx: 24, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn2', type: Pieces.PAWN, color: Colors.WHITE, position: 'B2', active: true, idx: 25, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn3', type: Pieces.PAWN, color: Colors.WHITE, position: 'C2', active: true, idx: 26, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn4', type: Pieces.PAWN, color: Colors.WHITE, position: 'D2', active: true, idx: 27, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn5', type: Pieces.PAWN, color: Colors.WHITE, position: 'E2', active: true, idx: 28, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn6', type: Pieces.PAWN, color: Colors.WHITE, position: 'F2', active: true, idx: 29, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn7', type: Pieces.PAWN, color: Colors.WHITE, position: 'G2', active: true, idx: 30, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0},
        {name: 'pawn8', type: Pieces.PAWN, color: Colors.WHITE, position: 'H2', active: true, idx: 31, weapon: Equipments.KNIFE, vest: false, enpassantStage: 0}]
}

export interface PieceCache {
    stabInfo: {
        destroyedAngle: number;
        launchForce: number;
        xRotate: number;
        fromAbove: boolean;
    } 
    shootInfo: {
        recoilAngle: number;
        recoilForce: number;
    },
    shotInfo: {
        destroyedAngle: number;
        launchForce: number;
        xRotate: number;
        gunHit: boolean;
    },
    shieldInfo: {
        destroyedAngle: number;
        launchForce: number;
        gunHit: boolean;
    }
    shiver: boolean;
}

// mainly contains information for animations
export function initializePieceCache(): PieceCache[] {
    const cache = [];

    for (let i = 0; i < 32; i++) {
        cache.push({ 
            stabInfo: {destroyedAngle: 999, launchForce: 999, xRotate: 0, fromAbove: false}, 
            shootInfo: {recoilAngle: 999, recoilForce: 999},
            shotInfo: {destroyedAngle: 999, launchForce: 999, xRotate: 0, gunHit: false}, 
            shieldInfo: {destroyedAngle: 999, launchForce: 999, gunHit: false}, 
            shiver: false });
    }

    return cache;
}

// request object to be sent to backend on move turns
export interface MoveRequestObject {
    selectedPieceIdx: number, 
    destination: string, 
    buys: Record<number, string>;
    promoteTo: string
} 