import React from 'react';
import { motion } from 'framer-motion';

interface ParticleProps {
    id: number;
    x: number;
    y: number;
    speed: number;
    direction: number;
}

const ParticleComponent: React.FC<ParticleProps> = ({ x, y, speed, direction }) => {
    const viewportFactor = 70 / 8 * window.innerHeight / 100;
    const xDist = Math.cos(direction) * speed * viewportFactor;

    // Calculate the y-coordinate for the drop
    const dropDistance = 10 * speed + Math.abs(Math.sin(direction) * speed); 
    const riseHeight = 12.5 * speed + Math.abs(Math.sin(direction) * speed); 
    const size = 0.4 + Math.random() * 0.2

    return (
        <motion.div 
            initial={{ opacity: 1, x: x * viewportFactor, y: y * viewportFactor }}
            animate={{
                opacity: [1, 1, 0],
                x: x * viewportFactor + xDist,
                y: [y * viewportFactor, y * viewportFactor - riseHeight, y * viewportFactor + dropDistance]
            }}
            transition={{
                duration: 1,
                ease: ["easeOut", "easeIn", "easeIn"] // Easing for initial rise, peak, and fall
            }}
            style={{
                position: 'absolute', 
                x: x * viewportFactor,
                y: y * viewportFactor,
                width: `${size}vh`,
                height: `${size}vh`,
                backgroundColor: '#6B6B6B',
                borderRadius: '25%',
                zIndex: 100,
                pointerEvents: 'none'
            }}
        />
    );
};

export default ParticleComponent;