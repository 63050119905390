import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Game.css';
import { useSpeechBubbleAnimationStatus } from './Animation';
import sounds from '../../sounds';
import GameEngine from '../../backend/GameEngine';
import { Colors } from '../../utils/Enums';

interface Props {
    myDrawRequest: boolean;
    mySpeech: string;
    setMySpeech: (mySpeech: string) => void;
    setMyDrawRequest: (draw: boolean) => void;
    setGameOverStatus: (status: {winner: Colors, condition: string}) => void
    gameEngine: GameEngine | null;
}

const speechTexts: string[] = ["Lets make it a draw?", "Agree to a draw?", "Call it a draw?"]
const rejectionMessages: string[] = ["No draw, thanks.", "Let's keep playing.", "No, let's continue.", "Declined, let's play on.", "I prefer to play on."]
const acceptionMessages: string[] = ["It's a draw!", "Draw accepted.", "Okay.", "Sure, we can draw.", "Draw sounds good."]


const MySpeechBubble: React.FC<Props> = ({ myDrawRequest, setMyDrawRequest, mySpeech, setMySpeech }) => {
    const [targetText, setTargetText] = useState<string>('')
    const [displayedText, setDisplayedText] = useState<string>('')
    const [textTimer, setTextTimer] = useState<number>(0)
    const [canDisplayText, setCanDisplayText] = useState<boolean>(false)
    const animation = useSpeechBubbleAnimationStatus(true)
    
    useEffect(() => {
        if (myDrawRequest) {
            setDisplayedText('')
            setTargetText(speechTexts[Math.floor(Math.random() * speechTexts.length)])
            animation.setStatus('enter')
            setTextTimer(10)
            const timer = setTimeout(() => {
                setCanDisplayText(true)
                return () => {
                    clearTimeout(timer)
                };
            }, 250);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myDrawRequest])

    useEffect(() => {
        if (mySpeech !== '') {
            setDisplayedText('')
            setTargetText(mySpeech)
            animation.setStatus('enter')
            setTextTimer(5)
            const timer = setTimeout(() => {
                setCanDisplayText(true)
                return () => {
                    clearTimeout(timer)
                };
            }, 250);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mySpeech])

    useEffect(() => {
        if (textTimer > 0) {
            const timer = setTimeout(() => {
                setTextTimer(textTimer - 1)
                return () => {
                    clearTimeout(timer)
                };
            }, 1000);
        } else {
            setMyDrawRequest(false)
            setCanDisplayText(false)
            setMySpeech('')
            animation.setStatus('exit')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textTimer, setMyDrawRequest, setMySpeech])

    useEffect(() => {
        if (textTimer > 0 && displayedText.length < targetText.length && canDisplayText) {
            const timer = setTimeout(() => {
                // Add one character from targetText to displayedText
                setDisplayedText(displayedText => displayedText + targetText[displayedText.length]);
            }, 75); 
            return () => clearTimeout(timer);
        }
    }, [textTimer, displayedText, targetText, canDisplayText]);

    useEffect(() => {
        if (displayedText.length < targetText.length && canDisplayText && displayedText.length % 2 === 0) {
            sounds['default_guy' + (Math.floor(Math.random() * 2) + 1)].play();
        }
    }, [displayedText, targetText, canDisplayText]);
    
    return (
        <motion.div className='MySpeechBubble'
                    initial='initial' 
                    animate={animation.status} 
                    variants={animation.statusAnimation}>
            <motion.div className='HorizontalDrawPanel'>
                <motion.div className='SmallText'>{displayedText}</motion.div>
            </motion.div>
        </motion.div>
    )
}

export const OpponentSpeechBubble: React.FC<Props> = ({ myDrawRequest, setMyDrawRequest, mySpeech, setMySpeech, setGameOverStatus, gameEngine }) => {
    const [targetText, setTargetText] = useState<string>('')
    const [displayedText, setDisplayedText] = useState<string>('')
    const [textTimer, setTextTimer] = useState<number>(0)
    const [canDisplayText, setCanDisplayText] = useState<boolean>(false)
    const animation = useSpeechBubbleAnimationStatus(false)

    useEffect(() => {
        if (myDrawRequest) {
            setDisplayedText('')
            setTargetText(speechTexts[Math.floor(Math.random() * 4)])
            animation.setStatus('enter')
            setTextTimer(10)
            const timer = setTimeout(() => {
                setCanDisplayText(true)
                return () => {
                    clearTimeout(timer)
                };
            }, 250);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myDrawRequest])

    useEffect(() => {
        if (mySpeech !== '') {
            setDisplayedText('')
            setTargetText(mySpeech)
            animation.setStatus('enter')
            setTextTimer(5)
            const timer = setTimeout(() => {
                setCanDisplayText(true)
                return () => {
                    clearTimeout(timer)
                };
            }, 250);
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mySpeech])

    useEffect(() => {
        if (textTimer > 0) {
            const timer = setTimeout(() => {
                setTextTimer(textTimer - 1)
                return () => {
                    clearTimeout(timer)
                };
            }, 1000);
        } else {
            setMyDrawRequest(false)
            setCanDisplayText(false)
            setMySpeech('')
            animation.setStatus('exit')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textTimer, setMyDrawRequest, setMySpeech])

    useEffect(() => {
        if (textTimer > 0 && displayedText.length < targetText.length && canDisplayText) {
            const timer = setTimeout(() => {
                // Add one character from targetText to displayedText
                setDisplayedText(displayedText => displayedText + targetText[displayedText.length]);
            }, 75); // Set this to control the speed of characters being added
            return () => clearTimeout(timer);
        }
    }, [textTimer, displayedText, targetText, canDisplayText]);

    useEffect(() => {
        if (displayedText.length < targetText.length && canDisplayText && displayedText.length % 2 === 0) {
            sounds['default_guy' + (Math.floor(Math.random() * 2) + 1)].play();
        }
    }, [displayedText, targetText, canDisplayText]);
    
    return (
        <motion.div className='OpponentSpeechBubble'
                    initial='initial' 
                    animate={animation.status} 
                    variants={animation.statusAnimation}>
            <motion.div className='HorizontalDrawPanel'>
                <motion.div className='SmallText'>{displayedText}</motion.div>
            </motion.div>
            {myDrawRequest && 
            <motion.div className='HorizontalDrawPanel'>
                <motion.div className='SmallText' style={{textDecoration: 'underline', marginRight: '3vh'}}
                            whileHover={{ scale: 1.1 }}
                            onMouseEnter={()=>{
                                sounds['hover'].play()
                            }}
                            onClick={()=>{
                                if (!canDisplayText) return
                                setMySpeech(acceptionMessages[Math.floor(Math.random() * acceptionMessages.length)])
                                setMyDrawRequest(false)
                                setGameOverStatus({winner: Colors.NONE, condition: 'Draw'})
                                animation.setStatus('exit')
                                if (gameEngine) {
                                    gameEngine.acceptDrawBackend()
                                }
                            }}>
                    Accept</motion.div>
                <motion.div className='SmallText' style={{textDecoration: 'underline'}}
                            whileHover={{ scale: 1.1 }}
                            onMouseEnter={()=>{
                                sounds['hover'].play()
                            }}
                            onClick={()=>{
                                if (!canDisplayText) return
                                setMySpeech(rejectionMessages[Math.floor(Math.random() * rejectionMessages.length)])
                                setMyDrawRequest(false)
                                animation.setStatus('exit')
                                if (gameEngine) {
                                    gameEngine.rejectDrawBackend()
                                }
                            }}>
                    Reject</motion.div>
            </motion.div>
            }
        </motion.div>
    )
}

export default MySpeechBubble;