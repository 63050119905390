import React, { useState } from "react";
import { motion } from "framer-motion";

import '../../utils/Common.css';
import "./Menu.css";
import "../origin/Origin.css";

import * as ScreenAnimation from './Animation';
import CharacterHandler from "../../utils/CharacterHandler";
import { Characters } from "../../utils/Enums";
import { getAuth } from "firebase/auth";
import CharacterSelector from "./CharacterSelector";
import CwgButton from "../../utils/CwgButton";
import ImageButton from "../../utils/ImageButton";
import images from "../../images";

interface Props {
    setAlertMessage: (alert: string) => void;
    volumeSettingOn: boolean;
    setVolumeSettingOn: (status: boolean) => void;
    LogOut: () => void;
    ToMode: () => void;
    ToGatcha: () => void;
    ToCustomize: () => void;
    character: Characters;
    setCharacter: (avatar: Characters) => void;
    allCharacters: Characters[];
    knuggets: number;
    setKnuggets: (newNuggets: number) => void;
}

const Menu: React.FC<Props> = ({ setAlertMessage, volumeSettingOn, setVolumeSettingOn, LogOut, ToMode, ToCustomize, ToGatcha, character, setCharacter, allCharacters, knuggets, setKnuggets }) => {
    const animation = ScreenAnimation.useAnimationStatus();
    const currentUser = getAuth().currentUser
    const [characterExpression, setCharacterExpression] = useState<string>('idle')
    const [selectorOn, setSelectorOn] = useState<boolean>(false)

    function BeginLogOut() {
        animation.TransitionOut();
        const exitTimer = setTimeout(() => {
            LogOut();
            clearTimeout(exitTimer);
        }, 550);
    }

    function BeginSelectMode() {
        animation.TransitionOut();
        const exitTimer = setTimeout(() => {
            ToMode();
            clearTimeout(exitTimer);
        }, 550);
    }

    function BeginCustomizeMode() {
        animation.TransitionOut();
        const exitTimer = setTimeout(() => {
            ToCustomize();
            clearTimeout(exitTimer);
        }, 550);
    }

    function BeginGatchaMode() {
        animation.TransitionOut();
        const exitTimer = setTimeout(() => {
            ToGatcha();
            clearTimeout(exitTimer);
        }, 550);
    }

    return (
        <motion.div className = "Screen" 
                    initial='initial' 
                    animate={animation.fadeStatus} 
                    variants={animation.fadeStatusAnimation}>

            <motion.div className = "HeadTitle" 
                        initial='initial' 
                        animate={animation.titleStatus}
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') { animation.setTitleStatus('idle') }
                        }}>
                Chess with Guns
            </motion.div>

            <motion.div className = "Subtitle" 
                        initial='initial' 
                        animate={animation.subtitleStatus}
                        variants={animation.subtitleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.subtitleStatus === 'enter') {
                                animation.setSubtitleStatus('idle')
                            }
                        }}>
                Finally, chess have received an update after a thousand years!
            </motion.div>
            <motion.div className = "Profile" 
                        initial='initial' 
                        animate={animation.profileStatus}
                        variants={animation.profileStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.profileStatus === 'enter') { animation.setProfileStatus('idle') }
                        }}>
                
                <CharacterHandler name={character} expression={characterExpression} setExpression={setCharacterExpression} allowAnnoy={true} smallBorder={true}/>
                <CwgButton 
                    style={{fontSize: '2.5vh', marginTop: '0.5vh', marginLeft: '28vh'}}
                    text={'SELECT'} 
                    recoverDuration={1000}
                    onClick={() => { 
                        animation.setSelectorStatus('enter')
                        setSelectorOn(true)
                    }} 
                    allowClick={animation.fadeStatus !== 'exit' && !selectorOn}/>
                <motion.div className = "Username" style={{scale: 1.25, marginBottom: '1vh', marginTop: '-2vh'}}>{currentUser ? currentUser.displayName : "??????????????"}</motion.div>
                <motion.div className = "TextSmall" style={{scale: 1.25}}>Scrubby</motion.div>
            </motion.div>
            
            <CwgButton 
                initial='initial'  
                animate={animation.startButtonStatus}
                variants={animation.startButtonStatusAnimation}
                setAnimation={animation.setStartButtonStatus}
                hasIdle={true}
                text={'START'} 
                recoverDuration={2000}
                onClick={() => { BeginSelectMode()  }} 
                allowClick={animation.fadeStatus !== 'exit'}/>
            
            <CwgButton 
                initial='initial' 
                animate={animation.customizeButtonStatus}
                variants={animation.customizeButtonStatusAnimation}
                setAnimation={animation.setCustomizeButtonStatus}
                hasIdle={true}
                text={'LAYOUT'} 
                recoverDuration={2000}
                onClick={() => { BeginCustomizeMode()  }} 
                allowClick={animation.fadeStatus !== 'exit'}/>

            <CwgButton 
                initial='initial' 
                animate={animation.gachaButtonStatus}
                variants={animation.gachaButtonStatusAnimation}
                setAnimation={animation.setGachaButtonStatus}
                hasIdle={true}
                text={'GACHA'} 
                recoverDuration={2000}
                onClick={() => { BeginGatchaMode()  }} 
                allowClick={animation.fadeStatus !== 'exit'}/>

            <CwgButton 
                initial='initial' 
                animate={animation.logoutButtonStatus}
                variants={animation.logoutButtonStatusAnimation}
                setAnimation={animation.setLogoutButtonStatus}
                hasIdle={true}
                text={'Logout'} 
                recoverDuration={2000}
                onClick={() => { BeginLogOut() }} 
                allowClick={animation.fadeStatus !== 'exit'}
                isBack={true}/>
            
            {   selectorOn && 
                <CharacterSelector oldCharacter={character} setCharacter={setCharacter} myCharacters={allCharacters} setCharacterSelector={setSelectorOn} animation={animation}/> 
            }

            <ImageButton 
                initial='initial' 
                animate={animation.volumeStatus}
                variants={animation.volumeAnimation}
                setAnimation={animation.setVolumeStatus}
                style={{position: 'absolute', width: '7.5vh', height: '7.5vh', right: '3vh', top: '3vh'}}
                hasIdle={true}
                onImg={images['settings_ui']} 
                recoverDuration={500}
                onClick={() => { setVolumeSettingOn(true) }} 
                allowClick={!volumeSettingOn}/>
        </motion.div>
    )
}

export default Menu;