import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AudioProvider } from './utils/AudioPlayers/AudioContext';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AudioProvider>
        <App />
    </AudioProvider>
);