import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Game.css';

import { Colors, Gamemodes } from '../../utils/Enums';
import images from '../../images';
import { getRankName } from '../../utils/MathUtil';
import { useGameOverAnimationStatus } from './Animation';
import sounds from '../../sounds';

interface Props {
    myColor: Colors; 
    gameOverStatus: {winner: Colors, condition: string};
    gameMode: string;
    myCredits: number;
    opponentCredits: number;
    backToMenu: () => void;
    changeMode: () => void;
    findMatch: (mode: string) => void;
}

function getCreditChange(gameMode: string, myCredits: number, opponentCredits: number, won: boolean, condition: string = '') {
    if (condition === 'Draw') {
        return 0
    } else if (gameMode === Gamemodes.STANDARD_COMPETITIVE || gameMode === Gamemodes.CLASSIC_COMPETITIVE) {
        const creditDiff: number = Math.abs(opponentCredits - myCredits)
        // if you win and you have more credits than opponent, then you gain less, vice versa
        const creditChange: number = (myCredits < opponentCredits && won) || (myCredits > opponentCredits && !won) ? Math.abs((100 + Math.floor((creditDiff) / 50))) : Math.abs((100 - Math.floor((creditDiff) / 50)))
        const sign = won ? 1 : -1
        if (!won && creditChange >= myCredits) {
            return sign * myCredits
        }
        return sign * creditChange
    } else {
        return 0
    }
}

function getRankPercentageBar(newCredits: number) {
    if (newCredits >= 5000) {
        return 100;
    }
    return ((newCredits % 250) / 250) * 100
}

function getKnuggetsWon(gameMode: string, myCredits: number, won: boolean, condition: string = '') {
    if (condition === 'Draw') {
        return 0
    } else if ((gameMode === Gamemodes.STANDARD_COMPETITIVE || gameMode === Gamemodes.CLASSIC_COMPETITIVE) && won) {
        if (myCredits < 250) {
            return 1
        } else if (myCredits > 5000) {
            return 8
        } else {
            return Math.floor(myCredits / 1000) + 1
        }
    } else if ((gameMode === Gamemodes.STANDARD_CASUAL || gameMode === Gamemodes.CLASSIC_CASUAL) && won) {
        return 1
    } else {
        return 0
    }
}

const GameOverDisplay: React.FC<Props> = ({ myColor, gameOverStatus, gameMode, myCredits, opponentCredits, backToMenu, changeMode, findMatch }) => {
    // animation related properties
    const animation = useGameOverAnimationStatus()

    const isWon = myColor === gameOverStatus.winner
    const creditsToProcess = getCreditChange(gameMode, myCredits, opponentCredits, isWon, gameOverStatus.condition)
    const [processedCredits, setProcessedCredits] = useState<number>(0)
    const knuggetsToProcess = getKnuggetsWon(gameMode, myCredits + creditsToProcess, isWon, gameOverStatus.condition)
    const [processedKnuggets, setProcessedKnuggets] = useState<number>(0)

    const creditIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const soundIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const [canClick, setCanClick] = useState<boolean>(false)

    useEffect(() => {
        if (gameOverStatus.condition !== '' && gameOverStatus.condition !== 'quit') {
            animation.setPanelStatus('enter')
            animation.setBackgroundStatus('enter')
            
            const timeout = setTimeout(() => {
                sounds['gameover'].play()
                setCanClick(true)
            }, 900)
            return () => clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameOverStatus])
    
    useEffect(() => {
        if (processedCredits === creditsToProcess) {
            return;
        }
        const intervalMs = 20; // How often to update (in milliseconds)
        const soundIntervalMs = 150; // Interval for playing credit sound
        const stepSize = isWon ? 1 : -1; // How much to increment each time

        // Set up an interval to update processedCredits
        const timeout = setTimeout(() => {
            creditIntervalRef.current = setInterval(() => {
                setProcessedCredits(currentProcessedCredits => {
                    // Calculate the new value
                    let newProcessedCredits = currentProcessedCredits + stepSize;
                    
                    // Adjust newProcessedCredits if it overshoots creditsToProcess
                    if ((stepSize > 0 && newProcessedCredits >= creditsToProcess) || 
                        (stepSize < 0 && newProcessedCredits <= creditsToProcess) ||
                        myCredits + newProcessedCredits === 0) {
                            newProcessedCredits = creditsToProcess;
                            if (soundIntervalRef.current) clearInterval(soundIntervalRef.current);
                            if (creditIntervalRef.current) clearInterval(creditIntervalRef.current);
                    }

                    return newProcessedCredits;
                });
            }, intervalMs);

            // Interval to play sound effect
            soundIntervalRef.current = setInterval(() => {
                sounds['credit'].play(); 
            }, soundIntervalMs);

            // Clear intervals when the component unmounts or when dependencies change
            return () => {
                if (soundIntervalRef.current) clearInterval(soundIntervalRef.current);
                if (creditIntervalRef.current) clearInterval(creditIntervalRef.current);
            };
        }, 1250);
        return () => clearTimeout(timeout);
    }, [creditsToProcess, processedCredits, myCredits, isWon]);

    useEffect(() => {
        if (processedKnuggets === knuggetsToProcess) {
            return;
        }
        const intervalMs = 200; 
        const stepSize = 1; 

        const timeout = setTimeout(() => {
            const interval = setInterval(() => {
                setProcessedKnuggets(currentProcessedKnuggets => {
                    let newProcessedKnuggets = currentProcessedKnuggets + stepSize;
                    
                    if (newProcessedKnuggets > knuggetsToProcess) {
                        newProcessedKnuggets = knuggetsToProcess;
                    }

                    return newProcessedKnuggets;
                });
            }, intervalMs);
            return () => clearInterval(interval);
        }, 1250);
        return () => clearTimeout(timeout);
    }, [knuggetsToProcess, processedKnuggets]);

    const winnerText: string = gameOverStatus.winner === Colors.WHITE ? 'White' : 'Black'
    const loserText: string = gameOverStatus.winner === Colors.WHITE ? 'Black' : 'White'
    let endGameMessage: string = ''
    if (gameOverStatus.condition === 'Wasted') {
        endGameMessage = `${loserText} king got smoked!`
    } else if (gameOverStatus.condition === 'Surrendered') {
        endGameMessage = `${loserText} have surrendered!`
    } else if (gameOverStatus.condition === 'Draw') {
        endGameMessage = `No winners.`
    } else if (gameOverStatus.condition === 'Stabbed') {
        endGameMessage = `${loserText} king has been stabbed.`
    } else if (gameOverStatus.condition === 'Timeout') {
        endGameMessage = `${loserText} has timed out.`
    } else {
        endGameMessage = `${winnerText} won by Checkmate!`
    }

    return (
        <>
            <motion.div className="DarkBackground"
                        initial='initial' 
                        animate={animation.backgroundStatus} 
                        variants={animation.backgroundStatusAnimation}/>
            <motion.div className="GameOverPanel"
                        initial='initial' 
                        animate={animation.panelStatus} 
                        variants={animation.panelStatusAnimation}>
                <motion.div className="InfoText" style={{marginTop: '1.5vh'}}> {endGameMessage} </motion.div>
                <div className='RowSelection' style={{marginTop: '0.5vh'}}>
                    <motion.div className="RankText"> {getRankName(myCredits + processedCredits)} </motion.div>
                    <motion.div className="InfoText" style = {{marginLeft: '2vh'}}> 
                        {`(${isWon ? '+' : '-'}${Math.abs(processedCredits)})`} 
                    </motion.div>
                </div>
                <motion.div className="CreditBarBackground">
                    <div className="CreditBar" style={{width: `${getRankPercentageBar(myCredits + processedCredits)}%`}}/>
                </motion.div>
                <motion.div className='RowSelection'>
                    <motion.img src={images['knugget']} style={{width: '5vh', height: '5vh'}}/>
                    <motion.div className="InfoText"> {`+${processedKnuggets}`} </motion.div>
                </motion.div>
                <div className='RowSelection' style={{marginTop: '0.5vh'}}>
                    <motion.div className="GameOverButton" 
                            onClick={()=>{
                                if (!canClick) return
                                if (soundIntervalRef.current) clearInterval(soundIntervalRef.current);
                                if (creditIntervalRef.current) clearInterval(creditIntervalRef.current);
                                findMatch(gameMode)
                                animation.TransitionOut()
                            }}
                            whileHover={{ scale: 1.1 }}> 
                        {'Find Match'} 
                    </motion.div>
                    <motion.div className="GameOverButton" 
                            onClick={()=>{
                                if (!canClick) return
                                if (soundIntervalRef.current) clearInterval(soundIntervalRef.current);
                                if (creditIntervalRef.current) clearInterval(creditIntervalRef.current);
                                changeMode()
                                animation.TransitionOut()
                            }}
                            whileHover={{ scale: 1.1 }}> 
                        {'Change Mode'} 
                    </motion.div>
                    <motion.div className="GameOverButton"
                            onClick={()=>{
                                if (!canClick) return
                                if (soundIntervalRef.current) clearInterval(soundIntervalRef.current);
                                if (creditIntervalRef.current) clearInterval(creditIntervalRef.current);
                                backToMenu()
                                animation.TransitionOut()
                            }}
                            whileHover={{ scale: 1.1 }}> 
                        {'Menu'} 
                    </motion.div>
                </div>
            </motion.div>

            <motion.div className="StatusText" 
                        initial='initial' 
                        animate={animation.panelStatus} 
                        variants={animation.panelStatusAnimation}>
                {gameOverStatus.condition === '' ? 'Victory' : gameOverStatus.condition}
            </motion.div>
        </>
    )
}

export default GameOverDisplay;