import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import './Layout.css';

import { Colors, Pieces } from '../../utils/Enums'
import skins from '../../utils/SkinMapper'
import { createPieceAnimations, createShadowAnimations } from './Animation'

interface PieceInfo {
    type: Pieces;
    color: Colors;
    alias: 'rook1' | 'knight1' | 'bishop1' | 'queen' | 'king' | 'bishop2' | 'knight2' | 'rook2' | 'pawn1' | 'pawn2' | 'pawn3' | 'pawn4' | 'pawn5' | 'pawn6' | 'pawn7' | 'pawn8';
    cellId: number;
}

export const whitePieces: PieceInfo[] = [
    {type: Pieces.ROOK, color: Colors.WHITE, alias: 'rook1', cellId: 3 * 8 + 0},
    {type: Pieces.KNIGHT, color: Colors.WHITE, alias: 'knight1', cellId: 3 * 8 + 1},
    {type: Pieces.BISHOP, color: Colors.WHITE, alias: 'bishop1', cellId: 3 * 8 + 2},
    {type: Pieces.QUEEN, color: Colors.WHITE, alias: 'queen', cellId: 3 * 8 + 3},
    {type: Pieces.KING, color: Colors.WHITE, alias: 'king', cellId: 3 * 8 + 4},
    {type: Pieces.BISHOP, color: Colors.WHITE, alias: 'bishop2', cellId: 3 * 8 + 5},
    {type: Pieces.KNIGHT, color: Colors.WHITE, alias: 'knight2', cellId: 3 * 8 + 6},
    {type: Pieces.ROOK, color: Colors.WHITE, alias: 'rook2', cellId: 3 * 8 + 7},
    {type: Pieces.PAWN, color: Colors.WHITE, alias: 'pawn1', cellId: 2 * 8 + 0},
    {type: Pieces.PAWN, color: Colors.WHITE, alias: 'pawn2', cellId: 2 * 8 + 1},
    {type: Pieces.PAWN, color: Colors.WHITE, alias: 'pawn3', cellId: 2 * 8 + 2},
    {type: Pieces.PAWN, color: Colors.WHITE, alias: 'pawn4', cellId: 2 * 8 + 3},
    {type: Pieces.PAWN, color: Colors.WHITE, alias: 'pawn5', cellId: 2 * 8 + 4},
    {type: Pieces.PAWN, color: Colors.WHITE, alias: 'pawn6', cellId: 2 * 8 + 5},
    {type: Pieces.PAWN, color: Colors.WHITE, alias: 'pawn7', cellId: 2 * 8 + 6},
    {type: Pieces.PAWN, color: Colors.WHITE, alias: 'pawn8', cellId: 2 * 8 + 7}]

export const blackPieces: PieceInfo[] = [
    {type: Pieces.ROOK, color: Colors.BLACK, alias: 'rook1', cellId: 3 * 8 + 0},
    {type: Pieces.KNIGHT, color: Colors.BLACK, alias: 'knight1', cellId: 3 * 8 + 1},
    {type: Pieces.BISHOP, color: Colors.BLACK, alias: 'bishop1', cellId: 3 * 8 + 2},
    {type: Pieces.QUEEN, color: Colors.BLACK, alias: 'queen', cellId: 3 * 8 + 4},
    {type: Pieces.KING, color: Colors.BLACK, alias: 'king', cellId: 3 * 8 + 3},
    {type: Pieces.BISHOP, color: Colors.BLACK, alias: 'bishop2', cellId: 3 * 8 + 5},
    {type: Pieces.KNIGHT, color: Colors.BLACK, alias: 'knight2', cellId: 3 * 8 + 6},
    {type: Pieces.ROOK, color: Colors.BLACK, alias: 'rook2', cellId: 3 * 8 + 7},
    {type: Pieces.PAWN, color: Colors.BLACK, alias: 'pawn1', cellId: 2 * 8 + 0},
    {type: Pieces.PAWN, color: Colors.BLACK, alias: 'pawn2', cellId: 2 * 8 + 1},
    {type: Pieces.PAWN, color: Colors.BLACK, alias: 'pawn3', cellId: 2 * 8 + 2},
    {type: Pieces.PAWN, color: Colors.BLACK, alias: 'pawn4', cellId: 2 * 8 + 3},
    {type: Pieces.PAWN, color: Colors.BLACK, alias: 'pawn5', cellId: 2 * 8 + 4},
    {type: Pieces.PAWN, color: Colors.BLACK, alias: 'pawn6', cellId: 2 * 8 + 5},
    {type: Pieces.PAWN, color: Colors.BLACK, alias: 'pawn7', cellId: 2 * 8 + 6},
    {type: Pieces.PAWN, color: Colors.BLACK, alias: 'pawn8', cellId: 2 * 8 + 7}]

function getPiecePositionX(pieceAlias: string, selectedColor: Colors) {
    if (pieceAlias === Pieces.KING) {
        return selectedColor === Colors.WHITE ? 4 : 3
    } else if (pieceAlias === Pieces.QUEEN) {
        return selectedColor === Colors.WHITE ? 3 : 4
    } else if (pieceAlias === 'rook1') {
        return 0
    } else if (pieceAlias === 'knight1') {
        return 1
    } else if (pieceAlias === 'bishop1') {
        return 2
    } else if (pieceAlias === 'rook2') {
        return 7
    } else if (pieceAlias === 'knight2') {
        return 6
    } else if (pieceAlias === 'bishop2') {
        return 5
    } else {
        const lastChar = pieceAlias.charAt(pieceAlias.length - 1);
        return parseInt(lastChar) - 1
    }
}

function getBoardPositionY(pieceAlias: string) {
    return pieceAlias.startsWith("pawn") ? 0 : 1
}

function getBoardPositionZ(pieceAlias: string) {
    return pieceAlias.startsWith("pawn") ? 10 : 11
}

export const getPieceByCellId = (cellId: number, selectedColor: Colors) => {
    const piece = selectedColor === Colors.WHITE ? whitePieces.find(p => p.cellId === cellId) : blackPieces.find(p => p.cellId === cellId);
    return piece || null;
}

interface Props {
    pieceInfo: PieceInfo;
    hoveredCellId: number;
    selectedCellId: number;
    skinName: string;
    selectedColor: Colors;
    hidePieces: boolean;
}

const Piece: React.FC<Props> = ({ pieceInfo, hoveredCellId, selectedCellId, skinName, selectedColor, hidePieces }) => {
    const isHovered = hoveredCellId === pieceInfo.cellId
    const isSelected = selectedCellId === pieceInfo.cellId
    
    const [animation, setAnimation] = useState<string>('specialEnter')
    const animationDelay = (pieceInfo.cellId % 8) * 0.075 + (pieceInfo.alias.startsWith('pawn') ? 0 : 0.075)
    const animationVariants = createPieceAnimations(animationDelay)
    const shadowVariants = createShadowAnimations(animationDelay)
    
    // user swaps their selected color
    useEffect(() => {
        if (selectedColor !== pieceInfo.color) {
            setAnimation('exit')
        } else {
            setAnimation('enter')
        }
    }, [selectedColor, pieceInfo.color])

    // user wishes to edit tiles and hides all the pieces
    useEffect(() => {
        if (hidePieces) {
            setAnimation('exit')
        } else if (!hidePieces && selectedColor === pieceInfo.color) {
            setAnimation('enter')
        }
    }, [hidePieces, pieceInfo.color, selectedColor])

    // user actions for hover and select
    useEffect(() => {
        if (selectedColor !== pieceInfo.color || hidePieces) {
            return
        }
        if (isHovered && isSelected) {
            setAnimation('selecthover')
        } else if (isSelected) {
            setAnimation('select')
        } else if (isHovered) {
            setAnimation('hover')
        } else if (animation === 'hover' || animation === 'select' || animation === 'selecthover') {
            setAnimation('none')
        }
    }, [hoveredCellId, selectedCellId, isHovered, isSelected, pieceInfo.color, selectedColor, animation, hidePieces])

    return (
        <motion.div className='MyPiece' 
                    style={{left: `${getPiecePositionX(pieceInfo.alias, pieceInfo.color) * (100 / 8)}%`, 
                            top: `${(getBoardPositionY(pieceInfo.alias) + 2) * (100 / 4)}%`, 
                            zIndex: getBoardPositionZ(pieceInfo.alias)} }>
            
            <motion.div className='MyPieceShadow' 
                        draggable="false"
                        initial='initial' 
                        animate={ animation } 
                        variants={ shadowVariants }/>

            <motion.img className='MyPieceImage' 
                        src={ pieceInfo.color === Colors.WHITE ? skins[skinName].whiteImage : skins[skinName].blackImage } 
                        draggable="false"
                        initial='initial' 
                        animate={ animation } 
                        variants={ animationVariants }/>
            
        </motion.div>
    )
}

export default Piece;