import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import images from '../../images';
import { Reward } from './Gacha';
import CharacterHandler from '../../utils/CharacterHandler';
import { Characters } from '../../utils/Enums';
import { getSkinPresetByName } from '../../utils/SkinMapper';
import { characters, validateCharacter } from '../../utils/CharacterMapper';

interface RewardProps {
    reward: Reward;
    opened: boolean;
}

const RealReward: React.FC<RewardProps> = ({ reward, opened }) => {
    const [expression, setExpession] = useState<string>('idle')
    
    const pieceOffset = '10vh'
    const characterOffset = '12.5vh'
    const weaponOffset = '0vh'

    useEffect(() => {
        if (!opened) return
        if (reward.type === 'character') {
            setTimeout(() => {
                setExpession(characters[validateCharacter(reward.item as Characters)].greet)
            }, 750);
        }
    }, [reward, opened])

    const getComponent = () => {
        switch (reward.type) {
            case 'piece':
                return (
                    <motion.img
                        style={{
                            display: 'flex',
                            marginBottom: pieceOffset,
                            scale: 1.15,
                            width: '100%',
                            height: '100%',
                            transformOrigin: '50% 75%'
                        }}
                        src={images[`white_${getSkinPresetByName(reward.item)?.type}_${getSkinPresetByName(reward.item)?.set}`]}
                        draggable={false}
                    />
                );

            case 'character':
                return (
                    <CharacterHandler
                        style={{ scale: 0.775, marginBottom: characterOffset, transformOrigin: '50% 75%' }}
                        name={reward.item as Characters}
                        expression={expression}
                        setExpression={setExpession}
                        allowAnnoy={false}
                        smallBorder={true}
                    />
                );

            case 'tile':
                return (
                    <motion.img
                        style={{
                            display: 'flex',
                            marginBottom: pieceOffset,
                            scale: 1.15,
                            width: '100%',
                            height: '100%',
                        }}
                        src={images[`white_${getSkinPresetByName(reward.item)?.type}_${getSkinPresetByName(reward.item)?.set}`]}
                        draggable={false}
                    />
                );

            case 'weapon':
                return (
                    <motion.img
                        style={{
                            display: 'flex',
                            marginBottom: weaponOffset,
                            scale: 2.5,
                            width: '100%',
                            height: '100%',
                            filter: 'brightness(25%)'
                        }}
                        src={images[`${getSkinPresetByName(reward.item, 'weapon')?.type}_${getSkinPresetByName(reward.item, 'weapon')?.set}_icon`]}
                        draggable={false}
                    />
                );

            default:
                return <></>; 
        }
    };

    return (
        getComponent()
    );
};

export default RealReward;