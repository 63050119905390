import { useAudio } from './AudioContext';  
import { useEffect } from 'react';
import sounds from '../../sounds';

// make sure that sfx volumes are updated
const useUpdateSoundVolumes = () => {
    const { volumeSettings } = useAudio();

    useEffect(() => {
        Object.keys(sounds).forEach(key => {
            sounds[key].volume(volumeSettings.soundEffectVolume * volumeSettings.masterVolume);
        });
    }, [volumeSettings]);

    return sounds;
};

export default useUpdateSoundVolumes;