import { motion } from 'framer-motion';
import React, { useState } from 'react';

import '../../utils/Common.css';
import './Layout.css';

import { Colors, Equipments, Pieces } from "../../utils/Enums";

import Piece, { whitePieces, blackPieces, getPieceByCellId } from './Piece'
import Cell from './Cell'
import SkinIcon from './SkinIcon'
import useLayout, { areLoadoutsDifferent, countSkinNameInLoadout, getDefaultLoadout } from './LoadoutManager'
import useSkins, { useSkinByType } from './Inventory';

import DisplayPanel from './DisplayPanel'
import LoadoutButton from './LoadoutButton'

import * as ScreenAnimation from './Animation'
import skins from '../../utils/SkinMapper';
import sounds from '../../sounds';

interface Props {
    setAlertMessage: (alert: string) => void;
    BackToMenu: () => void;
}

const Layout: React.FC<Props> = ({ setAlertMessage, BackToMenu }) => {
    // screen states
    const [selectedColor, setSelectedColor] = useState<Colors>(Colors.WHITE)
    const [hoveredCellId, setHoveredCellId] = useState<number>(-1)
    const [selectedCellId, setSelectedCellId] = useState<number>(-1)
    const [selectedSkinIconName, setSelectedSkinIconName] = useState<string>('')
    const [selectedAlias, setSelectedAlias] = useState<string>('')
    const [selectedType, setSelectedType] = useState<Pieces | Equipments>(Pieces.UNKNOWN)
    const [hidePieces, setHidePieces] = useState<boolean>(false)

    // layout 
    const { isLoadoutLoading, layouts, originalLayouts, updateLoadout, layoutNames, updateLayoutName, selectedLayout, setSelectedLayout, saveLayouts } = useLayout();
    const loadoutName = layoutNames[selectedLayout - 1];
    const loadout = layouts[selectedLayout - 1];
    
    // skins
    const { isSkinsLoading, allSkins } = useSkins();
    const skinsByType = useSkinByType(selectedType, allSkins);
    // number of skin pieces left to be equiped for the selected skin
    const numRemainingPieces = skinsByType[selectedSkinIconName] - countSkinNameInLoadout(selectedSkinIconName, loadout ? loadout : {})
    const selectedSkinCount = selectedSkinIconName !== '' ? numRemainingPieces || 0 : 0;

    // animations
    const animation = ScreenAnimation.useAnimationStatus();

    // others
    const isLoading = isLoadoutLoading || isSkinsLoading
    
    if (isLoading) {
        return (
            <div className = "Screen"/>
        )
    }

    function ToMenu() {
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            if (areLoadoutsDifferent(layouts, originalLayouts)) {
                saveLayouts()
            }
            BackToMenu()
            clearTimeout(exitTimer)
        }, 750);
    }

    const selectSkin = (pieceAlias: string, skinName: string) => {
        const newLoadout = {
            ...loadout,
            [pieceAlias]: skinName
        };
        updateLoadout(selectedLayout - 1, newLoadout);
    }

    const DeselectAll = () => {
        setSelectedCellId(-1)
        setSelectedType(Pieces.UNKNOWN)
        setSelectedSkinIconName('')
        setSelectedAlias('')
    }

    // creating the 4 by 8 chess board
    const cellComponents = () => {
        const newCells: React.ReactNode[][] = [];
        for (let i = 0; i < 4; i++) {
            newCells.push([]);
            for (let j = 0; j < 8; j++) {
                let cellId = i * 8 + j;
                newCells[i].push(
                    <Cell
                        key={cellId}
                        cellId={cellId}
                        hoveredCellId={hoveredCellId}
                        selectedCellId={selectedCellId}
                        skinName={'Default Tile'}
                        color={ (i + j) % 2 === 1 ? Colors.WHITE : Colors.BLACK }
                        hidePieces={hidePieces}
                        onClick={() => {
                            setSelectedCellId(cellId)
                            const tempPiece = getPieceByCellId(cellId, selectedColor)
                            if (hidePieces) {
                                sounds['pieceSelect'].play()
                            } else if (tempPiece) {
                                sounds['pieceSelect'].play()
                                setSelectedType(tempPiece.type)
                                setSelectedAlias(tempPiece.alias)
                                if (loadout) {
                                    setSelectedSkinIconName(loadout[tempPiece.alias])
                                }
                            } else {
                                DeselectAll()
                            }
                        }}
                        onHover={() => {
                            setHoveredCellId(cellId)
                            const tempPiece = getPieceByCellId(cellId, selectedColor)
                            if (hidePieces) {
                                sounds['pieceHover'].play()
                            } else if (tempPiece) {
                                sounds['pieceHover'].play()
                            }
                        }}
                    />
                );
            }
        }
        return newCells;
    };

    const skinIcons = Object.entries(skinsByType).map(([skinName, count], index) => (
        <SkinIcon 
            key={index}
            skinName={skinName}
            selectedSkinName={selectedSkinIconName}
            equipedSkinName={loadout && selectedAlias !== '' ? loadout[selectedAlias] : ''}
            selectedColor={selectedColor}
            selectSkinIcon={setSelectedSkinIconName}
        />
    ));

    const whitePieceComponents = whitePieces.map((piece, index) => {
        return (
            <Piece 
                key={index} 
                pieceInfo={piece} 
                hoveredCellId={hoveredCellId} 
                selectedCellId={selectedCellId} 
                skinName={ loadout ? loadout[piece.alias] : getDefaultLoadout()[piece.alias] } 
                selectedColor={selectedColor} 
                hidePieces={hidePieces}
            /> 
        );
    });

    const blackPieceComponents = blackPieces.map((piece, index) => {
        return (
            <Piece 
                key={index} 
                pieceInfo={piece} 
                hoveredCellId={hoveredCellId} 
                selectedCellId={selectedCellId} 
                skinName={ loadout ? loadout[piece.alias] : getDefaultLoadout()[piece.alias] } 
                selectedColor={selectedColor} 
                hidePieces={hidePieces}
            />
        );
    });

    const loadoutButtons = layouts.map((loadout, index) => (
        <LoadoutButton key={index + 1} 
                    loadoutIndex={index + 1} 
                    selectedLoadoutIndex={selectedLayout} 
                    setSelectedLoadout={setSelectedLayout} 
                    DeselectAll={DeselectAll} 
                    animation={animation}/>
    ))

    return (
        <div className = "Screen">
            <motion.div className = "HeadTitle" style={{marginTop: '-5vh'}}
                    initial='initial' 
                    animate={animation.titleStatus}
                    variants={animation.titleStatusAnimation}>
                Layout
            </motion.div>
            <motion.div className='LoadOutContainer'>
                {loadoutButtons}
            </motion.div>

            <div className='LayoutSettings'>
                <motion.input className='LayoutNameField' 
                            initial='initial' 
                            animate={animation.layoutUIStatus[0]} 
                            variants={animation.layoutButtonAnimation[0]} 
                            spellCheck={false} 
                            draggable={false} 
                            whileHover={{ scale: 1.05 }}
                            onMouseEnter={() => {
                                sounds['hover'].play()
                            }} 
                            onClick={()=>{sounds['select'].play()}}
                            type={"text"}
                            placeholder={"Layout " + (selectedLayout)} 
                            value={loadoutName ? loadoutName : ''}
                            onInput={e => updateLayoutName(selectedLayout - 1, e.currentTarget.value)} 
                            required/>

                <motion.div className='LayoutButton' 
                        initial='initial' 
                        animate={animation.layoutUIStatus[1]} 
                        variants={animation.layoutButtonAnimation[1]} 
                        style={{color: hidePieces ? "#B0B0B0" : "#6B6B6B"}}
                        onMouseEnter={() => {
                            sounds['hover'].play()
                        }} 
                        whileHover={{ scale: hidePieces ? 1 : 1.1 }}
                        onClick={() => {
                            if (hidePieces) return
                            sounds['select'].play()
                            if (selectedColor === Colors.WHITE) {
                                setSelectedColor(Colors.BLACK)
                            } else if (selectedColor === Colors.BLACK) {
                                setSelectedColor(Colors.WHITE)
                            }
                            DeselectAll()
                        }}>
                    Swap Side
                </motion.div>

                <motion.div 
                    className='LayoutButton' 
                    initial='initial' 
                    animate={animation.layoutUIStatus[2]} 
                    variants={animation.layoutButtonAnimation[2]} 
                    onMouseEnter={() => {
                        sounds['hover'].play()
                    }} 
                    whileHover={{ scale: 1.1 }}
                    onClick={() => {
                        setHidePieces(!hidePieces)
                        DeselectAll()
                        sounds['select'].play()
                    }}>{hidePieces ? 'Edit Pieces' : 'Edit Cells'}
                </motion.div>
            </div>

            <motion.div className='BoardContainer'>
                <DisplayPanel selectedPieceAlias={selectedAlias}
                            selectedSkinIconName={selectedSkinIconName} 
                            selectedSkinCount={selectedSkinCount} 
                            selectedColor={selectedColor} 
                            equipedSkinName={loadout && selectedAlias !== '' ? loadout[selectedAlias] : ''}
                            selectSkin={selectSkin}
                            animation={animation}/>

                <motion.div className = "MyBoard" 
                        onMouseLeave = {() => setHoveredCellId(-1)}
                        initial='initial' 
                        animate={animation.boardStatus}
                        variants={animation.boardStatusAnimation}>
                    { cellComponents() }
                    { whitePieceComponents }
                    { blackPieceComponents }
                </motion.div>

                <motion.div className='ButtonGroup'>
                    <motion.div className='EquipmentSlot'
                        initial='initial' 
                        animate={animation.weaponButtonStatus[5]} 
                        variants={animation.weaponButtonAnimation[5]}
                        onClick={() => {
                            setSelectedCellId(-1)
                            setSelectedAlias(Equipments.PISTOL)
                            setSelectedType(Equipments.PISTOL)
                            if (loadout) setSelectedSkinIconName(loadout[Equipments.PISTOL])
                            sounds['select'].play()
                        }}
                        onMouseEnter={() => {
                            setHoveredCellId(-1)
                            sounds['hover'].play()
                        }}> 
                        <motion.div whileHover={{ scale: 1.2 }}>
                            <motion.img 
                                draggable="false"
                                style={{width: '100%', height: '100%', transform: `scale(3) translateX(1%) rotate(-35deg)`}} 
                                src={ skins[loadout ? loadout[Equipments.PISTOL] : getDefaultLoadout()[Equipments.PISTOL]].equipmentDetail!.avatar } />
                        </motion.div>
                    </motion.div>
                    <motion.div className='EquipmentSlot'
                        initial='initial' 
                        animate={animation.weaponButtonStatus[1]} 
                        variants={animation.weaponButtonAnimation[1]}onClick={() => {
                            setSelectedCellId(-1)
                            setSelectedAlias(Equipments.KNIFE)
                            setSelectedType(Equipments.KNIFE)
                            if (loadout) setSelectedSkinIconName(loadout[Equipments.KNIFE])
                            sounds['select'].play()
                        }}
                        onMouseEnter={() => {
                            setHoveredCellId(-1)
                            sounds['hover'].play()
                        }}> 
                        <motion.div whileHover={{ scale: 1.2 }}>
                            <motion.img 
                                draggable="false"
                                style={{width: '100%', height: '100%', transform: `scale(4.5) rotate(-45deg) translateX(1%)`}} 
                                src={ skins[loadout ? loadout[Equipments.KNIFE] : getDefaultLoadout()[Equipments.KNIFE]].equipmentDetail!.avatar } />
                        </motion.div>
                    </motion.div>
                    <motion.div className='EquipmentSlot'
                        initial='initial' 
                        animate={animation.weaponButtonStatus[0]} 
                        variants={animation.weaponButtonAnimation[0]}
                        onClick={() => {
                            setSelectedCellId(-1)
                            setSelectedAlias(Equipments.SHIELD)
                            setSelectedType(Equipments.SHIELD)
                            if (loadout) setSelectedSkinIconName(loadout[Equipments.SHIELD])
                            sounds['select'].play()
                        }}
                        onMouseEnter={() => {
                            setHoveredCellId(-1)
                            sounds['hover'].play()
                        }}> 
                        <motion.div whileHover={{ scale: 1.2 }}>
                            <motion.img 
                                draggable="false"
                                style={{width: '100%', height: '100%', transform: `scale(1.5) translateX(1%)`}} 
                                src={ skins[loadout ? loadout[Equipments.SHIELD] : getDefaultLoadout()[Equipments.SHIELD]].equipmentDetail!.avatar } />
                        </motion.div>
                    </motion.div>

                    <motion.div className='EquipmentSlot'
                        initial='initial' 
                        animate={animation.weaponButtonStatus[2]} 
                        variants={animation.weaponButtonAnimation[2]}
                        onClick={() => {
                            setSelectedCellId(-1)
                            setSelectedAlias(Equipments.SNIPER)
                            setSelectedType(Equipments.SNIPER)
                            if (loadout) setSelectedSkinIconName(loadout[Equipments.SNIPER])
                            sounds['select'].play()
                        }}
                        onMouseEnter={() => {
                            setHoveredCellId(-1)
                            sounds['hover'].play()
                        }}> 
                        <motion.div whileHover={{ scale: 1.2 }}>
                            <motion.img 
                                draggable="false"
                                style={{width: '100%', height: '100%', transform: `scale(2.5) translateX(1%) rotate(-40deg)`}} 
                                src={ skins[loadout ? loadout[Equipments.SNIPER] : getDefaultLoadout()[Equipments.SNIPER]].equipmentDetail!.avatar } />
                        </motion.div>
                    </motion.div>
                    <motion.div className='EquipmentSlot'
                        initial='initial' 
                        animate={animation.weaponButtonStatus[3]} 
                        variants={animation.weaponButtonAnimation[3]}
                        onClick={() => {
                            setSelectedCellId(-1)
                            setSelectedAlias(Equipments.RIFLE)
                            setSelectedType(Equipments.RIFLE)
                            if (loadout) setSelectedSkinIconName(loadout[Equipments.RIFLE])
                            sounds['select'].play()
                        }}
                        onMouseEnter={() => {
                            setHoveredCellId(-1)
                            sounds['hover'].play()
                        }}> 
                        <motion.div whileHover={{ scale: 1.2 }}>
                            <motion.img 
                                draggable="false"
                                style={{width: '100%', height: '100%', transform: `scale(2.5) translateX(2%) rotate(-40deg)`}} 
                                src={ skins[loadout ? loadout[Equipments.RIFLE] : getDefaultLoadout()[Equipments.RIFLE]].equipmentDetail!.avatar } />
                        </motion.div>
                    </motion.div>
                    <motion.div className='EquipmentSlot'
                        initial='initial' 
                        animate={animation.weaponButtonStatus[4]} 
                        variants={animation.weaponButtonAnimation[4]}
                        onClick={() => {
                            setSelectedCellId(-1)
                            setSelectedAlias(Equipments.SHOTGUN)
                            setSelectedType(Equipments.SHOTGUN)
                            if (loadout) setSelectedSkinIconName(loadout[Equipments.SHOTGUN])
                            sounds['select'].play()
                        }}
                        onMouseEnter={() => {
                            setHoveredCellId(-1)
                            sounds['hover'].play()
                        }}> 
                        <motion.div whileHover={{ scale: 1.2 }}>
                            <motion.img 
                                draggable="false"
                                style={{width: '100%', height: '100%', transform: `scale(2.5) translateX(2%) rotate(-40deg)`}} 
                                src={ skins[loadout ? loadout[Equipments.SHOTGUN] : getDefaultLoadout()[Equipments.SHOTGUN]].equipmentDetail!.avatar } />
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>

            <motion.div className = "SkinContainer" 
                    initial='initial' 
                    animate={animation.skinSelectorStatus} 
                    variants={animation.skinSelectorStatusAnimation}>
                { skinIcons }
            </motion.div>

            <motion.div className='BackButton' 
                    initial='initial' 
                    animate={animation.backButtonStatus} 
                    variants={animation.backButtonStatusAnimation} 
                    onMouseEnter={()=>{sounds['hover'].play()}}
                    onClick={() => {
                        sounds['select'].play()
                        ToMenu()
                    }} whileHover={{ scale: 1.1 }}> 
                Back
            </motion.div>
        </div>
    )
}

export default Layout;