import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Mode.css';

import { Gamemodes } from "../../utils/Enums"

import * as ScreenAnimation from './Animation';
import Competitive from './Competitive';
import Casual from './Casual';
import Bot from './Bot';
import Custom from './Custom';
import CwgButton from '../../utils/CwgButton';

interface Props {
    setAlertMessage: (alert: string) => void;
    QueueForGame: (gamemode: string) => void;
    BackToMenu: () => void;
    ToHost: () => void;
    ToJoin: () => void;
    category: string;
}

const Mode: React.FC<Props> = ({ setAlertMessage, QueueForGame, BackToMenu, ToHost, ToJoin, category }) => {
    const mounted = useRef<boolean>(false);
    const isWhiteIcon = useRef<boolean>(Math.floor(Math.random() * 2) === 0);

    const [selectedGamemode, setSelectedGamemode] = useState<Gamemodes>(Gamemodes.UNKNOWN);
    const [selectedCategory, setSelectedCategory] = useState<string>(category);
    const [findingMatch, setFindingMatch] = useState<boolean>(false);
    const [isClickable, setIsClickable] = useState(true);

    const animation = ScreenAnimation.useAnimationStatus();

    function StepBack() {
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            BackToMenu()
            clearTimeout(exitTimer)
        }, 500);
    } 

    async function FindMatch(gamemode: Gamemodes) {
        const modesToQueue: string[] = [Gamemodes.STANDARD_COMPETITIVE, 
                                        Gamemodes.CLASSIC_COMPETITIVE, 
                                        Gamemodes.STANDARD_CASUAL, 
                                        Gamemodes.CLASSIC_CASUAL, 
                                        Gamemodes.CLASSIC_BOT, 
                                        Gamemodes.STANDARD_BOT, 
                                        Gamemodes.DUMMY_BOT]
        if (modesToQueue.includes(gamemode)) {
            setFindingMatch(true)
            animation.TransitionOut()
            // animation.ClearScreen()
            const exitTimer = setTimeout(() => {
                setSelectedCategory('')
                QueueForGame(gamemode)
                clearTimeout(exitTimer)
            }, 500);
        } else if (gamemode === Gamemodes.HOST_GAME) {
            setFindingMatch(true)
            animation.TransitionOut()
            const exitTimer = setTimeout(() => {
                ToHost()
                clearTimeout(exitTimer)
            }, 500);
        } else if (gamemode === Gamemodes.JOIN_GAME) {
            setFindingMatch(true)
            animation.TransitionOut()
            const exitTimer = setTimeout(() => {
                ToJoin()
                clearTimeout(exitTimer)
            }, 500);
        }
    }

    // check going back to menu
    useEffect(() => {
        if (!mounted.current) {
            return
        }
        if (selectedCategory !== '') {
            animation.ExitCategories(selectedCategory)
        } else {
            animation.BackToCategories()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory])

    // eliminate mouse spam
    useEffect(() => {
        if (!mounted.current || !isClickable) {
            return
        }
        setIsClickable(false);
        const timer = setTimeout(() => {
            setIsClickable(true);
        }, 400);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory])

    // update mount for initial render
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        } 
    }, [])

    return (
        <motion.div className = "Screen"
            initial='initial' 
            animate={animation.fadeStatus}
            variants={animation.fadeStatusAnimation}>
            <motion.div className = "HeadTitle" 
                        initial='initial' 
                        animate={animation.titleStatus}
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') {
                                animation.setTitleStatus('idle')
                            }
                        }}>
                Select Mode
            </motion.div>

            <div className='ModeScroll'>
                <Competitive setCategory={setSelectedCategory} setGamemode={setSelectedGamemode} selectedCategory={selectedCategory} selectedMode={selectedGamemode} isWhiteIcon={isWhiteIcon.current} 
                    animationStatus={animation.competitiveStatus} setAnimationStatus={animation.setCompetitiveStatus} animationVariants={animation.competitiveStatusAnimation}
                    FindMatch={FindMatch} findingMatch={findingMatch || !isClickable}/>
                <Casual setCategory={setSelectedCategory} setGamemode={setSelectedGamemode} selectedCategory={selectedCategory} selectedMode={selectedGamemode} isWhiteIcon={isWhiteIcon.current} 
                    animationStatus={animation.casualStatus} setAnimationStatus={animation.setCasualStatus} animationVariants={animation.casualStatusAnimation}
                    FindMatch={FindMatch} findingMatch={findingMatch || !isClickable}/>
                <Bot setCategory={setSelectedCategory} setGamemode={setSelectedGamemode} selectedCategory={selectedCategory} selectedMode={selectedGamemode} isWhiteIcon={isWhiteIcon.current} 
                    animationStatus={animation.botStatus} setAnimationStatus={animation.setBotStatus} animationVariants={animation.botStatusAnimation}
                    FindMatch={FindMatch} findingMatch={findingMatch || !isClickable}/>
                <Custom setCategory={setSelectedCategory} setGamemode={setSelectedGamemode} selectedCategory={selectedCategory} selectedMode={selectedGamemode} isWhiteIcon={isWhiteIcon.current} 
                    animationStatus={animation.customStatus} setAnimationStatus={animation.setCustomStatus} animationVariants={animation.customStatusAnimation}
                    FindMatch={FindMatch} findingMatch={findingMatch || !isClickable}/>
            </div>
            
            <CwgButton 
                initial='initial' 
                animate={animation.backStatus} 
                variants={animation.backAnimation}
                setAnimation={animation.setBackStatus}
                hasIdle={true}
                text={'Menu'} 
                recoverDuration={2000}
                onClick={() => { 
                    StepBack()
                }} 
                allowClick={true}
                isBack={true}/>
        </motion.div>
    )
}

export default Mode;