import { firestore, action } from "./CreateFirebaseEngine";
import { doc, onSnapshot } from "firebase/firestore";

import { Colors, Phases, Pieces } from '../utils/Enums'
import { stabPiece, opponentPieceFire, PosToCoord, CoordToPos, isCheckmate, updateShiver } from '../components/game/GameLogic'
import { getDefaultLoadout } from '../components/layout/LoadoutManager';
import { initializePieces, initializePieceCache } from '../components/game/GameSetup'
import { characters } from '../utils/CharacterMapper'

import sounds from '../sounds'

const TIMEOUT_IN_MILLISECONDS = 60000

const rejectionMessages = ["No draw, thanks.", "Let's keep playing.", "No, let's continue.", "Declined, let's play on.", "I prefer to play on."]
const acceptionMessages = ["It's a draw!", "Draw accepted.", "Okay.", "Sure, we can draw.", "Draw sounds good."]
const surrenderMessages = ["I surrender.", "It's over for me.", "I'm beaten.", ":/", "Okay, you win.", "I surrender."]

class GameEngine {
    #unsub;
    #matchId;
    #isClassic;
    #isBotMode;
    #gameMode;
    #initialized = false
    #botMoving = false
    #gameCreated = false
    #eventQueue = []
    #timeoutId;

    botStepsUpdated = 0 
    botUpdateSteps = []
    botUpdateCache = {}
    
    constructor(userId, matchId, oldPieces, setPieces, setTurn, setMyColor, 
                setMyUsername, setMyCredits, setMyLayout, setMyIncome, 
                setOpponentUsername, setOpponentCredits, setOpponentLayout,
                pieceCache, setPieceCache, setBoardShakeInfo, setScreenShakeInfo, 
                setShootAngle, setSelectedPos, gameOverStatus, setGameOverStatus,
                setOpponentDrawRequest, setOpponentSpeech, 
                opponentCharacter, setOpponentCharacter, 
                updateOpponentExpression, setUpdateTime, 
                gameMode, player_color,                                                                                                                                                                                                                                                                                                                                                                ) {
        this.#matchId = matchId;
        this.#gameMode = gameMode;
        this.#isClassic = gameMode.includes('classic');
        this.#isBotMode = gameMode.includes('bot');
        this.#botMoving = false
        this.#gameCreated = false
        this.#eventQueue = []

        this.botStepsUpdated = 0
        this.botUpdateSteps = []
        this.botUpdateCache = {}
        this.opponentExpressionTime = 0
        this.myExpressionTime = 0 // must be the same default value on backend

        this.boundCleanup = this.cleanup.bind(this);
        window.addEventListener('globalCleanup', this.boundCleanup);
        
        if (!this.#initialized && this.#isBotMode) {
            this.queueEvent({
                type: 'create',
                player_color: player_color,
            })
        }

        this.#unsub = onSnapshot(doc(firestore, this.#isBotMode ? `users/${userId}/saves/save` : "games/" + this.#matchId), async (doc) => {
            if (!this.#isBotMode) clearTimeout(this.#timeoutId)

            if (doc.exists && doc.data()) {
                if (!this.#isBotMode) this.#timeoutId = setTimeout(this.terminate, TIMEOUT_IN_MILLISECONDS)

                const data = doc.data();
                const isWhite = this.#isBotMode ? player_color === Colors.WHITE : userId === data['white']
                const myColor = isWhite ? Colors.WHITE : Colors.BLACK

                const pieces = data['pieces']
                const history = data['moves']
                const turn = data['turn']
                const phase = data['phase']

                if (!this.#isBotMode) setUpdateTime(data['lastUpdateTime'].toDate())
                if (!this.#initialized) {
                    this.#initialized = true
                    if (!this.#isBotMode) {
                        setMyColor(myColor)
                        setMyCredits(isWhite ? data['whiteCredits'] : data['blackCredits'])
                        setOpponentCredits(isWhite ? data['blackCredits'] : data['whiteCredits'])
                        setMyUsername(isWhite ? data['whiteUsername'] : data['blackUsername'])
                        setOpponentUsername(isWhite ? data['blackUsername'] : data['whiteUsername'])
                        const white_layout = data['whiteLayout'] ? data['whiteLayout'] : getDefaultLoadout();
                        const black_layout = data['blackLayout'] ? data['blackLayout'] : getDefaultLoadout();
                        setMyLayout(isWhite ? white_layout : black_layout)
                        setOpponentLayout(isWhite ? black_layout : white_layout)
                        setPieces(pieces)
                        setTurn({color: turn, phase: phase})
                        setOpponentCharacter(isWhite ? data['blackAvatar'] : data['whiteAvatar'])
                        this.#gameCreated = true
                    } else {
                        setPieces(initializePieces())
                        setPieceCache(initializePieceCache())
                    }

                    if (!this.#isClassic) {
                        setMyIncome(isWhite ? data['whiteIncome'] : data['blackIncome'])
                    }
                }

                if (!this.#isBotMode) {
                    const blackExpressionId = data['blackEmoteId'][0]
                    const whiteExpressionId = data['whiteEmoteId'][0]
                    const blackExpressionTime = data['blackEmoteId'][1]
                    const whiteExpressionTime = data['whiteEmoteId'][1]
                    const opponentCharacter = isWhite ? data['blackAvatar'] : data['whiteAvatar']
                    // checks that emote is -1 for idle and the update time is changed
                    if (isWhite && blackExpressionId >= 0 && blackExpressionId < 4 && blackExpressionTime !== this.opponentExpressionTime) {
                        updateOpponentExpression(characters[opponentCharacter].spammables[isWhite ? blackExpressionId : whiteExpressionId])
                        this.opponentExpressionTime = isWhite ? blackExpressionTime : whiteExpressionTime
                        return
                    } else if (!isWhite && whiteExpressionId >= 0 && whiteExpressionId < 4 && whiteExpressionTime !== this.opponentExpressionTime) {
                        updateOpponentExpression(characters[opponentCharacter].spammables[isWhite ? blackExpressionId : whiteExpressionId])
                        this.opponentExpressionTime = isWhite ? blackExpressionTime : whiteExpressionTime
                        return
                    } else if (isWhite && whiteExpressionTime !== this.myExpressionTime) {
                        this.myExpressionTime = whiteExpressionTime
                        // we dont have to capture our own emote update
                        return
                    } else if (!isWhite && blackExpressionTime !== this.myExpressionTime) {
                        this.myExpressionTime = blackExpressionTime
                        // we dont have to capture our own emote update
                        return
                    }
                }

                // do not update the front end if the change is made by the current user
                if (history.length > 0) {
                    if (!this.#gameCreated) return
                    const mostRecentMove = data['moves'][0]
                    const moveType = mostRecentMove.type
                    if (!this.#isClassic && moveType !== 'cwg_agent_move') {
                        setMyIncome(isWhite ? data['whiteIncome'] : data['blackIncome'])
                    }
                    if (moveType === "timeout") {
                        // TODO: tell the user that someone went afk for too long and the game is now over.
                        // The frontend should extrapolate who won/lost
                        alert("someone timed out")
                    } else if (myColor !== mostRecentMove.color) {
                        this.#botMoving = false
                        if (moveType === 'attack') {
                            const selectedPieceIdx = mostRecentMove.selectedPieceIdx
                            const attackedIdx = mostRecentMove.attackedIdx
                            const fromPos = mostRecentMove.from
                            const toPos = mostRecentMove.to
                            // only the stabbed piece need to be updated in animation
                            stabPiece(pieces, setPieces, pieceCache, setPieceCache, attackedIdx, toPos, fromPos, myColor, 0, (temp)=>{}, setBoardShakeInfo, setGameOverStatus, false)
                            if (isCheckmate(pieces, pieces[selectedPieceIdx].color, this.#isClassic, setGameOverStatus, setSelectedPos)) {
                                setPieces(pieces)
                                return
                            }
                            setPieces(pieces)
                            setTurn({color: turn, phase: phase})
                        } if (moveType === 'promote') {
                            const selectedPieceIdx = mostRecentMove.selectedPieceIdx
                            if (this.#isBotMode) {
                                const promoteTo = mostRecentMove.promoteTo
                                pieces[selectedPieceIdx].type = Pieces.PAWN
                                if (data['moves'][1].type === 'attack') {
                                    const secondMostRecentMove = data['moves'][1]
                                    const attackedIdx = secondMostRecentMove.attackedIdx
                                    const fromPos = secondMostRecentMove.from
                                    const toPos = secondMostRecentMove.to
                                    // only the stabbed piece need to be updated in animation
                                    stabPiece(pieces, setPieces, pieceCache, setPieceCache, attackedIdx, toPos, fromPos, myColor, 0, (temp)=>{}, setBoardShakeInfo, setGameOverStatus, false)
                                }
                                const timer = setTimeout(() => {
                                    pieces[selectedPieceIdx].type = promoteTo
                                    setPieces(pieces)
                                    isCheckmate(pieces, pieces[selectedPieceIdx].color, this.#isClassic, setGameOverStatus, setSelectedPos)
                                    return () => {clearTimeout(timer)};
                                }, 400);
                            } else {
                                setPieces(pieces)
                                isCheckmate(pieces, pieces[selectedPieceIdx].color, this.#isClassic, setGameOverStatus, setSelectedPos)
                            }
                            setPieces(pieces)
                            setTurn({color: turn, phase: phase})
                        } else if (moveType === 'fire' && mostRecentMove.shootAngle !== null) {
                            const hitIdxes = mostRecentMove.hitIdxes
                            const selectedPieceIdx = mostRecentMove.selectedPieceIdx
                            if (mostRecentMove.shootAngle === 999) {
                                setPieces(pieces)
                                setTurn({color: turn, phase: phase})
                                return
                            }
                            let shootAngle = mostRecentMove.shootAngle + Math.PI
                            if (shootAngle > Math.PI) {
                                shootAngle -= Math.PI * 2
                            }
                            if (hitIdxes.length > 0) {
                                let totalAngle = 0
                                // calculate the average of the hit angles
                                for (const hitIdx of hitIdxes) {
                                    const firstHitPiece = pieces[hitIdx]
                                    // recalculate shoot angle to accomodate the offset
                                    const [ originX, originY ] = PosToCoord(pieces[selectedPieceIdx].position, myColor)
                                    const [ targetX, targetY ] = PosToCoord(firstHitPiece.position, myColor)
                                    totalAngle += Math.atan2(targetY - originY, (targetX + 0.5) - (originX + 0.5));
                                }
                                setShootAngle(totalAngle / hitIdxes.length)
                            } else {
                                setShootAngle(shootAngle)
                            }
                            setSelectedPos(pieces[selectedPieceIdx].position)

                            const shootTimer = setTimeout(() => {
                                this.processFireAction(pieces, isWhite, mostRecentMove, pieceCache, setPieceCache, setGameOverStatus, setScreenShakeInfo, data)
                                setPieces(pieces)
                                setTurn({color: turn, phase: phase})
                                return () => {clearTimeout(shootTimer)};
                            }, 600);
                        } else if (moveType === 'buy') {
                            sounds['purchaseEquipment'].play()
                            setPieces(pieces)
                        } else if (moveType === 'castle') {
                            setPieces(pieces)
                            setTurn({color: turn, phase: phase})
                        } else if (moveType === 'move') {
                            const selectedPieceIdx = mostRecentMove.selectedPieceIdx
                            // const toPos = mostRecentMove.to
                            if (pieces[selectedPieceIdx].weapon !== 'knife') {
                                setPieces(pieces)
                                updateShiver(pieces, selectedPieceIdx, pieceCache, setPieceCache, phase, true)
                                setTurn({color: turn, phase: phase})
                            } else if (isCheckmate(pieces, pieces[selectedPieceIdx].color, this.#isClassic, setGameOverStatus, setSelectedPos)) {
                                setPieces(pieces)
                                return
                            }
                            setPieces(pieces)
                            setTurn({color: turn, phase: phase})
                        } else if (moveType === 'surrender') {
                            setOpponentSpeech(surrenderMessages[Math.floor(Math.random() * surrenderMessages.length)])
                            setGameOverStatus({winner: myColor === Colors.WHITE ? Colors.WHITE : Colors.BLACK, condition: 'Surrender'})
                        } else if (moveType === 'draw') {
                            setOpponentDrawRequest(true)
                        } else if (moveType === 'acceptDraw') {
                            setOpponentSpeech(acceptionMessages[Math.floor(Math.random() * acceptionMessages.length)])
                            setGameOverStatus({winner: Colors.NONE, condition: 'Draw'})
                        } else if (moveType === 'rejectDraw') {
                            setOpponentSpeech(rejectionMessages[Math.floor(Math.random() * rejectionMessages.length)])
                        } else if (moveType === 'cwg_agent_move') {
                            // special move packet for the bot agent, to condense transaction cost
                            const actions = mostRecentMove.actions
                            // revert the changes first, we don't want to update a game engine everytime when pieces change
                            for (let i = 0; i < actions.length; ++i) {
                                if (actions[i].type === 'buy') {
                                    const pieceIdx = actions[i].selectedPieceIdx
                                    const oldEquipment = actions[i].oldEquipment
                                    const equipment = actions[i].equipment
                                    if (equipment === 'shield') {
                                        pieces[pieceIdx].vest = false
                                    } else {
                                        pieces[pieceIdx].weapon = oldEquipment
                                    }
                                } else if (actions[i].type === 'promote') {
                                    const pieceIdx = actions[i].selectedPieceIdx
                                    pieces[pieceIdx].type = Pieces.PAWN
                                } else if (actions[i].type === 'move') {
                                    const pieceIdx = actions[i].selectedPieceIdx
                                    pieces[pieceIdx].position = actions[i].from
                                } else if (actions[i].type === 'attack') {
                                    const pieceIdx = actions[i].selectedPieceIdx
                                    const attackedIdx = actions[i].attackedIdx
                                    pieces[pieceIdx].position = actions[i].from
                                    pieces[attackedIdx].active = true
                                } else if (actions[i].type === 'castle') {
                                    const kingIdx = actions[i].kingIdx
                                    const rookIdx = actions[i].rookIdx
                                    pieces[kingIdx].position = oldPieces[kingIdx].position
                                    pieces[rookIdx].position = oldPieces[rookIdx].position
                                } else if (actions[i].type === 'fire') {
                                    const hitIdxes = actions[i].hitIdxes
                                    for (const idx of hitIdxes) {
                                        if (pieces[idx].active) pieces[idx].vest = true
                                        else pieces[idx].active = true
                                    }
                                }
                            }

                            this.botUpdateCache = {
                                turn: turn,
                                phase: phase,
                                whiteIncome: data['whiteIncome'],
                                blackIncome: data['blackIncome'],
                                isWhite: isWhite,
                            }
                            // check the first action, the rest will be updated by useEffect
                            const i = 0
                            if (actions[i].type === 'buy') {
                                const pieceIdx = actions[i].selectedPieceIdx
                                const equipment = actions[i].equipment
                                if (equipment === 'shield') {
                                    pieces[pieceIdx].vest = true
                                } else {
                                    pieces[pieceIdx].weapon = equipment
                                }
                                sounds['purchaseEquipment'].play()
                                setPieces(pieces)
                            } else if (actions[i].type === 'move') {
                                const pieceIdx = actions[i].selectedPieceIdx
                                const destination = actions[i].to
                                pieces[pieceIdx].position = destination
                                if (isCheckmate(pieces, pieces[pieceIdx].color, this.#isClassic, setGameOverStatus)) {
                                    setPieces(pieces)
                                    return
                                }
                                setPieces(pieces)
                            } else if (actions[i].type === 'attack') {
                                const pieceIdx = actions[i].selectedPieceIdx
                                const fromPos = actions[i].from
                                const destination = actions[i].to
                                const attackedIdx = actions[i].attackedIdx
                                pieces[pieceIdx].position = destination
                                // only the stabbed piece need to be updated in animation
                                stabPiece(pieces, setPieces, pieceCache, setPieceCache, attackedIdx, destination, fromPos, myColor, 0, (temp)=>{}, setBoardShakeInfo, setGameOverStatus, false)
                                if (isCheckmate(pieces, pieces[pieceIdx].color, this.#isClassic, setGameOverStatus, setSelectedPos)) {
                                    setPieces(pieces)
                                    return
                                }
                                setPieces(pieces)
                            } else if (actions[i].type === 'castle') {
                                const kingIdx = actions[i].kingIdx
                                const rookIdx = actions[i].rookIdx
                                const [kingX, kingY] = PosToCoord(pieces[kingIdx].position, myColor)
                                if (rookIdx === 0) {
                                    const newKingPos = CoordToPos(kingX - 2, kingY, myColor)
                                    const newRookPos = CoordToPos(kingX - 1, kingY, myColor)
                                    pieces[kingIdx].position = newKingPos
                                    pieces[rookIdx].position = newRookPos
                                } else {
                                    const newKingPos = CoordToPos(kingX + 2, kingY, myColor)
                                    const newRookPos = CoordToPos(kingX + 1, kingY, myColor)
                                    pieces[kingIdx].position = newKingPos
                                    pieces[rookIdx].position = newRookPos
                                }
                                setPieces(pieces)
                            }
                            if (actions.length === 1) {
                                this.botStepsUpdated = 0
                                this.botUpdateSteps = []
                                this.botUpdateCache = {}
                                // after every action finishes
                                const timer = setTimeout(() => {
                                    setMyIncome(isWhite ? data['whiteIncome'] : data['blackIncome'])
                                    setTurn({color: turn, phase: phase})
                                    return () => {clearTimeout(timer)};
                                }, 400);
                            } else if (actions.length > 1) {
                                this.botStepsUpdated = 1
                                this.botUpdateSteps = actions
                            }
                            if (i + 1 < actions.length && actions[i + 1].type === 'fire') {
                                setTurn({color: turn.color, phase: Phases.FIRE})
                            }
                        } 
                    } else if (this.#isBotMode && !this.#botMoving && this.#gameCreated) {
                        if (isCheckmate(pieces, Colors.WHITE, this.#isClassic) || isCheckmate(pieces, Colors.BLACK, this.#isClassic)) {
                            return
                        }
                        for (let i = 0; i < pieces.length; ++i) {
                            if (pieces[i].type === Pieces.KING && !pieces[i].active) {
                                return 
                            }
                        }
                        // all acknowledges that user's move have been registered, the bot agent can now make their move
                        if (mostRecentMove.color === myColor && turn !== myColor && phase === Phases.MOVE) {
                            // bot move have request, reset back to false after process is finished
                            this.#botMoving = true
                            const timer = setTimeout(() => {
                                this.queueEvent({
                                    type: 'move',
                                    selectedPieceIdx: 0,
                                    destination: ''
                                })
                                return () => {
                                    clearTimeout(timer)
                                };
                            }, 350);
                        } 
                    }
                } else {
                    this.#gameCreated = true
                }
                // once the update is received, remove the first event and process the next event (if there are any)
                if (this.#gameCreated) {
                    this.#eventQueue.shift()
                    await this.processEvent()
                }
            } else {
                console.log('game over')
            }
        });
    }

    processFireAction(pieces, isWhite, mostRecentMove, pieceCache, setPieceCache, setGameOverStatus, setScreenShakeInfo, data = null, isBot = false) {
        // offset by pi since it was the opponent's perspective
        const selectedPieceIdx = mostRecentMove.selectedPieceIdx
        let shootAngle = mostRecentMove.shootAngle + Math.PI 
        const hitIdxes = mostRecentMove.hitIdxes
        if (mostRecentMove.shootAngle === 999) {
            return
        }
        if (shootAngle > Math.PI) {
            shootAngle -= Math.PI * 2
        }
        if (!isBot && data) {
            opponentPieceFire(pieces, selectedPieceIdx, 
                isWhite ? data['blackLayout'][pieces[selectedPieceIdx].weapon] : data['whiteLayout'][pieces[selectedPieceIdx].weapon], 
                shootAngle, setScreenShakeInfo, pieceCache, setPieceCache, hitIdxes, setGameOverStatus)
        } else {
            opponentPieceFire(pieces, selectedPieceIdx, 'Pistol', 
                shootAngle, setScreenShakeInfo, pieceCache, setPieceCache, hitIdxes, setGameOverStatus)
        }
    }

    async queueEvent(event) {
        this.#eventQueue.push(event);
        // start processing the event if there are no events currently
        if (this.#eventQueue.length === 1) {
            if (this.#gameCreated || this.#eventQueue[0].type === 'create') {
                this.processEvent(event)
            }
        }
    }

    async processEvent() {
        if(this.#eventQueue.length === 0) {
            return
        }
        // retrieve the first event from the queue
        const event = this.#eventQueue[0];
        // process the event
        if (event.type === 'move') {
            await this.movePieceBackend(event.selectedPieceIdx, event.destination)
        } else if (event.type === 'promote') {
            await this.promotePieceBackend(event.selectedPieceIdx, event.promoteTo)
        } else if (event.type === 'buy') {
            await this.buyPieceBackend(event.selectedPieceIdx, event.equipment)
        } else if (event.type === 'fire') {
            await this.fireBackend(event.selectedPieceIdx, event.shootAngle)
        } else if (event.type === 'create') {
            await this.createGame(event.player_color)
        }
    }

    async createGame(player_color) {
        try {
            await action({
                matchId: '', 
                action: 'create',
                mode: this.#gameMode,
                args: [player_color]
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async movePieceBackend(selectedPieceIdx, destination) {
        try {
            await action({
                matchId: this.#matchId,
                action: 'move',
                mode: this.#gameMode,
                args: [selectedPieceIdx, destination]
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async buyPieceBackend(selectedPieceIdx, equipment) {
        try {
            await action({
                matchId: this.#matchId,
                action: 'buy',
                mode: this.#gameMode,
                args: [selectedPieceIdx, equipment]
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async promotePieceBackend(selectedPieceIdx, promoteTo) {
        try {
            await action({
                matchId: this.#matchId,
                action: 'promote',
                mode: this.#gameMode,
                args: [selectedPieceIdx, promoteTo]
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async fireBackend(selectedPieceIdx, shootAngle) {
        try {
            await action({
                matchId: this.#matchId,
                action: 'fire',
                mode: this.#gameMode,
                args: [selectedPieceIdx, shootAngle]
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async surrenderBackend() {
        try {
            if (this.#isBotMode) return
            await action({
                matchId: this.#matchId,
                action: 'surrender',
                mode: this.#gameMode,
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async requestDrawBackend() {
        try {
            if (this.#isBotMode) return
            await action({
                matchId: this.#matchId,
                action: 'request draw',
                mode: this.#gameMode,
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async acceptDrawBackend() {
        try {
            if (this.#isBotMode) return
            await action({
                matchId: this.#matchId,
                action: 'accept draw',
                mode: this.#gameMode,
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async rejectDrawBackend() {
        try {
            if (this.#isBotMode) return
            await action({
                matchId: this.#matchId,
                action: 'reject draw',
                mode: this.#gameMode,
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async emote(emoteId) {
        try {
            if (this.#isBotMode || emoteId < 0 || emoteId > 3) return
            await action({
                matchId: this.#matchId,
                action: 'emote',
                mode: this.#gameMode,
                args: [emoteId]
            })
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    async terminate() {
        try {
            // if (this.#isBotMode) return
            // await terminate()
        } catch (error) {
            throw new Error(error.code + ' ' + error.message)
        }
    }

    cleanup() {
        this.#unsub()
        this.#unsub = null
        window.removeEventListener('globalCleanup', this.boundCleanup);
    }

    destructor() {
        this.cleanup();
    }
}

export default GameEngine;