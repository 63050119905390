import { useState } from 'react';
import createFadeAnimation, { createUpAnimation, createDownAnimation, createFloatAnimation } from '../../utils/Animator';

const createCategoryAnimation = (delay = 0) => ({
    initial: {
        x: '-75vh', 
        opacity: 0
    },
    enter: {
        x: 0,
        opacity: 1, 
        transition: {
            type: 'spring',
            duration: 0.5,
            delay: delay
        }
    },
    exit: {
        x: '75vh',
        opacity: 0,
        transition: {
            type: 'spring', 
            duration: 0.5,
        }
    },
    shift: {},
    shiftIdle: {},
    idle: {}
});

const createShiftIdleAnimation = (x: string) => {
    return {
        y: ["0vh", "-1vh", "0vh"],
        opacity: 1,
        x: x, 
        transition: {
            duration: 4,  
            ease: "easeInOut",  
            repeat: Infinity,  
            repeatType: "loop",
        }
    }
}

const createModeAnimation = (xDest: string, delay = 0) => ({
    initial: {
        x: 0, 
        opacity: 0
    },
    enter: {
        x: xDest,
        opacity: 1, 
        transition: {
            type: 'spring',
            duration: 0.35,
            delay: delay
        }
    },
    exit: {
        x: 0,
        opacity: 0,
        transition: {
            type: 'spring', 
            duration: 0.4,
        }
    }, 
    idle: {}
});

export const createModeIdleAnimation = (x: string, delay = 0) => {
    return {
        y: ["0vh", "-1vh", "0vh"],
        opacity: 1,
        x: x, 
        transition: {
            duration: 4,  
            ease: "easeInOut",  
            repeat: Infinity,  
            repeatType: "loop",
            delay: delay
        }
    }
}

export const useCompetitiveAnimation = () => {
    const [standardStatus, setStandardStatus] = useState<string>('initial');
    const [classicStatus, setClassicStatus] = useState<string>('initial');
    const standardAnimation = createModeAnimation('33vh', 0.3)
    standardAnimation.idle = createModeIdleAnimation('33vh', 0.6)
    const classicAnimation = createModeAnimation('65vh', 0.2)
    classicAnimation.idle = createModeIdleAnimation('65vh', 0.3)

    function TransitionIn() { 
        setStandardStatus('enter')
        setClassicStatus('enter')
    }

    function TransitionOut() { 
        setStandardStatus('exit')
        setClassicStatus('exit')
    }

    return {
        standardStatus, setStandardStatus, standardAnimation,
        classicStatus, setClassicStatus, classicAnimation,
        TransitionIn, TransitionOut,
    };
}

export const useCasualAnimation = () => {
    const [standardStatus, setStandardStatus] = useState<string>('initial');
    const [classicStatus, setClassicStatus] = useState<string>('initial');
    const standardAnimation = createModeAnimation('33vh', 0.3)
    standardAnimation.idle = createModeIdleAnimation('33vh', 0.6)
    const classicAnimation = createModeAnimation('65vh', 0.2)
    classicAnimation.idle = createModeIdleAnimation('65vh', 0.3)

    function TransitionIn() { 
        setStandardStatus('enter')
        setClassicStatus('enter')
    }

    function TransitionOut() { 
        setStandardStatus('exit')
        setClassicStatus('exit')
    }

    return {
        standardStatus, setStandardStatus, standardAnimation,
        classicStatus, setClassicStatus, classicAnimation,
        TransitionIn, TransitionOut,
    };
}

export const useBotAnimation = () => {
    const [standardStatus, setStandardStatus] = useState<string>('initial');
    const [classicStatus, setClassicStatus] = useState<string>('initial');
    const [dummyStatus, setDummyStatus] = useState<string>('initial');
    const standardAnimation = createModeAnimation('33vh', 0.4)
    standardAnimation.idle = createModeIdleAnimation('33vh', 0.9)
    const classicAnimation = createModeAnimation('65vh', 0.3)
    classicAnimation.idle = createModeIdleAnimation('65vh', 0.6)
    const dummyAnimation = createModeAnimation('97vh', 0.2)
    dummyAnimation.idle = createModeIdleAnimation('97vh', 0.3)

    function TransitionIn() { 
        setStandardStatus('enter')
        setClassicStatus('enter')
        setDummyStatus('enter')
    }

    function TransitionOut() { 
        setStandardStatus('exit')
        setClassicStatus('exit')
        setDummyStatus('exit')
    }

    return {
        standardStatus, setStandardStatus, standardAnimation,
        classicStatus, setClassicStatus, classicAnimation,
        dummyStatus, setDummyStatus, dummyAnimation,
        TransitionIn, TransitionOut,
    };
}

export const useCustomAnimation = () => {
    const [joinStatus, setJoinStatus] = useState<string>('initial');
    const [hostStatus, setHostStatus] = useState<string>('initial');
    const joinAnimation = createModeAnimation('33vh', 0.3)
    joinAnimation.idle = createModeIdleAnimation('33vh', 0.6)
    const hostAnimation = createModeAnimation('65vh', 0.2)
    hostAnimation.idle = createModeIdleAnimation('65vh', 0.3)

    function TransitionIn() { 
        setJoinStatus('enter')
        setHostStatus('enter')
    }

    function TransitionOut() { 
        setJoinStatus('exit')
        setHostStatus('exit')
    }

    return {
        joinStatus, setJoinStatus, joinAnimation,
        hostStatus, setHostStatus, hostAnimation,
        TransitionIn, TransitionOut,
    };
}

export const useAnimationStatus = () => {
    const [fadeStatus, setFadeStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [titleStatus, setTitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [backStatus, setBackStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');

    const [competitiveStatus, setCompetitiveStatus] = useState<string>('enter');
    const [casualStatus, setCasualStatus] = useState<string>('enter');
    const [botStatus, setBotStatus] = useState<string>('enter');
    const [customStatus, setCustomStatus] = useState<string>('enter');

    const fadeStatusAnimation = createFadeAnimation(0)
    const titleStatusAnimation = createUpAnimation(0)
    const backAnimation = createDownAnimation(0)
    titleStatusAnimation.idle = createFloatAnimation(0)
    backAnimation.idle = createFloatAnimation(0.05)

    const competitiveStatusAnimation = createCategoryAnimation(0.3)
    const casualStatusAnimation = createCategoryAnimation(0.2)
    const botStatusAnimation = createCategoryAnimation(0.1)
    const customStatusAnimation = createCategoryAnimation(0)
    // left: -63
    // half width of 3 total tiles of width 30vh with 2vh gap is 94/2=47, we start at -47
    // hence -47--63=16
    const competitiveShiftX = '16vh'
    // left: -31
    // half width of 3 total tiles of width 30vh with 2vh gap is 94/2=47, we start at -47
    // hence -47--31=-16
    const casualShiftX = '-16vh'
    // right: 1
    // half width of 4 total tiles of width 30vh with 2vh gap is 126/2=63, we start at -63
    // hence -63-1=-64
    const botShiftX = '-64vh'
    // right: 33
    // half width of 3 total tiles of width 30vh with 2vh gap is 94/2=47, we start at -47
    // hence -47-33=-64
    const customShiftX = '-80vh'
    competitiveStatusAnimation.shift = { 
        x: competitiveShiftX,
        opacity: 1, 
        transition: {
            type: 'spring',
            duration: 0.5,
        }
    }
    casualStatusAnimation.shift = { 
        x: casualShiftX,
        opacity: 1, 
        transition: {
            type: 'spring',
            duration: 0.5,
        }
    }
    botStatusAnimation.shift = { 
        x: botShiftX,
        opacity: 1, 
        transition: {
            type: 'spring',
            duration: 0.5,
        }
    }
    customStatusAnimation.shift = { 
        x: customShiftX, 
        opacity: 1, 
        transition: {
            type: 'spring',
            duration: 0.5,
        }
    }
    competitiveStatusAnimation.shiftIdle = createShiftIdleAnimation(competitiveShiftX)
    casualStatusAnimation.shiftIdle = createShiftIdleAnimation(casualShiftX)
    botStatusAnimation.shiftIdle = createShiftIdleAnimation(botShiftX)
    customStatusAnimation.shiftIdle = createShiftIdleAnimation(customShiftX)
    competitiveStatusAnimation.idle = createFloatAnimation(0.9)
    casualStatusAnimation.idle = createFloatAnimation(0.6)
    botStatusAnimation.idle = createFloatAnimation(0.3)
    customStatusAnimation.idle = createFloatAnimation(0)

    function ExitCategories(selectedCategory: string) {
        if (selectedCategory === 'competitive') {
            setCompetitiveStatus('shift')
            setCasualStatus('exit')
            setBotStatus('exit')
            setCustomStatus('exit')
        } else if (selectedCategory === 'casual') {
            setCompetitiveStatus('exit')
            setCasualStatus('shift')
            setBotStatus('exit')
            setCustomStatus('exit')
        } else if (selectedCategory === 'bot') {
            setCompetitiveStatus('exit')
            setCasualStatus('exit')
            setBotStatus('shift')
            setCustomStatus('exit')
        } else if (selectedCategory === 'custom') {
            setCompetitiveStatus('exit')
            setCasualStatus('exit')
            setBotStatus('exit')
            setCustomStatus('shift')
        } 
    }

    function BackToCategories() { 
        if (competitiveStatus === 'exit' || competitiveStatus === 'shift' || competitiveStatus === 'shiftIdle') setCompetitiveStatus('enter')
        if (casualStatus === 'exit' || casualStatus === 'shift' || casualStatus === 'shiftIdle') setCasualStatus('enter') 
        if (botStatus === 'exit' || botStatus === 'shift' || botStatus === 'shiftIdle') setBotStatus('enter') 
        if (customStatus === 'exit' || customStatus === 'shift' || customStatus === 'shiftIdle') setCustomStatus('enter')
    } 

    function TransitionOut() { 
        setFadeStatus('exit')
        setTitleStatus('exit')
        setBackStatus('exit')

        setCompetitiveStatus('exit') 
        setCasualStatus('exit') 
        setBotStatus('exit') 
        setCustomStatus('exit')
    }

    return {
        fadeStatus, setFadeStatus, fadeStatusAnimation,
        titleStatus, setTitleStatus, titleStatusAnimation,
        backStatus, setBackStatus, backAnimation, 

        competitiveStatus, setCompetitiveStatus, competitiveStatusAnimation,
        casualStatus, setCasualStatus, casualStatusAnimation,
        botStatus, setBotStatus, botStatusAnimation,
        customStatus, setCustomStatus, customStatusAnimation,

        TransitionOut, ExitCategories, BackToCategories, 
    };
};