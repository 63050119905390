import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { AnimationControls, motion, useAnimation } from "framer-motion";

import '../../utils/Common.css';
import "./Gacha.css";

import sounds from "../../sounds";
import GachaEngine from "../../backend/GachaEngine";
import { BannerTypes } from "../../utils/Enums";
import images from "../../images";
import { useAnimationStatus } from "./Animation";
import CapsuleOpener from "./CapsuleOpener";
import CapsuleGenerator, { Capsule } from "./CapsuleGenerator";

interface Props {
    setAlertMessage: (alert: string) => void;
    BackToMenu: () => void;
    knuggets: number;
    setKnuggets: (newKnuggets: number) => void
}

export interface ShakeData {
    angle: number;
    force: number;
}

export interface Reward {
    item: string;
    type: string;
    rarity: string;
}

const Gacha: React.FC<Props> = ({ setAlertMessage, BackToMenu, knuggets, setKnuggets }) => {
    const animation = useAnimationStatus();
    const [machineCanLand, setMachineCanLand] = useState<boolean>(false)
    const [spinning, setSpinning] = useState<boolean>(false) // whenever maching is moving
    const [rolling, setRolling] = useState<boolean>(false) // when actully rolling x1 or x5
    const [capsules, setCapsules] = useState<Capsule[]>([])

    const [ gachaEngine, setGachaEngine ] = useState<GachaEngine | null>(null);
    const canRoll = gachaEngine && !spinning && !rolling
    const [rewards, setRewards] = useState<Reward[]>([]); // real rewards
    const [delayedRewards, setDelayedRewards] = useState<Reward[]>([]); // rewards used to update the capsulse

    const addCapsule = () => {
        const newCapsule = {
            id: Date.now(), 
            startX: '50vw',
            startY: '82.5vh',
            endX: `${-10 - Math.random() * 10}vw`,
            endY: `${75 + Math.random() * 15}vh`,
            peakHeight: `${20 + Math.random() * 30}vh`,
        };
        setCapsules(capsules => [...capsules, newCapsule]);

        // Set a timer to remove this capsule after 5 seconds
        setTimeout(() => {
            setCapsules(capsules => capsules.filter(capsule => capsule.id !== newCapsule.id));
        }, 2500);
    };

    useEffect(() => {
        const fetchGachaEngine = async () => {
            if (getAuth().currentUser) {
                setGachaEngine(new GachaEngine(getAuth().currentUser!.uid))
            }
        }
        fetchGachaEngine()
        const landTimer = setTimeout(() => {
            triggerMachineEnter()
            setMachineCanLand(true)
            triggerBallsEnter()
        }, 650);

        return () => clearTimeout(landTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function ToMenu() {
        animation.TransitionOut()
        triggerBallsExit()
        triggerMachineExit()
        const exitTimer = setTimeout(() => {
            BackToMenu()
            clearTimeout(exitTimer)
        }, 750);
    }

    function Roll1() {
        setRolling(true)
        triggerMachineShake(5, 2.5)
        triggerShakeBalls(5)
        const ball1Timer = setTimeout(() => {
            addCapsule()
            sounds['rollout' + Math.floor((Math.random() * 3) + 1)].play()
            clearTimeout(ball1Timer)
        }, 1100);
    }

    function Roll5() {
        setRolling(true)
        triggerMachineShake(8, 2.5)
        triggerShakeBalls(8)
        const ball1Timer = setTimeout(() => {
            addCapsule()
            sounds['rollout' + Math.floor((Math.random() * 3) + 1)].play()
            clearTimeout(ball1Timer)
        }, 1000);
        for (let i = 1; i <= 4; ++i) {
            const ballsTimer = setTimeout(() => {
                addCapsule()
                sounds['rollout' + Math.floor((Math.random() * 3) + 1)].play()
                clearTimeout(ballsTimer)
            }, 1100 + i * 150);
        }
    }

    useEffect(() => {
        if (rewards.length === 0) {
            setSpinning(false)
        } else {
            const ballsTimer = setTimeout(() => {
                setDelayedRewards(rewards)
                clearTimeout(ballsTimer)
            }, 1500 + rewards.length * 150);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewards])

    useEffect(() => {
        if (delayedRewards.length === 0) {
            setRewards(delayedRewards)
            setRolling(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delayedRewards])

    // Chaos Controller
    // ================================================
    const controls = useAnimation();

    const triggerCapsuleOpen = () => {
        const amplitude = 2.25;  // This controls how far out the shake goes
        controls.start({
            x: ['0vh', `${amplitude}vh`, `${amplitude}vh`, `-${amplitude}vh`, `-${amplitude}vh`, '0vh'],
            y: ['0vh', `${amplitude}vh`, `-${amplitude}vh`, `-${amplitude}vh`, `${amplitude}vh`, '0vh'],
            transition: { 
                duration: 0.05,  
                repeat: 2, // Repeat it to make it feel more intense
                repeatType: 'reverse',  // Goes back to the starting position each time
                ease: "easeInOut"  
            }
        });
    };

    const triggerMachineLanding = (force: number, angle: number) => {
        controls.start({
            x: `${Math.floor(Math.cos(angle) * (force))}vh`,
            y: `${Math.floor(Math.sin(angle) * (force))}vh`,
            transition: {
                duration: 0.05,
                ease: 'easeIn'
            }
        }).then(() => {
            controls.start({
                x: '0vh',
                y: '0vh',
                transition: {
                    duration: 1,
                    ease: 'easeOut'
                }
            });
        });
    };

    const machineControls = useAnimation();
    const triggerMachineShake = (repeat: number = 0, amp: number = 1.5) => {
        const amplitude = amp;  
    
        setSpinning(true)
        machineControls.start({
            rotate: [0, amplitude, -amplitude, 0],
            transition: {
                duration: 0.25,
                repeat: repeat,
                repeatType: 'reverse',  
                ease: "easeInOut"
            }
        }).then(() => {
            setSpinning(false)
        });
    };
    
    const ballsShake: AnimationControls[] = [useAnimation(), useAnimation(), useAnimation(), useAnimation()];
    const triggerShakeBalls = (repeat: number = 0, amp: number = 3, duration: number = 0.25, ballsIdx: number = -1) => {
        if (ballsIdx !== -1) {
            ballsShake[ballsIdx].start({
                y: ['0vh', `${-amp}vh`, '0vh'],
                x: 0,
                opacity: 1,
                transition: {
                    duration: duration,
                    ease: "easeInOut",
                    repeat: repeat,
                    repeatType: 'reverse'
                }
            });
            return
        }
        ballsShake.forEach((control, index) => {
            setTimeout(() => {
                control.start({
                    y: ['0vh', `${-amp}vh`, '0vh'],
                    x: 0,
                    opacity: 1,
                    transition: {
                        duration: duration,
                        ease: "easeInOut",
                        repeat: repeat,
                        repeatType: 'reverse'
                    }
                });
            }, index * 50); 
        });
    };
    const triggerBallsEnter = () => {
        ballsShake.forEach((control, index) => {
            setTimeout(() => {
                control.start({
                    y: ['-800vh', '0vh'],
                    x: 0,
                    opacity: 1,
                    transition: {
                        duration: 1,
                        ease: "easeInOut",
                    }
                }).then(() => {
                    triggerShakeBalls(0, 5, 0.5, index)
                });
            }, index * 50); 
        });
    };
    const triggerBallsExit = () => {
        ballsShake.forEach((control) => {
            control.start({
                y: 0,
                x: '100vh',
                opacity: 0,
                transition: {
                    type: 'spring', 
                    duration: 0.5,
                    delay: 0.1,
                }
            });
        });
    };

    const machineImageControl: AnimationControls = useAnimation()
    const triggerMachineEnter = () => {
        setTimeout(() => {
            machineImageControl.start({
                x: 0,
                y: ['-650vh', '0vh'],
                opacity: 1,
                transition: {
                    duration: 0.50,
                    ease: "easeInOut",
                }
            }).then(() => {
                triggerMachineLanding(4, Math.PI / 2)
            });
        }, 100); 
    };
    const triggerMachineExit = () => {
        machineImageControl.start({
            x: '100vh',
            y: 0,
            opacity: 0,
            transition: {
                type: 'spring', 
                duration: 0.5,
                delay: 0.1,
            }
        })
    };

    // ================================================
    
    return (
        <motion.div className = "Screen" style={{backgroundColor: '#333333'}}
                    initial = "initial"
                    animate = { animation.fadeStatus }
                    variants = { animation.fadeStatusAnimation }>
            <motion.div className='ScreenShakeWrapper' 
                        animate = { controls }>
                <motion.div className = "HeadTitle"
                    initial = "initial"
                    animate = { animation.titleStatus }
                    variants = { animation.titleStatusAnimation }
                    onAnimationComplete={()=>{
                        if (animation.titleStatus === 'enter') {
                            animation.setTitleStatus('idle')
                        }
                    }}>
                    Crime City
                </motion.div>
                
                <motion.div className = "Tokens"
                    initial = "initial"
                    animate = { animation.knuggetStatus }
                    variants = { animation.knuggetAnimation }
                    onAnimationComplete={()=>{
                        if (animation.knuggetStatus === 'enter') {
                            animation.setKnuggetStatus('idle')
                        }
                    }}>
                    <motion.div className="LargeText" style={{width: '100%'}}>
                        x {knuggets}
                    </motion.div>
                    <motion.img src={images['knugget']} style={{height: '100%'}}/>
                </motion.div>
                
                <motion.div className="GachaBanner"
                    initial = "initial"
                    animate = { animation.backgroundStatus }
                    variants = { animation.backgroundAnimation }
                    onAnimationComplete={()=>{
                        if (animation.backgroundStatus === 'enter') {
                            animation.setBackgroundStatus('idle')
                        }
                    }}> 
                </motion.div>
                <motion.div className="SpinButtons">
                    <motion.div className='Button' style={{position: 'absolute', bottom: '3.5vh', right: '12.5%'}}
                                onClick={() => { 
                                    if (!canRoll || knuggets < 20) return
                                    sounds['select'].play()
                                    Roll1()
                                    setKnuggets(knuggets - 20)
                                    gachaEngine.getPull(BannerTypes.ROTATING_BANNER, 1, setRewards)
                                }} 
                                onMouseEnter={()=>{
                                    if (!canRoll || knuggets < 20) return
                                    sounds['hover'].play()
                                }}
                                whileHover={{ scale: !canRoll || knuggets < 20 ? 1 : 1.1 }}
                                initial = "initial"
                                animate = { animation.roll1Status }
                                variants = { animation.roll1Animation }
                                onAnimationComplete={()=>{
                                    if (animation.roll1Status === 'enter') {
                                        animation.setRoll1Status('idle')
                                    }
                                }}> 
                        Spin 1
                    </motion.div>

                    <motion.div className='Button' style={{position: 'absolute', bottom: '3.5%', right: '3%'}}
                                onClick={() => { 
                                    if (!canRoll || knuggets < 100) return
                                    sounds['select'].play()
                                    Roll5()
                                    setKnuggets(knuggets - 100)
                                    gachaEngine.getPull(BannerTypes.ROTATING_BANNER, 5, setRewards)
                                }} 
                                onMouseEnter={()=>{
                                    if (!canRoll || knuggets <1000) return
                                    sounds['hover'].play()
                                }}
                                whileHover={{ scale: !canRoll || knuggets <1000 ? 1 : 1.1 }}
                                initial = "initial"
                                animate = { animation.roll5Status }
                                variants = { animation.roll5Animation }
                                onAnimationComplete={()=>{
                                    if (animation.roll5Status === 'enter') {
                                        animation.setRoll5Status('idle')
                                    } 
                                }}> 
                        Spin 5
                    </motion.div>
                </motion.div>

                <motion.div style={{
                            width: '75vh', 
                            bottom: '8vh',
                            position: 'absolute',
                            borderBottom: '0.5vh solid #6B6B6B',
                            pointerEvents: 'none'}}
                            initial = "initial"
                            animate = { animation.machineFloorStatus }
                            variants = { animation.machineFloorAnimation }/> 
                {machineCanLand && 
                <motion.div className="MachineWrapper"
                            animate = { machineControls }>
                    <motion.div className="GachaMachine"
                            style={{position: 'absolute'}}
                            initial = {{ y: '-800vh', opacity: 0 }}
                            animate = { ballsShake[0] }>
                        <motion.img style={{marginTop: '2.5vh', width: '100%', height: '100%'}} src={images["balls1"]}/>
                    </motion.div>
                    <motion.div className="GachaMachine"
                            style={{position: 'absolute'}}
                            initial = {{ y: '-800vh', opacity: 0 }}
                            animate = { ballsShake[1] }>
                        <motion.img style={{marginTop: '2.5vh', width: '100%', height: '100%'}} src={images["balls2"]}/>
                    </motion.div>
                    <motion.div className="GachaMachine"
                            style={{position: 'absolute'}}
                            initial = {{ y: '-800vh', opacity: 0 }}
                            animate = { ballsShake[2] }>
                        <motion.img style={{marginTop: '2.5vh', width: '100%', height: '100%'}} src={images["balls3"]}/>
                    </motion.div>
                    <motion.div className="GachaMachine"
                            style={{position: 'absolute'}}
                            initial = {{ y: '-800vh', opacity: 0 }}
                            animate = { ballsShake[3] }>
                        <motion.img style={{marginTop: '2.7vh', width: '100%', height: '100%'}} src={images["balls4"]}/>
                    </motion.div>
                    <motion.div className="GachaMachine"
                            style={{position: 'absolute'}} 
                            initial = {{ y: '-650vh', opacity: 0 }}
                            animate = { machineImageControl }>
                        <motion.img draggable={false} style={{width: '100%', height: '100%'}} src={rolling ? images["machine2"] : images["machine1"]}/>
                        <motion.div style={{position:'absolute', display:'flex', width: '37.5vh', height: '75vh', pointerEvents: 'auto'}} onClick={() => {
                            if (spinning) return
                            triggerMachineShake()
                            triggerShakeBalls(0, 1)
                        }}/>
                    </motion.div>
                </motion.div>}

                <CapsuleGenerator capsules={capsules}/>
                
                <motion.div className='BackButton' 
                            onClick={() => { 
                                sounds['select'].play()
                                ToMenu()
                            }} 
                            onMouseEnter={()=>{sounds['hover'].play()}}
                            whileHover={{ scale: 1.1 }}
                            initial = "initial"
                            animate = { animation.backStatus }
                            variants = { animation.backStatusAnimation }
                            onAnimationComplete={()=>{
                                if (animation.backStatus === 'enter') {
                                    animation.setBackStatus('idle')
                                }
                            }}> 
                    Back 
                </motion.div>
            </motion.div>
            
        <CapsuleOpener animate={controls} rewards={delayedRewards} setRewards={setDelayedRewards} shake={triggerCapsuleOpen}/>
        </motion.div>
    )
}

export default Gacha;