import images from '../images';
import sounds from '../sounds';
import { Equipments, Pieces, Sets } from './Enums'

interface Skin {
    type: string;
    set: Sets;
    grade: string;
    kind: 'piece' | 'equipment' | 'tile';
    whiteImage: string; // display image
    blackImage: string;
    order: number;
    equipmentDetail?: {
        avatar: string;
        avatarScale: number;
        avatarMarginRight: number;
        activeImage: string;
        idleImage: string;
        originX: string; // rotation origin X
        originY: string; // rotation origin Y
        skinDisplayYOffset: number // for the customization display
        fireSound: Howl | null
        reloadSound: Howl | null
    }
}

// define the new skins manually here with their custom name
const skinPreset = [
    // pieces
    {name: 'Grunt', type: Pieces.PAWN, set: Sets.DEFAULT, grade: 'normal', order: 100},
    {name: 'Sailor', type: Pieces.ROOK, set: Sets.DEFAULT, grade: 'normal', order: 101},
    {name: 'Cavalry', type: Pieces.KNIGHT, set: Sets.DEFAULT, grade: 'normal', order: 102},
    {name: 'Cadet', type: Pieces.BISHOP, set: Sets.DEFAULT, grade: 'normal', order: 103},
    {name: 'The Lieutenant', type: Pieces.QUEEN, set: Sets.DEFAULT, grade: 'normal', order: 104},
    {name: 'The General', type: Pieces.KING, set: Sets.DEFAULT, grade: 'normal', order: 105},

    {name: 'Pawn', type: Pieces.PAWN, set: Sets.CLASSIC, grade: 'normal', order: 106},
    {name: 'Rook', type: Pieces.ROOK, set: Sets.CLASSIC, grade: 'normal', order: 107},
    {name: 'Knight', type: Pieces.KNIGHT, set: Sets.CLASSIC, grade: 'normal', order: 108},
    {name: 'Bishop', type: Pieces.BISHOP, set: Sets.CLASSIC, grade: 'normal', order: 109},
    {name: 'The Queen', type: Pieces.QUEEN, set: Sets.CLASSIC, grade: 'normal', order: 110},
    {name: 'The King', type: Pieces.KING, set: Sets.CLASSIC, grade: 'normal', order: 111},

    {name: 'Debt Collector', type: Pieces.PAWN, set: Sets.MAFIA, grade: 'normal', order: 200},
    {name: 'Body Guard', type: Pieces.ROOK, set: Sets.MAFIA, grade: 'normal', order: 201},
    {name: 'Hitman', type: Pieces.KNIGHT, set: Sets.MAFIA, grade: 'normal', order: 202},
    {name: 'Vice', type: Pieces.BISHOP, set: Sets.MAFIA, grade: 'normal', order: 203},
    {name: 'The Mistress', type: Pieces.QUEEN, set: Sets.MAFIA, grade: 'normal', order: 204},
    {name: 'The Godfather', type: Pieces.KING, set: Sets.MAFIA, grade: 'normal', order: 205},

    {name: 'Debt Collector Prestige', type: Pieces.PAWN, set: Sets.ROUGE, grade: 'prestige', order: 206},
    {name: 'Body Guard Prestige', type: Pieces.ROOK, set: Sets.ROUGE, grade: 'prestige', order: 207},
    {name: 'Hitman Prestige', type: Pieces.KNIGHT, set: Sets.ROUGE, grade: 'prestige', order: 208},
    {name: 'Vice Prestige', type: Pieces.BISHOP, set: Sets.ROUGE, grade: 'prestige', order: 209},
    {name: 'The Mistress Prestige', type: Pieces.QUEEN, set: Sets.ROUGE, grade: 'prestige', order: 210},
    {name: 'The Godfather Prestige', type: Pieces.KING, set: Sets.ROUGE, grade: 'prestige', order: 211},

    {name: 'Police', type: Pieces.PAWN, set: Sets.LAW, grade: 'normal', order: 300},
    {name: 'Sergeant', type: Pieces.ROOK, set: Sets.LAW, grade: 'normal', order: 301},
    {name: 'Patrol', type: Pieces.KNIGHT, set: Sets.LAW, grade: 'normal', order: 302},
    {name: 'Constable', type: Pieces.BISHOP, set: Sets.LAW, grade: 'normal', order: 303},
    {name: 'The Prosecutor', type: Pieces.QUEEN, set: Sets.LAW, grade: 'normal', order: 304},
    {name: 'The Judge', type: Pieces.KING, set: Sets.LAW, grade: 'normal', order: 305},

    {name: 'Police Prestige', type: Pieces.PAWN, set: Sets.LAW_PRESTIGE, grade: 'prestige', order: 306},
    {name: 'Sergeant Prestige', type: Pieces.ROOK, set: Sets.LAW_PRESTIGE, grade: 'prestige', order: 307},
    {name: 'Patrol Prestige', type: Pieces.KNIGHT, set: Sets.LAW_PRESTIGE, grade: 'prestige', order: 308},
    {name: 'Constable Prestige', type: Pieces.BISHOP, set: Sets.LAW_PRESTIGE, grade: 'prestige', order: 309},
    {name: 'The Prosecutor Prestige', type: Pieces.QUEEN, set: Sets.LAW_PRESTIGE, grade: 'prestige', order: 310},
    {name: 'The Judge Prestige', type: Pieces.KING, set: Sets.LAW_PRESTIGE, grade: 'prestige', order: 311},

    // tiles
    // {name: 'Default Tile', type: Pieces.TILE, set: Sets.DEFAULT, grade: 'normal'},
    // {name: 'Mafia Tile', type: Pieces.TILE, set: Sets.DEFAULT, grade: 'normal'},
]

const equipmentPreset = [
    // defaults
    {name: 'Sniper', type: Equipments.SNIPER, set: Sets.DEFAULT, order: 120, scale: 4.25, margin: 0, originX: '25%', originY: '50%', grade: 'normal', skinDisplayYOffset: -1.5,
        fireSound: sounds['sniperFire'], reloadSound: sounds['sniperReload']},
    {name: 'Rifle', type: Equipments.RIFLE, set: Sets.DEFAULT, order: 121, scale: 4.25, margin: 2.5, originX: '0%', originY: '50%', grade: 'normal', skinDisplayYOffset: 0,
        fireSound: sounds['rifleFire'], reloadSound: sounds['rifleReload']},
    {name: 'Shotgun', type: Equipments.SHOTGUN, set: Sets.DEFAULT, order: 122, scale: 4.5, margin: 5.5, originX: '0%', originY: '50%', grade: 'normal', skinDisplayYOffset: 0.5,
        fireSound: sounds['shotgunFire'], reloadSound: sounds['shotgunReload']},
    {name: 'Pistol', type: Equipments.PISTOL, set: Sets.DEFAULT, order: 123, scale: 4.5, margin: 12, originX: '0%', originY: '50%', grade: 'normal', skinDisplayYOffset: 1,
        fireSound: sounds['pistolFire'], reloadSound: sounds['pistolReload']},
    {name: 'Knife', type: Equipments.KNIFE, set: Sets.DEFAULT, order: 124, scale: 7, margin: 8, originX: '0%', originY: '50%', grade: 'normal', skinDisplayYOffset: -3.75,
        fireSound: null, reloadSound: null},
    {name: 'Shield', type: Equipments.SHIELD, set: Sets.DEFAULT, order: 125, scale: 2.25, margin: 12.5, originX: '0%', originY: '50%', grade: 'normal', skinDisplayYOffset: -1,
        fireSound: null, reloadSound: null},

    // skins
    {name: 'Tommy Gun', type: Equipments.RIFLE, set: Sets.ROUGE, order: 220, scale: 4.7, margin: 3.8, originX: '0%', originY: '50%', grade: 'prestige', skinDisplayYOffset: 1.25,
        fireSound: sounds['tommyGunFire'], reloadSound: sounds['tommyGunReload']},
    {name: 'Revolver', type: Equipments.PISTOL, set: Sets.LAW_PRESTIGE, order: 221, scale: 4.5, margin: 12, originX: '0%', originY: '50%', grade: 'prestige', skinDisplayYOffset: 0.5,
        fireSound: sounds['pistolFire'], reloadSound: sounds['pistolReload']},
]

const skins: Record<string, Skin> = {};
skinPreset.forEach((skin) => {
    const key = skin.name;
    skins[key] = {
        set: skin.set,
        type: skin.type,
        grade: skin.grade, 
        kind: 'piece',
        whiteImage: images[`white_${skin.type}_${skin.set}`] || images[`white_${skin.type}_${Sets.DEFAULT}`],
        blackImage: images[`black_${skin.type}_${skin.set}`] || images[`black_${skin.type}_${Sets.DEFAULT}`],
        order: skin.order
    };
});
equipmentPreset.forEach((skin) => {
    const key = skin.name;
    skins[key] = {
        set: skin.set,
        type: skin.type,
        grade: skin.grade,
        kind: 'equipment',
        order: skin.order,
        whiteImage: images[`${skin.type}_${skin.set}_icon`],
        blackImage: images[`${skin.type}_${skin.set}_icon`],
        equipmentDetail: {
            avatar: images[`${skin.type}_${skin.set}_icon`],
            avatarScale: skin.scale,
            avatarMarginRight: skin.margin, 
            activeImage: images[`${skin.type}_${skin.set}_active`],
            idleImage: images[`${skin.type}_${skin.set}_idle`],
            originX: skin.originX,
            originY: skin.originY, 
            skinDisplayYOffset: skin.skinDisplayYOffset,
            fireSound: skin.fireSound,
            reloadSound: skin.reloadSound
        }
    };
});

// pieceAlias is in the form of pawn2, pawn3, etc...
export function getValidatedSkinName(pieceAlias: string, skinName: string) {
    if (skinName in skins) {
        return skinName
    }
    if (pieceAlias.includes('pawn')) {
        return 'Grunt';
    } else if (pieceAlias.includes('rook')) {
        return 'Sailor';
    } else if (pieceAlias.includes('knight')) {
        return 'Cavalry';
    } else if (pieceAlias.includes('bishop')) {
        return 'Cadet';
    } else if (pieceAlias.includes('queen')) {
        return 'The Lieutenant';
    } else if (pieceAlias.includes('king')) {
        return 'The General';
    } 
    return skinName
}

export function getSkinPresetByName(name: string, type: string = 'piece') {
    if (type === 'weapon') {
        return equipmentPreset.find(preset => preset.name === name);
    }
    return skinPreset.find(preset => preset.name === name);
}

export function getPrestigeColor(set: Sets): string {
    if (set === Sets.ROUGE) {
        return '#581022'
    } else if (set === Sets.LAW_PRESTIGE) {
        return '#232d46'
    } else {
        return '#D9D9D9'
    }
}

export function getSetFade(color: string): number {
    if (color === '#581022') { // rouge
        return 85
    } else if (color === '#232d46') { // law prestige 
        return 85
    } else {
        return 80
    }
}

export function getSetGlow(color: string): number {
    if (color === '#581022') { // rouge
        return 20
    } else if (color === '#232d46') { // law prestige 
        return 50
    } else {
        return 15
    }
}

export default skins;