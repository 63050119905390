import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Origin.css';
import { Screens } from "../../utils/Enums"

import * as ScreenAnimation from './Animation';
import CharacterHandler from '../../utils/CharacterHandler';
import { avaliableAvatars } from '../../utils/CharacterMapper';
import CwgButton from '../../utils/CwgButton';

interface Props {
    setAlertMessage: (alert: string) => void;
    ToLogin: () => void;
    ToRegister: () => void;
}

const randomPrenameMessages: String[] = ['You are here with, -', 'Find it relaxed with ', 'Find it fun with ', "Hi it's ", "", 'Down for chess with ', ':3 -sent by ', 'Hello from ', '', '', '', 'Hmm... -', '', '', '', "I'm "]
const randomPostnameMessages: String[] = ['.', '?', '?', '!', ' asks about your day!', '?', '.', '.', ' is staring you.', ' is looking for you.', " doesn't seem to like you.", '.', ' wants to bite.', ' is winking at you.', ' is judging you...', '.']

const randomCharacter = Math.floor(Math.random() * avaliableAvatars.length)
const randomMessageIdx = Math.floor(Math.random() * randomPrenameMessages.length)

const Origin: React.FC<Props> = ({ setAlertMessage, ToLogin, ToRegister }) => {
    // the stage we are on, will be set to login / register, once animations finish SetPage will be called
    const [stage, setStage] = useState(Screens.ORIGIN)
    const animation = ScreenAnimation.useAnimationStatus();

    const [characterExpression, setCharacterExpression] = useState<string>('idle')

    useEffect(() => {
        if (stage === Screens.LOGIN || stage === Screens.REGISTER) {
            animation.TransitionOut()
            const exitTimer = setTimeout(() => {
                if(stage === Screens.LOGIN) ToLogin()
                if(stage === Screens.REGISTER) ToRegister()
                clearTimeout(exitTimer)
            }, 250);
        }
    }, [stage, animation, ToLogin, ToRegister])

    return (
        <motion.div className = "Screen" 
                    initial='initial' 
                    animate={animation.fadeStatus} 
                    variants={animation.fadeStatusAnimation}>

            <motion.div className = "HeadTitle" 
                        initial='initial' 
                        animate={animation.titleStatus}
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') { animation.setTitleStatus('idle') }
                        }}>
                Chess with Guns
            </motion.div>

            <motion.div className = "Subtitle" 
                        initial='initial' 
                        animate={animation.subtitleStatus}
                        variants={animation.subtitleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.subtitleStatus === 'enter') { animation.setSubtitleStatus('idle') }
                        }}>
                Finally, chess have received an update after a thousand years!
            </motion.div>
            
            <motion.div className = "Profile" 
                        initial='initial' 
                        animate={animation.profileStatus}
                        variants={animation.profileStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.profileStatus === 'enter') { animation.setProfileStatus('idle') }
                        }}>
                
                <CharacterHandler name={avaliableAvatars[randomCharacter]} expression={characterExpression} setExpression={setCharacterExpression} allowAnnoy={true} smallBorder={true}/>
                <motion.div className = "Username" style={{scale: 1, marginBottom: '3vh', marginTop: '1.25vh'}}>{`${randomPrenameMessages[randomMessageIdx]}${avaliableAvatars[randomCharacter]}${randomPostnameMessages[randomMessageIdx]}`}</motion.div>
                <motion.div className = "TextSmall" style={{scale: 1.25}}></motion.div>
            </motion.div>
            
            <CwgButton 
                initial='initial'  
                animate={animation.loginButtonStatus}
                variants={animation.loginButtonStatusAnimation}
                setAnimation={animation.setLoginButtonStatus}
                hasIdle={true}
                text={'LOGIN'} 
                onClick={() => { setStage(Screens.LOGIN) }} 
                allowClick={animation.fadeStatus !== 'exit'}
                recoverDuration={2000}/>
            
            <CwgButton 
                initial='initial'  
                animate={animation.loginButtonStatus}
                variants={animation.loginButtonStatusAnimation}
                setAnimation={animation.setLoginButtonStatus}
                hasIdle={true}
                text={'REGISTER'} 
                onClick={() => { setStage(Screens.REGISTER) }} 
                allowClick={animation.fadeStatus !== 'exit'}
                recoverDuration={2000}/>

        </motion.div>
    );
}

export default Origin;