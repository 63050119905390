import React from 'react';
import { motion } from 'framer-motion';

import { Colors, Equipments, Pieces } from '../../utils/Enums'
import skins from '../../utils/SkinMapper'
import sounds from '../../sounds';

interface Props {
    skinName: string; 
    selectedSkinName: string;
    equipedSkinName: string;
    selectedColor: Colors;
    selectSkinIcon: (skin: string) => void;
}

// maps the additional offsets in viewport height (vh) of the piece in the skin icon pannel
// note: positive is down, negative is up
const offsetMapping: { [key in string]? : number } = {
    [Pieces.PAWN]: -15,
    [Pieces.ROOK]: -10,
    [Pieces.KNIGHT]: 0,
    [Pieces.BISHOP]: 10,
    [Pieces.QUEEN]: 17.5,
    [Pieces.KING]: 20,
    [Equipments.SNIPER]: 0,
    [Equipments.RIFLE]: 10,
    [Equipments.SHOTGUN]: 5, 
    [Equipments.PISTOL]: 0,
    [Equipments.SHIELD]: 0,
    [Equipments.KNIFE]: 0,
}

const shiftMapping: any = {
    [Equipments.SNIPER]: 0,
    [Equipments.RIFLE]: 0,
    [Equipments.SHOTGUN]: 3,
    [Equipments.PISTOL]: 0,
    [Equipments.SHIELD]: 0, 
    [Equipments.KNIFE]: 0,
}

const rotationMapping: any = {
    [Equipments.SNIPER]: 42.5,
    [Equipments.RIFLE]: 45,
    [Equipments.SHOTGUN]: 40,
    [Equipments.PISTOL]: 35,
    [Equipments.SHIELD]: 0, 
    [Equipments.KNIFE]: 45,
}

const SkinIcon: React.FC<Props> = ({ skinName, selectedSkinName, equipedSkinName, selectedColor, selectSkinIcon }) => {
    const selectedBackground = selectedSkinName !== '' && selectedSkinName === skinName ? { backgroundColor: `#CBCBCB`, zIndex: 5} : {};
    const equipedBackground = equipedSkinName !== '' && equipedSkinName === skinName ? { boxShadow: 'inset 0 0 0 2px #A3A3A3' } : {};
    const offsetValue = offsetMapping[skins[skinName].type] || 0;
    const shiftValue = shiftMapping[skins[skinName].type] || 0;
    
    const combinedStyles = {
        ...selectedBackground,
        ...equipedBackground
    };

    return (
        <div className="SkinIconImageBackground" style={combinedStyles}>
            <motion.div className='SkinIconControlPane'
                        onMouseEnter={() => {sounds['hover'].play()}}
                        whileHover={{ scale: 1.2 }} 
                        onClick={() => {
                            selectSkinIcon(skinName)
                            sounds['select'].play()
                        }}>
                <motion.img className="SkinIconImage" style={{ marginTop: `${offsetValue}%`, marginLeft: `${shiftValue}%`, 
                                transform: skins[skinName].kind === 'equipment' ? 
                                    `scale(${skins[skinName].equipmentDetail?.avatarScale! / 3.5}) rotate(-${rotationMapping[skins[skinName].type]}deg)` : '' }}
                            src={ selectedColor === Colors.WHITE ? skins[skinName].whiteImage : skins[skinName].blackImage } 
                            draggable="false"/>
            </motion.div>
        </div>
    )
}

export default SkinIcon;