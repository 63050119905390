import React from 'react';
import { motion } from 'framer-motion';
import sounds from '../../sounds';

interface LoadoutButtonProps {
    loadoutIndex: number;
    selectedLoadoutIndex: number;
    setSelectedLoadout: (loadoutIndex: number) => void;
    DeselectAll: () => void;
    animation: any;
}

const LoadoutButton: React.FC<LoadoutButtonProps> = ({ loadoutIndex, selectedLoadoutIndex, setSelectedLoadout, DeselectAll, animation }) => {
    const isSelected = loadoutIndex === selectedLoadoutIndex
    return (
        <motion.div 
            className='LoadoutButton' 
            initial='initial' 
            animate={animation.layoutButtonStatus[loadoutIndex - 1]}
            variants={animation.layoutButtonAnimation[loadoutIndex - 1]}
            style={{backgroundColor: isSelected ? "#CBCBCB" : "#D9D9D9"}}
            whileHover={{ scale: isSelected ? 1 : 1.15 }}
            onMouseEnter={()=>{sounds['hover'].play()}}
            onClick={() => {
                sounds['select'].play()
                setSelectedLoadout(loadoutIndex);
                DeselectAll();
            }}
        >
            {loadoutIndex}
        </motion.div>
    );
};

export default LoadoutButton;