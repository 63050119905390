export const createDarkenAnimation = (delay = 0) => ({
    initial: {
        backgroundColor: '#D9D9D9'
    },
    enter: {
        backgroundColor: '#333333',
        transition: {
            duration: 0.5,
            ease: "easeInOut",
            delay
        }
    },
    exit: {
        backgroundColor: '#D9D9D9',
        transition: {
            duration: 0.25,
            ease: "easeInOut",
            delay: 0.4
        }
    }
});

export const createFadeAnimation = (delay = 0) => ({
    initial: {
        opacity: 0
    },
    enter: {
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: "easeInOut",
            delay
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5,
            ease: "easeInOut",
            delay
        }
    }
});

export const createFadeZoomAnimation = (delay = 0) => ({
    initial: {
        opacity: 0,
        scale: 0.8
    },
    enter: {
        opacity: 1,
        scale: [0.8, 1.1, 1],
        transition: {
            duration: 0.25,
            ease: "easeInOut",
            delay
        }
    },
    exit: {
        opacity: 0,
        scale: [1, 1.1, 0.8],
        transition: {
            duration: 0.25,
            ease: "easeInOut",
            delay
        }
    }
});
  
export const createScaleAnimation = (delay = 0) => ({
    initial: {
        scale: 0.5
    },
    enter: {
        scale: 1.1,
        transition: {
            duration: 0.3,
            ease: "easeInOut",
            delay
        }
    },
    exit: {
        scale: 0.65,
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            delay
        }
    }
});

export const createDownAnimation = (delay = 0, sameDir = false) => ({
    initial: {
        y: '20vh',
        opacity: 0
    },
    enter: {
        y: 0,
        opacity: 1, 
        transition: {
            type: 'spring', 
            duration: 0.5,
            delay
        }
    },
    exit: {
        y: sameDir ? '-20vh' : '20vh', 
        opacity: 0,
        transition: {
            type: 'spring', 
            duration: 0.5,
            delay
        }
    }, 
    idle: {}
});

export const createUpAnimation = (delay = 0, sameDir = false) => ({
    initial: {
        x: 0,
        y: '-20vh', 
        opacity: 0
    },
    enter: {
        x: 0,
        y: 0,
        opacity: 1, 
        transition: {
            type: 'spring', 
            duration: 0.45,
            delay
        }
    },
    exit: {
        x: 0,
        y: sameDir ? '20vh' : '-20vh',
        opacity: 0,
        transition: {
            type: 'spring', 
            duration: 0.45,
            delay
        }
    }, 
    idle: {}
});

export const createLeftAnimation = (delay = 0, sameDir = false) => ({
    initial: {
        x: '-75vh', 
        opacity: 0
    },
    enter: {
        x: 0,
        opacity: 1, 
        transition: {
            type: 'spring',
            duration: 0.75,
            delay
        }
    },
    exit: {
        x: sameDir ? '-75vh' : '75vh',
        opacity: 0,
        transition: {
            type: 'spring', 
            duration: 0.9,
            delay
        }
    }, 
    idle: {}
});

export const createRightAnimation = (delay = 0, sameDir = false) => ({
    initial: {
        x: '75vh',
        opacity: 0
    },
    enter: {
        x: 0,
        opacity: 1, 
        transition: {
            type: 'spring', 
            duration: 0.75,
            delay
        }
    },
    exit: {
        x: sameDir ? '75vh' : '-75vh',
        opacity: 0,
        transition: {
            type: 'spring', 
            duration: 0.9,
            delay
        }
    }, 
    idle: {}
});

export const createFloatAnimation = (delay = 0) => {
    return {
        y: ["0vh", "-1vh", "0vh"],
        opacity: 1,
        x: 0, 
        transition: {
            duration: 4,  
            ease: "easeInOut",  
            repeat: Infinity,  
            repeatType: "loop",
            delay
        }
    }
}

export const useAlertAnimation = () => {
    return {
        initial: {
            x: 0,
            y: '-25vh',
            scale: 1,
            opacity: 0,
        },
        enter: {
            x: 0,
            y: 0,
            scale: 1,
            opacity: 1,
            transition: { 
                type: 'spring',
                duration: 0.5,
            }
        },
        exit: {
            x: 0, 
            y: '-25vh',
            scale: 1,
            opacity: 0,
            transition: { 
                duration: 0.5,
                ease: "easeInOut",
            }
        },
        idle: {}
    }
}

// opagueless animations
export const createUpAnimationNoOpacity = (delay = 0, sameDir = false) => ({
    initial: {
        x: 0,
        y: '-20vh', 
    },
    enter: {
        x: 0,
        y: 0,
        transition: {
            type: 'spring', 
            duration: 0.45,
            delay
        }
    },
    exit: {
        x: 0,
        y: sameDir ? '20vh' : '-20vh',
        transition: {
            type: 'spring', 
            duration: 0.45,
            delay
        }
    }, 
    idle: {}
});

export const createFloatAnimationNoOpacity = (delay = 0) => {
    return {
        y: ["0vh", "-1vh", "0vh"],
        x: 0, 
        transition: {
            duration: 4,  
            ease: "easeInOut",  
            repeat: Infinity,  
            repeatType: "loop",
            delay
        }
    }
}


export default createFadeAnimation