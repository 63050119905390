export enum Screens {
    // completed
    ORIGIN = 'origin', 
    LOGIN = 'login', 
    REGISTER = 'register', 
    MENU = 'menu', 
    GACHA = 'gacha', 
    BOARD = 'board', 
    MODE = 'mode', 
    QUEUE = 'queue',
    GAME = 'game', 
    HOST = 'host',
    JOIN = 'join', 
    VERIFY = 'verify', 
    RECOVER = 'recover', 

    // incomplete
    LOADING = 'loading', 
    SHOP = 'shop', 
}

export enum Gamemodes {
    STANDARD_COMPETITIVE = 'competitive standard',
    CLASSIC_COMPETITIVE = 'competitive classic',

    STANDARD_CASUAL = 'casual standard',
    CLASSIC_CASUAL = 'casual classic',
    JOIN_GAME = 'host game',
    HOST_GAME = 'join game',

    DUMMY_BOT = 'dummy',
    STANDARD_BOT = 'standard bot',
    CLASSIC_BOT = 'classic bot',

    UNKNOWN = 'unknown',
}

export enum BannerTypes {
    ROTATING_BANNER = 'rotating banner'
}

export enum Colors {
    WHITE = 'white',
    BLACK = 'black', 
    NONE = 'none', 
}

export enum Sets {
    DEFAULT = 'default',
    MAFIA = 'mafia',
    CLASSIC = 'classic',
    ROUGE = 'mafia_rouge',
    LAW = 'law',
    LAW_PRESTIGE = 'law_prestige',
}

export enum Pieces {
    PAWN = 'pawn',
    ROOK = 'rook',
    KNIGHT = 'knight',
    BISHOP = 'bishop',
    QUEEN = 'queen',
    KING = 'king', 

    UNKNOWN = 'unknown'
};

export enum Equipments {
    KNIFE = 'knife', 
    PISTOL = 'pistol', 
    SHOTGUN = 'shotgun', 
    RIFLE = 'rifle', 
    SNIPER = 'sniper', 

    SHIELD = 'shield', 
}

export enum Phases {
    MOVE = 'move', 
    FIRE = 'fire',
    BUY = 'buy',
    PROMOTE = 'promote'
}

export enum PieceIdxes {
    WHITE_KING = 20,
    BLACK_KING = 4
}

export enum Characters {
    DUMMY = 'Dummy',
    AARON = 'Aaron',
    CHAD = 'Chad',
    JEFFREY = 'Jeffrey',
    MrK = 'Mr.K',
    KRAYZ = 'KrayZ',
    UNKNOWN = 'Unknown',

    RACHEL = 'Rachel', 
    JASMINE = 'Jasmine',  
    MRSK = 'Mrs.K',
    GRANDMA = 'Grandma',

    FALCON = 'Falcon',
}

export function getCharacter(value: string): Characters | undefined {
    return Characters[value as keyof typeof Characters];
}

export default Pieces