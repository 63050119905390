import React, { useEffect, useState } from 'react';
import { MotionStyle, motion, useAnimation } from 'framer-motion';
import { Characters } from './Enums';
import { characters, validateCharacter } from './CharacterMapper';

interface Props {
    name: Characters;
    expression: string;
    setExpression: (expression: string) => void;
    allowAnnoy: boolean;
    smallBorder: boolean;
    style?: MotionStyle | undefined;
}

const CharacterHandler: React.FC<Props> = ({ name, expression, setExpression, allowAnnoy, smallBorder, style = undefined }) => {
    const characterDataset = characters[validateCharacter(name)]
    const isMultiFrame = characterDataset.expressions[expression].images.length > 1
    const [currentExpression, setCurrentExpression] = useState<string>('idle');
    const [currentFrame, setCurrentFrame] = useState<number>(0);
    const [expressionTimer, setExpressionTimer] = useState<number>(0);
    const [frameSwapCountdown, setFrameSwapCountdown] = useState<number>(0);

    const [blinking, setBlinking] = useState<boolean>(false);

    // annoy character effects
    const [numTaps, setNumTaps] = useState<number>(0)
    
    // update states when expression changes
    useEffect(() => {
        setCurrentExpression(expression)
        setCurrentFrame(0)
        setExpressionTimer(0)
        setFrameSwapCountdown(characterDataset.expressions[expression].frameSwapSpeed)
    }, [expression, characterDataset.expressions])

    // time how long the current expression is going on
    useEffect(() => {
        const intervalId = setInterval(() => {
            setExpressionTimer((prevCount) => prevCount + 0.1);
            setFrameSwapCountdown((prevCount) => prevCount > 0.3 ? prevCount - 0.1 : 0)
        }, 100);
        return () => clearInterval(intervalId);
    }, []); 

    // process blinking logic
    useEffect(() => {
        if (currentExpression === 'idle') {
            if (expressionTimer >= characterDataset.blinkFrequency && !blinking && !characterDataset.eyesCovered) {
                setBlinking(true)
            }
        } else if (expressionTimer >= characterDataset.expressions[expression].duration) {
            // reset animation back to idle
            setExpression('idle')
        }
    }, [expressionTimer, currentExpression, characterDataset.blinkFrequency, blinking, characterDataset.expressions, characterDataset.eyesCovered, expression, setExpression]); 
    useEffect(() => {
        if (blinking) {
            const timer = setTimeout(() => {
                setBlinking(false)
                setExpressionTimer(0)
                return () => clearTimeout(timer);
            }, 200);
        }
    }, [blinking]); 

    // update in game expressions
    useEffect(() => {
        triggerShake(characterDataset.expressions[currentExpression].shakeMagnitude, 
            characterDataset.expressions[currentExpression].shakeRate,
            currentExpression)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentExpression]); 

    // update frame swap
    useEffect(() => {
        if (!isMultiFrame) return
        if (frameSwapCountdown === 0) {
            setCurrentFrame(currentFrame === characterDataset.expressions[expression].images.length - 1 ? 0 : currentFrame + 1)
            setFrameSwapCountdown(characterDataset.expressions[expression].frameSwapSpeed)
        }
    }, [isMultiFrame, frameSwapCountdown, characterDataset.expressions, currentFrame, expression]); 

    // annoying character
    useEffect(() => {
        if (!allowAnnoy) return
        if (numTaps === 4) {
            setExpression(characterDataset.annoy)
            setNumTaps(0)
        }
    }, [allowAnnoy, numTaps, characterDataset.annoy, setExpression]); 

    // control avatar shake
    const controls = useAnimation();
    const triggerShake = (userMagnitude: number, numShakes: number, expression: string) => {
        const baseX = characterDataset.expressions[expression].xOffset;
        const baseY = characterDataset.expressions[expression].yOffset;
        const positionsX = [`${baseX}vh`];
        const positionsY = [`${baseY}vh`];

        // Define corners based on magnitude
        const topLeft = [baseX - userMagnitude, baseY - userMagnitude];
        const topRight = [baseX + userMagnitude, baseY - userMagnitude];
        const bottomLeft = [baseX - userMagnitude, baseY + userMagnitude];
        const bottomRight = [baseX + userMagnitude, baseY + userMagnitude];
        const up = [baseX, baseY - userMagnitude];
        const down = [baseX, baseY + userMagnitude];

        const corners = [topLeft, topRight, bottomLeft, bottomRight];
        let lastCornerIndex = -1;

        const shakeSpeed = characterDataset.expressions[expression].shakeType === 1 ? 0.125: 0.075

        for (let i = 1; i <= numShakes; i++) {
            if (characterDataset.expressions[expression].shakeType === 3) {
                continue
            }
            if (characterDataset.expressions[expression].shakeType === 1) {
                // up and down happy shake
                if (i % 2 !== 0) {
                    positionsX.push(`${up[0]}vh`);
                    positionsY.push(`${up[1]}vh`);
                } else {
                    positionsX.push(`${down[0]}vh`);
                    positionsY.push(`${down[1]}vh`);
                }
            } else {
                // chaotic shake
                let cornerIndex;
                do {
                    cornerIndex = Math.floor(Math.random() * corners.length);
                } while (cornerIndex === lastCornerIndex); // Ensure not the same as the last corner
                lastCornerIndex = cornerIndex;
        
                positionsX.push(`${corners[cornerIndex][0]}vh`);
                positionsY.push(`${corners[cornerIndex][1]}vh`);
            }
        }

        positionsX.push(`${baseX}vh`);
        positionsY.push(`${baseY}vh`);

        const shakeAnimation = {
            x: positionsX,
            y: positionsY,
            transition: {
                duration: numShakes * shakeSpeed,  
                ease: 'easeInOut'
            }
        };

        controls.start(shakeAnimation)
    };

    useEffect(() => {
        // Reset animation controls to ensure there are no lingering effects
        controls.set({
            x: `${characterDataset.expressions[currentExpression].xOffset}vh`,
            y: `${characterDataset.expressions[currentExpression].yOffset}vh`
        });
    }, [currentExpression, characterDataset.expressions, controls]);
    
    return (
        <motion.div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', ...style}}>
            <motion.div style={{
                display: 'flex',
                height: '35vh',
                width: '55vh',
                alignItems: 'flex-end',
                justifyContent: 'center',
                overflowX: 'clip',
                overflowY: 'hidden',
                position: 'relative', 
            }}> 
                {   !characterDataset.eyesCovered && currentExpression === 'idle' && 
                    <motion.img 
                        style={{
                            height: '30vh', 
                            width: '55vh', 
                            position: 'absolute',
                            x: `${characterDataset.expressions[currentExpression].xOffset}vh`,
                            y: `${characterDataset.expressions[currentExpression].yOffset}vh`,
                            scale: characterDataset.expressions[currentExpression].scale,
                            pointerEvents: 'none'
                        }}
                        src={characterDataset.eyes.images[0]} 
                        alt={`${name} ${expression}`}
                        draggable={false}
                        animate={controls}
                    />
                }
                <motion.img 
                    style={{
                        height: '30vh', 
                        width: '55vh', 
                        position: 'absolute',
                        x: `${characterDataset.expressions[currentExpression].xOffset}vh`,
                        y: `${characterDataset.expressions[currentExpression].yOffset}vh`,
                        scale: characterDataset.expressions[currentExpression].scale,
                        pointerEvents: 'none'
                    }}
                    src={blinking ? characterDataset.blink.images[0] : characterDataset.expressions[currentExpression].images[currentFrame]} 
                    alt={`${name} ${expression}`}
                    draggable={false}
                    onClick={() => {
                        if (!allowAnnoy) return
                        if (numTaps < 4 && expression === 'idle') {
                            triggerShake(numTaps < 3 ? 0.25 : characterDataset.expressions[characterDataset.annoy].shakeMagnitude, 
                                        numTaps < 3 ? 4 : characterDataset.expressions[characterDataset.annoy].shakeRate,
                                        numTaps < 3 ? 'idle' : characterDataset.annoy)
                            setNumTaps(numTaps + 1)
                        }
                    }}
                    animate={controls}
                />
                <motion.div 
                    style={{
                        height: '30vh', 
                        width: '22.5vh', 
                        position: 'absolute',
                        x: `${characterDataset.expressions[currentExpression].xOffset}vh`,
                        y: `${characterDataset.expressions[currentExpression].yOffset}vh`,
                        scale: characterDataset.expressions[currentExpression].scale,
                    }}
                    onClick={() => {
                        if (!allowAnnoy) return
                        if (numTaps < 4 && expression === 'idle') {
                            triggerShake(numTaps < 3 ? 0.25 : characterDataset.expressions[characterDataset.annoy].shakeMagnitude, 
                                        numTaps < 3 ? 4 : characterDataset.expressions[characterDataset.annoy].shakeRate,
                                        numTaps < 3 ? 'idle' : characterDataset.annoy)
                            setNumTaps(numTaps + 1)
                        }
                    }}
                />
            </motion.div>
                
            <motion.div style={{
                width: smallBorder ? '38vh' : '44vh', 
                borderBottom: '0.325vh solid #6B6B6B',
                pointerEvents: 'none'
            }}/>
            
        </motion.div>
    )
}

export default CharacterHandler;