import images from "../images";
import { Characters, Sets } from "./Enums"

interface AvatarTemplate {
    expression: string;
    xOffset: number;
    yOffset: number;
    scale: number;
    images: string[];
    duration: number; // in seconds
    shakeRate: number; // number of shakes
    shakeMagnitude: number; // how heavy the shakes are in vh
    frameSwapSpeed: number;
    shakeType: number; // 0 is normal shake, 1 is up and down
}

interface Avatar {
    [key: string]: { [expression: string]: AvatarTemplate };
}

export const avaliableAvatars = [Characters.FALCON, Characters.UNKNOWN, Characters.CHAD, Characters.JASMINE, Characters.MRSK, Characters.KRAYZ, Characters.GRANDMA, Characters.AARON, Characters.RACHEL, Characters.JEFFREY]
export const defaultCharacters = [Characters.AARON, Characters.RACHEL]

// Initialize the avatars object
const avatars: Avatar = {};
avatars[Characters.DUMMY] = {
    'idle': {
        expression: 'idle',
        xOffset: 34.5,  
        yOffset: 5.5, 
        scale: 2.05,  
        duration: 0, 
        images: [images['Dummy idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
};
avatars[Characters.JASMINE] = {
    'idle': {
        expression: 'idle',
        xOffset: 3.25,  
        yOffset: 4.2, 
        scale: 1.1,  
        duration: 0, 
        images: [images['Jasmine idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'eyeballs': {
        expression: 'eyeballs',
        xOffset: 3.25,
        yOffset: 4.2,
        scale: 1.1,
        duration: 0, 
        images: [images['Jasmine eyeballs']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'blink': {
        expression: 'blink',
        xOffset: 3.25,
        yOffset: 4.2,
        scale: 1.1,
        duration: 0.25, 
        images: [images['Jasmine blink']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'angry': {
        expression: 'angry',
        xOffset: 3.25,
        yOffset: 4.85,
        scale: 1.1,
        duration: 2, 
        images: [images['Jasmine angry']],
        shakeRate: 12,
        shakeMagnitude: 0.7,
        frameSwapSpeed: 0,
        shakeType: 0,
    },
    'greet': {
        expression: 'greet',
        xOffset: 3.25,
        yOffset: 4.85,
        scale: 1.1,
        duration: 2, 
        images: [images['Jasmine greet']],
        shakeRate: 2,
        shakeMagnitude: 1.2,
        frameSwapSpeed: 0,
        shakeType: 1,
    },
    'humiliate': {
        expression: 'humiliate',
        xOffset: 3.25,
        yOffset: 4.85,
        scale: 1.1,
        duration: 2, 
        images: [images['Jasmine humiliate']],
        shakeRate: 8,
        shakeMagnitude: 0.6,
        frameSwapSpeed: 0,
        shakeType: 1,
    },
    'trigger': {
        expression: 'trigger',
        xOffset: 3.25,
        yOffset: 4.85,
        scale: 1.1,
        duration: 2, 
        images: [images['Jasmine trigger']],
        shakeRate: 5,
        shakeMagnitude: 0.4,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'trip': {
        expression: 'trip',
        xOffset: 3.25,
        yOffset: 4,
        scale: 1.1,
        duration: 2, 
        images: [images['Jasmine trip']],
        shakeRate: 2,
        shakeMagnitude: 1.4,
        frameSwapSpeed: 0,
        shakeType: 1,
    }
};
avatars[Characters.CHAD] = {
    'idle': {
        expression: 'idle',
        xOffset: 0,
        yOffset: 2.5,
        scale: 1.25,
        duration: 0, 
        images: [images['Chad idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'wave': {
        expression: 'idle',
        xOffset: 0,
        yOffset: 2.5,
        scale: 1.25,
        duration: 1.5, 
        images: [images['Chad wave']],
        shakeRate: 2,
        shakeMagnitude: 1.2,
        frameSwapSpeed: 0,
        shakeType: 1,
    }, 
    'flex': {
        expression: 'flex',
        xOffset: 0,
        yOffset: 2.5,
        scale: 1.25,
        duration: 1.5, 
        images: [images['Chad flex1'], images['Chad flex2']],
        shakeRate: 2,
        shakeMagnitude: 1.2,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 'cry': {
        expression: 'cry',
        xOffset: 0,
        yOffset: 2,
        scale: 1.25,
        duration: 2.5, 
        images: [images['Chad cry']],
        shakeRate: 10,
        shakeMagnitude: 0.6,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 'flirt': {
        expression: 'flirt',
        xOffset: 0,
        yOffset: 2,
        scale: 1.25,
        duration: 2, 
        images: [images['Chad flirt']],
        shakeRate: 4,
        shakeMagnitude: 0.6,
        frameSwapSpeed: 0,
        shakeType: 1,
    }, 'shock': {
        expression: 'shock',
        xOffset: 0,
        yOffset: 2,
        scale: 1.25,
        duration: 2, 
        images: [images['Chad shocked']],
        shakeRate: 10,
        shakeMagnitude: 0.6,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
};
avatars[Characters.MRSK] = {
    'idle': {
        expression: 'idle',
        xOffset: -2,
        yOffset: 3.6,
        scale: 1.105,
        duration: 0, 
        images: [images['MrsK idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'eyeballs': {
        expression: 'eyeballs',
        xOffset: -2,
        yOffset: 3.6,
        scale: 1.105,
        duration: 0, 
        images: [images['MrsK eyeballs']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'blink': {
        expression: 'blink',
        xOffset: -2,
        yOffset: 3.6,
        scale: 1.105,
        duration: 0.25, 
        images: [images['MrsK blink']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'greet': {
        expression: 'greet',
        xOffset: -2,
        yOffset: 3.6,
        scale: 1.105,
        duration: 2, 
        images: [images['MrsK greet1'], images['MrsK greet2']],
        shakeRate: 8,
        shakeMagnitude: 0,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'shiver': {
        expression: 'shiver',
        xOffset: -2,
        yOffset: 3.6,
        scale: 1.105,
        duration: 2.5, 
        images: [images['MrsK shiver1'], images['MrsK shiver2']],
        shakeRate: 14,
        shakeMagnitude: 0.4,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'breakdown': {
        expression: 'breakdown',
        xOffset: -2,
        yOffset: 3.6,
        scale: 1.105,
        duration: 2, 
        images: [images['MrsK breakdown1'], images['MrsK breakdown2']],
        shakeRate: 20,
        shakeMagnitude: 0.8,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'snarl': {
        expression: 'snarl',
        xOffset: -2,
        yOffset: 3.6,
        scale: 1.105,
        duration: 2, 
        images: [images['MrsK snarl']],
        shakeRate: 16,
        shakeMagnitude: 0.6,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'excited': {
        expression: 'excited',
        xOffset: -2,
        yOffset: 3.6,
        scale: 1.105,
        duration: 2, 
        images: [images['MrsK excited']],
        shakeRate: 8,
        shakeMagnitude: 0.6,
        frameSwapSpeed: 0,
        shakeType: 1,
    }, 
};
avatars[Characters.RACHEL] = {
    'idle': {
        expression: 'idle',
        xOffset: 0,
        yOffset: 4.2,
        scale: 1.05,
        duration: 0, 
        images: [images['Rachel idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'eyeballs': {
        expression: 'eyeballs',
        xOffset: 0,
        yOffset: 4.2,
        scale: 1.05,
        duration: 0, 
        images: [images['Rachel eyeballs']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'blink': {
        expression: 'blink',
        xOffset: 0,
        yOffset: 4.2,
        scale: 1.05,
        duration: 0.25, 
        images: [images['Rachel blink']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'greet': {
        expression: 'greet',
        xOffset: 0,
        yOffset: 4.2,
        scale: 1.05,
        duration: 2, 
        images: [images['Rachel greet1'], images['Rachel greet2']],
        shakeRate: 2,
        shakeMagnitude: 1.2,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
    'cry': {
        expression: 'cry',
        xOffset: 0,
        yOffset: 4.2,
        scale: 1.05,
        duration: 2, 
        images: [images['Rachel cry']],
        shakeRate: 14,
        shakeMagnitude: 0.4,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'cheer': {
        expression: 'cheer',
        xOffset: 0.15,
        yOffset: 4.2,
        scale: 1.05,
        duration: 2, 
        images: [images['Rachel cheer']],
        shakeRate: 10,
        shakeMagnitude: 0.75,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
    'shock': {
        expression: 'shock',
        xOffset: 0,
        yOffset: 4.2,
        scale: 1.05,
        duration: 2, 
        images: [images['Rachel shock']],
        shakeRate: 7,
        shakeMagnitude: 0.8,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
};
avatars[Characters.AARON] = {
    'idle': {
        expression: 'idle',
        xOffset: 0,
        yOffset: 3,
        scale: 1.05,
        duration: 0, 
        images: [images['Aaron idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'eyeballs': {
        expression: 'eyeballs',
        xOffset: 0,
        yOffset: 3,
        scale: 1.05,
        duration: 0, 
        images: [images['Aaron eyeballs']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'blink': {
        expression: 'blink',
        xOffset: 0,
        yOffset: 3,
        scale: 1.05,
        duration: 0.25, 
        images: [images['Aaron blink']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'greet': {
        expression: 'greet',
        xOffset: 0,
        yOffset: 3,
        scale: 1.05,
        duration: 2, 
        images: [images['Aaron greet1'], images['Aaron greet2']],
        shakeRate: 2,
        shakeMagnitude: 1.2,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
    'cry': {
        expression: 'cry',
        xOffset: 0,
        yOffset: 3,
        scale: 1.05,
        duration: 2, 
        images: [images['Aaron cry']],
        shakeRate: 14,
        shakeMagnitude: 0.4,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'cheer': {
        expression: 'cheer',
        xOffset: 0.15,
        yOffset: 3,
        scale: 1.05,
        duration: 2, 
        images: [images['Aaron cheer']],
        shakeRate: 10,
        shakeMagnitude: 0.75,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
    'shock': {
        expression: 'shock',
        xOffset: 0,
        yOffset: 3,
        scale: 1.05,
        duration: 2, 
        images: [images['Aaron shock']],
        shakeRate: 7,
        shakeMagnitude: 0.8,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
};
avatars[Characters.JEFFREY] = {
    'idle': {
        expression: 'idle',
        xOffset: 0,
        yOffset: 3.5,
        scale: 1.2,
        duration: 0, 
        images: [images['Jeffrey idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'eyeballs': {
        expression: 'eyeballs',
        xOffset: 0,
        yOffset: 3.5,
        scale: 1.2,
        duration: 0, 
        images: [images['Jeffrey eyeballs']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'blink': {
        expression: 'blink',
        xOffset: 0,
        yOffset: 3.5,
        scale: 1.2,
        duration: 0.25, 
        images: [images['Jeffrey blink']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'greet': {
        expression: 'greet',
        xOffset: 0,
        yOffset: 3.5,
        scale: 1.2,
        duration: 2, 
        images: [images['Jeffrey greet1'], images['Jeffrey greet2']],
        shakeRate: 2,
        shakeMagnitude: 1.2,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
    'angry': {
        expression: 'angry',
        xOffset: 0,
        yOffset: 3.5,
        scale: 1.2,
        duration: 2, 
        images: [images['Jeffrey angry']],
        shakeRate: 14,
        shakeMagnitude: 0.4,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'smack': {
        expression: 'smack',
        xOffset: 0,
        yOffset: 3.5,
        scale: 1.2,
        duration: 2, 
        images: [images['Jeffrey smacked']],
        shakeRate: 10,
        shakeMagnitude: 0.75,
        frameSwapSpeed: 0.5,
        shakeType: 2,
    }, 
    'shock': {
        expression: 'shock',
        xOffset: 0,
        yOffset: 3.5,
        scale: 1.2,
        duration: 1.5, 
        images: [images['Jeffrey shock']],
        shakeRate: 4,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'jump': {
        expression: 'jump',
        xOffset: 0,
        yOffset: 3.5,
        scale: 1.2,
        duration: 2, 
        images: [images['Jeffrey jump2'], images['Jeffrey jump1']],
        shakeRate: 2,
        shakeMagnitude: 0.8,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
};

avatars[Characters.GRANDMA] = {
    'idle': {
        expression: 'idle',
        xOffset: -4.5,
        yOffset: 6,
        scale: 1.25,
        duration: 0, 
        images: [images['Grandma idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'greet': {
        expression: 'greet',
        xOffset: -4.5,
        yOffset: 6,
        scale: 1.25,
        duration: 2, 
        images: [images['Grandma greet1'], images['Grandma greet2']],
        shakeRate: 2,
        shakeMagnitude: 1.2,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
    'argue': {
        expression: 'argue',
        xOffset: -4.5,
        yOffset: 6,
        scale: 1.25,
        duration: 2, 
        images: [images['Grandma argue']],
        shakeRate: 14,
        shakeMagnitude: 0.8,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'cookies': {
        expression: 'cookies',
        xOffset: -4.5,
        yOffset: 6,
        scale: 1.25,
        duration: 2, 
        images: [images['Grandma cookies']],
        shakeRate: 2,
        shakeMagnitude: 1.2,
        frameSwapSpeed: 0,
        shakeType: 1,
    }, 
    'faint': {
        expression: 'faint',
        xOffset: -4.5,
        yOffset: 6,
        scale: 1.25,
        duration: 2, 
        images: [images['Grandma faint']],
        shakeRate: 7,
        shakeMagnitude: 0.8,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'confused': {
        expression: 'confused',
        xOffset: -4.5,
        yOffset: 6,
        scale: 1.25,
        duration: 2, 
        images: [images['Grandma confused1'], images['Grandma confused2']],
        shakeRate: 2,
        shakeMagnitude: 1,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
};

avatars[Characters.FALCON] = {
    'idle': {
        expression: 'idle',
        xOffset: 0,
        yOffset: 2.75,
        scale: 1.45,
        duration: 0, 
        images: [images['Falcon idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'greet': {
        expression: 'greet',
        xOffset: 0,
        yOffset: 2.75,
        scale: 1.45,
        duration: 0.75, 
        images: [images['Falcon greet']],
        shakeRate: 2,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0,
        shakeType: 1,
    }, 
    'wanted': {
        expression: 'wanted',
        xOffset: 0,
        yOffset: 2.75,
        scale: 1.45,
        duration: 2, 
        images: [images['Falcon wanted']],
        shakeRate: 2,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0,
        shakeType: 1,
    }, 
    'react': {
        expression: 'react',
        xOffset: 0,
        yOffset: 2.75,
        scale: 1.45,
        duration: 2, 
        images: [images['Falcon react'], images['Falcon react'], images['Falcon react'], images['Falcon react2']],
        shakeRate: 10,
        shakeMagnitude: 2,
        frameSwapSpeed: 0.2,
        shakeType: 0,
    }, 
    'report': {
        expression: 'report',
        xOffset: 0,
        yOffset: 2.75,
        scale: 1.45,
        duration: 1.5, 
        images: [images['Falcon report']],
        shakeRate: 6,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0.5,
        shakeType: 0,
    }, 
    'fail': {
        expression: 'fail',
        xOffset: 0,
        yOffset: 2.75,
        scale: 1.45,
        duration: 2, 
        images: [images['Falcon failed']],
        shakeRate: 2,
        shakeMagnitude: 1,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
};

avatars[Characters.KRAYZ] = {
    'idle': {
        expression: 'idle',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 0, 
        images: [images['KrayZ idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'greet': {
        expression: 'greet',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 1.5, 
        images: [images['KrayZ greet1'], images['KrayZ greet2']],
        shakeRate: 2,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0.4,
        shakeType: 1,
    }, 
    'dance': {
        expression: 'dance',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 2.5, 
        images: [images['KrayZ dance1'], images['KrayZ dance2']],
        shakeRate: 4,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0.5,
        shakeType: 1,
    }, 
    'flex': {
        expression: 'flex',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 2, 
        images: [images['KrayZ flex']],
        shakeRate: 2,
        shakeMagnitude: 2,
        frameSwapSpeed: 0,
        shakeType: 1,
    }, 
    'sing': {
        expression: 'sing',
        xOffset: 0,
        yOffset: 3,
        scale: 1.15,
        duration: 2, 
        images: [images['KrayZ sing']],
        shakeRate: 10,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0,
        shakeType: 1,
    }, 
    'wow': {
        expression: 'wow',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 2, 
        images: [images['KrayZ wow1'], images['KrayZ wow2'], images['KrayZ wow3'], images['KrayZ wow4'], images['KrayZ wow5'], images['KrayZ wow6']],
        shakeRate: 2,
        shakeMagnitude: 2,
        frameSwapSpeed: 0.2,
        shakeType: 1,
    }, 
};

avatars[Characters.UNKNOWN] = {
    'idle': {
        expression: 'idle',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 0, 
        images: [images['Unknown idle']],
        shakeRate: 0,
        shakeMagnitude: 1,
        frameSwapSpeed: 0,
        shakeType: 0,
    }, 
    'greet': {
        expression: 'greet',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 1.5, 
        images: [images['Unknown greet']],
        shakeRate: 2,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0.4,
        shakeType: 3,
    }, 
    'glitch': {
        expression: 'glitch',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 2, 
        images: [images['Unknown glitch1'], images['Unknown glitch2']],
        shakeRate: 4,
        shakeMagnitude: 0,
        frameSwapSpeed: 0.5,
        shakeType: 3,
    }, 
    'laugh': {
        expression: 'laugh',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 2, 
        images: [images['Unknown laugh']],
        shakeRate: 10,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0,
        shakeType: 1,
    }, 
    'smile': {
        expression: 'smile',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 2, 
        images: [images['Unknown smile']],
        shakeRate: 10,
        shakeMagnitude: 1.5,
        frameSwapSpeed: 0,
        shakeType: 3,
    }, 
    'question': {
        expression: 'question',
        xOffset: 0,
        yOffset: 6,
        scale: 1.3,
        duration: 3, 
        images: [images['Unknown question1'], images['Unknown question2'], images['Unknown question3'], images['Unknown question4'], images['Unknown question5'], images['Unknown question5'], images['Unknown question5'], images['Unknown question5'], images['Unknown question5'], images['Unknown question5']],
        shakeRate: 2,
        shakeMagnitude: 2,
        frameSwapSpeed: 0.5,
        shakeType: 3,
    }, 
};

interface CharacterTemplate {
    expressions: { [key: string]: AvatarTemplate };
    spammables: string[];
    eyesCovered: boolean;
    eyes: AvatarTemplate;
    blink: AvatarTemplate;
    blinkFrequency: number; // number of seconds till blink
    annoy: string; // name of the annoy avatar to be used in menu when tapped 
    greet: string;
    shock: string; // name of the avatar used when in check or when a piece is shot
    loss: string; 
    win: string; 
    description: string;
    set: Sets;
}

interface Character {
    [key: string]: CharacterTemplate;
}

// const validCharacters = [Characters.JASMINE]

export const characters: Character = {
    [Characters.DUMMY]: {
        expressions: {
            'idle': avatars[Characters.DUMMY].idle,
        },
        spammables: [],
        eyesCovered: true,
        eyes: avatars[Characters.DUMMY].idle,
        blink: avatars[Characters.DUMMY].idle,
        blinkFrequency: 10, 
        annoy: 'idle',
        greet: 'idle',
        shock: 'idle',
        loss: 'idle',
        win: 'idle',
        description: 'This is a target dummy.',
        set: Sets.DEFAULT
    },
    [Characters.JASMINE]: {
        expressions: {
            'idle': avatars[Characters.JASMINE].idle,
            'angry': avatars[Characters.JASMINE].angry,
            'greet': avatars[Characters.JASMINE].greet,
            'humiliate': avatars[Characters.JASMINE].humiliate,
            'trigger': avatars[Characters.JASMINE].trigger,
            'trip': avatars[Characters.JASMINE].trip,
        },
        spammables: ['greet', 'humiliate', 'trigger', 'angry'],
        eyesCovered: false,
        eyes: avatars[Characters.JASMINE].eyeballs,
        blink: avatars[Characters.JASMINE].blink,
        blinkFrequency: 8, 
        annoy: 'angry',
        greet: 'greet',
        shock: 'angry',
        loss: 'trip',
        win: 'humiliate',
        description: 'Jasmine is a mean girl with anger issues. She humiliates everyone she come across.',
        set: Sets.DEFAULT
    },
    [Characters.CHAD]: {
        expressions: {
            'idle': avatars[Characters.CHAD].idle,
            'wave': avatars[Characters.CHAD].wave,
            'flex': avatars[Characters.CHAD].flex,
            'cry': avatars[Characters.CHAD].cry,
            'flirt': avatars[Characters.CHAD].flirt,
            'shock': avatars[Characters.CHAD].shock,
        },
        spammables: ['wave', 'flirt', 'flex', 'cry'],
        eyesCovered: true,
        eyes: avatars[Characters.CHAD].idle,
        blink: avatars[Characters.CHAD].idle,
        blinkFrequency: 7, 
        annoy: 'flex',
        greet: 'wave',
        shock: 'shock',
        loss: 'cry',
        win: 'flex',
        description: 'Everybody loves a Chad. He is a surfer with big muscles.',
        set: Sets.DEFAULT
    },
    [Characters.MRSK]: {
        expressions: {
            'idle': avatars[Characters.MRSK].idle,
            'greet': avatars[Characters.MRSK].greet,
            'shiver': avatars[Characters.MRSK].shiver,
            'breakdown': avatars[Characters.MRSK].breakdown,
            'snarl': avatars[Characters.MRSK].snarl,
            'excited': avatars[Characters.MRSK].excited,
        },
        spammables: ['greet', 'excited', 'shiver', 'snarl'],
        eyesCovered: false,
        eyes: avatars[Characters.MRSK].eyeballs,
        blink: avatars[Characters.MRSK].blink,
        blinkFrequency: 20, 
        annoy: 'snarl',
        greet: 'greet',
        shock: 'shiver',
        loss: 'breakdown',
        win: 'excited',
        description: 'Mrs.K is a troublesome and paranoid women who seem to lost it all in the head.',
        set: Sets.DEFAULT
    },
    [Characters.RACHEL]: {
        expressions: {
            'idle': avatars[Characters.RACHEL].idle,
            'greet': avatars[Characters.RACHEL].greet,
            'cry': avatars[Characters.RACHEL].cry,
            'cheer': avatars[Characters.RACHEL].cheer,
            'shock': avatars[Characters.RACHEL].shock,
        },
        spammables: ['greet', 'cheer', 'cry', 'shock'],
        eyesCovered: false,
        eyes: avatars[Characters.RACHEL].eyeballs,
        blink: avatars[Characters.RACHEL].blink,
        blinkFrequency: 6, 
        annoy: 'shock',
        greet: 'greet',
        shock: 'shock',
        loss: 'cry',
        win: 'cheer',
        description: 'An ambitious girl with big dreams!',
        set: Sets.DEFAULT
    },
    [Characters.AARON]: {
        expressions: {
            'idle': avatars[Characters.AARON].idle,
            'greet': avatars[Characters.AARON].greet,
            'cry': avatars[Characters.AARON].cry,
            'cheer': avatars[Characters.AARON].cheer,
            'shock': avatars[Characters.AARON].shock,
        },
        spammables: ['greet', 'cheer', 'cry', 'shock'],
        eyesCovered: false,
        eyes: avatars[Characters.AARON].eyeballs,
        blink: avatars[Characters.AARON].blink,
        blinkFrequency: 6, 
        annoy: 'shock',
        greet: 'greet',
        shock: 'shock',
        loss: 'cry',
        win: 'cheer',
        description: 'A young boy with a sharp mind!',
        set: Sets.DEFAULT
    },
    [Characters.JEFFREY]: {
        expressions: {
            'idle': avatars[Characters.JEFFREY].idle,
            'greet': avatars[Characters.JEFFREY].greet,
            'angry': avatars[Characters.JEFFREY].angry,
            'jump': avatars[Characters.JEFFREY].jump,
            'shock': avatars[Characters.JEFFREY].shock,
            'smack': avatars[Characters.JEFFREY].smack,
        },
        spammables: ['greet', 'jump', 'shock', 'angry'],
        eyesCovered: false,
        eyes: avatars[Characters.JEFFREY].eyeballs,
        blink: avatars[Characters.JEFFREY].blink,
        blinkFrequency: 8, 
        annoy: 'angry',
        greet: 'greet',
        shock: 'shock',
        loss: 'smack',
        win: 'jump',
        description: 'Jeffrey is a short king that claims all the best things come in small packages.',
        set: Sets.DEFAULT
    }, 
    [Characters.GRANDMA]: {
        expressions: {
            'idle': avatars[Characters.GRANDMA].idle,
            'greet': avatars[Characters.GRANDMA].greet,
            'argue': avatars[Characters.GRANDMA].argue,
            'faint': avatars[Characters.GRANDMA].faint,
            'cookies': avatars[Characters.GRANDMA].cookies,
            'confused': avatars[Characters.GRANDMA].confused,
        },
        spammables: ['greet', 'cookies', 'confused', 'argue'],
        eyesCovered: true,
        eyes: avatars[Characters.GRANDMA].idle,
        blink: avatars[Characters.GRANDMA].idle,
        blinkFrequency: 0, 
        annoy: 'argue',
        greet: 'greet',
        shock: 'confused',
        loss: 'faint',
        win: 'cookies',
        description: 'Grandma Eve is a cookie wizard by day, ruthless chess tactician by night.',
        set: Sets.DEFAULT
    },
    [Characters.KRAYZ]: {
        expressions: {
            'idle': avatars[Characters.KRAYZ].idle,
            'greet': avatars[Characters.KRAYZ].greet,
            'dance': avatars[Characters.KRAYZ].dance,
            'flex': avatars[Characters.KRAYZ].flex,
            'sing': avatars[Characters.KRAYZ].sing,
            'wow': avatars[Characters.KRAYZ].wow,
        },
        spammables: ['greet', 'dance', 'sing', 'wow'],
        eyesCovered: true,
        eyes: avatars[Characters.KRAYZ].idle,
        blink: avatars[Characters.KRAYZ].idle,
        blinkFrequency: 0, 
        annoy: 'greet',
        greet: 'greet',
        shock: 'sing',
        loss: 'wow',
        win: 'flex',
        description: 'KrayZ is another best rapper in town. He dresses with class and glass and sings with swag and drip.',
        set: Sets.DEFAULT
    }, 
    [Characters.FALCON]: {
        expressions: {
            'idle': avatars[Characters.FALCON].idle,
            'greet': avatars[Characters.FALCON].greet,
            'wanted': avatars[Characters.FALCON].wanted,
            'react': avatars[Characters.FALCON].react,
            'report': avatars[Characters.FALCON].report,
            'fail': avatars[Characters.FALCON].fail,
        },
        spammables: ['greet', 'wanted', 'react', 'report'],
        eyesCovered: true,
        eyes: avatars[Characters.FALCON].idle,
        blink: avatars[Characters.FALCON].idle,
        blinkFrequency: 0, 
        annoy: 'wanted',
        greet: 'greet',
        shock: 'report',
        loss: 'fail',
        win: 'greet',
        description: 'A detective known for infiltrating the mafia world, outsmarting mafia schemes and solving tough cases with ease.',
        set: Sets.LAW_PRESTIGE
    }, 
    [Characters.UNKNOWN]: {
        expressions: {
            'idle': avatars[Characters.UNKNOWN].idle,
            'greet': avatars[Characters.UNKNOWN].greet,
            'smile': avatars[Characters.UNKNOWN].smile,
            'laugh': avatars[Characters.UNKNOWN].laugh,
            'glitch': avatars[Characters.UNKNOWN].glitch,
            'question': avatars[Characters.UNKNOWN].question,
        },
        spammables: ['greet', 'smile', 'glitch', 'question'],
        eyesCovered: true,
        eyes: avatars[Characters.UNKNOWN].idle,
        blink: avatars[Characters.UNKNOWN].idle,
        blinkFrequency: 0, 
        annoy: 'glitch',
        greet: 'greet',
        shock: 'glitch',
        loss: 'laugh',
        win: 'laugh',
        description: 'A detective known for infiltrating the mafia world, outsmarting mafia schemes and solving tough cases with ease.',
        set: Sets.DEFAULT
    }
};

export function validateCharacter(char: Characters): Characters {
    if (char in characters) {
        return char;
    }
    return Characters.AARON;
}