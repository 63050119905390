import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { avaliableAvatars, characters } from '../../utils/CharacterMapper';
import { Characters } from '../../utils/Enums';

import '../../utils/Common.css';
import sounds from '../../sounds';
import { setPlayerAvatar } from '../../backend/CreateFirebaseEngine';

interface Props {
    oldCharacter: Characters;
    setCharacter: (newCharacter: Characters) => void;
    myCharacters: Characters[]
    setCharacterSelector: (newStatus: boolean) => void;
    animation: any;
}

interface CharacterWindowProps {
    name: Characters;
    selectedAvatar: Characters;
    setSelectedAvatar: (newAvatar: Characters) => void;
    isDisplay: boolean;
} 

const CharacterWindow: React.FC<CharacterWindowProps> = ({ name, selectedAvatar, setSelectedAvatar, isDisplay }) => {
    const characterDataset = characters[name]
    const expression = 'idle'

    return (
        <motion.div style={{scale: 0.8}}>
            <motion.div style={{
                display: 'flex',
                height: '30vh',
                width: isDisplay ? '37.5vh' : '30vh',
                alignItems: 'center',
                justifyContent: 'center',
                overflowX: 'clip',
                overflowY: 'hidden',
                position: 'relative', 
                border: isDisplay ? '0vh solid #6B6B6B' : '0.35vh solid #6B6B6B',
                borderRadius: isDisplay ? '0vh' : '1vh',
                margin: isDisplay ? '-3vh' : '-2.5vh',
                backgroundColor:  (selectedAvatar === name && !isDisplay) ? '#B0B0B0' : 'transparent'
            }}
            whileHover={{ scale: isDisplay ? 1 : 1.05 }} 
            onMouseEnter={()=>{
                if (isDisplay) return
                sounds['hover'].play()
            }}
            onClick={()=>{
                if (isDisplay) return
                sounds['select'].play()
                setSelectedAvatar(name)
            }}>
                {   !characterDataset.eyesCovered && 
                    <motion.img 
                        style={{
                            height: '100%', 
                            position: 'absolute',
                            x: `${characterDataset.expressions[expression].xOffset}vh`,
                            y: `${characterDataset.expressions[expression].yOffset}vh`,
                            scale: characterDataset.expressions[expression].scale
                        }}
                        src={characterDataset.eyes.images[0]} 
                        alt={`${name} ${expression}`}
                        draggable={false}
                    />
                }
                <motion.img 
                    style={{
                        height: '100%', 
                        position: 'absolute',
                        x: `${characterDataset.expressions[expression].xOffset}vh`,
                        y: `${characterDataset.expressions[expression].yOffset}vh`,
                        scale: characterDataset.expressions[expression].scale
                    }}
                    src={characterDataset.expressions[expression].images[0]} 
                    alt={`${name} ${expression}`}
                    draggable={false}
                />
            </motion.div>
        </motion.div>
    )
}

function getAvaliableCharacters(myCharacters: Characters[]) {
    const finalCharacters: Characters[] = []
    finalCharacters.push(Characters.AARON)
    finalCharacters.push(Characters.RACHEL)
    for (let i = 0; i < avaliableAvatars.length; ++i) {
        if (myCharacters.includes(avaliableAvatars[i]) && !finalCharacters.includes(avaliableAvatars[i])) {
            finalCharacters.push(avaliableAvatars[i])
        }
    }
    return finalCharacters
}

const CharacterSelector: React.FC<Props> = ({ oldCharacter, setCharacter, myCharacters, setCharacterSelector, animation }) => {
    const [selectedCharacter, setSelectedCharacter] = useState<Characters>(oldCharacter)
    const [currentCharacter, setCurrentCharacter] = useState<Characters>(oldCharacter)

    const avaliableCharacters = getAvaliableCharacters(myCharacters)

    return (
        <motion.div style={{
            position: 'absolute', 
            width: '78vh', 
            height: '61vh',
            border: '0.3vh solid #6B6B6B',
            borderRadius: '1vh',
            backgroundColor: '#D9D9D9', 
            padding: '0.5vh',
            marginTop: '14vh',
        }}
        initial='initial' 
        animate={animation.selectorStatus}
        variants={animation.selectorAnimation}
        onAnimationComplete={()=>{
            if (animation.selectorStatus === 'enter') { 
                animation.setSelectorStatus('idle') 
            }
        }}>
            <motion.div style={{display: 'flex', flexDirection: 'row', marginTop: '0.5vh'}}>
                <motion.div className='LargeText' style={{fontSize: '4vh', marginLeft: '0.75vh', textAlign: 'left', marginRight: '3vh', flexGrow: 1}}>
                    Representative Selector
                </motion.div>
                <motion.div className='LargeText' style={{fontSize: '4vh', marginLeft: '0.75vh', textAlign: 'center', marginRight: '0.75vh', textDecoration: 'underline' }}
                        whileHover={{ scale: 1.05 }} 
                        onMouseEnter={()=>{
                            sounds['hover'].play()
                        }}
                        onClick={()=>{
                            sounds['select'].play()
                            if (animation.selectorStatus !== 'exit') {
                                // one transaction to save characater
                                if (currentCharacter !== oldCharacter) {
                                    setPlayerAvatar({
                                        character: currentCharacter
                                    })
                                    setCharacter(currentCharacter)
                                }
                                animation.setSelectorStatus('exit') 
                                const exitTimer = setTimeout(() => {
                                    setCharacterSelector(false)
                                    clearTimeout(exitTimer);
                                }, 550);
                            }
                        }}>
                    X
                </motion.div>
            </motion.div>
            <motion.div style={{display: 'flex', flexDirection: 'row', border: '0.3vh solid #6B6B6B', margin: '0.5vh', borderRadius: '1vh'}}>
                <CharacterWindow name={selectedCharacter} selectedAvatar={selectedCharacter} setSelectedAvatar={setSelectedCharacter} isDisplay={true}/>
                <motion.div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
                    <motion.div className='LargeText' style={{fontSize: '3.5vh', marginTop: '1.5vh', textAlign: 'right', marginRight: '3vh'}}>
                        {selectedCharacter}
                    </motion.div>
                    <motion.div className='NormalText' style={{height: '12vh', fontSize: '2.5vh', marginTop: '0.5vh', textAlign: 'right', marginRight: '3vh'}}>
                        {characters[selectedCharacter].description}
                    </motion.div>
                    <motion.div className='NormalText' style={{fontSize: '3vh', textAlign: 'center', marginTop: '0.25vh', marginRight: currentCharacter === selectedCharacter ? '-23.5vh' : '-27.25vh', 
                                textDecoration: currentCharacter === selectedCharacter ? 'none' : 'underline', 
                                color: currentCharacter === selectedCharacter ? '#B0B0B0' : '#6B6B6B'}}
                            whileHover={{ scale: currentCharacter === selectedCharacter ? 1 : 1.05 }} 
                            onMouseEnter={()=>{
                                if (currentCharacter === selectedCharacter) return
                                sounds['hover'].play()
                            }}
                            onClick={()=>{
                                if (currentCharacter === selectedCharacter) return
                                sounds['select'].play()
                                setCurrentCharacter(selectedCharacter)
                            }}>
                        {currentCharacter === selectedCharacter ? 'SELECTED' : 'SELECT'}
                    </motion.div>
                </motion.div>
            </motion.div>
            <motion.div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap', 
                width: '100%', 
                height: '27vh',
                justifyContent: 'flex-start',
                overflowX: 'scroll',  
                marginTop: '1.5vh',
                alignItems: 'center',
                paddingBottom: '0.5vh', // for scrollbar
            }}>
                <style>
                    {`
                        ::-webkit-scrollbar {
                            height: 1vh; 
                        }
                        ::-webkit-scrollbar-track {
                            background: #CBCBCB;
                            border-radius: 0.5vh; 
                        }
                        ::-webkit-scrollbar-thumb {
                            background: #6B6B6B;
                            border-radius: 0.5vh; 
                        }
                        ::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
                    `}
                </style>
                {avaliableCharacters.map((character, index) => (
                    <CharacterWindow key={index} name={character} selectedAvatar={selectedCharacter} setSelectedAvatar={setSelectedCharacter} isDisplay={false} />
                ))}
            </motion.div>
        </motion.div>
    )
}

export default CharacterSelector;
