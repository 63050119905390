import { motion } from 'framer-motion';
import React from 'react';

import '../../utils/Common.css';
import './Layout.css';

import { isDefaultSkin } from './Inventory'
import { Colors, Equipments } from '../../utils/Enums'
import skins from '../../utils/SkinMapper'
import sounds from '../../sounds';

interface Props {
    selectedPieceAlias: string;
    selectedSkinIconName: string;
    selectedSkinCount: number;
    selectedColor: Colors;
    equipedSkinName: string
    selectSkin: (pieceAlias: string, skinName: string) => void;
    animation: any;
}

const scaleMapping: any = {
    [Equipments.SNIPER]: 1.5,
    [Equipments.RIFLE]: 1.5,
    [Equipments.SHOTGUN]: 1.5,
    [Equipments.PISTOL]: 2,
    [Equipments.SHIELD]: 1.125, 
    [Equipments.KNIFE]: 3.25,
}

const DisplayPanel: React.FC<Props> = ({ selectedPieceAlias, selectedSkinIconName, selectedSkinCount, selectedColor, equipedSkinName, selectSkin, animation }) => {
    const skin = skins[selectedSkinIconName];
    // we can equip the current skin it is not the same as the already equipped one and it is either a default skin or has a count greater than 0
    const canEquip = equipedSkinName !== selectedSkinIconName && (isDefaultSkin(selectedSkinIconName) || selectedSkinCount > 0)
    const displayedName = selectedSkinIconName.replace(/ Prestige/g, '')
    return (
        <motion.div className='DisplayPanel' 
                    initial='initial' 
                    animate={animation.displayPanelStatus} 
                    variants={animation.displayPanelStatusAnimation}>
            {skin && <div>
                <div className='SmallText'>
                    {displayedName}
                </div>
                <img className='DisplayPanelImage' style={{pointerEvents: 'none', transform: `scale(${skin.kind === 'equipment' ? scaleMapping[skin.type] : 1}) translateY(${skin.kind === 'equipment' ? 5 + skin.equipmentDetail?.skinDisplayYOffset! : 0}%)`}}
                            src={selectedColor === Colors.WHITE ? skin.whiteImage : skin.blackImage} 
                            alt='preview'
                            draggable={false}
                />
                <div className='SmallText' style={{marginLeft: '12.5vh', marginTop: '-1vh', marginBottom: '-2.5vh', visibility: isDefaultSkin(selectedSkinIconName) || skin.kind === 'equipment' ? 'hidden' : 'visible'}}>
                    {isDefaultSkin(selectedSkinIconName) || skin.kind === 'equipment' ? 'default' : `x${selectedSkinCount}`}
                </div>
                <motion.div className='SmallButton' style={{y: '90%', marginTop: '5%', marginBottom: '-2%'}}
                            onClick={() => {
                                sounds['select'].play()
                            }}
                            onMouseEnter={() => {
                                sounds['hover'].play()
                            }}
                            whileHover={{ scale: 1.125 }}>
                    View
                </motion.div>
                <motion.div className='SmallButton'
                            style={{color: canEquip ? '#6B6B6B' : '#B0B0B0'}}
                            whileHover={{ scale: canEquip ? 1.125 : 1 }}
                            onClick={() => {
                                if (!canEquip) {
                                    return
                                }
                                selectSkin(selectedPieceAlias, selectedSkinIconName)
                                if (skin.kind === 'equipment' && skin.type !== Equipments.KNIFE && skin.type !== Equipments.SHIELD) {
                                    skin.equipmentDetail!.reloadSound!.play() 
                                } else {
                                    sounds['equip'].play() 
                                }
                            }}
                            onMouseEnter={() => {
                                if (equipedSkinName !== selectedSkinIconName) sounds['hover'].play()
                            }}>
                    {equipedSkinName !== selectedSkinIconName ? 'Equip' : 'Equipped'}
                </motion.div>
            </div>}
        </motion.div>
    );
}

export default DisplayPanel;