import { motion } from 'framer-motion';
import React, { useState } from 'react';

import * as ScreenAnimation from './Animation';
import sounds from '../../sounds';

import '../../utils/Common.css';
import './Join.css';
import CwgButton from '../../utils/CwgButton';
import { joinLobby } from '../../backend/CreateFirebaseEngine';

interface Props {
    setAlertMessage: (alert: string) => void;
    BackToCasual: () => void;
    ToHost: () => void;
}

const Join: React.FC<Props> = ({ setAlertMessage, BackToCasual, ToHost }) => {
    const animation = ScreenAnimation.useAnimationStatus();

    const [lobbyId, setLobbyId] = useState<string>("")
    const [processing, setProcessing] = useState<boolean>(false)

    function ToCasual() {
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            BackToCasual()
            clearTimeout(exitTimer)
        }, 550);
    }

    async function processJoinLobby(lobbyId: string) {
        setProcessing(true)
        try {
            await joinLobby({
                gameId: lobbyId,
            }).then((result: any) => {
                const status = result.data.data.status
                if (status === 'invalid id') {
                    setAlertMessage('Invalid lobby ID!')
                } else if (status === 'full') {
                    setAlertMessage('Lobby is full!')
                } else if (status === 'invalid user') {
                    setAlertMessage('Identical user joining lobby!')
                } else if (status === 'success') {
                    ToHost()
                } else {
                    setAlertMessage('An error has occured, try again later.')
                }
                setProcessing(false)
            });
        } catch (error) {
            setAlertMessage('Queuing too rapidly! Try again in a few seconds.')
            setProcessing(false)
        }
    }

    const allowClick = lobbyId !== '' && !processing

    return (
        <motion.div className = "Screen">
            <motion.div className = "HeadTitle" 
                        initial='initial' 
                        animate={animation.titleStatus} 
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') { animation.setTitleStatus('idle') }
                        }}>
                Join Lobby
            </motion.div>

            <form autoComplete="off">
            <motion.input className='InputField' 
                        initial='initial' 
                        animate={animation.inputStatus} 
                        variants={animation.InputStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.inputStatus === 'enter') { animation.setInputStatus('idle') }
                        }}
                        autoFocus 
                        spellCheck={false} 
                        draggable={false} 
                        whileHover={{ scale: 1.05 }} 
                        placeholder={"Enter Lobby ID"} 
                        onMouseEnter={()=>{sounds['hover'].play()}}
                        onInput={e => setLobbyId(e.currentTarget.value)}
                        required/>
            </form>

            <CwgButton 
                initial='initial' 
                animate={animation.joinButtonStatus} 
                variants={animation.joinButtonAnimation}
                setAnimation={animation.setJoinButtonStatus}
                hasIdle={true}
                style={{marginTop: '7.5vh'}}
                text={'Join Game'} 
                recoverDuration={2000}
                onClick={() => { 
                    processJoinLobby(lobbyId)
                }} 
                allowClick={allowClick}/>

            <CwgButton 
                initial='initial' 
                animate={animation.backStatus} 
                variants={animation.backStatusAnimation}
                setAnimation={animation.setBackStatus}
                hasIdle={true}
                text={'Leave'} 
                recoverDuration={2000}
                onClick={() => { 
                    ToCasual()
                }} 
                allowClick={true}
                isBack={true}/>
        </motion.div>
    );
}

export default Join;