import { MotionStyle, motion } from "framer-motion";
import { Dispatch, SetStateAction, useState } from "react";
import sounds from "../sounds";

import './Common.css';

interface Props {
    onImg: string;
    offImg?: string;
    onCondition?: boolean;
    onClick: () => void;
    allowClick: boolean;
    recoverDuration: number;
    initial?: string;
    animate?: string;
    variants?: any;
    setAnimation?: Dispatch<SetStateAction<"initial" | "exit" | "enter" | "idle">>;
    hasIdle?: boolean;
    style?: MotionStyle | undefined;
}

const ImageButton: React.FC<Props> = ({ onImg, offImg = '', onCondition = true, onClick, allowClick, recoverDuration = 0, initial = "", animate = "", variants = null, setAnimation, hasIdle = false, style = undefined }) => {
    const [isClickable, setIsClickable] = useState(true);

    const handleClick = () => {
        if (!allowClick || !isClickable) return;
        sounds['select'].play();
        onClick();
        setIsClickable(false); // Disable the button
        setTimeout(() => {
            setIsClickable(true); // Re-enable the button
        }, recoverDuration);
    };

    const canClick = allowClick && isClickable

    return (
        <motion.img style={{opacity : canClick ? 1 : 0.5, ...style}}
            draggable={false}
            initial={initial}
            animate={animate}
            variants={variants}
            onAnimationComplete={() => {
                if (hasIdle && setAnimation && animate === 'enter') {
                    setAnimation('idle')
                }
            }}
            whileHover={{scale: canClick ? 1.1 : 1}}
            onMouseEnter={() => {
                if (!canClick) return
                sounds['hover'].play()
            }}
            onClick={() => {
                if (!canClick) return
                sounds['select'].play()
                handleClick()
            }}
            src={onCondition ? onImg : offImg}/>
    );
}

export default ImageButton;