import './App.css';
import './backend/CreateFirebaseEngine';
import { signOut, getAuth } from 'firebase/auth';
import Origin from './components/origin/Origin.tsx';
import Login from './components/login/Login.tsx';
import Register from "./components/register/Register.tsx";
import Menu from "./components/menu/Menu.tsx";
import Mode from "./components/mode/Mode.tsx";
import Queue from "./components/queue/Queue.tsx";
import Layout from "./components/layout/Layout.tsx";
import Game from "./components/game/Game.tsx";
import Gacha from "./components/gacha/Gacha.tsx";
import Host from "./components/host/Host.tsx";
import Join from "./components/join/Join.tsx";
import Verify from "./components/help/Verification.tsx";
import Recover from "./components/help/Recover.tsx";
import AlertMessenger from "./utils/AlertMessenger.tsx";
import AudioSetting from "./utils/AudioPlayers/AudioSetting";
import { useState, useEffect } from "react";
import { Screens, Gamemodes } from "./utils/Enums.tsx"

import CreateAuthEngine from "./backend/CreateAuthEngine";
import { avaliableAvatars, defaultCharacters } from './utils/CharacterMapper';
import { getLoginInfo } from './backend/CreateFirebaseEngine';
import useMusicPlayer from './utils/AudioPlayers/MusicPlayer';
import useSoundEffect from './utils/AudioPlayers/SoundPlayer';
import {usePreloadImages} from "./images";

import { motion, useAnimation } from 'framer-motion';

function App() {
    const [page, setPage] = useState(Screens.ORIGIN);
    const [matchId, setMatchId] = useState("");
    const [gameMode, setGameMode] = useState(-1);
    const [gameStage, setGameStage] = useState(1);
    const [navigationCounter, setNavigationCounter] = useState(0); // Counter to ensure unique keys
    const [character, setCharacter] = useState(avaliableAvatars[Math.floor(Math.random() * 2)])
    const [allCharacters, setAllCharacters] = useState([])
    const [loginInfo, setLoginInfo] = useState(null)
    const [knuggets, setKnuggets] = useState(0)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [categoryPage, setCategoryPage] = useState("Category")
    
    const [alertMessage, setAlertMessage] = useState("")
    const [messageCount, setMessageCount] = useState(0)
    const [volumeSettingOn, setVolumeSettingOn] = useState(false)

    // for custom lobbies
    const [isHosting, setIsHosting] = useState(false)

    usePreloadImages();
    useSoundEffect();
    useMusicPlayer(page, gameMode, gameStage);

    const authEngine  = CreateAuthEngine();

    function GetPage() {
        // Increment the counter every time GetPage is called to ensure a unique key
        const key = `${page}-${matchId}-${gameMode}-${navigationCounter}`;

        switch (page) {
            case Screens.LOGIN:
                return <Login key={key} setAlertMessage={updateMessage} authEngine={ authEngine } LoginUser={ LoginUser } BackToOrigin={ () => GoToPage(Screens.ORIGIN) } RecoverPassword={ () => GoToPage(Screens.RECOVER) }/>;
            case Screens.REGISTER:
                return <Register key={key} setAlertMessage={updateMessage} authEngine={ authEngine } RegisterUser={ RegisterUser } BackToOrigin={ () => GoToPage(Screens.ORIGIN) }/>
            case Screens.ORIGIN:
                return <Origin key={key} setAlertMessage={updateMessage} ToLogin={ () => GoToPage(Screens.LOGIN) } ToRegister={ () => GoToPage(Screens.REGISTER) }/>;
            case Screens.MENU:
                return <Menu key={key} setAlertMessage={updateMessage} volumeSettingOn={volumeSettingOn} setVolumeSettingOn={setVolumeSettingOn} 
                        LogOut={ Logout } ToMode={ () => GoToPage(Screens.MODE) } ToCustomize={ () => GoToPage(Screens.BOARD) } ToGatcha={ () => GoToPage(Screens.GACHA)} 
                        character={character} setCharacter={setCharacter} allCharacters={allCharacters} knuggets={knuggets} setKnuggets={setKnuggets}/>;
            case Screens.GACHA:
                return <Gacha key={key} setAlertMessage={updateMessage} BackToMenu={ () => { GoToPage(Screens.MENU)} } knuggets={knuggets} setKnuggets={setKnuggets}/>;
            case Screens.MODE:
                return <Mode key={key} setAlertMessage={updateMessage} QueueForGame={ QueueForGame } BackToMenu={ BackToMenu } ToHost={() => {
                    setIsHosting(true)
                    GoToPage(Screens.HOST)}} 
                    ToJoin={() => GoToPage(Screens.JOIN)} category={categoryPage}/>;
            case Screens.QUEUE:
                return <Queue key={key} setAlertMessage={updateMessage} gameMode={gameMode} ExitToMode={ () => { setCategoryPage("Category") 
                    GoToPage(Screens.MODE)} } StartGame={ StartGame } myCharacter={character} credits={gameMode.includes('classic') ? loginInfo.classicCredits : loginInfo.standardCredits}/>;
            case Screens.GAME:
                return <Game key={key} setAlertMessage={updateMessage} gameMode={gameMode} matchId={matchId} backToMenu={BackToMenu} changeMode={BackToMode} findMatch={QueueForGame} myCharacter={character} setGameStage={setGameStage}/>;
            case Screens.BOARD:
                return <Layout key={key} setAlertMessage={updateMessage} BackToMenu={ BackToMenu }/>;
            case Screens.HOST:
                return <Host key={key} setAlertMessage={updateMessage} BackToCasual={ BackToCasual } myCharacter={character} isHost={isHosting} StartGame={ StartGame }/>;
            case Screens.JOIN:
                return <Join key={key} setAlertMessage={updateMessage} BackToCasual={ BackToCasual } ToHost={()=>{
                    setIsHosting(false)
                    GoToPage(Screens.HOST)}}/>;
            case Screens.VERIFY:
                return <Verify key={key} setAlertMessage={updateMessage} BackToLogin={ () => Logout() }/>;
            case Screens.RECOVER:
                return <Recover key={key} setAlertMessage={updateMessage} BackToLogin={ () => Logout() }/>;
            default:
                throw Error("Unknown page");
        }
    }

    function GoToPage(page) {
        setPage(page)
        setNavigationCounter((prev) => prev + 1);
    }

    function BackToCasual() {
        setCategoryPage("Casual")
        GoToPage(Screens.MODE)
    }

    function StartGame(matchId, mode) {
        setMatchId(matchId)
        setGameMode(mode)
        GoToPage(Screens.GAME);
    }

    async function LoginUser() {
        if (isLoggedIn) return Promise.resolve(); // Early return, explicitly return a resolved Promise
    
        try {
            const result = await getLoginInfo();
            const loginInfo = result.data.transactionResult.returnData;
            if (!result.data.transactionResult.success) {
                if (loginInfo === 'unverified email') {
                    GoToPage(Screens.VERIFY)
                    return
                } else {
                    setAlertMessage('Failed to login. Try again later!');
                    throw new Error('Failed to login. Try again later!'); 
                }
            }
    
            setLoginInfo(loginInfo);
            setKnuggets(loginInfo.knuggets);
            setAllCharacters(loginInfo.allCharacters || []);
    
            const chosenCharacter = loginInfo.character === 'Aaron' || loginInfo.character === 'Rachel' || avaliableAvatars.includes(loginInfo.character) 
                ? loginInfo.character 
                : defaultCharacters[Math.floor(Math.random() * defaultCharacters.length)];
    
            setCharacter(chosenCharacter);
            setIsLoggedIn(true);
    
            // Use the timer to delay navigation, return a promise that resolves when navigation is triggered
            return new Promise((resolve) => {
                setTimeout(() => {
                    GoToPage(Screens.MENU);
                    resolve(); // Resolve the promise after navigation
                }, 250);
            });
    
        } catch (error) {
            throw error
        }
    }

    const Logout = async () => {
        try {
          await signOut(getAuth());
          setIsLoggedIn(false)
          GoToPage(Screens.LOGIN);
        } catch (error) {
          console.error("Logout failed: ", error);
        }
    };

    function RegisterUser() {
        GoToPage(Screens.VERIFY);
    }

    function BackToMenu() {
        GoToPage(Screens.MENU);
    }

    function BackToMode() {
        GoToPage(Screens.MODE);
    }

    function QueueForGame(gameMode) {
        setGameMode(gameMode)
        if (gameMode === Gamemodes.DUMMY_BOT || gameMode === Gamemodes.CLASSIC_BOT || gameMode === Gamemodes.STANDARD_BOT) {
            GoToPage(Screens.GAME);
        } else {
            GoToPage(Screens.QUEUE);
        }
    }

    useEffect(() => {
        const handleWheel = (e) => e.preventDefault();
    
        window.addEventListener('wheel', handleWheel, { passive: false });
    
        // Cleanup on unmount
        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);

    // Shake animations for alerts
    const updateMessage = (newMessage) => {
        setAlertMessage(newMessage);
        setMessageCount(t => t + 1); 
    };

    const controls = useAnimation();
    const triggerShake = () => {
        const baseX = 0;
        const baseY = 0;
        const shakeMagnitude = 1;
        const numShakes = 8;
        const shakeSpeed = 0.05;
        const positionsX = [`${baseX}vh`];
        const positionsY = [`${baseY}vh`];

        // Define corners based on magnitude
        const topLeft = [baseX - shakeMagnitude, baseY - shakeMagnitude];
        const topRight = [baseX + shakeMagnitude, baseY - shakeMagnitude];
        const bottomLeft = [baseX - shakeMagnitude, baseY + shakeMagnitude];
        const bottomRight = [baseX + shakeMagnitude, baseY + shakeMagnitude];

        const corners = [topLeft, topRight, bottomLeft, bottomRight];
        let lastCornerIndex = -1;

        for (let i = 1; i <= numShakes; i++) {
            // chaotic shake
            let cornerIndex;
            do {
                cornerIndex = Math.floor(Math.random() * corners.length);
            } while (cornerIndex === lastCornerIndex); // Ensure not the same as the last corner
            lastCornerIndex = cornerIndex;
    
            positionsX.push(`${corners[cornerIndex][0]}vh`);
            positionsY.push(`${corners[cornerIndex][1]}vh`);
        }
        positionsY.push(`0vh`);
        positionsX.push(`0vh`);

        const shakeAnimation = {
            x: positionsX,
            y: positionsY,
            transition: {
                duration: numShakes * shakeSpeed,
                ease: 'easeInOut'
            }
        };

        controls.start(shakeAnimation);
    };

    return (
        <div className = "App">
            <motion.div className='Wrapper' animate={controls}>
                { GetPage() }
                <AlertMessenger message={alertMessage} setMessage={setAlertMessage} messageCount={messageCount} setMessageCount={setMessageCount} triggerShake={triggerShake}/>
                <AudioSetting volumeSettingOn={volumeSettingOn} setVolumeSettingOn={setVolumeSettingOn}/>
            </motion.div>
        </div>
    );
}

export default App;