// function that returns a point that is distance away from the origin given the angle
export function getPointAwayFromOrigin(
    originX: number, originY: number, angle: number, distance: number
) {
    const deltaX = Math.cos(angle) * distance;
    const deltaY = Math.sin(angle) * distance;
  
    const newX = originX + deltaX;
    const newY = originY + deltaY;
  
    return [ newX, newY ];
}

// given line form (x1, y1) to (x2, y2), check intersection between rectangle at rx ry with rw rh
export function checkLineRectIntersection(
    x1: number, y1: number, x2: number, y2: number, rx: number, ry: number, rw: number, rh: number
) {
    const lineSlope = (y2 - y1) / (x2 - x1);
    const lineIntercept = y1 - lineSlope * x1;

    const leftY = lineSlope * rx + lineIntercept;
    const rightY = lineSlope * (rx + rw) + lineIntercept;
    const topX = (ry - lineIntercept) / lineSlope;
    const bottomX = (ry + rh - lineIntercept) / lineSlope;

    const minX = Math.min(x1, x2);
    const maxX = Math.max(x1, x2);
    const minY = Math.min(y1, y2);
    const maxY = Math.max(y1, y2);

    if (
        (minX <= rx && rx <= maxX && ry <= leftY && leftY <= ry + rh) ||
        (minX <= rx + rw && rx + rw <= maxX && ry <= rightY && rightY <= ry + rh) ||
        (minY <= ry && ry <= maxY && rx <= topX && topX <= rx + rw) ||
        (minY <= ry + rh && ry + rh <= maxY && rx <= bottomX && bottomX <= rx + rw)
    ) {
        return true; // Line intersects the rectangle
    }

    return false; // Line does not intersect the rectangle
}

function getSubRank(creditTier: number) {
    if (creditTier === 1) {
        return 'I'
    } else if (creditTier === 2) {
        return 'II'
    } else if (creditTier === 3) {
        return 'III'
    } else if (creditTier === 4) {
        return 'IV'
    } else {
        return '?'
    }
}

export function getLeagueName(credits: number) {
    if (credits < 250) {
        return 'Scrubby'
    } else if (credits < 1000) {
        return 'Beginner '
    } else if (credits < 2000) {
        return 'Intermediate '
    } else if (credits < 3000) {
        return 'Advanced '
    } else if (credits < 4000) {
        return 'Expert '
    } else if (credits < 5000) {
        return 'Master '
    } else {
        return 'Grandmaster'
    }
}

export function getRankName(credits: number) {
    if (credits < 250) {
        return 'Scrubby'
    } else if (credits < 1000) {
        return 'Beginner ' + getSubRank(3 - Math.floor((credits - 250) / 250))
    } else if (credits < 2000) {
        return 'Intermediate ' + getSubRank(4 - Math.floor((credits - 1000) / 250))
    } else if (credits < 3000) {
        return 'Advanced ' + getSubRank(4 - Math.floor((credits - 2000) / 250))
    } else if (credits < 4000) {
        return 'Expert ' + getSubRank(4 - Math.floor((credits - 3000) / 250))
    } else if (credits < 5000) {
        return 'Master ' + getSubRank(4 - Math.floor((credits - 4000) / 250))
    } else {
        return 'Grandmaster'
    }
}