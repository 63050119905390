import { useState } from 'react';
import createFadeAnimation, { createDownAnimation, createFloatAnimation, useAlertAnimation, createUpAnimation } from '../../utils/Animator';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [fadeStatus, setFadeStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [titleStatus, setTitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [usernameStatus, setUsernameStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [passwordStatus, setPasswordStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [recoverStatus, setRecoverStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [loginStatus, setLoginStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [backStatus, setBackStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');

    const [alertAnimationStatus, setAlertAnimationStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('initial');

    const fadeStatusAnimation = createFadeAnimation(0)
    const titleStatusAnimation = createUpAnimation(0)
    const usernameStatusAnimation = createUpAnimation(0.05)
    const passwordStatusAnimation = createUpAnimation(0.10)
    const recoverStatusAnimation = createUpAnimation(0.15)
    const loginStatusAnimation = createUpAnimation(0.20)
    const backStatusAnimation = createDownAnimation(0)

    const alertAnimation = useAlertAnimation()

    titleStatusAnimation.idle = createFloatAnimation(0)
    usernameStatusAnimation.idle = createFloatAnimation(0.1)
    passwordStatusAnimation.idle = createFloatAnimation(0.2)
    recoverStatusAnimation.idle = createFloatAnimation(0.3)
    loginStatusAnimation.idle = createFloatAnimation(0.4)
    backStatusAnimation.idle = createFloatAnimation(0)

    function TransitionOut() {
        setFadeStatus('exit')
        setTitleStatus('exit')
        setUsernameStatus('exit')
        setPasswordStatus('exit')
        setRecoverStatus('exit')
        setLoginStatus('exit')
        setBackStatus('exit')
        setAlertAnimationStatus('exit')
    }

    return {
        fadeStatus, setFadeStatus, fadeStatusAnimation,
        titleStatus, setTitleStatus, titleStatusAnimation,
        usernameStatus, setUsernameStatus, usernameStatusAnimation,
        passwordStatus, setPasswordStatus, passwordStatusAnimation,
        recoverStatus, setRecoverStatus, recoverStatusAnimation,
        loginStatus, setLoginStatus, loginStatusAnimation,
        backStatus, setBackStatus, backStatusAnimation,
        alertAnimationStatus, setAlertAnimationStatus, alertAnimation,
        TransitionOut
    };
};