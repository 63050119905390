import { useState } from 'react';
import createFadeAnimation, { createFloatAnimation, createUpAnimation } from '../../utils/Animator';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [fadeStatus, setFadeStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [titleStatus, setTitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [subtitleStatus, setSubtitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [profileStatus, setProfileStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [loginButtonStatus, setLoginButtonStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [registerButtonStatus, setRegisterButtonStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');

    const fadeStatusAnimation = createFadeAnimation(0)
    const titleStatusAnimation = createUpAnimation(0)
    const subtitleStatusAnimation = createUpAnimation(0.05)

    const profileStatusAnimation = createUpAnimation(0.10)
    const loginButtonStatusAnimation = createUpAnimation(0.15)
    const registerButtonStatusAnimation = createUpAnimation(0.20)
    
    titleStatusAnimation.idle = createFloatAnimation(0)
    subtitleStatusAnimation.idle = createFloatAnimation(0.2)
    profileStatusAnimation.idle = createFloatAnimation(0.4)
    loginButtonStatusAnimation.idle = createFloatAnimation(0.6)
    registerButtonStatusAnimation.idle = createFloatAnimation(0.8)

    function TransitionOut() {
        setFadeStatus('exit')
        setTitleStatus('exit')
        setSubtitleStatus('exit')
        setProfileStatus('exit')
        setLoginButtonStatus('exit')
        setRegisterButtonStatus('exit')
    }

    return {
        fadeStatus, setFadeStatus, fadeStatusAnimation,
        titleStatus, setTitleStatus, titleStatusAnimation,
        subtitleStatus, setSubtitleStatus, subtitleStatusAnimation,
        profileStatus, setProfileStatus, profileStatusAnimation,
        loginButtonStatus, setLoginButtonStatus, loginButtonStatusAnimation,
        registerButtonStatus, setRegisterButtonStatus, registerButtonStatusAnimation,
        TransitionOut
    };
};

// wiggle animation
// subtitleStatusAnimation.idle = {
//     rotate: [0, 2, -2, 2, -2, 0],
//     x: 0,
//     opacity: 1, 
//     transition: {
//         duration: 2,     
//         ease: "easeInOut", 
//         repeat: Infinity,
//         repeatDelay: 0.5  
//     }
// };