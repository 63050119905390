import { MotionStyle, motion, useAnimation } from "framer-motion";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import sounds from "../sounds";

import './Common.css';

interface Props {
    text: string;
    onClick: () => void;
    allowClick: boolean;
    recoverDuration: number;
    initial?: string;
    animate?: string;
    variants?: any;
    setAnimation?: Dispatch<SetStateAction<"initial" | "exit" | "enter" | "idle">>;
    hasIdle?: boolean;
    style?: MotionStyle | undefined;
    isBack?: boolean;
    updateOnChange?: boolean;
}

const CwgButton: React.FC<Props> = ({ text, onClick, allowClick, recoverDuration = 0, initial = "", animate = "", variants = null, setAnimation, hasIdle = false, style = undefined, isBack = false, updateOnChange = true }) => {
    const [isClickable, setIsClickable] = useState<boolean>(true);
    const controls = useAnimation();
    const [previousText, setPreviousText] = useState<string>(text);

    const handleClick = () => {
        if (!allowClick || !isClickable) return;
        sounds['select'].play();
        onClick();
        setIsClickable(false); // Disable the button
        setTimeout(() => {
            setIsClickable(true); // Re-enable the button
        }, recoverDuration);
    };

    useEffect(() => {
        if (!updateOnChange) return
        if (text !== previousText) {
            const sequence = async () => {
                await controls.start({
                    scale: 1.1,
                    transition: { type: 'spring', duration: 0.1 }
                });
                await controls.start({
                    scale: 1,
                    transition: { type: 'spring', duration: 0.2 }
                });
            };
            sequence();
            setPreviousText(text);
        }
    }, [text, controls, previousText, updateOnChange]);
    
    const canClick = allowClick && isClickable

    return (
        <motion.div className={isBack?'BackButton':'Button'} style={{color : canClick ? '#6B6B6B' : '#B0B0B0', textDecoration: canClick ? 'underline' : 'none', ...style}}
            initial={initial}
            animate={animate}
            variants={variants}
            whileHover={{scale: canClick ? 1.1 : 1}}
            onAnimationComplete={() => {
                if (hasIdle && setAnimation && animate === 'enter') {
                    setAnimation('idle')
                }
            }}
            onMouseEnter={() => {
                if (!canClick) return
                sounds['hover'].play()
            }}
            onClick={() => {
                if (!canClick) return
                sounds['select'].play()
                handleClick()
            }}>

            <motion.div
                initial={{ scale: 1 }}
                animate={controls}>
                {text}
            </motion.div>
        </motion.div>
    );
}

export default CwgButton;