import GameEngine from '../../backend/GameEngine';
import sounds from '../../sounds';
import Pieces, { Colors, Equipments, Gamemodes, Phases } from '../../utils/Enums'
import { checkLineRectIntersection, getPointAwayFromOrigin } from '../../utils/MathUtil';
import skins from '../../utils/SkinMapper';
import { ShakeData } from './Game';
import { PieceCache } from './GameSetup';
import { PieceInfo } from './Piece';
import { EQUIPMENTS } from './Shop';

const DIRECTIONS = {
    [Pieces.PAWN]: {directions: [[1, -1], [-1, -1]], multiStep: false},
    [Pieces.KNIGHT]: {directions: [[-1, 2], [1, 2], [-1, -2], [1, -2], [-2, 1], [2, 1], [-2, -1], [2, -1]], multiStep: false},
    [Pieces.KING]: {directions: [[1, 1], [-1, -1], [1, -1], [-1, 1], [1, 0], [-1, 0], [0, 1], [0, -1]], multiStep: false},
    [Pieces.ROOK]:  {directions: [[1, 0], [-1, 0], [0, 1], [0, -1]], multiStep: true},
    [Pieces.BISHOP]:  {directions: [[1, 1], [-1, -1], [1, -1], [-1, 1]], multiStep: true},
    [Pieces.QUEEN]:  {directions: [[1, 1], [-1, -1], [1, -1], [-1, 1], [1, 0], [-1, 0], [0, 1], [0, -1]], multiStep: true}
}

const bounty: { [key in Pieces]: number } = {
    [Pieces.PAWN]: 100,
    [Pieces.KNIGHT]: 200,
    [Pieces.KING]: 0,
    [Pieces.ROOK]: 200,
    [Pieces.BISHOP]: 200,
    [Pieces.QUEEN]: 350,
    [Pieces.UNKNOWN]: 0,
};

// converts position i.e. A8 to coordinate i.e [0, 0] with respect to the player
export function PosToCoord(position: string, myColor: Colors) {
    // Turn the coordinate into numbers
    let col = position.charAt(0).charCodeAt(0) - 'A'.charCodeAt(0);
    let row = 8 - parseInt(position.charAt(1));

    // flip the coordinate system if side is black
    if (myColor === 'black') {
        col = 7 - col
        row = 7 - row
    }

    // Return the coordinate as an array [x, y]
    return [col, row];
}

// converts coordinate i.e [0, 0] to position i.e. A8 with respect to the player
export function CoordToPos(x: number, y: number, myColor: Colors) {
    if (myColor === Colors.WHITE) {
        let col = String.fromCharCode('A'.charCodeAt(0) + x)
        let row = String.fromCharCode('8'.charCodeAt(0) - y)
        return col + row
    } else {
        let col = String.fromCharCode('H'.charCodeAt(0) - x)
        let row = String.fromCharCode('1'.charCodeAt(0) + y)
        return col + row
    }
}

export function Moved(piece: PieceInfo) {
    if (piece.color === Colors.WHITE) {
        if (piece.type === Pieces.KING) {
            return piece.position !== "E1"
        } else if (piece.type === Pieces.QUEEN) {
            return piece.position !== "D1"
        } else if (piece.type === Pieces.BISHOP) {
            return piece.position !== "F1" && piece.position !== "C1"
        } else if (piece.type === Pieces.KNIGHT) {
            return piece.position !== "G1" && piece.position !== "B1"
        } else if (piece.type === Pieces.ROOK) {
            return piece.position !== "H1" && piece.position !== "A1"
        } else {
            return piece.position[1] !== "2"
        }
    } else {
        if (piece.type === Pieces.KING) {
            return piece.position !== "E8"
        } else if (piece.type === Pieces.QUEEN) {
            return piece.position !== "D8"
        } else if (piece.type === Pieces.BISHOP) {
            return piece.position !== "F8" && piece.position !== "C8"
        } else if (piece.type === Pieces.KNIGHT) {
            return piece.position !== "G8" && piece.position !== "B8"
        } else if (piece.type === Pieces.ROOK) {
            return piece.position !== "H8" && piece.position !== "A8"
        } else {
            return piece.position[1] !== "7"
        }
    }
}

// returns true if the given piece is dangerous to us, aka opponent piece and active and holding a knife
export function IsPieceDangerous(piece: PieceInfo, myColor: Colors) {
    return piece.color !== myColor && piece.active && piece.weapon === Equipments.KNIFE
}

// generate a 2D board from all the pieces
export function GenerateChessGrid(pieces: PieceInfo[], myColor: Colors): (PieceInfo | null)[][] {
    const board: (PieceInfo | null)[][] = Array(8).fill(null).map(() => Array(8).fill(null));
    pieces.forEach(piece => {
        // only add active pieces
        if (piece.active) {
            const [col, row] = PosToCoord(piece.position, myColor)
            board[row][col] = piece
        }
    });
    return board
}

// used for turning a piece into a printable char
function getPieceChar(piece: PieceInfo | null): string {
    if (piece === null) {
        return '.'; // or space ' ' to represent empty square
    }
    const pieceCharMap: { [key: string]: string } = {
        'pawn': 'P',
        'knight': 'N',
        'bishop': 'B',
        'rook': 'R',
        'queen': 'Q',
        'king': 'K',
    };
    let char = pieceCharMap[piece.type];
    if (piece.color === Colors.BLACK) {
        char = char.toLowerCase();
    }
    return char;
}

// turn the chess grid into a printable string
export function chessGridToString(chessGrid: (PieceInfo | null)[][]): string {
    let boardString = '';
    for (const row of chessGrid) {
        for (const cell of row) {
            boardString += getPieceChar(cell) + ' ';
        }
        boardString += '\n'; 
    }
    return boardString;
}

// gets the position of my king
function GetKingPos(chessGrid: (PieceInfo | null)[][], myColor: Colors): [number, number] {
    for (let y = 0; y < chessGrid.length; y++) {
        for (let x = 0; x < chessGrid[y].length; x++) {
            const piece = chessGrid[y][x];
            if (piece && piece.type === Pieces.KING && piece.color === myColor) {
                return [x, y];
            }
        }
    }
    return [-1, -1]; 
}
  
// returns true if the move chessGrid results in check for myColor's side
// note: only a piece holding a knife can check a king
export function IsChecked(chessGrid: (PieceInfo | null)[][], myColor: Colors, isClassic: boolean) {
    if (!isClassic) return false
    const [kingX, kingY]: [number, number] = GetKingPos(chessGrid, myColor)
    for (const pieceType in DIRECTIONS) {
        const directions = DIRECTIONS[pieceType as keyof typeof DIRECTIONS].directions

        const multiStep = DIRECTIONS[pieceType as keyof typeof DIRECTIONS].multiStep
        for (const dir of directions) {
            let x = kingX + dir[0]
            let y = kingY + dir[1]
            if (multiStep) {
                // if the piece can walk more than one step (i.e bishop, rook, queen)
                while (x >= 0 && x < 8 && y >= 0 && y < 8) {
                    const piece = chessGrid[y][x]
                    // encounters a piece
                    if (piece) {
                        // checkmate if the piece is the type we are looking for and is dangerous
                        if (piece.type === pieceType && IsPieceDangerous(piece, myColor)) {
                            return true
                        }
                        break
                    } 
                    x += dir[0]
                    y += dir[1]
                }
            } else {
                // if the piece can walk only one step (i.e knight, king, pawn)
                if (x >= 0 && x < 8 && y >= 0 && y < 8) {
                    const piece = chessGrid[y][x]
                    if (piece && piece.type === pieceType && IsPieceDangerous(piece, myColor)) {
                        return true
                    }
                }
            }
        }
    }
    return false
}

export function CanMove(chessGrid: (PieceInfo | null)[][], selectedPiece: PieceInfo, isClassic: boolean,
                        originX: number, originY: number, destX: number, destY: number, enpassant: boolean = false) {
    const myColor: Colors = selectedPiece.color
    if (destX >= 0 && destX < 8 && destY >= 0 && destY < 8) {
        // if there is already a piece and it is not dangerous
        if (chessGrid[destY][destX] && chessGrid[destY][destX]!.active && chessGrid[destY][destX]!.color === myColor) {
            return false
        }
        if (!isClassic) {
            return true
        }
        // deep copy the current value
        const temp = chessGrid[destY][destX] ? JSON.parse(JSON.stringify(chessGrid[destY][destX])) : null;
        chessGrid[destY][destX] = selectedPiece
        chessGrid[originY][originX] = null

        if (enpassant && destY + 1 < 8) {
            const enpassantPiece = chessGrid[destY + 1][destX] ? JSON.parse(JSON.stringify(chessGrid[destY + 1][destX])) : null;
            chessGrid[destY + 1][destX] = null
            const checked: boolean = IsChecked(chessGrid, myColor, isClassic)

            chessGrid[destY][destX] = temp
            chessGrid[originY][originX] = selectedPiece
            chessGrid[destY + 1][destX] = enpassantPiece
            return !checked
        } else {
            const checked: boolean = IsChecked(chessGrid, myColor, isClassic)
            chessGrid[destY][destX] = temp
            chessGrid[originY][originX] = selectedPiece
            return !checked
        }
    }
    return false
}

// generate a 2D boolean array indicating where the selected piece can move to
export function GetCanMovePos(pieces: PieceInfo[], selectedPiece: PieceInfo | null, myColor: Colors, phase: Phases, isClassic: boolean) {
    // default pieces on a 2D grid
    const canMoveGrid: boolean[][] = Array(8).fill(false).map(() => Array(8).fill(false));
    if (!selectedPiece || phase !== Phases.MOVE) {
        return canMoveGrid
    }
    const chessGrid: (PieceInfo | null)[][] = GenerateChessGrid(pieces, myColor)
    // directions on which the piece can move 
    const directions = DIRECTIONS[selectedPiece.type as keyof typeof DIRECTIONS].directions
    const multiStep = DIRECTIONS[selectedPiece.type as keyof typeof DIRECTIONS].multiStep
    const [ originX, originY ] = PosToCoord(selectedPiece.position, myColor)

    if (selectedPiece.type === Pieces.PAWN) {
        // this is pawn moving up
        if (selectedPiece.color === myColor) {
            // this is pawn attacking position
            if (selectedPiece.weapon === Equipments.KNIFE) {
                for (const dir of directions) {
                    let [ x, y ] = PosToCoord(selectedPiece.position, myColor)
                    x += dir[0]
                    y += dir[1]
                    // piece must exist to attack
                    if (x >= 0 && x < 8 && y >= 0 && y < 8 && chessGrid[y][x]) {
                        canMoveGrid[y][x] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, x, y)
                    }
                }
                // check for en-passant
                if (originX - 1 >= 0 && originY - 1 >= 0 && chessGrid[originY][originX - 1] && 
                    chessGrid[originY][originX - 1]?.type === Pieces.PAWN && chessGrid[originY][originX - 1]?.enpassantStage === 1) {
                        canMoveGrid[originY - 1][originX - 1] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, originX - 1, originY - 1, true)
                }
                if (originX + 1 < 8 && originY - 1 >= 0 && chessGrid[originY][originX + 1] && 
                    chessGrid[originY][originX + 1]?.type === Pieces.PAWN && chessGrid[originY][originX + 1]?.enpassantStage === 1) {
                        canMoveGrid[originY - 1][originX + 1] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, originX + 1, originY - 1, true)
                }
            }
            if (originY - 1 >= 0 && !chessGrid[originY - 1][originX]) {
                canMoveGrid[originY - 1][originX] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, originX, originY - 1)
            }
            // pawn have not moved
            if (originY === 6 && !chessGrid[originY - 2][originX] && !chessGrid[originY - 1][originX]) {
                canMoveGrid[originY - 2][originX] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, originX, originY - 2)
            }
        } else {
            // this is pawn attacking position
            if (selectedPiece.weapon === Equipments.KNIFE) {
                for (const dir of directions) {
                    let [ x, y ] = PosToCoord(selectedPiece.position, myColor)
                    x -= dir[0]
                    y -= dir[1]
                    // piece must exist to attack
                    if (x >= 0 && x < 8 && y >= 0 && y < 8 && chessGrid[y][x]) {
                        canMoveGrid[y][x] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, x, y)
                    }
                }
                // check for en-passant
                if (originX + 1 < 8 && originY + 1 < 8 && chessGrid[originY][originX + 1] && 
                    chessGrid[originY][originX + 1]?.type === Pieces.PAWN && chessGrid[originY][originX + 1]?.enpassantStage === 1) {
                        canMoveGrid[originY + 1][originX + 1] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, originX + 1, originY + 1, true)
                }
                if (originX - 1 < 8 && originY + 1 >= 0 && chessGrid[originY][originX - 1] && 
                    chessGrid[originY][originX - 1]?.type === Pieces.PAWN && chessGrid[originY][originX - 1]?.enpassantStage === 1) {
                        canMoveGrid[originY + 1][originX - 1] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, originX - 1, originY + 1, true)
                }
            }
            if (originY + 1 < 8 && !chessGrid[originY + 1][originX]) {
                canMoveGrid[originY + 1][originX] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, originX, originY + 1)
            }
            // pawn have not moved
            if (originY === 1 && !chessGrid[originY + 2][originX] && !chessGrid[originY + 1][originX]) {
                canMoveGrid[originY + 2][originX] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, originX, originY + 2)
            }
        }
    } else {
        for (const dir of directions) {
            let [ x, y ] = PosToCoord(selectedPiece.position, myColor)
            x += dir[0]
            y += dir[1]
            while (x >= 0 && x < 8 && y >= 0 && y < 8) {
                // cant move any further if we encounter our own piece or if the current piece does not have a knife
                if (chessGrid[y][x] && 
                    (chessGrid[y][x]?.color === selectedPiece.color || selectedPiece.weapon !== Equipments.KNIFE)) {
                        break
                }
                canMoveGrid[y][x] = CanMove(chessGrid, selectedPiece, isClassic, originX, originY, x, y)

                // stop at the first piece
                if (chessGrid[y][x]) {
                    break
                }
                
                if (!multiStep) {
                    break
                }
                x += dir[0]
                y += dir[1]
            }
        }
        // check for castle 
        if (selectedPiece.type === Pieces.KING && selectedPiece.canCastle && !IsChecked(chessGrid, myColor, isClassic)) {
            let [ kingX, kingY ] = PosToCoord(selectedPiece.position, myColor)
            // check queen and king side castle rules
            if (chessGrid[kingY][0] && chessGrid[kingY][0]!.active && chessGrid[kingY][0]!.type === Pieces.ROOK && chessGrid[kingY][0]!.canCastle) {
                let isBlocked = false
                for (let i = kingX - 1; i > 0; --i) {
                    if (chessGrid[kingY][i] && chessGrid[kingY][i]?.active) {
                        isBlocked = true
                        break
                    }
                }
                const canCross = CanMove(chessGrid, selectedPiece, isClassic, kingX, kingY, kingX - 1, kingY)

                chessGrid[kingY][kingX - 2] = chessGrid[kingY][kingX]
                chessGrid[kingY][kingX - 1] = chessGrid[kingY][0]
                chessGrid[kingY][kingX] = null
                chessGrid[kingY][0] = null
                const destinationChecked: boolean = IsChecked(chessGrid, myColor, isClassic)
                // reset values
                chessGrid[kingY][kingX] = chessGrid[kingY][kingX - 2]
                chessGrid[kingY][0] = chessGrid[kingY][kingX - 1]
                chessGrid[kingY][kingX - 2] = null
                chessGrid[kingY][kingX - 1] = null

                canMoveGrid[kingY][0] = !isBlocked && canCross && !destinationChecked
            }
            if (chessGrid[kingY][7] && chessGrid[kingY][7]!.active && chessGrid[kingY][7]!.type === Pieces.ROOK && chessGrid[kingY][7]!.canCastle) {
                let isBlocked = false
                for (let i = kingX + 1; i < 7; ++i) {
                    if (chessGrid[kingY][i] && chessGrid[kingY][i]?.active) {
                        isBlocked = true
                        break
                    }
                }
                const canCross = CanMove(chessGrid, selectedPiece, isClassic, kingX, kingY, kingX + 1, kingY)

                chessGrid[kingY][kingX + 2] = chessGrid[kingY][kingX]
                chessGrid[kingY][kingX + 1] = chessGrid[kingY][7]
                chessGrid[kingY][kingX] = null
                chessGrid[kingY][7] = null
                const destinationChecked: boolean = IsChecked(chessGrid, myColor, isClassic)
                // reset values
                chessGrid[kingY][kingX] = chessGrid[kingY][kingX + 2]
                chessGrid[kingY][7] = chessGrid[kingY][kingX + 1]
                chessGrid[kingY][kingX + 2] = null
                chessGrid[kingY][kingX + 1] = null

                canMoveGrid[kingY][7] = !isBlocked && canCross && !destinationChecked
            }
        }
    }
    return canMoveGrid
}

export function movePiece(
    pieces: PieceInfo[], setPieces: (newPieces: PieceInfo[]) => void, pieceIdx: number, cellX: number, cellY: number, myColor: Colors,
) {
    // if this piece is a pawn, store the pawn Y for checking enpassant
    const [ , pawnY ] = PosToCoord(pieces[pieceIdx].position, myColor)
    const position: string = CoordToPos(cellX, cellY, myColor)
    pieces[pieceIdx].position = position
    if (pieces[pieceIdx].type === Pieces.KING || pieces[pieceIdx].type === Pieces.ROOK) {
        pieces[pieceIdx].canCastle = false
    } else if (pieces[pieceIdx].type === Pieces.PAWN) {
        // enpassant logic
        if (pieces[pieceIdx].enpassantStage === 1) {
            pieces[pieceIdx].enpassantStage = 2
        } else if (pieces[pieceIdx].enpassantStage === 0) {
            if (Math.abs(pawnY - cellY) === 2) {
                pieces[pieceIdx].enpassantStage = 1
            } else {
                pieces[pieceIdx].enpassantStage = 2
            }
        } 
    }
    for (const piece of pieces) {
        if (piece.type === Pieces.PAWN && piece.enpassantStage === 1 && piece.idx !== pieceIdx) {
            pieces[piece.idx].enpassantStage = 2
        }
    }
    setPieces([...pieces]);
}

export function moveCastle(
    pieces: PieceInfo[], setPieces: (newPieces: PieceInfo[]) => void, myColor: Colors, kingIdx: number, rookIdx: number, kingPosition: string, rookX: number
) {
    const [kingX, kingY] = PosToCoord(kingPosition, myColor)
    if (kingX < rookX) {
        const finalKingPosition: string = CoordToPos(kingX + 2, kingY, myColor)
        const finalRookPosition: string = CoordToPos(kingX + 1, kingY, myColor)
        pieces[kingIdx].position = finalKingPosition
        pieces[rookIdx].position = finalRookPosition
    } else {
        const finalKingPosition: string = CoordToPos(kingX - 2, kingY, myColor)
        const finalRookPosition: string = CoordToPos(kingX - 1, kingY, myColor)
        pieces[kingIdx].position = finalKingPosition
        pieces[rookIdx].position = finalRookPosition
    }
    pieces[kingIdx].canCastle = false
    pieces[rookIdx].canCastle = false
    setPieces(pieces)
}

export function buyEquipment(
    pieces: PieceInfo[], setPieces: (newPieces: PieceInfo[]) => void, selectedPiece: PieceInfo, equipment: Equipments,
    myIncome: number, setMyIncome: (newIncome: number) => void, gameMode: string, 
) {
    if (!equipment) {
        return false
    }
    if (myIncome < EQUIPMENTS[equipment].cost) {
        return false
    }

    // buy the equipment
    if (equipment === Equipments.SHIELD && !pieces[selectedPiece.idx].vest) {
        pieces[selectedPiece.idx].vest = true
        sounds['purchaseEquipment'].play()
    } else if (equipment !== Equipments.SHIELD && pieces[selectedPiece.idx].weapon !== equipment) {
        pieces[selectedPiece.idx].weapon = equipment
        sounds['purchaseEquipment'].play()
    } else {
        return false
    }
    if (gameMode !== Gamemodes.DUMMY_BOT) {
        setMyIncome(myIncome - EQUIPMENTS[equipment].cost)
    }

    setPieces(pieces)
    return true
}

// also used after game engine update for animation
export function stabPiece(
    pieces: PieceInfo[], setPieces: (newPieces: PieceInfo[]) => void, 
    pieceCache: PieceCache[], setPieceCache: (newPieceCache: PieceCache[]) => void, 
    pieceIdx: number, piecePos: string, selectedPos: string, myColor: Colors, 
    myIncome: number, setMyIncome: (newIncome: number) => void, 
    setBoardShakeInfo: (shakeInfo: ShakeData) => void, 
    setGameOverStatus: (status: {winner: Colors, condition: string}) => void, updatePieces: boolean = true, isDummy: boolean = false,
) {
    const [ targetX, targetY ] = PosToCoord(piecePos, myColor)
    const [ sourceX, sourceY ] = PosToCoord(selectedPos, myColor)

    const destroyAngle = Math.atan2(targetY - sourceY, targetX - sourceX)
    const launchForce = 2 + (Math.sqrt((targetY - sourceY) * (targetY - sourceY) + (targetX - sourceX) * (targetX - sourceX)))
    
    pieceCache[pieceIdx].stabInfo = {
        destroyedAngle: destroyAngle,
        launchForce: launchForce,
        xRotate: sourceX < targetX ? 1 : -1,
        fromAbove: sourceY <= targetY // attacking piece overlay attacked piece from above
    }

    if (pieces[pieceIdx].weapon !== Equipments.KNIFE) {
        pieceCache[pieceIdx].shotInfo = {
            destroyedAngle: destroyAngle,
            launchForce: launchForce,
            xRotate: sourceX < targetX ? 1 : -1,
            gunHit: false
        }
    }

    if (pieces[pieceIdx].vest) {
        pieceCache[pieceIdx].shieldInfo = {
            destroyedAngle: destroyAngle,
            launchForce: launchForce,
            gunHit: false
        }
    }

    const timer = setTimeout(() => {
        if (updatePieces) {
            setMyIncome(myIncome + bounty[pieces[pieceIdx].type])
        }
        setBoardShakeInfo({
            angle: pieceCache[pieceIdx].stabInfo.destroyedAngle, 
            force: pieceCache[pieceIdx].stabInfo.launchForce + 2
        });
        return () => {
            clearTimeout(timer)
        };
    }, 400); 

    pieces[pieceIdx].active = false
    if (pieces[pieceIdx].type === Pieces.KING && !isDummy) {
        setGameOverStatus({winner: pieces[pieceIdx].color === Colors.BLACK ? Colors.WHITE : Colors.BLACK, condition: 'Stabbed'})
    }

    setPieceCache(pieceCache)
    
    // dont update pieces if it is opponent turn, this is redundant from what is done in game engine
    if (updatePieces) {
        setPieces(pieces)
    }
}

export function getNextTurn(turn: {color: Colors, phase: Phases}, gameMode: string, canFire: boolean = false) {

    const opponentTurn = gameMode === Gamemodes.DUMMY_BOT ? turn.color : (turn.color === Colors.WHITE ? Colors.BLACK : Colors.WHITE)
    let nextTurn = {color: opponentTurn, phase: Phases.MOVE}
    if (gameMode.includes('classic')) {
        nextTurn = {color: opponentTurn, phase: Phases.MOVE}
    } else if (turn.phase === Phases.MOVE && canFire) {
        nextTurn = {color: turn.color, phase: Phases.FIRE}
    } else if (turn.phase === Phases.MOVE) {
        nextTurn = {color: opponentTurn, phase: Phases.MOVE}
    } else if (turn.phase === Phases.FIRE) {
        nextTurn = {color: opponentTurn, phase: Phases.MOVE}
    } 
    return nextTurn
}

export function promotePawn(pieces: PieceInfo[], setPieces: (newPieces: PieceInfo[]) => void, idx: number, newType: Pieces,
                            turn: {color: Colors, phase: Phases}, setTurn: (nextTurn: {color: Colors, phase: Phases}, debug?: number) => void, 
                            gameMode: string, 
                            setSelectedPiece: (pieceInfo: PieceInfo | null) => void,
                            setPromotionSet: (promotionSet: string) => void,
                            setGameOverStatus: (status: {winner: Colors, condition: string}) => void,
                            gameEngine: GameEngine | null) {
    if (idx === -1 && pieces[idx].type !== Pieces.PAWN) {
        return
    }
    pieces[idx].type = newType
    if (gameEngine) {
        gameEngine.queueEvent({
            type: 'promote',
            selectedPieceIdx: idx,
            promoteTo: newType
        })
    }
    if (pieces[idx].weapon === Equipments.KNIFE) {
        setSelectedPiece(null)
    } else {
        setSelectedPiece(pieces[idx])
    }   
    setPieces(pieces)
    const timer = setTimeout(() => {
        setPromotionSet('')
        return () => {
            clearTimeout(timer)
        };
    }, 500);
    // end game if there is a checkmate after promotion
    if (isCheckmate(pieces, pieces[idx].color, gameMode.includes('classic'), setGameOverStatus)) {
        return
    } else {
        if (pieces[idx].weapon === Equipments.KNIFE || gameMode.includes('classic')) {
            const nextColor: Colors = gameMode === Gamemodes.DUMMY_BOT ? turn.color : (turn.color === Colors.WHITE ? Colors.BLACK : Colors.WHITE)
            setTurn( {color: nextColor, phase: Phases.MOVE})
        } else {
            setTurn({color: turn.color, phase: Phases.FIRE})
        }
    }
}

export function updateShiver(pieces: PieceInfo[], selectedPieceIdx: number, pieceCache: PieceCache[], 
        setPieceCache: (newPieceCache: PieceCache[]) => void, phase: Phases, canShoot: boolean) {
    if (pieces[selectedPieceIdx].weapon === Equipments.KNIFE || !canShoot || phase !== Phases.FIRE) {
        return
    }
    const [selectedX, selectedY] = PosToCoord(pieces[selectedPieceIdx].position, pieces[selectedPieceIdx].color)
    for (const piece of pieces) {
        if (piece.active && piece.color !== pieces[selectedPieceIdx].color) {
            const [x, y] = PosToCoord(piece.position, pieces[selectedPieceIdx].color)
            const maxRange = EQUIPMENTS[pieces[selectedPieceIdx].weapon].maxRange
            if (Math.abs(x - selectedX) <= maxRange && Math.abs(y - selectedY) <= maxRange) {
                pieceCache[piece.idx].shiver = true
            }
        }
    }
    setPieceCache(pieceCache)
}

// this function checks if you checkmate the opponent after a move
export function isCheckmate(pieces: PieceInfo[], movingColor: Colors, isClassic: boolean, 
        setGameOverStatus?: (status: {winner: Colors, condition: string}) => void, setSelectedPos?: (newPos: string) => void) {
    if (!isClassic) {
        return false
    }
    const opponentColor = movingColor === Colors.WHITE ? Colors.BLACK : Colors.WHITE
    const chessGrid: (PieceInfo | null)[][] = GenerateChessGrid(pieces, opponentColor)
    
    // opponent not checked after the move piece moved
    if (!IsChecked(chessGrid, opponentColor, isClassic)) {
        return false
    }

    // check if opponent king can move
    let opponentKingIdx: number = 0
    for (const piece of pieces) {
        if (piece.type === Pieces.KING && piece.color !== movingColor) {
            opponentKingIdx = piece.idx
            break
        }
    }
    const canMoveGrid: boolean[][] = GetCanMovePos(pieces, pieces[opponentKingIdx], opponentColor, Phases.MOVE, isClassic)
    for (let i = 0; i < canMoveGrid.length; i++) {
        for (let j = 0; j < canMoveGrid[i].length; j++) {
            if (canMoveGrid[i][j]) {
                // king has a place to move
                return false
            }
        }
    }

    // get all of our pieces that checks the king
    // idx of pieces that checks the king
    const checkPieceIdxes: number[] = []
    for (const piece of pieces) {
        if (piece.active && piece.color === movingColor) {
            const pieceCanMoveGrid: boolean[][] = GetCanMovePos(pieces, piece, piece.color, Phases.MOVE, isClassic)
            let found: boolean = false
            for (let i = 0; i < pieceCanMoveGrid.length; i++) {
                for (let j = 0; j < pieceCanMoveGrid[i].length; j++) {
                    const piecePos = CoordToPos(j, i, piece.color)
                    if (pieceCanMoveGrid[i][j] && piecePos === pieces[opponentKingIdx].position) {
                        checkPieceIdxes.push(piece.idx)
                        found = true
                        break
                    }
                }
                if (found) {
                    break
                }
            }
        }
    }
    // list of possible positions that a piece can move to to block the checkmate
    const blockCheckmatePos: string[] = []
    for (const pieceIdx of checkPieceIdxes) {
        const checkPiece = pieces[pieceIdx]
        if (!(DIRECTIONS[checkPiece.type as keyof typeof DIRECTIONS].multiStep)) {
            // this piece must be taken down
            blockCheckmatePos.push(checkPiece.position)
        } else {
            const [attackX, attackY] = PosToCoord(checkPiece.position, opponentColor)
            const [kingX, kingY] = PosToCoord(pieces[opponentKingIdx].position, opponentColor)
            // move anywhere in between or take down the piece to block the checkmate
            // get positions from attack pos to king pos
            const xStep: number = kingX === attackX ? 0 : (kingX > attackX ? 1 : -1)
            const yStep: number = kingY === attackY ? 0 : (kingY > attackY ? 1 : -1)
            for (let x = attackX, y = attackY, numLoop = 0; numLoop < 10; x += xStep, y += yStep, numLoop += 1) {
                if (x === kingX && y === kingY) {
                    break
                }
                blockCheckmatePos.push(CoordToPos(x, y, opponentColor))
            }
        }
    }
    for (const piece of pieces) {
        if (piece.color === opponentColor && piece.active) {
            const pieceCanMoveGrid: boolean[][] = GetCanMovePos(pieces, piece, opponentColor, Phases.MOVE, isClassic)
            for (let i = 0; i < pieceCanMoveGrid.length; i++) {
                for (let j = 0; j < pieceCanMoveGrid[i].length; j++) {
                    if (pieceCanMoveGrid[i][j] && blockCheckmatePos.includes(CoordToPos(j, i, opponentColor))) {
                        // make sure that even after blocking the checkmate, the piece is still not in check
                        const [pieceX, pieceY] = PosToCoord(piece.position, opponentColor)
                        const temp = chessGrid[i][j] ? JSON.parse(JSON.stringify(chessGrid[i][j])) : null;
                        chessGrid[i][j] = piece
                        chessGrid[pieceY][pieceX] = null

                        const checked: boolean = IsChecked(chessGrid, opponentColor, isClassic)
                        chessGrid[i][j] = temp
                        chessGrid[pieceY][pieceX] = piece
                        // a piece can block the checkmate
                        if (!checked) {
                            return false
                        }
                    }
                }
            }
        }
    }
    if (setGameOverStatus) {
        setGameOverStatus({winner: movingColor, condition: 'Checkmate'})
    }
    // reset selected position
    if (setSelectedPos) {   
        setSelectedPos('')
    }
    return true
}

export function clickCell(
    pieces: PieceInfo[], setPieces: (newPieces: PieceInfo[]) => void, 
    selectedPiece: PieceInfo | null, setSelectedPos: (newPos: string) => void, setSelectedPiece: (piece: PieceInfo | null) => void, 
    canMovePos: boolean[][], cellX: number, cellY: number, myColor: Colors,

    turn: {color: Colors, phase: Phases}, setTurn: (nextTurn: {color: Colors, phase: Phases}, debug?: number) => void, 

    pieceCache: PieceCache[], setPieceCache: (newPieceCache: PieceCache[]) => void, 
    myIncome: number, setMyIncome: (newIncome: number) => void, 
    setBoardShakeInfo: (shakeInfo: ShakeData) => void, setGameOverStatus: (status: {winner: Colors, condition: string}) => void, 
    gameMode: string, allowMove: boolean, 
    gameEngine: GameEngine | null
) {
    const chessGrid: (PieceInfo | null)[][] = GenerateChessGrid(pieces, myColor)
    const cellPos = CoordToPos(cellX, cellY, myColor)

    // cannot move to selected position
    if (!selectedPiece || !canMovePos[cellY][cellX] || turn.color !== myColor || turn.phase !== Phases.MOVE) {
        setSelectedPos(cellPos)
        return
    }

    if (!allowMove) return 
    // there is a piece on the cell we are trying to go
    if (chessGrid[cellY][cellX]) {
        // select piece
        if (chessGrid[cellY][cellX]!.active && (chessGrid[cellY][cellX]!.color === myColor || gameMode === Gamemodes.DUMMY_BOT)) {
            // also need to make sure that in dummy mode we can attack opposite pieces
            if (chessGrid[cellY][cellX]!.color === selectedPiece.color || gameMode !== Gamemodes.DUMMY_BOT) {
                if (selectedPiece.type === Pieces.KING && chessGrid[cellY][cellX]!.type === Pieces.ROOK) {
                    if (gameEngine) {
                        gameEngine.queueEvent({
                            type: 'move',
                            selectedPieceIdx: selectedPiece.idx,
                            destination: cellPos
                        })
                    }
                    moveCastle(pieces, setPieces, myColor, selectedPiece.idx, chessGrid[cellY][cellX]!.idx, selectedPiece.position, cellX)
                    if (isCheckmate(pieces, myColor, gameMode.includes('classic'), setGameOverStatus)) {
                        return
                    } 
                    const nextTurn = getNextTurn(turn, gameMode, selectedPiece.weapon !== Equipments.KNIFE)
                    setTurn(nextTurn)
                    // in firing phase, don't change selected piece
                    if (selectedPiece.weapon !== Equipments.KNIFE) {
                        setSelectedPos(cellPos)
                    } 
                } else if (chessGrid[cellY][cellX]!.color === selectedPiece.color) {
                    // selecting another piece
                    setSelectedPos(chessGrid[cellY][cellX]!.position)
                }
                return
            }
        } 

        // stab piece
        stabPiece(pieces, setPieces, pieceCache, setPieceCache, chessGrid[cellY][cellX]!.idx, 
            pieces[chessGrid[cellY][cellX]!.idx].position, pieces[selectedPiece.idx].position, myColor, myIncome, setMyIncome, setBoardShakeInfo, setGameOverStatus, true, true)
    } else if (selectedPiece.type === Pieces.PAWN) {
        const [selectedX,] = PosToCoord(selectedPiece.position, myColor)
        // check for enpassant
        if (selectedPiece.color === myColor) {
            if (Math.abs(selectedX - cellX) === 1 && cellY + 1 < 8 && chessGrid[cellY + 1][cellX] && chessGrid[cellY + 1][cellX]?.type === Pieces.PAWN && chessGrid[cellY + 1][cellX]?.color !== myColor) {
                stabPiece(pieces, setPieces, pieceCache, setPieceCache, chessGrid[cellY + 1][cellX]!.idx, 
                    pieces[chessGrid[cellY + 1][cellX]!.idx].position, pieces[selectedPiece.idx].position, myColor, myIncome, setMyIncome, setBoardShakeInfo, setGameOverStatus, true, true)
            } 
        } else {
            if (Math.abs(selectedX - cellX) === 1 && cellY - 1 >= 0 && chessGrid[cellY - 1][cellX] && chessGrid[cellY - 1][cellX]?.type === Pieces.PAWN && chessGrid[cellY - 1][cellX]?.color === myColor) {
                stabPiece(pieces, setPieces, pieceCache, setPieceCache, chessGrid[cellY - 1][cellX]!.idx, 
                    pieces[chessGrid[cellY - 1][cellX]!.idx].position, pieces[selectedPiece.idx].position, myColor, myIncome, setMyIncome, setBoardShakeInfo, setGameOverStatus, true, true)
            } 
        }
    }
    
    movePiece(pieces, setPieces, selectedPiece.idx, cellX, cellY, myColor)
    // make sure that CanMovePos updates correctly
    if (selectedPiece.weapon !== Equipments.KNIFE) {
        setSelectedPos(cellPos)
    } 
    
    if (gameEngine) {
        gameEngine.queueEvent({
            type: 'move',
            selectedPieceIdx: selectedPiece.idx,
            destination: cellPos
        })
    }

    // end game if there is a checkmate
    if (isCheckmate(pieces, myColor, gameMode.includes('classic'), setGameOverStatus)) {
        setSelectedPiece(null)
        return
    } 
    
    // check for pawn promotion
    if (selectedPiece.type === Pieces.PAWN && ((selectedPiece.color === myColor && cellY === 0) || (selectedPiece.color !== myColor && cellY === 7))) {
        setSelectedPos(cellPos)
        setTurn({color: turn.color, phase: Phases.PROMOTE})
    } else {
        setSelectedPiece(null)
        const nextTurn = getNextTurn(turn, gameMode, selectedPiece.weapon !== Equipments.KNIFE)
        setTurn(nextTurn)
    }
}

export function pieceFire(pieces: PieceInfo[], setPieces: (newPieces: PieceInfo[]) => void,
                            selectedPieceIdx: number, weaponSkinName: string, shootAngle: number, 
                            setScreenShakeInfo: (shakeInfo: ShakeData) => void,
                            pieceCache: PieceCache[], setPieceCache: (newPieceCache: PieceCache[]) => void,
                            setGameOverStatus: (status: {winner: Colors, condition: string}) => void,
                            myIncome: number, setMyIncome: (income: number) => void, gameMode: string, myColor: Colors) {
    const selectedPiece = pieces[selectedPieceIdx]
    const recoil = EQUIPMENTS[selectedPiece.weapon].recoil
    setScreenShakeInfo({angle: shootAngle, force: recoil})
    skins[weaponSkinName].equipmentDetail!.fireSound!.play()
    pieceCache[selectedPiece.idx].shootInfo = {recoilAngle: shootAngle + Math.PI, recoilForce: EQUIPMENTS[selectedPiece.weapon].recoil}
    
    const hitPieceIdxes: number[] = getHitPieces(pieces, selectedPiece, gameMode === Gamemodes.DUMMY_BOT ? myColor : selectedPiece.color, shootAngle)
    for (const pieceIdx of hitPieceIdxes) {
        if (pieces[pieceIdx].vest) {
            pieces[pieceIdx].vest = false
            pieceCache[pieceIdx].shieldInfo = {
                destroyedAngle: shootAngle,
                launchForce: EQUIPMENTS[selectedPiece.weapon].hitForce,
                gunHit: true
            }
        } else {
            pieces[pieceIdx].active = false
            pieceCache[pieceIdx].shotInfo = {
                destroyedAngle: shootAngle,
                launchForce: EQUIPMENTS[selectedPiece.weapon].hitForce,
                xRotate: selectedPiece.position[0] < pieces[pieceIdx].position[0] ? 1 : -1, 
                gunHit: true
            }
            if (pieces[pieceIdx].type === Pieces.KING && gameMode !== Gamemodes.DUMMY_BOT) {
                setGameOverStatus({winner: pieces[pieceIdx].color === Colors.WHITE ? Colors.BLACK : Colors.WHITE, condition: 'Wasted'})
            } else if (gameMode.includes('competitive') || gameMode.includes('casual')) {
                setMyIncome(myIncome + bounty[pieces[pieceIdx].type])
            }
        }
    }
    setPieces(pieces)
    setPieceCache(pieceCache)
}

// this function is only for updating opponent firing animation, it should minic the function above
export function opponentPieceFire(pieces: PieceInfo[],
                            selectedPieceIdx: number, weaponName: string, shootAngle: number, 
                            setScreenShakeInfo: (shakeInfo: ShakeData) => void,
                            pieceCache: PieceCache[], setPieceCache: (newPieceCache: PieceCache[]) => void,
                            hitPieceIdxes: number[],
                            setGameOverStatus: (status: {winner: Colors, condition: string}) => void) {
    const selectedPiece = pieces[selectedPieceIdx]
    const recoil = EQUIPMENTS[selectedPiece.weapon].recoil
    setScreenShakeInfo({angle: shootAngle, force: recoil})
    skins[weaponName].equipmentDetail!.fireSound!.play()
    pieceCache[selectedPiece.idx].shootInfo = {recoilAngle: shootAngle + Math.PI, recoilForce: EQUIPMENTS[selectedPiece.weapon].recoil}
    
    for (const pieceIdx of hitPieceIdxes) {
        // backend kept active true, which means a shield is hit
        if (pieces[pieceIdx].active) {
            pieceCache[pieceIdx].shieldInfo = {
                destroyedAngle: shootAngle,
                launchForce: EQUIPMENTS[selectedPiece.weapon].hitForce,
                gunHit: true
            }
        } else {
            pieceCache[pieceIdx].shotInfo = {
                destroyedAngle: shootAngle,
                launchForce: EQUIPMENTS[selectedPiece.weapon].hitForce,
                xRotate: selectedPiece.position[0] < pieces[pieceIdx].position[0] ? 1 : -1, 
                gunHit: true
            }
            if (pieces[pieceIdx].type === Pieces.KING) {
                setGameOverStatus({winner: pieces[pieceIdx].color === Colors.WHITE ? Colors.BLACK : Colors.WHITE, condition: 'Wasted'})
            }
        }
    }
    setPieceCache(pieceCache)
}

function getHitPieces(pieces: PieceInfo[], selectedPiece: PieceInfo, myColor: Colors, fireAngle: number) {
    const uniqueHitTargets: number[] = [];
    const hitDistance: number[] = [];
    const selectedWeapon: Equipments = selectedPiece.weapon
    const anglesToProcess: number[] = selectedWeapon === Equipments.SHOTGUN ? 
        [fireAngle - Math.PI / 6, fireAngle, fireAngle + Math.PI / 6] : [fireAngle]
    for (const angle of anglesToProcess) {
        const [ originX, originY ]: number[] = PosToCoord(selectedPiece.position, myColor)
        const sourceX = originX * 3 + 1.5
        const sourceY = originY * 3 + 0.75
        // we add 1 to the range to account for corner blocks, later we clean outranged pieces up
        const [ fireX, fireY ]: number[] = getPointAwayFromOrigin(
            sourceX, sourceY, 
            angle, (EQUIPMENTS[selectedWeapon].maxRange + 1) * 3
        )
        pieces.forEach(piece => {
            if (!piece.active || piece.idx === selectedPiece.idx) {
                // continue to the next piece
                return
            }
            const [col, row]: number[] = PosToCoord(piece.position, myColor)
            // construct a cross shaped bounding box (total 2 rectangles)
            const horizontalBoundLeft: number = col * 3
            const horizontalBoundTop: number = row * 3 + 1
            const horizontalBoundWidth: number = 3
            const horizontalBoundHeight: number = 1
            const verticleBoundLeft: number = col * 3 + 1
            const verticleBoundTop: number = row * 3
            const verticleBoundWidth: number = 1
            const verticleBoundHeight: number = 3
            // check if there is an intersection between the horizontal and verticle bound
            const intersectHorizontalBound: boolean = checkLineRectIntersection(
                sourceX, sourceY,
                fireX, fireY,
                horizontalBoundLeft, horizontalBoundTop,
                horizontalBoundWidth, horizontalBoundHeight
            )
            const intersectVerticleBound: boolean = checkLineRectIntersection(
                sourceX, sourceY,
                fireX, fireY,
                verticleBoundLeft, verticleBoundTop,
                verticleBoundWidth, verticleBoundHeight
            )
            const isIntersected: boolean = intersectHorizontalBound || intersectVerticleBound
            const xDiff = originX - col
            const yDiff = originY - row
            const inRange = Math.abs(xDiff) <= EQUIPMENTS[selectedWeapon].maxRange 
                            && Math.abs(yDiff) <= EQUIPMENTS[selectedWeapon].maxRange
            if (isIntersected && inRange && !uniqueHitTargets.includes(piece.idx)) {
                uniqueHitTargets.push(piece.idx)
                // we can use actual coordinates to calculate distance
                hitDistance.push(
                    Math.sqrt(xDiff * xDiff + yDiff * yDiff)
                )
            }
        });
    }

    // shotgun hits everything in its path
    if (selectedWeapon === Equipments.SHOTGUN) {
        return uniqueHitTargets
    }

    // find the first target hit
    let minDistance: number = 100;
    let minDistanceIdx: number = -1;
    for (let i = 0; i < uniqueHitTargets.length; ++i) {
        if (hitDistance[i] < minDistance) {
            minDistance = hitDistance[i]
            minDistanceIdx = uniqueHitTargets[i]
        }
    }

    if (minDistanceIdx === -1) {
        return []
    } else {
        return [minDistanceIdx]
    }
}