import { useEffect, useState } from 'react';
import SkinCollectionEngine from '../../backend/SkinCollectionEngine';
import { Equipments, Pieces } from "../../utils/Enums";
import skins from "../../utils/SkinMapper";

import { getAuth } from "firebase/auth";

export interface SkinMapping {
    [key: string]: number;
}

export const defaultSkinNames = [
    'Pawn', 'Rook', 'Knight', 'Bishop', 'The Queen', 'The King', 
    'Grunt', 'Sailor', 'Cavalry', 'Cadet', 'The Lieutenant', 'The General',
    'Pistol', 'Shotgun', 'Rifle', 'Sniper', 'Knife', 'Shield'
];

const defaultPieceSkins: SkinMapping = {};
for (const name of defaultSkinNames) {
    defaultPieceSkins[name] = 0;
}

export const useSkins = () => {
    const [ isSkinsLoading, setIsSkinsLoading ] = useState<boolean>(true);
    const [ loadedSkins, setLoadedSkins ] = useState<SkinMapping>({});
    // eslint-disable-next-line
    const [ _, setSkinCollectionEngine ] = useState<SkinCollectionEngine | null>(null);

    useEffect(() => {
        const fetchSkins = async () => {
            setIsSkinsLoading(true);
            try {
                // initializing the skin collection engine
                setSkinCollectionEngine(new SkinCollectionEngine(getAuth().currentUser?.uid, (skinCollection: any) => {
                    // filter all the valid skins
                    const filteredSkinObject: Record<string, number> = Object.fromEntries(
                        Object.entries(skinCollection)
                          .filter(([skinName]) => skins.hasOwnProperty(skinName))
                    ) as Record<string, number>;
                    
                    setLoadedSkins(filteredSkinObject)
                }));
            } catch (error) {
                console.error("Error fetching skins", error);
            } finally {
                setIsSkinsLoading(false);
            }
        };

        fetchSkins();
    }, []);

    const allSkins = { ...defaultPieceSkins, ...loadedSkins };

    return { isSkinsLoading, allSkins };
};

export const useSkinByType = (type: Pieces | Equipments, allSkins: SkinMapping) => {
    const filteredSkins: SkinMapping = {};

    for (const [skinName, count] of Object.entries(allSkins)) {
        if (skins[skinName].type === type) {
            filteredSkins[skinName] = count;
        }
    }

    const sortedFilteredSkins: SkinMapping = Object.entries(filteredSkins)
        .sort((a, b) => skins[a[0]].order - skins[b[0]].order)
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    return sortedFilteredSkins;
};

export const isDefaultSkin = (skinName: string) => {
    return defaultSkinNames.includes(skinName);
}

export default useSkins