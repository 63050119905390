import { useState } from 'react';
import { createDarkenAnimation, createDownAnimation, createFloatAnimation, createUpAnimation } from '../../utils/Animator';
import { ShakeData } from './Gacha';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [fadeStatus, setFadeStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [titleStatus, setTitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [backStatus, setBackStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');

    const [backgroundStatus, setBackgroundStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [machineFloorStatus, setMachineFloorStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [knuggetStatus, setKnuggetStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [roll1Status, setRoll1Status] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [roll5Status, setRoll5Status] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');

    const fadeStatusAnimation = createDarkenAnimation(0)
    const titleStatusAnimation = createUpAnimation(0)
    const backStatusAnimation = createDownAnimation(0)

    const machineFloorAnimation = createMachineFloorAnimations()
    const backgroundAnimation = createUpAnimation(0.1)
    const knuggetAnimation = createUpAnimation(0.2)
    const roll1Animation = createDownAnimation(0)
    const roll5Animation = createDownAnimation(0)
    
    titleStatusAnimation.idle = createFloatAnimation(0)
    backgroundAnimation.idle = createFloatAnimation(0.4)
    backStatusAnimation.idle = createFloatAnimation(0.2)
    knuggetAnimation.idle = createFloatAnimation(0.1)
    roll1Animation.idle = createFloatAnimation(0.2)
    roll5Animation.idle = createFloatAnimation(0.2)

    function TransitionOut() {
        setFadeStatus('exit')
        setTitleStatus('exit')
        setBackStatus('exit')

        setMachineFloorStatus('exit')
        setBackgroundStatus('exit')
        setKnuggetStatus('exit')
        setRoll1Status('exit')
        setRoll5Status('exit')
    }

    return {
        fadeStatus, setFadeStatus, fadeStatusAnimation,
        titleStatus, setTitleStatus, titleStatusAnimation,
        backStatus, setBackStatus, backStatusAnimation,

        backgroundStatus, setBackgroundStatus, backgroundAnimation,
        knuggetStatus, setKnuggetStatus, knuggetAnimation,
        machineFloorStatus, setMachineFloorStatus, machineFloorAnimation,
        roll1Status, setRoll1Status, roll1Animation,
        roll5Status, setRoll5Status, roll5Animation,
        TransitionOut, 
    };
};

export const createMachineFloorAnimations = () => ({
    initial: {
        y: '20vh',
        opacity: 0
    },
    enter: {
        y: 0,
        opacity: 1, 
        transition: {
            duration: 0.5,
            delay: 0.2
        }
    },
    exit: {
        x: '100vh',
        y: 0,
        opacity: 0,
        transition: {
            type: 'spring', 
            duration: 0.5,
            delay: 0.1
        }
    }, 
    idle: {}
})

export const useScreenShakeAnimation = (shakeInfo: ShakeData) => {
    const [screenShakeStatus, setScreenShakeStatus] = useState<string>("idle");

    const screenShakeAnimation = {
        shake: {
            transform: `translate(${Math.floor(Math.cos(shakeInfo.angle) * (shakeInfo.force))}%, 
                        ${Math.floor(Math.sin(shakeInfo.angle) * (shakeInfo.force))}%)`,
            transition: {
                duration: 0.05,
                ease: 'easeIn'
            },
        },
        idle: {
            transform: `translate(0%, 0%)`,
            transition: {
                duration: 1,
                ease: 'easeOut'
            },
        },
    }

    return {
        screenShakeStatus, setScreenShakeStatus, screenShakeAnimation,
    };
}

function getSpinXOffset(capsuleId: number) {
    switch (capsuleId) {
      case 0:
        return 5;
      case 4:
        return -5;
      case 1:
        return 0;
      case 3:
        return 0;
      default:
        return 0;
    }
}

function getSelectXOffset(capsuleId: number) {
    switch (capsuleId) {
      case 0:
        return -10;
      case 4:
        return 10;
      case 1:
        return -10;
      case 3:
        return 10;
      default:
        return 0;
    }
}

export const useCapsuleAnimation = (capsuleId: number) => {
    const [capsuleStatus, setCapsuleStatus] = useState<string>("initial");
    const xOffset = (capsuleId - 2.5) * 30 + 2.5
    const selectYOffset = capsuleId === 2 ? -10 : (capsuleId % 2 === 0 ? -17.5 : 37.5)
    const spinXOffset = getSpinXOffset(capsuleId)
    const selectXOffset = getSelectXOffset(capsuleId)
    const capsuleAnimation = {
        initial: {
            x: `${xOffset}vh`,
            y: '-180vh',
            scale: 1,
            opacity: 1,
        },
        select: {
            x: `${xOffset + selectXOffset}vh`,
            y: `${selectYOffset}vh`,
            scale: 1,
            opacity: 1,
            transition: { 
                ease: "easeInOut", 
                duration: 0.3
            }
        },
        spin: {
            x: `${xOffset + spinXOffset}vh`,
            y: `${selectYOffset + (capsuleId % 2 === 1 ? -42.5 : -17.5)}vh`,
            scale: [1, 1.75, 1.6],
            opacity: 1,
            transition: { 
                ease: "easeInOut", 
                duration: 0.65
            }
        },
        enter: {
            x: `${xOffset}vh`,
            y: ['-80vh', '20vh'], 
            scale: 1,
            transition: { 
                ease: [0.22, 1, 0.36, 1],
                duration: 0.5,
            }
        },
        exit: {
            x: `${xOffset}vh`,
            y: '-100vh',
            scale: 1.6,
            opacity: 0,
            transition: { 
                duration: 0.2,
                ease: "easeInOut",
            }
        },
        idle: {}
    }
    capsuleAnimation.idle = {
        y: ['20vh', '18vh', '20vh'],
        opacity: 1,
        scale: 1,
        x: `${xOffset}vh`,
        transition: {
            duration: 4,  
            ease: "easeInOut",  
            repeat: Infinity,  
            repeatType: "loop",
            delay: capsuleId * 0.4
        }
    }

    return {
        capsuleStatus, setCapsuleStatus, capsuleAnimation,
    };
}

export const useShellAnimation = (top: boolean, finalAngle: number) => {
    const finalAngleRadians = finalAngle * Math.PI / 180;

    const distance = 65 * (top ? -1 : 1); 
    const xOffset = distance * Math.cos(finalAngleRadians); 
    const yOffset = -distance * Math.sin(finalAngleRadians); 

    const [shellStatus, setShellStatus] = useState<string>("initial");

    const shellStatusAnimation = {
        initial: {
            y: 0,
            opacity: 1,
            scale: 1,
            rotate: '0deg',
        },
        select: {
            y: 0,
            opacity: 1,
            scale: 1,
            rotate: ['0deg', '360deg'],
            transition: { 
                ease: "easeInOut", 
                duration: 0.3
            }
        },
        spin: {
            y: 0,
            opacity: 1,
            scale: 1,
            rotate: [`0deg`, `720deg`, `${finalAngle}deg`],
            transition: { 
                ease: "easeInOut", 
                duration: 0.65
            }
        },
        explode: {
            x: `${xOffset}vh`,
            y: `${yOffset}vh`,
            opacity: 0,
            scale: 0.25,
            rotate: `${finalAngle}deg`, 
            transition: { 
                duration: 0.3,
                ease: "easeInOut",
            }
        },
    }

    return {
        shellStatus, setShellStatus, shellStatusAnimation,
    };
}

export const useRewardAnimation = (capsuleId: number) => {
    const [rewardStatus, setRewardStatus] = useState<string>("initial");

    const rewardStatusAnimation = {
        initial: {
            scale: 0.5,
            filter: 'brightness(8)', 
            opacity: 0,
        },
        enter: {
            scale: [0.5, 1.5, 1], 
            filter: ['brightness(8)', 'brightness(8)', 'brightness(1)'], 
            opacity: 1,
            transition: { 
                duration: 0.5,
                ease: "easeInOut",
                times: [0, 0.5, 1], 
            }
        },
        idle: {}
    }
    rewardStatusAnimation.idle = {
        y: ['0vh', '1vh', '0vh'],
        opacity: 1,
        scale: 1,
        filter: 'brightness(1)', 
        transition: {
            duration: 4,  
            ease: "easeInOut",  
            repeat: Infinity,  
            repeatType: "loop",
            delay: capsuleId * 0.15
        }
    }

    return {
        rewardStatus, setRewardStatus, rewardStatusAnimation,
    };
}


export const useRewardNameAnimation = () => {
    const [rewardNameStatus, setRewardNameStatus] = useState<string>("initial");

    const rewardNameStatusAnimation = {
        initial: {
            scale: 2,
            rotate: 0, 
            opacity: 0,
            y: 0,
        },
        enter: {
            scale: [1.5, 2.5, 1], 
            rotate: [0, -10, 0], 
            opacity: [0, 1, 1],
            y: ['-4vh', '-6vh', '0vh'],
            transition: { 
                duration: 0.55,
                ease: "easeInOut",
                delay: 0.2,
                times: [0, 0.6, 1] 
            }
        },
    }

    return {
        rewardNameStatus, setRewardNameStatus, rewardNameStatusAnimation,
    };
}

export const useStumpAnimation = () => {
    const [stumpStatus, setStumpStatus] = useState<string>("initial");

    const stumpStatusAnimation = {
        initial: {
            scale: 1,
            y: 0,
            opacity: 1,
        },
        jump: {
            scale: 1, 
            y: ['0vh', '-2vh', '0vh'],
            opacity: 1,
            transition: { 
                duration: 0.3,
                ease: "easeInOut",
                times: [0, 0.5, 1], 
            }
        },
        spin: {
            scale: [1, 1.3, 1], 
            y: 0,
            opacity: 1,
            transition: { 
                duration: 0.3,
                ease: "easeInOut",
                times: [0, 0.5, 1], 
            }
        },
    }

    return {
        stumpStatus, setStumpStatus, stumpStatusAnimation,
    };
}

export const useLightAnimation = () => {
    const [lightStatus, setLightStatus] = useState<string>("initial");

    const lightStatusAnimation = {
        initial: {
            scale: 1,
            opacity: 1,
        },
        piece: {
            scale: [1, 1.15, 0.75], 
            opacity: 0.75,
            transition: { 
                duration: 0.3,
                ease: "easeInOut",
            }
        },
        character: {
            scale: [1, 1.15, 1], 
            opacity: 1,
            transition: { 
                duration: 0.3,
                ease: "easeInOut",
            }
        },
        weapon: {
            scale: [1, 1.15, 0.75], 
            opacity: 1,
            transition: { 
                duration: 0.3,
                ease: "easeInOut",
            }
        },
        exit: {
            scale: [1, 1.15, 0], 
            opacity: 0,
            transition: { 
                duration: 0.3,
                ease: "easeInOut",
            }
        },
    }

    return {
        lightStatus, setLightStatus, lightStatusAnimation,
    };
}

export const useExitTextAnimation = () => {
    const [exitStatus, setExitStatus] = useState<string>("initial");

    const exitStatusAnimation = {
        initial: {
            y: '20vh',
        },
        enter: {
            y: '0vh',
            transition: { 
                duration: 0.3,
                type: 'spring',
            }
        },
        exit: {
            y: '20vh',
            transition: { 
                type: 'spring',
            }
        },
        idle:{}
    }
    exitStatusAnimation.idle = {
        y: ['0vh', '1vh', '0vh'],
        transition: {
            duration: 4,  
            ease: "easeInOut",  
            repeat: Infinity,  
            repeatType: "loop",
        }
    }

    return {
        exitStatus, setExitStatus, exitStatusAnimation,
    };
}