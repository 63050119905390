import React from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Game.css';
import sounds from '../../sounds';
import { Characters } from '../../utils/Enums';
import { characters } from '../../utils/CharacterMapper';
import GameEngine from '../../backend/GameEngine';

interface Props {
    character: Characters;
    currentExpression: string;
    setCurrentExpression: (expression: string) => void;
    isBot: boolean;
    gameEngine: GameEngine | null;
}

const ExpressionSpammer: React.FC<Props> = ({ character, currentExpression, setCurrentExpression, isBot, gameEngine }) => {
    const characterDataset = characters[character]
    const spammableExpressions: string[] = characterDataset.spammables
    const canEmote = currentExpression === 'idle'

    return (
        <motion.div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '0.5vh'}}>
            <motion.div className='EmoteButton'
                style={{margin: '0.5vh', borderColor: canEmote ? '#6B6B6B' : '#B0B0B0'}}
                whileHover={{ scale: canEmote ? 1.1 : 1 }}
                onMouseEnter={() => {
                    if (!canEmote) return
                    sounds['hover'].play()
                }}
                onClick={() => {
                    if (!canEmote) return
                    sounds['select'].play()
                    setCurrentExpression(spammableExpressions[0])
                    if (!isBot && gameEngine) {
                        gameEngine.emote(0)
                    }
                }}>
                {/* <motion.img style={{position:'relative', width: '90%', height: '90%', marginLeft: '5%', marginTop: '5%', opacity: canEmote ? 1 : 0.4}} 
                                    src={images['wave']}
                            draggable={false}/> */}
            </motion.div>
            <motion.div className='EmoteButton'
                style={{margin: '0.5vh', borderColor: canEmote ? '#6B6B6B' : '#B0B0B0'}}
                whileHover={{ scale: canEmote ? 1.1 : 1 }}
                onMouseEnter={() => {
                    if (!canEmote) return
                    sounds['hover'].play()
                }}
                onClick={() => {
                    if (!canEmote) return
                    sounds['select'].play()
                    setCurrentExpression(spammableExpressions[1])
                    if (!isBot && gameEngine) {
                        gameEngine.emote(1)
                    }
                }}>
            </motion.div>
            <motion.div className='EmoteButton'
                style={{margin: '0.5vh', borderColor: canEmote ? '#6B6B6B' : '#B0B0B0'}}
                whileHover={{ scale: canEmote ? 1.1 : 1 }}
                onMouseEnter={() => {
                    if (!canEmote) return
                    sounds['hover'].play()
                }}
                onClick={() => {
                    if (!canEmote) return
                    sounds['select'].play()
                    setCurrentExpression(spammableExpressions[2])
                    if (!isBot && gameEngine) {
                        gameEngine.emote(2)
                    }
                }}>
            </motion.div>
            <motion.div className='EmoteButton'
                style={{margin: '0.5vh', borderColor: canEmote ? '#6B6B6B' : '#B0B0B0'}}
                whileHover={{ scale: canEmote ? 1.1 : 1 }}
                onMouseEnter={() => {
                    if (!canEmote) return
                    sounds['hover'].play()
                }}
                onClick={() => {
                    if (!canEmote) return
                    sounds['select'].play()
                    setCurrentExpression(spammableExpressions[3])
                    if (!isBot && gameEngine) {
                        gameEngine.emote(3)
                    }
                }}>
            </motion.div>
        </motion.div>
    )
}

export default ExpressionSpammer;