import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

import images from '../../images';
import './Game.css';

import { createPieceAnimations, createShadowAnimations, createShieldDestroyAnimations, createWeaponDestroyAnimations } from './Animation'
import { Colors, Pieces, Equipments, Phases, Gamemodes } from '../../utils/Enums'
import skins from '../../utils/SkinMapper'

import { Loadout } from './GameState'
import { PosToCoord } from './GameLogic'
import { PieceCache } from './GameSetup'
import { EQUIPMENTS } from './Shop';
import sounds from '../../sounds';

export interface PieceInfo {
    name: 'rook1' | 'knight1' | 'bishop1' | 'queen' | 'king' | 'bishop2' | 'knight2' | 'rook2' | 'pawn1' | 'pawn2' | 'pawn3' | 'pawn4' | 'pawn5' | 'pawn6' | 'pawn7' | 'pawn8';
    type: Pieces;
    color: Colors;
    position: string;
    active: boolean;
    idx: number;
    weapon: Equipments;
    vest: boolean;
    canCastle?: boolean;
    // 0 indicates that pawn have not moved, 
    // 1 indicates pawn just moved 2 tiles up, it is vulnerable to enpassant
    // 2 indicates that opponent cannot perform enpassant on this pawn 
    enpassantStage?: 0 | 1 | 2; 
    promoted?: boolean;
}

interface Props {
    pieceInfo: PieceInfo;
    pieceCache: PieceCache;
    hoveredCellPos: string;
    selectedCellPos: string;
    myColor: Colors;
    phase: Phases;
    turn: Colors;
    shootAngle: number;
    layout: Loadout;
    canShoot: boolean;
    setCanShoot: (canShoot: boolean) => void;
    addParticle: (x: number, y: number, speed: number, direction: number) => void;
    gameMode: string;
}

const Piece: React.FC<Props> = ({ pieceInfo, pieceCache, hoveredCellPos, selectedCellPos, myColor, phase, turn, shootAngle, layout, canShoot, setCanShoot, addParticle, gameMode }) => {
    const mounted = useRef(false);
    const phaseRef = useRef<Phases>(Phases.MOVE);
    const isDummy = gameMode === Gamemodes.DUMMY_BOT
    const canSelect = myColor === pieceInfo.color || isDummy
    const isHovered = canSelect && hoveredCellPos === pieceInfo.position
    const isSelected = selectedCellPos === pieceInfo.position
    const [coord, setCoord] = useState<number[]>([PosToCoord(pieceInfo.position, myColor)[0], PosToCoord(pieceInfo.position, myColor)[1]])
    // for animation
    const [displaying, setDisplaying] = useState<boolean>(true)
    const [animation, setAnimation] = useState<string>('specialEnter')
    const [processedParticle, setProcessedParticle] = useState<boolean>(false)
    // animation for when the weaponed or shield piece is shot
    const [weaponAnimation, setWeaponAnimation] = useState<string>('none')
    const [shieldAnimation, setShieldAnimation] = useState<string>('none')
    // allow weapon to rotate
    const [gunLinger, setGunLinger] = useState<boolean>(false)
    // allow gun to hold in the angle of direction that shot after firing
    const [shieldUp, setShieldUp] = useState<boolean>(false)
    const [zIndex, setZIndex] = useState<number>(isSelected ? coord[1] * 3 + 11 : coord[1] * 3 + 10)
    const animationDelay = (pieceInfo.color === myColor ? coord[0] % 8 : 7 - coord[0] % 8) * 0.15
                            + (pieceInfo.name.startsWith('pawn') ? 0 : 0.075)
    const animationVariants = createPieceAnimations(animationDelay, coord[0], coord[1], 
        Math.floor(pieceCache.stabInfo.launchForce * Math.cos(pieceCache.stabInfo.destroyedAngle)), 
        Math.floor(pieceCache.stabInfo.launchForce * Math.sin(pieceCache.stabInfo.destroyedAngle)),
        Math.floor(pieceCache.shotInfo.launchForce * Math.cos(pieceCache.shotInfo.destroyedAngle)), 
        Math.floor(pieceCache.shotInfo.launchForce * Math.sin(pieceCache.shotInfo.destroyedAngle)),
        Math.floor(pieceCache.shieldInfo.launchForce * Math.cos(pieceCache.shieldInfo.destroyedAngle)), 
        Math.floor(pieceCache.shieldInfo.launchForce * Math.sin(pieceCache.shieldInfo.destroyedAngle)),
        Math.floor(pieceCache.shootInfo.recoilForce * Math.cos(pieceCache.shootInfo.recoilAngle)), 
        Math.floor(pieceCache.shootInfo.recoilForce * Math.sin(pieceCache.shootInfo.recoilAngle)), 
        pieceCache.stabInfo.xRotate, pieceCache.shotInfo.xRotate)
    const shadowVariants = createShadowAnimations(animationDelay, coord[0], coord[1],
        Math.floor(pieceCache.shieldInfo.launchForce * Math.cos(pieceCache.shieldInfo.destroyedAngle)), 
        Math.floor(pieceCache.shieldInfo.launchForce * Math.sin(pieceCache.shieldInfo.destroyedAngle)),
        Math.floor(pieceCache.shootInfo.recoilForce * Math.cos(pieceCache.shootInfo.recoilAngle)), 
        Math.floor(pieceCache.shootInfo.recoilForce * Math.sin(pieceCache.shootInfo.recoilAngle)))
    // we want to add a bit of randomness on the launch direction
    const weaponAngleOffset = useRef<number>( Math.random() * (Math.PI / 3 * 2) - Math.PI / 3)
    const shieldAngleOffset = useRef<number>( Math.random() * (Math.PI / 6) - Math.PI / 12)
    const weaponDestroyVariants = createWeaponDestroyAnimations(coord[0], coord[1],
        Math.floor(pieceCache.shotInfo.launchForce * Math.cos(pieceCache.shotInfo.destroyedAngle + weaponAngleOffset.current)), 
        Math.floor(pieceCache.shotInfo.launchForce * Math.sin(pieceCache.shotInfo.destroyedAngle + weaponAngleOffset.current)),
        pieceCache.shotInfo.xRotate, pieceCache.shotInfo.gunHit)
    const shieldDestroyVariants = createShieldDestroyAnimations(coord[0], coord[1],
        Math.floor(pieceCache.shieldInfo.launchForce * Math.cos(pieceCache.shieldInfo.destroyedAngle + shieldAngleOffset.current)), 
        Math.floor(pieceCache.shieldInfo.launchForce * Math.sin(pieceCache.shieldInfo.destroyedAngle + shieldAngleOffset.current)), 
        pieceCache.shieldInfo.gunHit)
    const [shiverStatus, setShiverStatus] = useState<string>('noShiver')
    // 0 for front, 1 for back for normal pieces, for knight 0 is left, 1 is right
    const [oldPos, setOldPos] = useState<string>(pieceInfo.position)
    const [orientation, setOrientation] = useState<number>(pieceInfo.color !== myColor || pieceInfo.type === Pieces.KNIGHT ? 0 : 1)

    const hasWeapon: boolean = pieceInfo.weapon !== Equipments.KNIFE
    const hasShield: boolean = pieceInfo.vest

    // user actions for hover and select
    useEffect(() => {
        if (animation === 'lift' || animation === 'drop' || animation === 'stabbed' || animation === 'recoil' || animation === 'shot' || animation === 'knockback' 
            || phase === Phases.FIRE || phase === Phases.PROMOTE || !pieceInfo.active) {
                return
        }
        let animString = ''
        if (isSelected) {
            animString += 'select'
        }
        if (isHovered) {
            animString += 'hover'
        }
        if ((pieceInfo.color === myColor || gameMode === Gamemodes.DUMMY_BOT) && animString !== '') {
            setAnimation(animString)
        } else if (animation.includes('select') || animation.includes('hover')) {
            setAnimation('none');
        }
    }, [hoveredCellPos, selectedCellPos, isHovered, isSelected, pieceInfo.color, animation, phase, myColor, pieceCache.shiver, pieceInfo.active, gameMode])

    // selected sound effect
    useEffect(() => {
        if (mounted.current && isSelected && pieceInfo.active) {
            sounds['pieceSelect'].play()
        }
    }, [isSelected, pieceInfo.active])

    useEffect(() => {
        // do not execute on initial render
        if (mounted.current && oldPos !== pieceInfo.position) {
            // fast timer so that lift can be played after king check shiver update
            setAnimation('lift')
            const timer = setTimeout(() => {
                const [ newX, newY ] = PosToCoord(pieceInfo.position, myColor)
                if (pieceInfo.type === Pieces.KNIGHT) {
                    const [ oldX, ] = PosToCoord(oldPos, myColor)
                    if (newX > oldX) {
                        setOrientation(1)
                    } else if (newX < oldX) {
                        setOrientation(0)
                    }
                } else {
                    const [ , oldY ] = PosToCoord(oldPos, myColor)
                    if (newY > oldY) {
                        setOrientation(0)
                    } else if (newY < oldY) {
                        setOrientation(1)
                    }
                }
                setOldPos(pieceInfo.position)
                setCoord([newX, newY])
                setAnimation('drop')
                return () => {
                    clearTimeout(timer)
                };
            }, animationVariants.lift['transition']['duration'] + 300);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pieceInfo.position, oldPos, pieceInfo.type, myColor])

    // updates shiver status
    useEffect(() => {
        if (!mounted.current) {
            return
        } 
        if (pieceCache.shiver) {
            setShiverStatus('shiver') 
        } else {
            setShiverStatus('noshiver') 
        }
    }, [pieceCache.shiver])

    // if game just loaded, do not display any disabled pieces
    useEffect(() => {
        // only execute on initial render
        if (!mounted.current) {
            setDisplaying(pieceInfo.active) 
        } 
    }, [pieceInfo.active])

    // update shield up
    useEffect(() => {
        if (mounted.current) {
            if (phase === Phases.FIRE && !isSelected) {
                setShieldUp(true)
            } else if (phase === Phases.MOVE) {
                setShieldUp(false)
            }
        } 
    }, [phase, isSelected])

    // the two use effect updates the rotatable weapon
    useEffect(() => {
        if (!mounted.current) {
            return
        }
        setGunLinger(false) 
    }, [pieceInfo.weapon])
    // update rotatable weapon activation
    useEffect(() => {
        if (!mounted.current) return
        if (phase === Phases.FIRE && hasWeapon && isSelected && pieceInfo.active) {
            if (turn === pieceInfo.color) {
                setGunLinger(false)     
            }
            const timer = setTimeout(() => {
                if (phase !== Phases.FIRE) return
                setGunLinger(true) 
                setCanShoot(true)
                // gun can rotate and shoot now, play reload sound
                skins[layout[pieceInfo.weapon]].equipmentDetail!.reloadSound!.play()
                return () => {clearTimeout(timer)}; 
            }, 500);
        } 
    }, [phase, hasWeapon, layout, isSelected, pieceInfo.weapon, setCanShoot, pieceInfo.active, pieceInfo.color, turn]) 

    useEffect(() => {
        // only execute if not initial render and launch force is not default value
        if (mounted.current && pieceCache.stabInfo.launchForce !== 999) {
            setAnimation('stabbed')
            const timer = setTimeout(() => {
                setDisplaying(false)
                return () => {
                    clearTimeout(timer)
                }; 
            }, 2000);
        }
    }, [pieceCache.stabInfo]) 

    // bullet hit piece
    useEffect(() => {
        // only execute if not initial render and launch force is not default value
        if (mounted.current && pieceCache.shotInfo.launchForce !== 999) {
            const timer = setTimeout(() => {
                setWeaponAnimation('shot')
                if (pieceCache.shotInfo.gunHit) {
                    sounds['bulletHit' + Math.floor((Math.random() * 4) + 1)].play()
                    setAnimation('shot')
                    const timer1 = setTimeout(() => {
                        setDisplaying(false)
                        return () => {
                            clearTimeout(timer1)
                        }; 
                    }, 2000);
                    return () => {
                        clearTimeout(timer)
                    }; 
                } 
            // 400 is the delay of the stabbed animation
            }, pieceCache.shotInfo.gunHit ? 100 : 400);
        }
    }, [pieceCache.shotInfo]) 

    useEffect(() => {
        // only execute if not initial render and launch force is not default value
        if (mounted.current && !processedParticle && ((pieceCache.shotInfo.gunHit && pieceCache.shotInfo.launchForce !== 999) || 
                                                      (pieceCache.shieldInfo.gunHit && pieceCache.shieldInfo.launchForce !== 999))) {
            const timer = setTimeout(() => {
                for (let i = 0; i < 10; ++i) {
                    const randSpeed = pieceCache.shotInfo.launchForce !== 999 ? 5 + Math.floor(Math.random() * (pieceCache.shotInfo.launchForce - 2)) :
                                                                                5 + Math.floor(Math.random() * (pieceCache.shieldInfo.launchForce - 2)) 
                    const spread =  pieceCache.shotInfo.destroyedAngle - Math.PI / 6 + Math.random() * (Math.PI / 3)
                    addParticle(coord[0] + 0.5, coord[1] + 0.5, randSpeed, spread)
                }
                setProcessedParticle(true)
                return () => {
                    clearTimeout(timer)
                }; 
            // 400 is the delay of the stabbed animation
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pieceCache.shotInfo, pieceCache.shieldInfo, coord, processedParticle]) 

    // bullet hit shield
    useEffect(() => {
        // only execute if not initial render and launch force is not default value
        if (mounted.current && pieceCache.shieldInfo.launchForce !== 999) {
            const timer = setTimeout(() => {
                if (pieceCache.shieldInfo.gunHit) {
                    sounds['bulletHit' + Math.floor((Math.random() * 4) + 1)].play()
                    setAnimation('knockback')
                }
                setShieldAnimation('shot')
                return () => {
                    clearTimeout(timer)
                }; 
            // timer between gun hit and stabbed
            }, pieceCache.shieldInfo.gunHit ? 100 : 400);
        }
    }, [pieceCache.shieldInfo]) 

    useEffect(() => {
        // only execute if not initial render and recoil force is not default value
        if (mounted.current && pieceCache.shootInfo.recoilForce !== 999) {
            setAnimation('recoil')
        }
    }, [pieceCache.shootInfo]) 
    
    useEffect(() => {
        phaseRef.current = phase
    }, [phase])
    useEffect(() => {
        if (mounted.current && pieceCache.shootInfo.recoilForce < 999) {
            const timer = setTimeout(() => {
                if (phase !== Phases.FIRE && turn !== pieceInfo.color) setGunLinger(false)
                return () => { clearTimeout(timer) }; 
            }, isDummy ? 1000 : 2000);
        } 
    }, [pieceCache.shootInfo, shootAngle, isDummy, phase, pieceInfo.color, turn]) 

    // update mount for initial render
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true 
        } 
    }, [])

    // update z index
    useEffect(() => {
        if (mounted.current && (pieceCache.shotInfo.launchForce !== 999 || pieceCache.stabInfo.fromAbove)) {
            const timer = setTimeout(() => {
                setZIndex(100)
                return () => {
                    clearTimeout(timer)
                };
            }, 350);
        } else {
            const statusOffset: number = pieceInfo.active ? 0 : -1
            setZIndex(isSelected ? coord[1] * 3 + 11 + statusOffset : coord[1] * 3 + 10 + statusOffset)
        }
    }, [pieceCache.shotInfo, pieceCache.stabInfo, coord, isSelected, pieceInfo.active])

    useEffect(() => {
        if (!mounted.current || phase !== Phases.FIRE || !isSelected || !canShoot) {
            return
        } 
        if (pieceInfo.type === Pieces.KNIGHT) {
            if (shootAngle > -Math.PI / 2 && shootAngle < Math.PI / 2) {
                if (orientation === 1) return
                setShiverStatus('bump')
                setOrientation(1)
            } else {
                if (orientation === 0) return
                setShiverStatus('bump')
                setOrientation(0)
            }
        } else {
            if (shootAngle < -Math.PI / 48 && shootAngle > - 47 * Math.PI / 48) {
                if (orientation === 1) return
                setShiverStatus('bump')
                setOrientation(1)
            } else {
                if (orientation === 0) return
                setShiverStatus('bump')
                setOrientation(0)
            }
        }
    }, [shootAngle, pieceInfo.type, phase, orientation, isSelected, canShoot])

    const skinImage = orientation === 0 ? 
                                (`${pieceInfo.color}_${pieceInfo.type}_${skins[layout[pieceInfo.name]] ? skins[layout[pieceInfo.name]].set : 'default'}`) :
                                (`${pieceInfo.color}_${pieceInfo.type}_${skins[layout[pieceInfo.name]] ? skins[layout[pieceInfo.name]].set : 'default'}_back`)

    return (
        displaying ? 
        <motion.div className='Piece'> 
            { pieceInfo.active && 
            <motion.div className='PieceShadow' 
                        draggable="false" 
                        initial='initial' 
                        animate={ animation } 
                        variants={ shadowVariants }/> }

            { shieldAnimation === 'shot' &&
            <motion.img className='PieceImage' 
                        style={{x: `${coord[0] * 50}%`, y: `${coord[1] * 50}%`, zIndex: 100,
                                transformOrigin: `${EQUIPMENTS[Equipments.SHIELD].idleTransformOrigin}`}}
                        draggable="false"
                        src={skins[layout['shield']].equipmentDetail!.idleImage}
                        initial='none' 
                        animate={ shieldAnimation } 
                        variants={ shieldDestroyVariants }
                        onAnimationComplete={()=>{
                            if (shieldAnimation === 'shot') {
                                setShieldAnimation('none')
                            }
                        }}/> }
            
            { weaponAnimation === 'shot' && hasWeapon && 
            <motion.img className='PieceImage' 
                        style={{x: pieceInfo.color === myColor ? `${coord[0] * 50}%` : `${coord[0] * 50 - 30}%`, y: `${coord[1] * 50}%`, zIndex: 100, 
                            transformOrigin: `${EQUIPMENTS[pieceInfo.weapon].idleTransformOrigin}`, 
                            scaleX: pieceInfo.color === myColor ? 1 : -1}}
                        // get the skin associated with the equipped weapon
                        src={ skins[layout[pieceInfo.weapon]].equipmentDetail!.idleImage }
                        draggable="false"
                        initial='none'
                        animate={ weaponAnimation }
                        variants={ weaponDestroyVariants }
                        onAnimationComplete={()=>{
                            if (weaponAnimation === 'shot') {
                                setWeaponAnimation('none')
                            }
                        }}/> }

            <motion.div className='PieceImage' 
                        // we want to offset the z index a bit so that the selected piece's weapon can hold priority against pieces of same y
                        // also layer the piece to the top if it is shot
                        style={{zIndex: zIndex, 
                            transformOrigin: pieceInfo.active ? "50% 100%" : "center 75%"}}
                        draggable="false" 
                        initial='initial' 
                        animate={ animation } 
                        variants={ animationVariants } 
                        onAnimationComplete={()=>{
                            if (animation === 'drop') {
                                setAnimation('none')
                                const randomDrop = Math.floor(Math.random() * 2) + 1
                                sounds[`drop${randomDrop}`].play()
                            } else if (animation === 'recoil') {
                                setAnimation('none2')
                            } else if (animation === 'knockback') {
                                setAnimation('none2')
                            } else if (animation === 'specialEnter') {
                                sounds[`drop${Math.floor(Math.random() * 2) + 1}`].play() 
                            }
                        }}>
                
                <motion.div
                        style={{width: '100%', height: '100%', position: 'absolute', transformOrigin: '50% 100%'}}
                        initial='noShiver' 
                        animate={ shiverStatus } 
                        variants={ animationVariants }
                        onAnimationComplete={() => {
                            if (shiverStatus === 'bump') setShiverStatus('noShiver')
                        }} >
                    <motion.div>
                        {(weaponAnimation !== 'shot' || pieceInfo.active) && hasWeapon && gunLinger && 
                            // weapon active rotable image
                            <motion.img style={{width: '100%', height: '100%', position: 'absolute', alignItems: 'center',
                                    top: '2.75vh',
                                    opacity: gunLinger ? 1 : 0, 
                                    // z index identifies if the weapon should be rendered in front or after the piece
                                    zIndex: shootAngle < -Math.PI / 48 && shootAngle > - 47 * Math.PI / 48 ? coord[1] * 3 : coord[1] * 3 + 20, 
                                    transform: `rotate(${ shootAngle }rad) 
                                                scaleY(${ (shootAngle > -Math.PI / 2 && shootAngle < Math.PI / 2) ? 1.625 : -1.625 }) 
                                                scaleX(${ 1.625 })`}} 
                                    // get the skin associated with the equipped weapon
                                    src={skins[layout[pieceInfo.weapon]].equipmentDetail!.activeImage}/>
                        }

                        {(weaponAnimation !== 'shot' || pieceInfo.active) && hasWeapon && !gunLinger && 
                            // weapon idle image
                            <motion.img style={{width: '100%', height: '100%', position: 'absolute', 
                                    opacity: gunLinger ? 0 : 1, 
                                    zIndex: orientation !== 0 ? coord[1] * 3 + 10 : coord[1] * 3 + 11,
                                    transform: `scaleX(${ orientation === 1 ? 1 : -1 })`}}
                                    // get the skin associated with the equipped weapon
                                    src={skins[layout[pieceInfo.weapon]].equipmentDetail!.idleImage}/>
                        }

                        { (shieldAnimation !== 'shot' || pieceInfo.active) && hasShield &&
                        // if shield is active, then display idle shield either if the animation is not shot or if the piece is active
                        <motion.img style={{width: '100%', height: '100%', position: 'absolute',
                                            zIndex: orientation !== 0 ? coord[1] * 3 + 10 : coord[1] * 3 + 11,
                                            // shield offset to look like blocking bullet
                                            transform: `translateX(${ shieldUp && !isSelected ? '0vh' : orientation !== 0 ? '-1.55vh' : '1.55vh' })`}}
                                // get the skin associated with the equipped weapon
                                src={skins[layout['shield']].equipmentDetail!.idleImage}/>}

                        <motion.img style={{width: '100%', height: '100%', position: 'absolute',
                                            zIndex: coord[1] * 3 + 10,
                                            transform: `scaleX(${ orientation === 0 ? 1 : -1 })`}}
                                    // construct the image path with the format color_type_set
                                    src={ images[skinImage] } 
                                    alt={ `${pieceInfo.color}_${pieceInfo.type}_${skins[layout[pieceInfo.name]] ? skins[layout[pieceInfo.name]].set : 'default'}` }/>
                    </motion.div>
                </motion.div>
            </motion.div>
        </motion.div>
        : null
    )
}

export default Piece;