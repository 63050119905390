import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Game.css';

import { Colors, Phases } from '../../utils/Enums';
import { useStatusDisplayAnimationStatus } from './Animation';
import sounds from '../../sounds';

interface Props {
    myColor: Colors; 
    turn: {color: Colors, phase: Phases};
    gameOverStatus: {winner: Colors, condition: string};
    endTurn: () => void;
}

const StatusDisplay: React.FC<Props> = ({ myColor, turn, gameOverStatus, endTurn }) => {
    // animation related properties
    const animation = useStatusDisplayAnimationStatus()

    // the temp selected piece is here so that the weapon slots doesnt disapear when exit animation occures 
    const [displayedStatus, setDisplayedStatus] = useState<string>('')
    useEffect(() => {
        const opponentColor = myColor === Colors.WHITE ? 'Black' : 'White'
        if (turn.phase === Phases.MOVE) {
            setDisplayedStatus(turn.color === myColor ? `Make your move` :`${opponentColor}'s turn to move`)
        } else {
            if (turn.phase === Phases.PROMOTE) {
                setDisplayedStatus(turn.color === myColor ? `Promote your pawn` :`${opponentColor}'s turn to promote`)
            } else if (turn.phase === Phases.FIRE) {
                setDisplayedStatus(turn.color === myColor ? `Open Fire!` : `Prepare for impact!`)
            } else if (turn.phase === Phases.BUY) {
                setDisplayedStatus(turn.color === myColor ? `Buy or end turn [SPACE]` :`${opponentColor} buying equipments`)
            }
        }
        if (animation.status !== 'initialEnter') {
            animation.setStatus('update')
        }
    // ignore calls to animation change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [turn])

    useEffect(() => {
        if (gameOverStatus.condition !== '') {
            animation.setStatus('exit')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameOverStatus])

    const myBuyPhase = turn.color === myColor && turn.phase === Phases.BUY

    return (
        <motion.div className="GameStatus"
                    style={{zIndex: 0, textDecoration: myBuyPhase ? 'underline' : 'none'}}
                    initial='initial' 
                    animate={animation.status} 
                    variants={animation.statusAnimation}
                    onAnimationComplete={()=>{
                        if (animation.status === 'update' || animation.status === 'initialEnter') {
                            animation.setStatus('enter')
                        }
                    }}
                    onClick={()=>{
                        if (!myBuyPhase) return
                        endTurn()
                        sounds['select'].play()
                    }}
                    onMouseEnter={()=>{
                        if (!myBuyPhase) return
                        sounds['hover'].play()
                    }}
                    whileHover={{ scale: myBuyPhase ? 1.1 : 1 }}>
            <div className = "NormalText">{displayedStatus}</div>
        </motion.div>
    )
}

export default StatusDisplay;