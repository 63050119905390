import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Mode.css';
import sounds from '../../sounds';
import images from '../../images';
import { useCasualAnimation } from './Animation';
import { Gamemodes } from '../../utils/Enums';
import CardBack from './CardBack';

interface Props {
    setCategory: (value: any) => void; 
    setGamemode: (value: any) => void; 
    selectedCategory: string;
    selectedMode: string;
    isWhiteIcon: boolean;
    animationStatus: string;
    setAnimationStatus: (value: string) => void; 
    animationVariants: any;
    FindMatch: (mode: Gamemodes) => void;
    findingMatch: boolean;
}

const Casual: React.FC<Props> = ({ setCategory, setGamemode, selectedCategory, selectedMode, isWhiteIcon, animationStatus, setAnimationStatus, animationVariants, FindMatch, findingMatch }) => {
    const [isSelected, setIsSelected] = useState<boolean>(false)
    const [zAxis, setZAxis] = useState<number>(10)
    const [flipStandard, setFlipStandard] = useState<boolean>(true);
    const [flipClassic, setFlipClassic] = useState<boolean>(true);

    const modeAnimations = useCasualAnimation()
    const canInteract = (animationStatus === 'idle' || animationStatus === 'shiftIdle') && !findingMatch
    const categoryText = 'casual'

    useEffect(() => {
        if (selectedCategory === categoryText) {
            setIsSelected(true)
        } else {
            setIsSelected(false)
        }
    }, [selectedCategory])

    useEffect(() => {
        if (animationStatus === 'shift') {
            setZAxis(20)
            modeAnimations.TransitionIn()
        } else if (animationStatus === 'idle') {
            setZAxis(10)
        } else if (animationStatus === 'exit' || animationStatus === 'enter') {
            modeAnimations.TransitionOut()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationStatus])

    useEffect(() => {
        if (selectedMode === Gamemodes.STANDARD_CASUAL) {
            setFlipStandard(false)
        } else {
            setFlipStandard(true)
        }
        if (selectedMode === Gamemodes.CLASSIC_CASUAL) {
            setFlipClassic(false)
        } else {
            setFlipClassic(true)
        }
    }, [selectedMode])
  
    return (
        <motion.div className='CardContainer'
                style={{zIndex: zAxis}}
                animate={ animationStatus } 
                initial='initial' 
                variants={ animationVariants }
                onAnimationComplete={()=>{
                    if (animationStatus === 'enter') {
                        setAnimationStatus('idle')
                    }
                    if (animationStatus === 'shift') {
                        setAnimationStatus('shiftIdle')
                    }
                }}>
            <motion.div className='Category'
                whileTap={{ scale: canInteract ? 1.3 : 1, zIndex: 20 }}
                whileHover={{ scale: canInteract ? 1.075 : 1}}
                style={{width: '30vh', height: '60vh', backgroundColor: 'transparent'}}
                animate={ modeAnimations.standardStatus } 
                initial='initial' 
                variants={ modeAnimations.standardAnimation }
                onAnimationComplete={()=>{
                    if (modeAnimations.standardStatus === 'enter') {
                        modeAnimations.setStandardStatus('idle')
                    }
                }}
                onHoverStart={() => {
                    if (!canInteract) return
                    sounds['hover2'].play()
                }}
                onClick={() => { 
                    if (!canInteract) return
                    if (selectedMode === Gamemodes.STANDARD_CASUAL) {
                        FindMatch(Gamemodes.STANDARD_CASUAL)
                        sounds['select2'].play()
                        return
                    }
                    sounds['select3'].play()
                    setGamemode(Gamemodes.STANDARD_CASUAL)
                }}>
                <motion.div
                    transition={{ duration: 0.4 }}
                    animate={{ rotateY: flipStandard ? 0 : 180 }}
                    className="front"
                >
                    <div className='CardBorder'/>
                    <div className='CategoryTitle'>
                        Standard
                    </div>
                    
                    <img className='CategoryIcon' src={ isWhiteIcon ? images['white_knight_mafia'] : images['black_knight_mafia'] } alt="Category" draggable="false"/>
                    
                    <div className='CategoryDescription'>
                        Chess with Guns
                    </div>
                    <div className='CategoryDescription'>
                        15s Countdown
                    </div>
                </motion.div>
                <CardBack flip={flipStandard}/>
            </motion.div>
            <motion.div className='Category'
                whileTap={{ scale: canInteract ? 1.3 : 1, zIndex: 20 }}
                whileHover={{ scale: canInteract ? 1.075 : 1}}
                style={{width: '30vh', height: '60vh', backgroundColor: 'transparent'}}
                animate={ modeAnimations.classicStatus } 
                initial='initial' 
                variants={ modeAnimations.classicAnimation }
                onAnimationComplete={()=>{
                    if (modeAnimations.classicStatus === 'enter') {
                        modeAnimations.setClassicStatus('idle')
                    }
                }}
                onHoverStart={() => {
                    if (!canInteract) return
                    sounds['hover2'].play()
                }}
                onClick={() => { 
                    if (!canInteract) return
                    if (selectedMode === Gamemodes.CLASSIC_CASUAL) {
                        FindMatch(Gamemodes.CLASSIC_CASUAL)
                        sounds['select2'].play()
                        return
                    }
                    sounds['select3'].play()
                    setGamemode(Gamemodes.CLASSIC_CASUAL)
                }}>
                <motion.div
                    transition={{ duration: 0.4 }}
                    animate={{ rotateY: flipClassic ? 0 : 180 }}
                    className="front"
                >
                    <div className='CardBorder'/>
                    <div className='CategoryTitle'>
                        Classic
                    </div>
                    
                    <img className='CategoryIcon' src={ isWhiteIcon ? images['white_bishop_mafia'] : images['black_bishop_mafia'] } alt="Category" draggable="false"/>
                    
                    <div className='CategoryDescription'>
                        Regular Chess
                    </div>
                    <div className='CategoryDescription'>
                        15s Countdown
                    </div>
                </motion.div>
                <CardBack flip={flipClassic}/>
            </motion.div>
            <motion.div className='Category' 
                    whileTap={{ scale: canInteract ? 1.3 : 1 }}
                    whileHover={{ scale: canInteract ? 1.075 : 1 }}
                    style={{ backgroundColor: isSelected ? "#BDBDBD" : "#CBCBCB"}}
                    onHoverStart={() => {
                        if (!canInteract) return
                        sounds['hover2'].play()
                    }}
                    onClick={() => { 
                        if (!canInteract) return
                        sounds['select3'].play()
                        if (isSelected) {
                            setCategory('')
                        } else {
                            setCategory(categoryText)
                        }
                        setGamemode(Gamemodes.UNKNOWN)
                    }}>
                
                <div className='CardBorder'/>

                <div className='CategoryTitle'>
                    Casual
                </div>
                
                <img className='CategoryIcon' src={ isWhiteIcon ? images['white_king_mafia'] : images['black_king_mafia'] } alt="Category" draggable="false"/>
                
                <div className='CategoryDescription'>
                    No Stakes
                </div>
                <div className='CategoryDescription'>
                    No Rewards
                </div>
            </motion.div>
        </motion.div>
    );
}

export default Casual