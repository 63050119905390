import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Game.css';

import { Colors, Equipments, Phases } from '../../utils/Enums';

import { useShopAnimationStatus } from './Animation'
import sounds from '../../sounds';
import skins from '../../utils/SkinMapper';
import { Loadout } from './GameState';

interface Props {
    myTurn: boolean;
    phase: Phases;
    myIncome: number;
    myLayout: Loadout;
    gameOverStatus: {winner: Colors, condition: string};
    draggedEquipmentAlias: {dragStart: number, equipment: string} | null;
    setDraggedEquipmentAlias: (newDrag: {dragStart: number, equipment: string} | null) => void;
    isClassic: boolean;
    isBasic: boolean;
    setAllowMove: (allow: boolean) => void;
}

interface Equipment {
    alias: string;
    id: Equipments;
    cost: number;
    minRange: number;  
    maxRange: number;
    hitForce: number; 
    recoil: number; 
    idleTransformOrigin: string;
    fireSound: Howl | null;
    reloadSound: Howl | null;
}

export const EQUIPMENTS: { [key: string]: Equipment } = {
    [Equipments.SNIPER]: { alias: 'sniper', id: Equipments.SNIPER, cost: 1200, minRange: 2, maxRange: 15, hitForce: 11, recoil: 9, idleTransformOrigin: '65% 70%', fireSound: sounds['sniperFire'], reloadSound: sounds['sniperReload'] },
    [Equipments.RIFLE]: { alias: 'rifle', id: Equipments.RIFLE, cost: 800, minRange: 1, maxRange: 2, hitForce: 9, recoil: 6, idleTransformOrigin: '65% 65%', fireSound: sounds['rifleFire'], reloadSound: sounds['rifleReload'] },
    [Equipments.SHOTGUN]: { alias: 'shotgun', id: Equipments.SHOTGUN, cost: 800, minRange: 1, maxRange: 1, hitForce: 10, recoil: 7, idleTransformOrigin: '65% 60%', fireSound: sounds['shotgunFire'], reloadSound: sounds['shotgunReload'] },
    [Equipments.PISTOL]: { alias: 'pistol', id: Equipments.PISTOL, cost: 500, minRange: 1, maxRange: 1, hitForce: 7.5, recoil: 5, idleTransformOrigin: '60% 85%', fireSound: sounds['pistolFire'], reloadSound: sounds['pistolReload'] },
    [Equipments.KNIFE]: { alias: 'knife', id: Equipments.KNIFE, cost: 100, minRange: 0, maxRange: 0, hitForce: 0, recoil: 0, idleTransformOrigin: '0% 0%', fireSound: null, reloadSound: null },
    [Equipments.SHIELD]: { alias: 'shield', id: Equipments.SHIELD, cost: 150, minRange: 0, maxRange: 0, hitForce: 0, recoil: 0, idleTransformOrigin: '50% 75%', fireSound: null, reloadSound: null },
};

export const BASIC: { [key: string]: Equipment } = {
    [Equipments.PISTOL]: EQUIPMENTS[Equipments.PISTOL],
    [Equipments.KNIFE]: EQUIPMENTS[Equipments.KNIFE],
    [Equipments.SHIELD]: EQUIPMENTS[Equipments.SHIELD],
};

export const ADVANCED: { [key: string]: Equipment } = {
    [Equipments.SNIPER]: EQUIPMENTS[Equipments.SNIPER],
    [Equipments.RIFLE]: EQUIPMENTS[Equipments.RIFLE],
    [Equipments.SHOTGUN]: EQUIPMENTS[Equipments.SHOTGUN],
};

const Shop: React.FC<Props> = ({ myTurn, phase, myIncome, gameOverStatus, myLayout, draggedEquipmentAlias, setDraggedEquipmentAlias, isClassic, isBasic, setAllowMove }) => {
    const animation = useShopAnimationStatus(isBasic);
    useEffect(() => {
        if (!isClassic) {
            animation.setStatus('enter')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (gameOverStatus.condition !== '') {
            animation.setStatus('exit')
        }
    // ignore calls to animation change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameOverStatus])

    useEffect(() => {
        if (!myTurn) {
            setDraggedEquipmentAlias(null)
        }
    }, [myTurn, setDraggedEquipmentAlias])
    const equipmentTabs = Object.values(isBasic ? BASIC : ADVANCED).map((equipment: Equipment) => {
        const active = true
        const tabBackgroundColor: string = !active ? '#CBCBCB' : 'transparent'
        const canPurchase = phase === Phases.MOVE && draggedEquipmentAlias === null && myIncome >= equipment.cost && myTurn
        return (
            <motion.div className='ShopTile'
                        key={equipment.id}
                        style={{backgroundColor: tabBackgroundColor}}
                        onMouseDown={()=>{
                            if (!canPurchase) return
                            setDraggedEquipmentAlias({dragStart: Date.now(), equipment: equipment.alias}) 
                            setAllowMove(false)
                        }}
                        onMouseEnter={() => {
                            if (!canPurchase) return
                            sounds['hover'].play()
                        }}
                        whileHover={{ scale: !canPurchase ? 1 : 1.1 }}>
                { myLayout[equipment.alias] && 
                <motion.img className='ShopTileIcon' style={{scale: skins[myLayout[equipment.alias]].equipmentDetail?.avatarScale,
                            marginRight: `${skins[myLayout[equipment.alias]].equipmentDetail?.avatarMarginRight}vh`,
                            marginTop: equipment.id === Equipments.SHIELD ? '1.5vh' : 0, opacity: canPurchase ? 1 : 0.3}}
                    src={skins[myLayout[equipment.alias]].equipmentDetail?.avatar}
                    draggable="false"/> }
                    
                <motion.div className='SmallerText'
                            style={{position: 'absolute', right: '3.5vh', marginTop: '3.5vh', textDecoration: 'underline', opacity: canPurchase ? 1 : 0.3}}>
                    ${equipment.cost}
                </motion.div>
            </motion.div>
        );
    }) 
    
    return (
        <motion.div className="Shop"
                    style={{zIndex: -100, marginBottom: isBasic ? '-44vh' : '44vh'}}
                    initial='initial' 
                    animate={animation.status} 
                    variants={animation.statusAnimation}>
            { equipmentTabs }
        </motion.div>
    )
}

export default Shop;