import { useState } from 'react';
import { createLeftAnimation, createUpAnimation, createDownAnimation } from '../../utils/Animator';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [titleStatus, setTitleStatus] = useState<string>("enter");
    const [skinSelectorStatus, setSkinSelectorStatus] = useState<string>("enter");
    const [backButtonStatus, setBackButtonStatus] = useState<string>("enter");
    const [displayPanelStatus, setDisplayPanelStatus] = useState<string>("enter");
    const [boardStatus, setBoardStatus] = useState<string>("enter");
    const [layoutButtonStatus, setLayoutButtonStatus] = useState<string[]>([
        "enter", "enter", "enter", "enter", "enter", "enter", "enter", "enter"
    ]);
    const [weaponButtonStatus, setWeaponButtonStatus] = useState<string[]>([
        "enter", "enter", "enter", "enter", "enter", "enter"
    ]);
    const [layoutUIStatus, setLayoutUIStatus] = useState<string[]>([
        "enter", "enter", "enter"
    ]);
    
    const titleStatusAnimation = createLeftAnimation(0.15);
    const skinSelectorStatusAnimation = createLeftAnimation(0.25);
    const backButtonStatusAnimation = createDownAnimation(0);
    const displayPanelStatusAnimation = createLeftAnimation(0.2);
    const boardStatusAnimation = createLeftAnimation(0.15);
    const layoutButtonAnimation = [
        createLeftAnimation(0.35), 
        createLeftAnimation(0.30), 
        createLeftAnimation(0.25), 
        createLeftAnimation(0.20), 
        createLeftAnimation(0.15), 
        createLeftAnimation(0.10), 
        createLeftAnimation(0.05), 
        createLeftAnimation(0), 
    ]
    const weaponButtonAnimation = [
        createLeftAnimation(0), 
        createLeftAnimation(0.05), 

        createLeftAnimation(0.05), 
        createLeftAnimation(0.1), 
        createLeftAnimation(0.15), 
        createLeftAnimation(0.20), 
    ]
    const layoutUIStatusAnimation = [
        createUpAnimation(0.0), 
        createUpAnimation(0.1), 
        createUpAnimation(0.2), 
    ]
    
    function TransitionOut() {
        setTitleStatus('exit')
        setSkinSelectorStatus('exit')
        setBackButtonStatus('exit')
        setDisplayPanelStatus('exit')
        setBoardStatus('exit')
        setLayoutButtonStatus([
            "exit", "exit", "exit", "exit", "exit", "exit", "exit", "exit"
        ]);
        setWeaponButtonStatus([
            "exit", "exit", "exit", "exit", "exit", "exit"
        ]);
        setLayoutUIStatus([
            "exit", "exit", "exit"
        ]);
    }

    return {
        titleStatus, setTitleStatus, titleStatusAnimation,
        skinSelectorStatus, setSkinSelectorStatus, skinSelectorStatusAnimation,
        backButtonStatus, setBackButtonStatus, backButtonStatusAnimation,
        displayPanelStatus, setDisplayPanelStatus, displayPanelStatusAnimation,
        layoutButtonStatus, setLayoutButtonStatus, layoutButtonAnimation, 
        weaponButtonStatus, setWeaponButtonStatus, weaponButtonAnimation,
        layoutUIStatus, setLayoutUIStatus, layoutUIStatusAnimation,
        boardStatus, setBoardStatus, boardStatusAnimation,
        TransitionOut
    };
};

// animations for the piece during customization
export const createPieceAnimations = (delay: number = 0) => ({
    initial: {
        y: '-10vh',
        opacity: 0
    },

    none: {
        y: 0,
        scale: 1, 
        opacity: 1, 
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        }
    },

    enter: {
        y: 0,
        opacity: 1, 
        transition: {
            duration: 0.15,
            ease: "easeInOut",
            delay
        }
    },

    specialEnter: {
        y: 0,
        opacity: 1, 
        transition: {
            duration: 0.15,
            ease: "easeInOut",
            delay: delay + 0.4
        }
    },

    exit: {
        y: '-10vh', 
        opacity: 0,
        transition: {
            duration: 0.15,
            ease: "easeInOut",
            delay
        }
    }, 

    hover: { 
        y: 0,
        scale: 1.2,
        opacity: 1,
        transition: { 
            duration: 0.25,
            ease: "easeInOut"
        }
    },

    select: {
        y: '-2vh',
        opacity: 1,
        transition: { 
            duration: 0.25,
            ease: "easeInOut"
        }
    },

    selecthover: {
        scale: 1.2, 
        y: '-3vh',
        opacity: 1,
        transition: { 
            duration: 0.25,
            ease: "easeInOut"
        } 
    },
});

export const createShadowAnimations = (delay: number = 0) => ({
    initial: {
        scale: 1.25,
        opacity: 0
    },

    none: {
        scale: 1, 
        opacity: 0.3, 
        transition: {
            duration: 0.15,
            ease: "easeInOut",
        }
    },

    enter: {
        scale: 1, 
        opacity: 0.3, 
        transition: {
            duration: 0.15,
            ease: "easeInOut",
            delay
        }
    },

    specialEnter: {
        scale: 1,
        opacity: 0.3, 
        transition: {
            duration: 0.4,
            ease: "easeInOut",
            delay: delay + 0.2
        }
    },

    exit: {
        scale: 1.25, 
        opacity: 0,
        transition: {
            duration: 0.15,
            ease: "easeInOut",
            delay
        }
    }, 

    hover: { 
        scale: 1,
        opacity: 0.3, 
        transition: { 
            duration: 0.25,
            ease: "easeInOut"
        }
    },

    select: {
        scale: 1,
        opacity: 0.3, 
        transition: { 
            duration: 0.25,
            ease: "easeInOut"
        }
    },

    selecthover: {
        scale: 1,
        opacity: 0.3, 
        transition: { 
            duration: 0.25,
            ease: "easeInOut"
        } 
    },
})