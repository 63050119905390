import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Game.css';

import { PieceInfo } from './Piece';
import Pieces, { Colors, Gamemodes, Phases } from '../../utils/Enums';

import { usePromoteAnimationStatus } from './Animation'
import { promotePawn } from './GameLogic';
import images from '../../images';
import GameEngine from '../../backend/GameEngine';
import sounds from '../../sounds';

interface Props {
    pieces: PieceInfo[];
    setPieces: (newPieces: PieceInfo[]) => void;
    selectedIdx: number;
    myColor: Colors;
    gameMode: string;
    setSelectedPiece: (pieceInfo: PieceInfo | null) => void;
    promotionSet: string;
    setPromotionSet: (newSet: string) => void;
    turn: {color: Colors, phase: Phases};
    setTurn: (newTurn: {color: Colors, phase: Phases}) => void;
    setGameOverStatus: (status: {winner: Colors, condition: string}) => void;
    gameEngine: GameEngine | null;
}
const Promotion: React.FC<Props> = ({ pieces, setPieces, selectedIdx, myColor, gameMode, setSelectedPiece, promotionSet, setPromotionSet, turn, setTurn, setGameOverStatus, gameEngine }) => {
    const animation = usePromoteAnimationStatus();
    const [display, setDisplay] = useState<boolean>(false)
    const [promotingIdx, setPromotingIdx] = useState<number>(selectedIdx)
    
    useEffect(() => {
        if (promotionSet !== '') {
            setDisplay(true)
            animation.TransitionIn()
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotionSet])

    useEffect(() => {
        if (selectedIdx >= 0 && selectedIdx < pieces.length) {
            setPromotingIdx(selectedIdx)
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIdx])

    return (
        <>
            {
            display && 
            <div className='PromotionDisplay'>
                <motion.div className="DarkBackground"
                            initial='initial' 
                            animate={animation.status} 
                            variants={animation.statusAnimation}
                            onAnimationComplete={() => {
                                if (animation.status === 'exit') {
                                    setDisplay(false)
                                }
                            }}/>
                <div style={{position: 'absolute', display: 'flex', flexDirection: 'row'}}>
                    <motion.div
                            initial='initial' 
                            animate={animation.option1Status} 
                            variants={animation.option1StatusAnimation}>
                        <motion.div className='Promotion'
                                    whileHover={{ scale: 1.15, rotate: 5 }}
                                    whileTap={{ scale: 1.25 }}
                                    onMouseEnter={() => {
                                        sounds['hover'].play()
                                    }}
                                    onClick={()=>{
                                        sounds['select'].play()
                                        promotePawn(pieces, setPieces, selectedIdx, Pieces.BISHOP, turn, setTurn, gameMode, setSelectedPiece, setPromotionSet, setGameOverStatus, gameEngine)
                                        animation.TransitionOut()
                                    }}>
                            <motion.img className='PromotionPiece'
                                style={{marginTop: '0.5vh'}}
                                // we want to offset the z index a bit so that the selected piece's weapon can hold priority against pieces of same y
                                src={ images[pieces[promotingIdx].color + '_bishop_' + (gameMode === Gamemodes.DUMMY_BOT && pieces[promotingIdx].color !== myColor ? 'default' : promotionSet)] } 
                                alt={ promotionSet }
                                draggable="false"/>
                        </motion.div>
                        <motion.div className='PromotionText'>
                            Bishop
                        </motion.div>
                    </motion.div>
                    <motion.div
                            initial='initial' 
                            animate={animation.option2Status} 
                            variants={animation.option2StatusAnimation}>
                        <motion.div className='Promotion'
                            whileHover={{ scale: 1.15, rotate: 5 }}
                            whileTap={{ scale: 1.25 }}
                            onMouseEnter={() => {
                                sounds['hover'].play()
                            }}
                            onClick={()=>{
                                sounds['select'].play()
                                promotePawn(pieces, setPieces, selectedIdx, Pieces.QUEEN, turn, setTurn, gameMode, setSelectedPiece, setPromotionSet, setGameOverStatus, gameEngine)
                                animation.TransitionOut()
                            }}>
                            <motion.img className='PromotionPiece'
                                style={{marginTop: '1vh'}}
                                // we want to offset the z index a bit so that the selected piece's weapon can hold priority against pieces of same y
                                src={ images[pieces[promotingIdx].color + '_queen_' + (gameMode === Gamemodes.DUMMY_BOT && pieces[promotingIdx].color !== myColor ? 'default' : promotionSet)] } 
                                alt={ promotionSet }
                                draggable="false"/>
                        </motion.div>
                        <motion.div className='PromotionText'>
                            Queen
                        </motion.div>
                    </motion.div>
                    <motion.div
                            initial='initial' 
                            animate={animation.option3Status} 
                            variants={animation.option3StatusAnimation}>
                        <motion.div className='Promotion'
                            whileHover={{ scale: 1.15, rotate: 5 }}
                            whileTap={{ scale: 1.25 }}
                            onMouseEnter={() => {
                                sounds['hover'].play()
                            }}
                            onClick={()=>{
                                sounds['select'].play()
                                promotePawn(pieces, setPieces, selectedIdx, Pieces.ROOK, turn, setTurn, gameMode, setSelectedPiece, setPromotionSet, setGameOverStatus, gameEngine)
                                animation.TransitionOut()
                            }}>
                            <motion.img className='PromotionPiece'
                                style={{marginTop: '-2vh'}}
                                // we want to offset the z index a bit so that the selected piece's weapon can hold priority against pieces of same y
                                src={ images[pieces[promotingIdx].color + '_rook_' + (gameMode === Gamemodes.DUMMY_BOT && pieces[promotingIdx].color !== myColor ? 'default' : promotionSet)] } 
                                alt={ promotionSet }
                                draggable="false"/>
                        </motion.div>
                        <motion.div className='PromotionText'>
                            Rook
                        </motion.div>
                    </motion.div>
                    <motion.div
                            initial='initial' 
                            animate={animation.option4Status} 
                            variants={animation.option4StatusAnimation}>
                        <motion.div className='Promotion'
                            onMouseEnter={() => {
                                sounds['hover'].play()
                            }}
                            onClick={()=>{
                                sounds['select'].play()
                                promotePawn(pieces, setPieces, selectedIdx, Pieces.KNIGHT, turn, setTurn, gameMode, setSelectedPiece, setPromotionSet, setGameOverStatus, gameEngine)
                                animation.TransitionOut()
                            }}
                            whileHover={{ scale: 1.15, rotate: 5 }}
                            whileTap={{ scale: 1.25 }}>
                            <motion.img className='PromotionPiece'
                                // we want to offset the z index a bit so that the selected piece's weapon can hold priority against pieces of same y
                                src={ images[pieces[promotingIdx].color + '_knight_' + (gameMode === Gamemodes.DUMMY_BOT && pieces[promotingIdx].color !== myColor ? 'default' : promotionSet)] } 
                                alt={ promotionSet }
                                draggable="false"/>
                        </motion.div>
                        <motion.div className='PromotionText'>
                            Knight
                        </motion.div>
                    </motion.div>
                </div>
            </div>
            }
        </>
    )
}

export default Promotion;