import { useState, useEffect, useRef } from 'react';
import { Howl } from 'howler';
import { Gamemodes, Screens } from '../Enums';
import { useAudio, VolumeSettings } from './AudioContext';

const musicSceneMapping: Record<string, Screens[]>  = {
    "menu_music": [Screens.MENU, Screens.MODE, Screens.QUEUE, Screens.BOARD, Screens.SHOP],
    "gacha_music": [Screens.GACHA],
}

const musicDuration: Record<string, number>  = {
    "menu_music": 60,
    "gacha_music": 58.75,
    "game_classic_music1": 16,
    "game_classic_music2": 24,
    "game_classic_music3": 20,
    "game_standard_music1": 20,
    "game_standard_music2": 20,
    "game_standard_music3": 20,
}

const usePreloadedTracks = () => {
    const musicTracksRef = useRef<Record<string, Howl>>({});

    useEffect(() => {
        const tracksInfo = [
            { name: 'menu_music', src: require('../../sounds/music/menu_music.wav') },
            { name: 'gacha_music', src: require('../../sounds/music/gacha_music.wav') },
            { name: 'game_classic_music1', src: require('../../sounds/music/game_classic_music1.wav') },
            { name: 'game_classic_music2', src: require('../../sounds/music/game_classic_music2.wav') },
            { name: 'game_classic_music3', src: require('../../sounds/music/game_classic_music3.wav') },
            { name: 'game_standard_music1', src: require('../../sounds/music/game_standard_music1.wav') },
            { name: 'game_standard_music2', src: require('../../sounds/music/game_standard_music2.wav') },
            { name: 'game_standard_music3', src: require('../../sounds/music/game_standard_music3.wav') },
        ];

        tracksInfo.forEach(track => {
            // Preload and store in ref
            musicTracksRef.current[track.name] = new Howl({
                src: [track.src],
                preload: true, // Make sure to preload
                onloaderror: (id, err) => console.log(`Load error on ${track.name}:`, err) // Log errors
            });
        });

        // Capture the current state of the ref for cleanup
        const currentTracks = musicTracksRef.current;

        return () => {
            // Use the captured state for cleanup to ensure consistency
            Object.values(currentTracks).forEach(howl => howl.unload());
        };
    }, []); // Ensure this runs only once on mount

    return musicTracksRef;
};

const useMusicPlayer = (currentScene: Screens, gameMode: Gamemodes, gameStage: number) => {
    const [currentMusic, setCurrentMusic] = useState<string>("");
    const curMusicRef = useRef<Howl | null>(null)
    const volumeSettingRef = useRef<VolumeSettings | null>(null)
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const currentGameStageRef = useRef<number>(gameStage);
    const currentGameModeRef = useRef<Gamemodes>(gameMode);
    const currentSceneRef = useRef<Screens>(currentScene);

    const { volumeSettings } = useAudio();
    const musicTracksRef = usePreloadedTracks();
    
    // update the currently playing music after volume change
    useEffect(() => {
        volumeSettingRef.current = volumeSettings
        if (curMusicRef.current) {
            curMusicRef.current.volume(volumeSettings.musicVolume * volumeSettings.masterVolume);
        }
    }, [volumeSettings]);

    useEffect(() => {
        currentGameStageRef.current = gameStage;
    }, [gameStage]);

    useEffect(() => {
        currentGameModeRef.current = gameMode;
    }, [gameMode]);

    useEffect(() => {
        currentSceneRef.current = currentScene;
    }, [currentScene]);
    
    const updateMusic = (): string => {
        if (currentSceneRef.current === Screens.GAME) {
            if (currentGameModeRef.current.includes('classic')) {
                return 'game_classic_music' + currentGameStageRef.current
            } else {
                return 'game_standard_music' + currentGameStageRef.current
            }
        }
        for (const [music, scenes] of Object.entries(musicSceneMapping)) {
            if (scenes.includes(currentSceneRef.current)) {
                return music;
            }
        }
        return "";
    };

    function playNewMusic(music: string) {
        if (!music) {
            if (curMusicRef.current) {
                curMusicRef.current.fade(curMusicRef.current.volume(), 0, 1000);
            }
            return
        }
        if (curMusicRef.current && !(currentScene === Screens.GAME && currentMusic.includes('game'))) {
            curMusicRef.current.fade(curMusicRef.current.volume(), 0, 1000);
        }
        startNewTrack(music)
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(() => {
            const newMusic = updateMusic()
            playNewMusic(newMusic); 
        }, musicDuration[music] * 1000);
    }

    const startNewTrack = (music: string) => {
        const volumeSettings = volumeSettingRef.current;
        if (!volumeSettings) return; // Ensure volume settings are available

        // Stop the currently playing track if there is one
        if (curMusicRef.current) {
            curMusicRef.current.stop();
            curMusicRef.current = null; 
        }

        const howl = musicTracksRef.current[music];
        if (!howl) {
            console.error(`Track not found: ${music}`);
            return; // Handle missing tracks gracefully
        }

        setCurrentMusic(music); // Assuming this sets the currently playing track in your state

        // Set volume and start playing the new track
        howl.volume(volumeSettings.musicVolume * volumeSettings.masterVolume);
        howl.play();

        // Update the current track reference to this howl
        curMusicRef.current = howl;
    };
    
    useEffect(() => {
        if (currentScene === Screens.GAME && currentMusic.includes('game')) {
            return
        }

        const newMusic = updateMusic();

        if (currentMusic === "") {
            playNewMusic(newMusic)
        } else if (newMusic && newMusic !== currentMusic && curMusicRef.current) {
            curMusicRef.current.fade(curMusicRef.current.volume(), 0, 1000);
            setTimeout(() => {
                playNewMusic(updateMusic()); 
            }, 1750);
        } else if (curMusicRef.current && newMusic !== currentMusic) {
            curMusicRef.current.fade(curMusicRef.current.volume(), 0, 1000);
            setCurrentMusic('')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScene, volumeSettings]); 

    return currentMusic;
};

export default useMusicPlayer;