import { motion } from 'framer-motion';
import React, { useState } from 'react';
import sounds from '../../sounds';

import '../../utils/Common.css';

import * as ScreenAnimation from './Animation';
import CwgButton from '../../utils/CwgButton';

interface Props {
    setAlertMessage: (alert: string) => void;
    authEngine: {
        LogInWithEmailAndPassword: (email: string, password: string) => Promise<string>;
    };
    LoginUser: () => Promise<any>;
    RecoverPassword: () => void;
    BackToOrigin: () => void;
}

const Login: React.FC<Props> = ({ setAlertMessage, authEngine, LoginUser, BackToOrigin, RecoverPassword }) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [canLogin, setCanLogin] = useState<boolean>(true);
    const [canLogWithEnter, setCanLogWithEnter] = useState<boolean>(true);
    const failedLoginRecoveryDuration = 2000

    const animation = ScreenAnimation.useAnimationStatus();
    
    async function Login() {
        if (!canLogin) {
            console.log("Login in progress..."); 
            return;
        }
        if(!authEngine) {
            console.error('authEngine is undefined');
            setCanLogin(true)
            return;
        }
        authEngine.LogInWithEmailAndPassword(email, password)
            .then((user) => {
                if (user) {
                    LoginUser().then(() => {
                        // Trigger the animation after LoginUser completes
                        animation.TransitionOut();
                    }).catch((error) => {
                        setCanLogin(true)
                    });
                } else {
                    setCanLogin(true)
                }
            })
            .catch((error) => {
                switch (error.message) {
                    case 'auth/too-many-requests':
                        setAlertMessage("Too many attempts! Please wait a few minutes to try again.")
                        break;
                    default:
                        setAlertMessage("Invalid username or password.")
                        break;
                    }
                setCanLogin(true)
            });
    }

    function ToOrigin() {
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            BackToOrigin()
            clearTimeout(exitTimer)
        }, 250);
    }

    function Recover() {
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            RecoverPassword()
            clearTimeout(exitTimer)
        }, 250);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (email === '' || password === '') {
                setAlertMessage("Credentials empty!")
                return
            }
            if (email === 'ACCountNine38' && password === 'ACCountNine38') {
                setAlertMessage("Hi you are not supposed to know that!")
                return
            }
            if (canLogin && canLogWithEnter) {
                setCanLogin(false)
                setCanLogWithEnter(false)
                Login()
                setTimeout(() => {
                    setCanLogWithEnter(true); // Re-enable the button
                }, failedLoginRecoveryDuration);
            }
        }
    };
    
    const isDisabled = !canLogin || animation.fadeStatus === 'exit'
    return (
        <motion.div className = "Screen" 
                    initial='initial' 
                    animate={animation.fadeStatus} 
                    variants={animation.fadeStatusAnimation}>
            
            <motion.div className = "HeadTitle" 
                        initial='initial' 
                        animate={animation.titleStatus} 
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') { animation.setTitleStatus('idle') }
                        }}>
                Login
            </motion.div>

            <motion.input className='InputField' 
                        initial='initial' 
                        animate={animation.usernameStatus} 
                        variants={animation.usernameStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.usernameStatus === 'enter') { animation.setUsernameStatus('idle') }
                        }}
                        spellCheck={false} 
                        draggable={false} 
                        whileHover={{ scale: isDisabled ? 1 : 1.05 }} 
                        type={"text"}
                        onChange={e => {
                            if (isDisabled) return
                            setEmail(e.currentTarget.value)
                        }} 
                        placeholder={"Email"} 
                        onMouseEnter={()=>{
                            if (isDisabled) return
                            sounds['hover'].play()}
                        }
                        disabled={isDisabled}
                        required/>
            
            <motion.input className='InputField' 
                        initial='initial' 
                        animate={animation.passwordStatus} 
                        variants={animation.passwordStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.passwordStatus === 'enter') { animation.setPasswordStatus('idle') }
                        }}
                        spellCheck={false} 
                        draggable={false} 
                        whileHover={{ scale: isDisabled ? 1 : 1.05 }} 
                        onKeyDown={handleKeyDown}
                        type={"password"}
                        onChange={e => {
                            if (isDisabled) return
                            setPassword(e.currentTarget.value)
                        }} 
                        placeholder={"Password"} 
                        onMouseEnter={()=>{
                            if (isDisabled) return
                            sounds['hover'].play()
                        }}
                        disabled={isDisabled}
                        required/>

            <div style={{paddingTop:'3.5vh'}}></div>
            <CwgButton 
                initial='initial' 
                animate={animation.recoverStatus} 
                variants={animation.recoverStatusAnimation}
                setAnimation={animation.setRecoverStatus}
                style={{fontSize: '2.5vh'}}
                hasIdle={true}
                text={'Recover Password'} 
                onClick={() => { 
                    Recover()
                }} 
                allowClick={!isDisabled}
                recoverDuration={2000}/>

            <div style={{paddingTop:'4.75vh'}}></div>
            <CwgButton 
                initial='initial' 
                animate={animation.loginStatus} 
                variants={animation.loginStatusAnimation}
                setAnimation={animation.setLoginStatus}
                hasIdle={true}
                text={canLogin ? `Login` : `Loading`} 
                onClick={() => {
                    if (email === 'ACCountNine38' && password === 'ACCountNine38') {
                        setAlertMessage("Hi you are not supposed to know that!")
                        return
                    }
                    setCanLogin(false)
                    Login()
                }} 
                allowClick={!isDisabled}
                recoverDuration={failedLoginRecoveryDuration}/>

            <CwgButton 
                initial='initial' 
                animate={animation.backStatus} 
                variants={animation.backStatusAnimation}
                setAnimation={animation.setBackStatus}
                hasIdle={true}
                text={'Back'} 
                recoverDuration={2000}
                onClick={() => { 
                    ToOrigin()
                }} 
                allowClick={!isDisabled}
                isBack={true}/>

        </motion.div>
    );
}

export default Login;