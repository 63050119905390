import '../../utils/Common.css';
import './Register.css'
import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import * as ScreenAnimation from './Animation';
import sounds from '../../sounds';
import CwgButton from '../../utils/CwgButton';

/*
validation conditions
username < 18char
password < 30char
password must contain letters and numbers
non-existing email
password matches confirm password
recapcha passes
terms and condition checked

username passes profanity check (to be added)
*/
interface Props {
    setAlertMessage: (alert: string) => void;
    authEngine: {
        RegisterWithEmailPasswordAndUsername: (
            email: string,
            password: string,
            username: string,
            subscribe: boolean,
            token: string,
        ) => Promise<string>; // Adjust this type if the Promise resolves with something different
        // Other methods of auth_engine can be typed here if they are used
    };
    RegisterUser: () => void;
    BackToOrigin: () => void;
}
  
const Register: React.FC<Props> = ({ setAlertMessage, authEngine, RegisterUser, BackToOrigin }) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const recaptchaRef = useRef<any>(null);
    
    // Validation states
    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);
    const [termsRead, setTermsRead] = useState<boolean>(false);
    const [subscribe, setSubscribe] = useState<boolean>(false);
    const [canRegister, setCanRegister] = useState<boolean>(true);

    const animation = ScreenAnimation.useAnimationStatus();

    function isAlphanumericWithSymbols(str: string) {
        // Regular expression to match alphanumeric characters and specific symbols
        const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9\-_' ]+$/;
    
        return regex.test(str);
    }

    async function handleRegister() {
        if (!canRegister) return;
        setCanRegister(false);
        sounds['select'].play();

        // check pre-requirements
        let fail = false
        if (email === '') {
            setAlertMessage('Email cannot be empty!');
            fail = true
        } else if (username === '') {
            setAlertMessage('Username cannot be empty!');
            fail = true
        } else if (password === '' || confirmPassword === '') {
            setAlertMessage('Password or confirmed password cannot be empty!');
            fail = true
        } else if (!isAlphanumericWithSymbols(username)) {
            setAlertMessage('Username can only include at least 1 letter and may contain numbers, "-", "_", and "\'".');
            fail = true
        } else if (username.length < 3) {
            setAlertMessage('Username is too short!');
            fail = true
        } else if (username.length > 20) {
            setAlertMessage('Username is too short!');
            fail = true
        } else if (password.length < 6) {
            setAlertMessage('Password must be at least 6 characters!');
            fail = true
        } else if (!email.includes('@')) {
            setAlertMessage('Invalid email address!');
            fail = true
        } else if (password !== confirmPassword) {
            setAlertMessage('The confirmed password does not match!');
            setPassword('')
            setConfirmPassword('')
            fail = true
        } else if (!termsRead) {
            setAlertMessage('Must read and agree to Terms & Condition!');
            fail = true
        } else if (!reCaptchaToken) {
            setAlertMessage('Please complete the reCAPTCHA verification!');
            fail = true
            setCanRegister(true);
            return
        }
        if (fail) {
            setCanRegister(true);
            return
        }

        if (reCaptchaToken === null) return
        try {
            await authEngine.RegisterWithEmailPasswordAndUsername(email, password, username, subscribe, reCaptchaToken)
            .then(result => {
                if (result === '') {
                    RegisterUser();
                } else {
                    setAlertMessage(result)
                }
            })
            .catch(error => {
                setAlertMessage('An error occurred during registration. Please try again.')
            });
        } catch (error) {
            setAlertMessage('An error occurred while registering user!');
            if (recaptchaRef.current) {
                (recaptchaRef.current as any).reset(); 
            }
        } finally {
            setCanRegister(true);
        }
    };
  
    const VerifyReCapcha = (token: string | null) => {
        setReCaptchaToken(token);
    };

    function ToMenu() {
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            BackToOrigin()
            clearTimeout(exitTimer)
        }, 550);
    } 

    const HandleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTermsRead(event.target.checked);
    }; 

    const HandleSubscribeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubscribe(event.target.checked);
    }; 

    return (
        <motion.div className = "Screen" 
                    initial='initial' 
                    animate={animation.fadeStatus} 
                    variants={animation.fadeStatusAnimation}>

            <motion.div className = "HeadTitle" 
                        initial='initial' 
                        animate={animation.titleStatus} 
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') { animation.setTitleStatus('idle') }
                        }}>
                Register
            </motion.div>
            <motion.div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '38vh', pointerEvents: canRegister ? 'auto' : 'none'}}>
                <form autoComplete="off">
                <motion.input className='InputField' 
                            initial='initial' 
                            animate={animation.emailStatus} 
                            variants={animation.emailStatusAnimation} 
                            onAnimationComplete={()=>{
                                if (animation.emailStatus === 'enter') { animation.setEmailStatus('idle') }
                            }}
                            spellCheck={false} 
                            draggable={false} 
                            whileHover={{ scale: 1.05 }} 
                            type={"text"}
                            onInput={e => setEmail(e.currentTarget.value)} 
                            placeholder={"Email"} 
                            onMouseEnter={()=>{sounds['hover'].play()}}
                            required/>
                </form>
                <form autoComplete="off">
                <motion.input className='InputField' 
                            initial='initial' 
                            animate={animation.usernameStatus} 
                            variants={animation.usernameStatusAnimation} 
                            onAnimationComplete={()=>{
                                if (animation.usernameStatus === 'enter') { animation.setUsernameStatus('idle') }
                            }}
                            spellCheck={false} 
                            draggable={false} 
                            whileHover={{ scale: 1.05 }} 
                            type={"text"}
                            onInput={e => setUsername(e.currentTarget.value)} 
                            placeholder={"Username"} 
                            onMouseEnter={()=>{sounds['hover'].play()}}
                            required/>
                </form>
                <form autoComplete="off">
                <motion.input className='InputField' 
                            initial='initial' 
                            animate={animation.passwordStatus} 
                            variants={animation.passwordStatusAnimation} 
                            onAnimationComplete={()=>{
                                if (animation.passwordStatus === 'enter') { animation.setPasswordStatus('idle') }
                            }}
                            spellCheck={false} 
                            draggable={false} 
                            whileHover={{ scale: 1.05 }} 
                            type={"password"}
                            placeholder={"Password"} 
                            onMouseEnter={()=>{sounds['hover'].play()}}
                            onInput={e => setPassword(e.currentTarget.value)} required/>
                </form>
                <form autoComplete="off">
                <motion.input className='InputField' 
                            initial='initial' 
                            animate={animation.repasswordStatus} 
                            variants={animation.repasswordStatusAnimation} 
                            onAnimationComplete={()=>{
                                if (animation.repasswordStatus === 'enter') { animation.setRepasswordStatus('idle') }
                            }}
                            spellCheck={false} 
                            draggable={false} 
                            whileHover={{ scale: 1.05 }} 
                            type={"password"}
                            placeholder={"Confirm Password"} 
                            onMouseEnter={()=>{sounds['hover'].play()}}
                            onInput={e => setConfirmPassword(e.currentTarget.value)} required/>
                </form>
                
                <motion.div className='TermsHolder' 
                            style={{marginTop: "1vh"}}
                            initial='initial' 
                            animate={animation.termsStatus} 
                            variants={animation.termsStatusAnimation} 
                            onAnimationComplete={()=>{
                                if (animation.termsStatus === 'enter') { animation.setTermsStatus('idle') }
                            }}>
                    
                    <motion.div className='TermsLabel' whileHover={{ scale: 1.05 }} onMouseEnter={()=>{sounds['hover'].play()}}>
                        Terms & Conditions
                    </motion.div>
                    <motion.div style={{flex: 1}}/>
                    <motion.input className='TermsCheckbox' whileHover={{ scale: 1.1 }}
                            type="checkbox"
                            checked={termsRead}
                            onChange={HandleCheckboxChange}
                            onMouseEnter={()=>{sounds['hover'].play()}}/>

                </motion.div>

                <motion.div className='TermsHolder' 
                            initial='initial' 
                            animate={animation.termsStatus} 
                            variants={animation.termsStatusAnimation} 
                            onAnimationComplete={()=>{
                                if (animation.termsStatus === 'enter') { animation.setTermsStatus('idle') }
                            }}>
                    
                    <motion.div className='TermsLabel' whileHover={{ scale: 1.05 }} onMouseEnter={()=>{sounds['hover'].play()}}>
                        Subscribe to Updates
                    </motion.div>
                    <motion.div style={{flex: 1}}/>
                    <motion.input className='TermsCheckbox' whileHover={{ scale: 1.1 }}
                            type="checkbox"
                            checked={subscribe}
                            onChange={HandleSubscribeChange}
                            onMouseEnter={()=>{sounds['hover'].play()}}/>

                </motion.div>

                <motion.div className='Capcha' 
                            initial='initial' 
                            animate={animation.capchaStatus} 
                            variants={animation.capchaStatusAnimation}
                            onAnimationComplete={()=>{
                                if (animation.capchaStatus === 'enter') { animation.setCapchaStatus('idle') }
                            }}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Ld_cd0nAAAAADqQXNtEKHFb1K_y9gDRpCkonAUR"
                        onChange={VerifyReCapcha}
                    />
                </motion.div>

                <div style={{paddingTop:'2vh'}}></div>

                <CwgButton 
                    initial='initial' 
                    animate={animation.registerStatus} 
                    variants={animation.registerStatusAnimation}
                    setAnimation={animation.setRegisterStatus}
                    hasIdle={true}
                    text={canRegister ? 'Register' : 'Loading'} 
                    recoverDuration={1000}
                    onClick={() => { handleRegister() }} 
                    allowClick={animation.fadeStatus !== 'exit' && canRegister}/>
                
            </motion.div>
            <CwgButton 
                initial='initial' 
                animate={animation.backStatus} 
                variants={animation.backStatusAnimation}
                setAnimation={animation.setBackStatus}
                hasIdle={true}
                text={'Back'} 
                recoverDuration={2000}
                onClick={() => { 
                    ToMenu()
                }} 
                allowClick={animation.fadeStatus !== 'exit' && canRegister}
                isBack={true}/>
        </motion.div>
    );
}

export default Register;