import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useCapsuleAnimation, useLightAnimation, useRewardAnimation, useRewardNameAnimation, useShellAnimation, useStumpAnimation } from './Animation';
import images from '../../images';
import { Reward } from './Gacha';
import RealReward from './Reward';
import { getPrestigeColor, getSetFade, getSetGlow, getSkinPresetByName } from '../../utils/SkinMapper';
import { characters, validateCharacter } from '../../utils/CharacterMapper';
import { Characters } from '../../utils/Enums';

interface CapsuleProps {
    id: number;
    reward: Reward;
    rewards: Reward[];
    updateNumOpened: () => void;
    updateNumDropped: () => void;
    canFade: boolean;
    shake: () => void;
}

function getTextMarginTop(type: string): string {
    if (type === 'piece') {
        return '22vh'
    } else if (type === 'character') {
        return '23.5vh'
    } else if (type === 'weapon') {
        return '21vh'
    }
    return '-21vh'
}

const Capsule: React.FC<CapsuleProps> = ({ id, reward, rewards, updateNumOpened, updateNumDropped, canFade, shake }) => {
    const [ opened, setOpened ] = useState<boolean>(false);
    const [ canSpin, setCanSpin ] = useState<boolean>(false);
    const [ canExplode, setCanExplode ] = useState<boolean>(false);
    const [ explode, setExplode ] = useState<boolean>(false);
    // 0 - unopened
    // 1 - selected
    // 2 - opened
    const [ spinAngle, setSpinAngle ] = useState<number>(Math.floor(Math.random() * 180) - 360);
    const { capsuleAnimation, capsuleStatus, setCapsuleStatus } = useCapsuleAnimation(id);
    const { shellStatus: bottomStatus, setShellStatus: setBottomStatus, shellStatusAnimation: bottomStatusAnimation } = useShellAnimation(false, spinAngle);
    const { shellStatus: topStatus, setShellStatus: setTopStatus, shellStatusAnimation: topStatusAnimation } = useShellAnimation(true, spinAngle);
    const { rewardStatus, setRewardStatus, rewardStatusAnimation } = useRewardAnimation(id);
    const { rewardNameStatus, setRewardNameStatus, rewardNameStatusAnimation } = useRewardNameAnimation();
    const { stumpStatus, setStumpStatus, stumpStatusAnimation } = useStumpAnimation()
    const { lightStatus, setLightStatus, lightStatusAnimation } = useLightAnimation()
    const zIndex = id % 2 === 1 ? 10 : 5

    useEffect(() => {
        if (explode) {
            setBottomStatus('explode')
            setTopStatus('explode')
            shake()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [explode]); 

    useEffect(() => {
        if (canFade) {
            setCapsuleStatus('exit')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canFade]); 

    // reset all animations
    useEffect(() => {
        if (rewards.length > 0) {
            setSpinAngle(Math.floor(Math.random() * 180) - 360)
            setCapsuleStatus('enter')
        } else {
            setSpinAngle(Math.floor(Math.random() * 180) - 360)
            setCapsuleStatus('enter')
            setCanSpin(false)
            setCanExplode(false)
            setExplode(false)
            setStumpStatus('initial')
            setRewardNameStatus('initial')
            setTopStatus('initial')
            setBottomStatus('initial')
            setCapsuleStatus('initial')
            setRewardStatus('initial')
            setLightStatus('initial')
            setOpened(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewards]); 

    const textColor = getPrestigeColor(reward.type === 'character' ? characters[validateCharacter(reward.item as Characters)].set : getSkinPresetByName(reward.item, reward.type)?.set!)
    return (
        <motion.div
            className='Capsule'
            style={{zIndex: zIndex, pointerEvents: !opened ? 'auto' : 'none'}}
            draggable={false}
            key={id}
            initial="initial"
            animate={capsuleStatus}
            variants={capsuleAnimation}
            onAnimationStart={()=>{
                if (capsuleStatus === 'select') {
                    setCanSpin(true)
                } else if (capsuleStatus === 'spin') {
                    setCanExplode(true)
                }
            }}
            onAnimationComplete={() => {
                if (capsuleStatus === 'enter') {
                    setCapsuleStatus('idle')
                } else if (capsuleStatus === 'select' && canSpin) {
                    setCanSpin(false)
                    setCapsuleStatus('spin')
                    setTopStatus('spin')
                    setBottomStatus('spin')
                } else if (capsuleStatus === 'spin' && canExplode) {
                    setCanExplode(false)
                    setExplode(true)
                    setRewardStatus('enter')
                    if (reward.rarity === 'prestige') {
                        if (reward.type === 'piece') {
                            setLightStatus('piece')
                        } else if (reward.type === 'character') {
                            setLightStatus('character')
                        } else if (reward.type === 'weapon') {
                            setLightStatus('weapon')
                        } else {
                            setLightStatus('exit')
                        }
                    } else {
                        setLightStatus('exit')
                    }
                }
            }}
            onClick={() => {
                if (opened) return
                setOpened(true)
                updateNumOpened()
                setCapsuleStatus('select');
                setTopStatus('select')
                setBottomStatus('select')
            }}
        >

            {
                explode && 
                <motion.div style={{display: 'flex', 
                    position: 'absolute', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 10, width: '100%'}}
                            initial="initial"
                            animate={rewardStatus}
                            variants={rewardStatusAnimation}
                            onAnimationComplete={() => {
                                if (rewardStatus === 'enter') {
                                    setRewardStatus('idle')
                                    setRewardNameStatus('enter')
                                    updateNumDropped()
                                } 
                            }}>
                    <motion.div initial="initial"
                            animate={stumpStatus}
                            variants={stumpStatusAnimation}
                            style={{width: '100%', height: '100%'}}>
                        <RealReward reward={reward} opened={opened}/>
                    </motion.div>
                    {
                        (reward.type === 'piece' || reward.type === 'character' || reward.type === 'weapon') &&
                        <motion.div className='NormalText' style={{width: '150%', height: '4vh', position: 'absolute', 
                                                                marginTop: getTextMarginTop(reward.type), 
                                                                color: textColor, 
                                                                fontWeight: 'bold', textShadow: reward.rarity === 'prestige' ? '0vh 0vh 0.35vh #ffffff' : 'none'}}
                                    initial="initial"
                                    animate={rewardNameStatus}
                                    variants={rewardNameStatusAnimation}
                                    onAnimationComplete={() => {
                                        if (rewardNameStatus === 'enter') {
                                            if (reward.type === 'piece') {
                                                setStumpStatus('jump')
                                            } else if (reward.type === 'weapon') {
                                                setStumpStatus('spin')
                                            }
                                        }
                                    }}>
                            {reward.item.replace(/ Prestige$/, '')}
                        </motion.div>
                    }
                </motion.div>
            }
            <motion.div
                style={{position: 'absolute', display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}
                whileHover={{ scale: !opened ? 1.25 : 1, rotate: !opened ? '15deg' : '0deg' }}
                whileTap={{ scale: 2, rotate: `${Math.floor(Math.random() * 120 - 60)}deg` }}>
                <motion.div className='Spotlight'
                    style={{zIndex: 0, scale: reward.rarity === 'prestige' ? 1.05 : 1, backgroundImage: `radial-gradient(circle closest-side, ${textColor} 0%, ${textColor} ${getSetGlow(textColor)}%, transparent ${getSetFade(textColor)}%)`}}
                    initial="initial"
                    animate={lightStatus}
                    variants={lightStatusAnimation}/>
                <motion.img 
                    style={{position: 'absolute', zIndex: 25}}
                    initial="initial"
                    animate={bottomStatus}
                    variants={bottomStatusAnimation}
                    className='CapsuleLayers' src={images['ball']} draggable={false}/>
                <motion.img 
                    style={{position: 'absolute', zIndex: 25}}
                    initial="initial"
                    animate={topStatus}
                    variants={topStatusAnimation}
                    className='CapsuleLayers' src={images['ball']} draggable={false}/>
                </motion.div> 
        </motion.div>
    );
};

export default Capsule;