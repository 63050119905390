import React, { useState, useEffect, useRef } from "react";
import { getAuth } from "firebase/auth";
import { motion } from "framer-motion";

import '../../utils/Common.css';
import "./Queue.css";
import "../host/Host.css";

import * as ScreenAnimation from './Animation';
import MatchMakingEngine from "../../backend/MatchMakingEngine";
import CharacterHandler from "../../utils/CharacterHandler";
import { Characters } from "../../utils/Enums";
import { characters } from "../../utils/CharacterMapper";
import { getLeagueName } from "../../utils/MathUtil";
import CwgButton from "../../utils/CwgButton";

interface Props {
    setAlertMessage: (alert: string) => void;
    gameMode: string;
    ExitToMode: () => void;
    StartGame: (matchId: string, mode: string) => void;
    myCharacter: Characters;
    credits: number;
}

const Queue: React.FC<Props> = ({ setAlertMessage, gameMode, ExitToMode, StartGame, myCharacter, credits }) => {
    const animation = ScreenAnimation.useAnimationStatus();
    const [opponent, setOpponent] = useState<string>('');
    const [ready, setReady] = useState<boolean>(false);
    const [opponentReady, setOpponentReady] = useState<boolean>(false);
    const [opponentUsername, setOpponentUsername] = useState<string>('');
    const [opponentCharacter, setOpponentCharacter] = useState<Characters | ''>('');
    const [matchId, setMatchId] = useState<string>('');
    const [inQueue, setInQueue] = useState<boolean>(false);
    const [skipTime, setSkipTime] = useState<boolean>(false);
    const [buttonText, setButtonText] = useState<string>("Start Queue");
    const buttonExitText = 'Exiting'
    const buttonSearchText = 'Searching'
    const buttonRequeueText = 'Re-queue'

    const mounted = useRef<boolean>(false);
    
    const currentUser = getAuth().currentUser
    const [characterExpression, setCharacterExpression] = useState<string>('idle')
    const [opponentExpression, setOpponentExpression] = useState<string>('idle')

    const [ matchMakingEngine, setMatchMakingEngine ] = useState<MatchMakingEngine | null>(null);

    // prevent match spamming or matching while already in queue or in match
    const maxMatchTime = 10
    const [lastQueueUpdateTime, setLastQueueUpdateTime] = useState<Date | undefined>(undefined); 
    const [matchTimer, setMatchTimer] = useState<number>(maxMatchTime); 
    const intervalRef = useRef<NodeJS.Timeout | null>(null); // Holds the interval ID for cleanup

    // buffers
    const [reQueueBuffer, setReQueueBuffer] = useState<boolean>(false); 
    const [readyBuffer, setReadyBuffer] = useState<boolean>(false); 

    const startMatchTimer = (): void => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        intervalRef.current = setInterval(() => {
            setMatchTimer((prevMatchTimer: number) => {
                if (prevMatchTimer <= 1) {
                    clearInterval(intervalRef.current as NodeJS.Timeout);
                    intervalRef.current = null;
                    return 0;
                }
                return prevMatchTimer - 1;
            });
        }, 1000);
    };
    useEffect(() => {
        if (!lastQueueUpdateTime || !mounted.current || (ready && opponentReady)) return // last condition is dont trigger if we skip time
        setMatchTimer(Math.max(maxMatchTime - Math.floor((new Date().getTime() - lastQueueUpdateTime.getTime()) / 1000), 0))
        startMatchTimer()
    }, [lastQueueUpdateTime, ready, opponentReady]);

    // if both players are ready, skip to 3 seconds
    useEffect(() => {
        if (ready && opponentReady && skipTime && matchTimer > 4) {
            setSkipTime(false)
            setMatchTimer(4)
        }
    }, [ready, opponentReady, matchTimer, skipTime]);
    useEffect(() => {
        setReadyBuffer(false)
        if (opponent) {
            setSkipTime(true)
        } else {
            setLastQueueUpdateTime(undefined)
        }
    }, [opponent]);

    // process queuing
    useEffect(() => {
        // first queue
        StartQueue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchMakingEngine])
    function StartQueue() {
        setInQueue(true)
        setButtonText(buttonSearchText);
        setMatchTimer(maxMatchTime)
        startMatchTimer();
        if (!matchMakingEngine) return
        matchMakingEngine.inQueue = true
        matchMakingEngine.enterQueue().then(() => {})
        .catch(() => {
            ReQueueBuffer()
            matchMakingEngine.inQueue = false
            setAlertMessage("Failed to queue! Try again in a few seconds.");
            setButtonText(buttonRequeueText)
        });
    }

    // Clean up the interval when the component unmounts
    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    // timer reaches 0, exit queue or start game
    function ResetOpponent() {
        setOpponent('')
        setOpponentReady(false)
        setReady(false)
        setOpponentUsername('')
        setOpponentCharacter('')
    }
    useEffect(() => {
        if (mounted.current && Math.floor(matchTimer) === 0) {
            if (matchId !== '' && ready && opponentReady) {
                // start game
                animation.TransitionOut()
                const exitTimer = setTimeout(() => {
                    ReQueueBuffer()
                    StartGame(matchId, gameMode)
                    clearTimeout(exitTimer)
                }, 500);
            } else if (matchMakingEngine && inQueue && (!ready || !opponentReady || matchId === '')) {
                // exit queue
                matchMakingEngine?.exitQueue().then(() => {
                    setButtonText(buttonRequeueText)
                    ReQueueBuffer()
                    ResetOpponent()
                }).catch(() => {
                    // setAlertMessage(error.message);
                    ReQueueBuffer()
                    ResetOpponent()
                });
            } else {
                ReQueueBuffer()
                ResetOpponent()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchTimer, matchMakingEngine]);

    useEffect(() => {
        if (!mounted.current) {
            return
        } 
        if (opponent) {
            setButtonText('Ready')
            setCharacterExpression(characters[myCharacter].greet)
            setOpponentExpression(characters[opponentCharacter].greet)
        } else {
            ReQueueBuffer()
            setButtonText('Re-queue')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opponent])

    useEffect(() => {
        if (!mounted.current) {
            return
        } 
        if (ready) {
            setCharacterExpression(characters[myCharacter].win)
            if (!opponentReady) {
                setButtonText('Waiting')
            } else {
                setButtonText('Commencing')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready, myCharacter])

    useEffect(() => {
        if (!mounted.current) {
            return
        } 
        if (opponentReady) {
            setOpponentExpression(characters[opponentCharacter].win)
            if (ready) {
                setButtonText('Commencing')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opponentReady, opponentCharacter])

    // transition the user in
    useEffect(() => {
        const hostTimer = setTimeout(() => {
            animation.setHostPanelStatus('enter')
            clearTimeout(hostTimer)
        }, 450);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        const initiateEngine = async () => {
            try {
                setMatchMakingEngine(new MatchMakingEngine(
                    getAuth().currentUser?.uid, 
                    gameMode, 
                    setOpponent, 
                    setOpponentUsername, 
                    setOpponentCharacter, 
                    setReady, 
                    setOpponentReady,
                    setMatchId,
                    setLastQueueUpdateTime, 
                ))
            } catch (error) {
                console.error("Error match making", error);
            }
        };

        initiateEngine();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

    useEffect(() => {
        if (!mounted.current) {
            return
        }
        if (opponent === '') {
            animation.OpponentLeaves()
        } else {
            animation.OpponentFound()
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [opponent])

    function ReQueueBuffer() {
        setReQueueBuffer(true)
        setInQueue(false)
        const exitTimer = setTimeout(() => {
            setReQueueBuffer(false)
            clearTimeout(exitTimer)
        }, 500);
    }
    function ExitQueue() {
        if (inQueue) {
            setInQueue(false)
            matchMakingEngine?.exitQueue()
            .catch((error: any) => {
                // setAlertMessage("Error exiting queue!");
            });
        }
        setButtonText(buttonExitText)
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            ExitToMode()
            ResetOpponent()
            clearTimeout(exitTimer)
        }, 550);
    }
    
    // update mount for initial render
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isComp = gameMode.includes("competitive")
    const canClick = ((opponent && !ready) || (matchMakingEngine !== null && !inQueue)) && buttonText !== buttonExitText && buttonText !== buttonSearchText && !reQueueBuffer && !readyBuffer
    function handleClick() {
        if (!canClick) return
        if (!inQueue) {
            StartQueue()
        } else {
            setReadyBuffer(true)
            matchMakingEngine?.readyUp().then(() => {
                setReady(true)
            })
            .catch(() => {
                setAlertMessage("Failed to ready! Try again in a few seconds.");
                setReadyBuffer(false)
            });
        }
    }
    
    return (
        <motion.div className = "Screen" 
                    initial='initial' 
                    animate={animation.fadeStatus} 
                    variants={animation.fadeStatusAnimation}>

            <motion.div className = "HeadTitle"
                        style={{marginTop: '2vh'}}
                        initial='initial' 
                        animate={animation.titleStatus} 
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') { animation.setTitleStatus('idle') }
                        }}>
                {isComp ? "Competitive" : "Casual"} Lobby
            </motion.div>

            <motion.div className='PanelContainer'>
                <motion.div className='HostPanel'
                        initial='initial' 
                        animate={animation.hostPanelStatus} 
                        variants={animation.hostPanelAnimation}
                        onAnimationComplete={()=>{
                            if (animation.hostPanelStatus === 'enter') { animation.setHostPanelStatus('idle') }
                        }}>
                    <motion.div className = "Profile">
                        <CharacterHandler name={myCharacter} expression={characterExpression} setExpression={setCharacterExpression} allowAnnoy={true} smallBorder={false}/>
                        <motion.div className = "LargeText" style={{marginTop: '0.5vh'}}>{currentUser ? currentUser.displayName : "??????????????"}</motion.div>
                    </motion.div>
                    <motion.div className = "NormalText" style={{marginTop: '-2.5vh'}}>{ready ? "Ready" : "Not Ready"}</motion.div>
                </motion.div>
                <motion.div className='InfoPanel'
                        initial='initial' 
                        animate={animation.infoPanelStatus} 
                        variants={animation.infoPanelAnimation}
                        onAnimationComplete={()=>{
                            if (animation.infoPanelStatus === 'enter') { animation.setInfoPanelStatus('idle') }
                        }}>
                    <motion.div className='VeryLargeText' style={{marginBottom: '3vh'}}>
                        VS
                    </motion.div>
                    <motion.div className='LargeText' style={{marginBottom: '1vh'}}>
                        League: {getLeagueName(credits)}
                    </motion.div>
                    <motion.div className='LargeText' style={{marginBottom: '5vh'}}>
                        Mode: {gameMode.includes('classic') ? 'Classic' : 'Standard'}
                    </motion.div>
                    <CwgButton 
                        text={`${buttonText}${!inQueue ? '' : ` (${Math.max(Math.floor(matchTimer - 1), 0)})`}`} 
                        onClick={handleClick} 
                        allowClick={canClick}
                        recoverDuration={500}/>
                </motion.div>
                <motion.div className='HostPanel' 
                        initial='initial' 
                        animate={animation.opponentPanelStatus} 
                        variants={animation.opponentPanelAnimation}
                        onAnimationComplete={()=>{
                            if (animation.opponentPanelStatus === 'enter') { animation.setOpponentPanelStatus('idle') }
                        }}>
                    <motion.div className = "Profile">
                        {opponentCharacter && 
                            <CharacterHandler name={opponentCharacter} expression={opponentExpression} setExpression={setOpponentExpression} allowAnnoy={true} smallBorder={false}/>
                        }
                        {opponentUsername && 
                            <motion.div className = "LargeText" style={{marginTop: '0.5vh'}}>{currentUser ? opponentUsername : "??????????????"}</motion.div>
                        }
                    </motion.div>
                    <motion.div className = "NormalText" style={{marginTop: '-2.5vh'}}>{opponentReady ? "Ready" : "Not Ready"}</motion.div>
                </motion.div>
            </motion.div>

            <CwgButton 
                initial='initial' 
                animate={animation.cancelStatus} 
                variants={animation.cancelStatusAnimation}
                setAnimation={animation.setCancelStatus}
                hasIdle={true}
                text={'Leave'} 
                recoverDuration={2000}
                onClick={() => { ExitQueue() }} 
                allowClick={!ready}
                isBack={true}/>
        </motion.div>
    )
}

export default Queue;