import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Game.css';
import sounds from '../../sounds';
import { useUIOverlayAnimationStatus } from './Animation';
import { Colors } from '../../utils/Enums';
import GameEngine from '../../backend/GameEngine';

interface Props {
    myColor: Colors;
    overlayUI: 'none' | 'surrender' | 'draw' | 'settings';
    setOverlayUI: (newOverlay: 'none' | 'surrender' | 'draw') => void;
    setGameOverStatus: (status: {winner: Colors, condition: string}) => void;
    setMySpeech: (speech: string) => void;
    gameEngine: GameEngine | null;
}

const surrenderMessages: string[] = ["I surrender.", "It's over.", "I'm beaten.", ":(", "You win.", "I surrender."]

const Surrender: React.FC<Props> = ({ myColor, overlayUI, setOverlayUI, setGameOverStatus, setMySpeech, gameEngine }) => {
    const [active, setActive] = useState<boolean>(false)
    const [canInteract, setCanInteract] = useState<boolean>(false)
    const animation = useUIOverlayAnimationStatus()

    useEffect(() => {
        if (overlayUI === 'surrender' && !active) {
            animation.setPanelStatus('enter')
            animation.setBackgroundStatus('enter')
            setActive(true)
            setCanInteract(true)
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overlayUI, active])

    return (
        <>
            <motion.div className="DarkBackground"
                        style={{pointerEvents: 'none'}}
                        initial='initial' 
                        animate={animation.backgroundStatus} 
                        variants={animation.backgroundStatusAnimation}/>
            <motion.div className='SurrenderAndDraw'
                        initial='initial' 
                        animate={animation.panelStatus} 
                        variants={animation.panelStatusAnimation}>
                <motion.div className='InfoText' style={{marginTop: '4vh'}}>
                    Confirm to surrender.
                </motion.div>
                <motion.div className='RowSelection' style={{marginTop: '2vh'}}>
                    <motion.div className="GameOverButton" 
                            onClick={()=>{
                                if (!canInteract) return
                                setMySpeech(surrenderMessages[Math.floor(Math.random() * surrenderMessages.length)])
                                sounds['select'].play()
                                setGameOverStatus({winner: myColor === Colors.WHITE ? Colors.BLACK : Colors.WHITE, condition: 'Surrender'})
                                animation.TransitionOut()
                                setCanInteract(false)
                                const timer = setTimeout(() => {
                                    if (gameEngine) {
                                        gameEngine.surrenderBackend()
                                    }
                                    setActive(false)
                                    setOverlayUI('none')
                                    return () => {
                                        clearTimeout(timer)
                                    };
                                }, 200);
                            }}
                            onMouseEnter={()=>{
                                if (!canInteract) return
                                sounds['hover'].play()
                            }}
                            whileHover={{ scale: 1.1 }}> 
                        Confirm
                    </motion.div>
                    <motion.div className="GameOverButton" style={{marginLeft: '5vh'}}
                            onClick={()=>{
                                if (!canInteract) return
                                sounds['select'].play()
                                animation.TransitionOut()
                                setCanInteract(false)
                                const timer = setTimeout(() => {
                                    setActive(false)
                                    setOverlayUI('none')
                                    return () => {
                                        clearTimeout(timer)
                                    };
                                }, 200);
                            }}
                            onMouseEnter={()=>{
                                if (!canInteract) return
                                sounds['hover'].play()
                            }}
                            whileHover={{ scale: 1.1 }}> 
                        Cancel
                    </motion.div>
                </motion.div>
            </motion.div>
            <motion.div className="BigTitle" 
                        initial='initial' 
                        animate={animation.panelStatus} 
                        variants={animation.panelStatusAnimation}>
                Surrender
            </motion.div>
        </>
    )
}

export default Surrender;