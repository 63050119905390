import { useState } from 'react';
import createFadeAnimation, { createLeftAnimation, createDownAnimation, createFloatAnimation, useAlertAnimation, createRightAnimation, createUpAnimation } from '../../utils/Animator';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [fadeStatus, setFadeStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [titleStatus, setTitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [idStatus, setIdStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [infoPanelStatus, setInfoPanelStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [hostPanelStatus, setHostPanelStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('initial');
    const [opponentPanelStatus, setOpponentPanelStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('initial');
    const [backStatus, setBackStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [startStatus, setStartStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('initial');

    const [alertAnimationStatus, setAlertAnimationStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('initial');

    const fadeStatusAnimation = createFadeAnimation(0)
    const titleStatusAnimation = createUpAnimation(0)
    const idStatusAnimation = createUpAnimation(0.05)
    const infoPanelAnimation = createUpAnimation(0.1)
    const hostPanelAnimation = createLeftAnimation(0, true)
    const opponentPanelAnimation = createRightAnimation(0, true)
    const backStatusAnimation = createDownAnimation(0)
    const startStatusAnimation = createDownAnimation(0)

    const alertAnimation = useAlertAnimation()

    titleStatusAnimation.idle = createFloatAnimation(0)
    idStatusAnimation.idle = createFloatAnimation(0)
    infoPanelAnimation.idle = createFloatAnimation(0.1)
    startStatusAnimation.idle = createFloatAnimation(0.2)
    hostPanelAnimation.idle = createFloatAnimation(0)
    opponentPanelAnimation.idle = createFloatAnimation(0)
    backStatusAnimation.idle = createFloatAnimation(0)

    function TransitionOut() {
        setFadeStatus('exit')
        setTitleStatus('exit')
        setIdStatus('exit')
        setHostPanelStatus('exit')
        setOpponentPanelStatus('exit')
        setInfoPanelStatus('exit')
        setBackStatus('exit')
        setAlertAnimationStatus('exit')
        setStartStatus('exit')
    }

    function OpponentFound() {
        setOpponentPanelStatus('enter')
        setStartStatus('enter')
    }

    function OpponentLeaves() {
        setOpponentPanelStatus('exit')
        setStartStatus('exit')
    }

    return {
        fadeStatus, setFadeStatus, fadeStatusAnimation,
        titleStatus, setTitleStatus, titleStatusAnimation,
        idStatus, setIdStatus, idStatusAnimation,
        hostPanelStatus, setHostPanelStatus, hostPanelAnimation,
        opponentPanelStatus, setOpponentPanelStatus, opponentPanelAnimation,
        infoPanelStatus, setInfoPanelStatus, infoPanelAnimation,
        backStatus, setBackStatus, backStatusAnimation,
        alertAnimationStatus, setAlertAnimationStatus, alertAnimation,
        startStatus, setStartStatus, startStatusAnimation,
        TransitionOut, OpponentFound, OpponentLeaves
    };
};