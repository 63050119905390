import { useEffect } from 'react';
import { Colors, Pieces, Equipments, Sets } from '../utils/Enums'

const colors = [Colors.WHITE, Colors.BLACK];
const pieces = [Pieces.PAWN, Pieces.ROOK, Pieces.KNIGHT, Pieces.BISHOP, Pieces.QUEEN, Pieces.KING];
const guns = [Equipments.PISTOL, Equipments.SHOTGUN, Equipments.RIFLE, Equipments.SNIPER]
const completedSets = [Sets.DEFAULT, Sets.MAFIA, Sets.CLASSIC, Sets.ROUGE, Sets.LAW, Sets.LAW_PRESTIGE]

const images: Record<string, string> = {};

// Assuming Sets and Pieces are enums or objects that define your sets and pieces
completedSets.forEach(set => {
    colors.forEach(color => {
        pieces.forEach(piece => {
            const front_path = `${color}_${piece}_${set}`
            try {
                images[front_path] = require(`./pieces/${set}/${color}_${piece}_${set}.svg`);
            } catch (error) {
                console.log(`failed to load ${front_path}, resetting to default`);
                images[front_path] = require(`./pieces/${Sets.DEFAULT}/${color}_${piece}_${set}.svg`);
            }
            const back_path = `${color}_${piece}_${set}_back`
            try {
                images[back_path] = require(`./pieces/${set}/${color}_${piece}_${set}_back.svg`);
            } catch (error) {
                images[back_path] = images[front_path]
            }
        });
    });
});

// load all the equipements
completedSets.forEach(set => {
    guns.forEach(gun => {
        try {
            images[`${gun}_${set}_active`] = require(`./equipments/active/${gun}_${set}_active.svg`);
        } catch (error) {}
        try {
            images[`${gun}_${set}_icon`] = require(`./equipments/icons/${gun}_${set}_icon.svg`);
        } catch (error) {}
        try {
            images[`${gun}_${set}_idle`] = require(`./equipments/idle/${gun}_${set}_idle.svg`);
        } catch (error) {}
    });
    try {
        images[`knife_${set}_icon`] = require(`./equipments/icons/knife_${set}_icon.svg`);
    } catch (error) {}
    try {
        images[`shield_${set}_icon`] = require(`./equipments/icons/shield_${set}_icon.svg`);
    } catch (error) {}
    try {
        images[`shield_${set}_idle`] = require(`./equipments/idle/shield_${set}_idle.svg`);
    } catch (error) {}
})

// manually added images
images['bottom_shadow'] = require(`./utils/bottom_shadow.svg`).default;
images['surrender'] = require('./utils/surrender.svg').default;
images['predict'] = require('./utils/predict.png');
images['defaultIcon'] = require('./avatars/default_icon.png');
images['knugget'] = require('./utils/knugget.svg').default;
images['eye_open'] = require('./utils/eye_open.svg').default
images['eye_close'] = require('./utils/eye_close.svg').default
images['copy_ui'] = require('./utils/copy_ui.svg').default
images['settings_ui'] = require('./utils/settings.svg').default

// load character avatars
images['Rachel idle'] = require('./avatars/Rachel/no eyes.svg').default
images['Rachel eyeballs'] = require(`./avatars/Rachel/eyes.svg`).default
images['Rachel blink'] = require('./avatars/Rachel/eyes closed.svg').default
images['Rachel greet1'] = require('./avatars/Rachel/wave1.svg').default
images['Rachel greet2'] = require('./avatars/Rachel/wave2.svg').default
images['Rachel cry'] = require(`./avatars/Rachel/cry.svg`).default
images['Rachel cheer'] = require('./avatars/Rachel/cheer.svg').default
images['Rachel shock'] = require('./avatars/Rachel/shock.svg').default

images['Aaron idle'] = require('./avatars/Aaron/idle.svg').default
images['Aaron eyeballs'] = require(`./avatars/Aaron/eyeballs.svg`).default
images['Aaron blink'] = require('./avatars/Aaron/blink.svg').default
images['Aaron greet1'] = require('./avatars/Aaron/wave1.svg').default
images['Aaron greet2'] = require('./avatars/Aaron/wave2.svg').default
images['Aaron cry'] = require(`./avatars/Aaron/cry.svg`).default
images['Aaron cheer'] = require('./avatars/Aaron/cheer.svg').default
images['Aaron shock'] = require('./avatars/Aaron/shock.svg').default

images['Jasmine idle'] = require('./avatars/Jasmine/jasmine idle.svg').default
images['Jasmine eyeballs'] = require(`./avatars/Jasmine/jasmine idle eyeballs.svg`).default
images['Jasmine blink'] = require('./avatars/Jasmine/jasmine idle eye close.svg').default
images['Jasmine greet'] = require('./avatars/Jasmine/jasmine greet.svg').default
images['Jasmine angry'] = require(`./avatars/Jasmine/jasmine angry.svg`).default
images['Jasmine humiliate'] = require('./avatars/Jasmine/jasmine humiliate.svg').default
images['Jasmine trigger'] = require('./avatars/Jasmine/jasmine trigger.svg').default
images['Jasmine trip'] = require('./avatars/Jasmine/jasmine trip.svg').default

images['Chad idle'] = require('./avatars/Chad/chadidle.svg').default
images['Chad flirt'] = require('./avatars/Chad/chadflirt.svg').default
images['Chad shocked'] = require('./avatars/Chad/chadshocked.svg').default
images['Chad wave'] = require('./avatars/Chad/chadwave.svg').default
images['Chad flex1'] = require('./avatars/Chad/chadflex1.svg').default
images['Chad flex2'] = require('./avatars/Chad/chadflex2.svg').default
images['Chad cry'] = require('./avatars/Chad/chadcry.svg').default

images['MrsK idle'] = require('./avatars/MrsK/mrsk idle.svg').default
images['MrsK eyeballs'] = require('./avatars/MrsK/mrsk idle eyeballs.svg').default
images['MrsK blink'] = require('./avatars/MrsK/mrsk idle eyes closed.svg').default
images['MrsK greet1'] = require('./avatars/MrsK/mrsk greet1.svg').default
images['MrsK greet2'] = require('./avatars/MrsK/mrsk greet2.svg').default
images['MrsK shiver1'] = require('./avatars/MrsK/mrsk shiver1.svg').default
images['MrsK shiver2'] = require('./avatars/MrsK/mrsk shiver2.svg').default
images['MrsK breakdown1'] = require('./avatars/MrsK/mrsk breakdown1.svg').default
images['MrsK breakdown2'] = require('./avatars/MrsK/mrsk breakdown2.svg').default
images['MrsK snarl'] = require('./avatars/MrsK/mrsk snarl.svg').default
images['MrsK excited'] = require('./avatars/MrsK/mrsk excited.svg').default

images['Jeffrey idle'] = require('./avatars/Jeffrey/jeffreyidle.svg').default
images['Jeffrey eyeballs'] = require('./avatars/Jeffrey/jeffreyidleeyeballs.svg').default
images['Jeffrey blink'] = require('./avatars/Jeffrey/jeffreyidleeyesclosed.svg').default
images['Jeffrey greet1'] = require('./avatars/Jeffrey/jeffreygreet1.svg').default
images['Jeffrey greet2'] = require('./avatars/Jeffrey/jeffreygreet2.svg').default
images['Jeffrey jump1'] = require('./avatars/Jeffrey/jeffreyjump1.svg').default
images['Jeffrey jump2'] = require('./avatars/Jeffrey/jeffreyjump2.svg').default
images['Jeffrey shock'] = require('./avatars/Jeffrey/jeffreyshock.svg').default
images['Jeffrey angry'] = require('./avatars/Jeffrey/jeffreyangry.svg').default
images['Jeffrey smacked'] = require('./avatars/Jeffrey/jeffreysmacked.svg').default

images['Grandma idle'] = require('./avatars/Grandma/grandma idle.svg').default
images['Grandma greet1'] = require('./avatars/Grandma/grandma greet1.svg').default
images['Grandma greet2'] = require('./avatars/Grandma/grandma greet2.svg').default
images['Grandma confused1'] = require('./avatars/Grandma/grandma confused1.svg').default
images['Grandma confused2'] = require('./avatars/Grandma/grandma confused2.svg').default
images['Grandma cookies'] = require('./avatars/Grandma/grandma cookies.svg').default
images['Grandma faint'] = require('./avatars/Grandma/grandma faint.svg').default
images['Grandma argue'] = require('./avatars/Grandma/grandma argue.svg').default

images['Dummy idle'] = require('./avatars/Dummy/dummy idle.svg').default

images['Falcon idle'] = require('./avatars/Falcon/falcon idle.svg').default
images['Falcon greet'] = require('./avatars/Falcon/falcon greet.svg').default
images['Falcon react'] = require('./avatars/Falcon/falcon react.svg').default
images['Falcon react2'] = require('./avatars/Falcon/falcon react2.svg').default
images['Falcon report'] = require('./avatars/Falcon/falcon report.svg').default
images['Falcon wanted'] = require('./avatars/Falcon/falcon wanted.svg').default
images['Falcon failed'] = require('./avatars/Falcon/falcon job failed.svg').default

images['KrayZ idle'] = require('./avatars/KrayZ/krayz idle.svg').default
images['KrayZ greet1'] = require('./avatars/KrayZ/krayz greet1.svg').default
images['KrayZ greet2'] = require('./avatars/KrayZ/krayz greet2.svg').default
images['KrayZ dance1'] = require('./avatars/KrayZ/krayz dance1.svg').default
images['KrayZ dance2'] = require('./avatars/KrayZ/krayz dance2.svg').default
images['KrayZ flex'] = require('./avatars/KrayZ/krayz flex.svg').default
images['KrayZ sing'] = require('./avatars/KrayZ/krayz sing.svg').default
images['KrayZ wow1'] = require('./avatars/KrayZ/krayz wow1.svg').default
images['KrayZ wow2'] = require('./avatars/KrayZ/krayz wow2.svg').default
images['KrayZ wow3'] = require('./avatars/KrayZ/krayz wow3.svg').default
images['KrayZ wow4'] = require('./avatars/KrayZ/krayz wow4.svg').default
images['KrayZ wow5'] = require('./avatars/KrayZ/krayz wow5.svg').default
images['KrayZ wow6'] = require('./avatars/KrayZ/krayz wow6.svg').default

images['Unknown idle'] = require('./avatars/Unknown/unknown idle.svg').default
images['Unknown greet'] = require('./avatars/Unknown/unknown greet.svg').default
images['Unknown glitch1'] = require('./avatars/Unknown/unknown glitch1.svg').default
images['Unknown glitch2'] = require('./avatars/Unknown/unknown glitch2.svg').default
images['Unknown smile'] = require('./avatars/Unknown/unknown smile.svg').default
images['Unknown laugh'] = require('./avatars/Unknown/unknown laugh.svg').default
images['Unknown question1'] = require('./avatars/Unknown/unknown question1.svg').default
images['Unknown question2'] = require('./avatars/Unknown/unknown question2.svg').default
images['Unknown question3'] = require('./avatars/Unknown/unknown question3.svg').default
images['Unknown question4'] = require('./avatars/Unknown/unknown question4.svg').default
images['Unknown question5'] = require('./avatars/Unknown/unknown question5.svg').default

images['wave'] = require('./utils/Wave_UI.svg').default

images['ball'] = require('./gatcha/ball.svg').default
images['balls1'] = require('./gatcha/balls1.svg').default
images['balls2'] = require('./gatcha/balls2.svg').default
images['balls3'] = require('./gatcha/balls3.svg').default
images['balls4'] = require('./gatcha/balls4.svg').default
images['ball_top'] = require('./gatcha/ball_top.svg').default
images['ball_bottom'] = require('./gatcha/ball_bottom.svg').default
images['machine1'] = require('./gatcha/machine1.svg').default
images['machine2'] = require('./gatcha/machine2.svg').default

declare global {
    interface Window {
        usePreloadImagesData?: Record<string, unknown[]>;
    }
}

const imageSrcs = Object.values(images);
export const usePreloadImages = (): void => {
    useEffect(() => {
        const randomStr = Math.random().toString(32).slice(2) + Date.now();
        window.usePreloadImagesData = window.usePreloadImagesData ?? {};
        window.usePreloadImagesData[randomStr] = [];
        for (const src of imageSrcs) {
            // preload the image
            const img = new Image();
            img.src = src;
            // keep a reference to the image
            window.usePreloadImagesData[randomStr].push(img);
        }
        return () => {
            delete window.usePreloadImagesData?.[randomStr];
        };
    }, []);
};

export default images;