import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useAlertAnimation } from "./Animator";
import sounds from "../sounds";

interface Props {
    message: string;
    setMessage: (newMessage: string) => void;
    messageCount: number;
    triggerShake: () => void;
}

const AlertMessenger: React.FC<Props> = ({ message, setMessage, messageCount, triggerShake }) => {
    const [displayMessage, setDisplayMessage] = useState('');
    const [alertAnimationStatus, setAlertAnimationStatus] = useState<'initial' | 'enter' | 'exit'>('initial');
    const alertAnimation = useAlertAnimation();
    const messageRef = useRef<HTMLDivElement>(null);
    const [offset, setOffset] = useState('50%');
    const exitTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const isMessage = message.startsWith("message:");

    useEffect(() => {
        if (message === '') {
            return
        }
        setDisplayMessage('');
        if (!isMessage) triggerShake();
        setAlertAnimationStatus('enter');
        let index = 0;
        const typeWriter = setInterval(() => {
            if (index < message.length) {
                setDisplayMessage(message.substring(0, index + 1));
                index++;
            } else {
                clearInterval(typeWriter);
                // Clear any existing exit timeout to reset the timer
                if (exitTimeoutRef.current) {
                    clearTimeout(exitTimeoutRef.current);
                }
                exitTimeoutRef.current = setTimeout(() => {
                    setAlertAnimationStatus('exit');
                    // Optionally clear the message after the exit animation, based on your use case
                    setTimeout(() => {
                        setMessage('')
                        setDisplayMessage('');
                    }, alertAnimation.exit.transition.duration * 1000);
                }, 5000);  // Time shown before starting exit
            }
        }, 25);

        return () => {
            clearInterval(typeWriter);
            if (exitTimeoutRef.current) {
                clearTimeout(exitTimeoutRef.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, messageCount, isMessage]);

    useEffect(() => {
        if (messageRef.current) {
            const width = messageRef.current.offsetWidth;
            const newOffset = `calc(50% - ${width / 2}px)`;
            setOffset(newOffset);
        }
    }, [displayMessage]);

    return (
        <motion.div ref={messageRef} className='AlertMessage' style={{ left: offset, top: '2.5vh', position: 'absolute' }}
            initial='initial'
            animate={alertAnimationStatus}
            variants={alertAnimation}
            whileHover={{scale: alertAnimationStatus === 'exit' ? 1 : 1.1}}
            onMouseEnter={() => {
                if (alertAnimationStatus === 'exit') return
                sounds['hover'].play()
            }}
            onClick={() => {
                if (alertAnimationStatus === 'exit') return
                sounds['select'].play()
                setAlertAnimationStatus('exit');
                setTimeout(() => {
                    setMessage('')
                    setDisplayMessage('');
                }, alertAnimation.exit.transition.duration * 1000);
            }}>
            <motion.div className='NormalText'>
                {displayMessage}
            </motion.div>
        </motion.div>
    );
}

export default AlertMessenger;