import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Capsule from './Capsule';
import createFadeAnimation from '../../utils/Animator';
import { Reward } from './Gacha';
import { useExitTextAnimation } from './Animation';

interface CapsuleGeneratorProps {
    rewards: Reward[];
    setRewards: (rewards: Reward[]) => void;
    shake: () => void
    animate: any;
}

const CapsuleOpener: React.FC<CapsuleGeneratorProps> = ({ rewards, setRewards, shake, animate }) => {
    const [numOpened, setNumOpened] = useState<number>(0)
    const [numDropped, setNumDropped] = useState<number>(0)
    const [canFade, setCanFade] = useState<boolean>(false)
    const [fadeStatus, setFadeStatus] = useState<string>('initial')
    const fadeAnimation = createFadeAnimation()
    const {exitStatus, setExitStatus, exitStatusAnimation} = useExitTextAnimation()
    fadeAnimation.enter.opacity = 0.75

    useEffect(() => {
        console.log(rewards)
        if (rewards.length === 0) {
            setNumOpened(0)
            setNumDropped(0)
        }
        setFadeStatus('enter')
    }, [rewards])

    useEffect(() => {
        if (numOpened > 0 && numOpened === rewards.length) {
            setExitStatus('enter')
        }
    }, [numOpened, rewards.length, setExitStatus])

    return (
        <motion.div className='CapsuleOpenerWrapper' 
                style={{pointerEvents: rewards.length > 0 && numDropped > 0 ? 'auto' : 'none', textAlign: 'center'}} 
                onClick={()=>{
                    if (rewards.length > 0 && rewards.length === numOpened) {
                        setFadeStatus('exit')
                        setExitStatus('exit')
                    }
                }}>
            {   
                rewards.length > 0 && 
                <motion.div className='DarkBackground'
                    initial='initial'
                    animate={fadeStatus}
                    variants={fadeAnimation}
                    onAnimationStart={() => {
                        if (fadeStatus === 'exit') {
                            setCanFade(true)
                        }
                    }}
                    onAnimationComplete={() => {
                        if (fadeStatus === 'exit' && canFade) {
                            setFadeStatus('initial')
                            setRewards([])
                            setCanFade(false)
                        }
                    }}
                />
            }

            <motion.div className='NormalText' style={{position: 'absolute', color: '#D9D9D9', scale: 1.3, bottom: '6vh', width: '100%'}}
                initial='initial'
                animate={exitStatus}
                variants={exitStatusAnimation}
                onAnimationComplete={() => {
                    if (exitStatus === 'enter') {
                        setExitStatus('idle')
                    }
                }}>
                Tap to continue...
            </motion.div>

            <motion.div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw'}} animate={animate}>
            {
                rewards.length === 5 && 
                <Capsule
                    id={0}
                    reward={rewards[1]}
                    rewards={rewards}
                    updateNumOpened={() => {
                        setTimeout(() => {
                            setNumOpened(prevNumOpened => prevNumOpened + 1);
                        }, 750)  
                    }}
                    updateNumDropped={() => {setNumDropped(numDropped + 1)}}
                    canFade={canFade}
                    shake={shake}
                /> 
            }
            { 
                rewards.length === 5 && 
                <Capsule
                    id={1}
                    reward={rewards[2]}
                    rewards={rewards}
                    updateNumOpened={() => {
                        setTimeout(() => {
                            setNumOpened(prevNumOpened => prevNumOpened + 1);
                        }, 750)  
                    }}
                    updateNumDropped={() => {setNumDropped(numDropped + 1)}}
                    canFade={canFade}
                    shake={shake}
                />
            }
            {
                rewards.length > 0 && 
                <Capsule
                    id={2}
                    reward={rewards[0]}
                    rewards={rewards}
                    updateNumOpened={() => {
                        setTimeout(() => {
                            setNumOpened(prevNumOpened => prevNumOpened + 1);
                        }, 750)  
                    }}
                    updateNumDropped={() => {setNumDropped(numDropped + 1)}}
                    canFade={canFade}
                    shake={shake}
                />
            }
            {
                rewards.length === 5 && 
                <Capsule
                    id={3}
                    reward={rewards[3]}
                    rewards={rewards}
                    updateNumOpened={() => {
                        setTimeout(() => {
                            setNumOpened(prevNumOpened => prevNumOpened + 1);
                        }, 750)  
                    }}
                    updateNumDropped={() => {setNumDropped(numDropped + 1)}}
                    canFade={canFade}
                    shake={shake}
                /> 
            }
            { 
                rewards.length === 5 && 
                <Capsule
                    id={4}
                    reward={rewards[4]}
                    rewards={rewards}
                    updateNumOpened={() => {
                        setTimeout(() => {
                            setNumOpened(prevNumOpened => prevNumOpened + 1);
                        }, 750)  
                    }}
                    updateNumDropped={() => {setNumDropped(numDropped + 1)}}
                    canFade={canFade}
                    shake={shake}
                />
            }
            </motion.div>
        </motion.div>
    );
};

export default CapsuleOpener;