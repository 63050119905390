import { useState } from 'react';
import createFadeAnimation, { createDownAnimation, createFloatAnimation, createUpAnimation } from '../../utils/Animator';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [fadeStatus, setFadeStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");
    const [titleStatus, setTitleStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");
    const [emailStatus, setEmailStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");
    const [usernameStatus, setUsernameStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");
    const [passwordStatus, setPasswordStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");
    const [repasswordStatus, setRepasswordStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");
    const [termsStatus, setTermsStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");
    const [capchaStatus, setCapchaStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");
    const [registerStatus, setRegisterStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");
    const [backStatus, setBackStatus] = useState<"initial" | "exit" | "enter" | "idle">("enter");

    const fadeStatusAnimation = createFadeAnimation(0);
    const titleStatusAnimation = createUpAnimation(0.02);
    const emailStatusAnimation = createUpAnimation(0.04);
    const usernameStatusAnimation = createUpAnimation(0.06);
    const passwordStatusAnimation = createUpAnimation(0.08);
    const repasswordStatusAnimation = createUpAnimation(0.1);
    const termsStatusAnimation = createUpAnimation(0.1);
    const capchaStatusAnimation = createUpAnimation(0.125);
    const registerStatusAnimation = createUpAnimation(0.15);
    const backStatusAnimation = createDownAnimation(0);

    function TransitionOut() {
        setFadeStatus('exit')
        setTitleStatus('exit')
        setEmailStatus('exit')
        setUsernameStatus('exit')
        setPasswordStatus('exit')
        setRepasswordStatus('exit')
        setTermsStatus('exit')
        setCapchaStatus('exit')
        setRegisterStatus('exit')
        setBackStatus('exit')
    }

    titleStatusAnimation.idle = createFloatAnimation(0)
    emailStatusAnimation.idle = createFloatAnimation(0.2)
    usernameStatusAnimation.idle = createFloatAnimation(0.4)
    passwordStatusAnimation.idle = createFloatAnimation(0.6)
    repasswordStatusAnimation.idle = createFloatAnimation(0.8)
    termsStatusAnimation.idle = createFloatAnimation(1)
    capchaStatusAnimation.idle = createFloatAnimation(1.2)
    registerStatusAnimation.idle = createFloatAnimation(1.4)
    backStatusAnimation.idle = createFloatAnimation(0)

    return {
        fadeStatus, setFadeStatus, fadeStatusAnimation,
        titleStatus, setTitleStatus, titleStatusAnimation,
        emailStatus, setEmailStatus, emailStatusAnimation,
        usernameStatus, setUsernameStatus, usernameStatusAnimation,
        passwordStatus, setPasswordStatus, passwordStatusAnimation,
        repasswordStatus, setRepasswordStatus, repasswordStatusAnimation,
        termsStatus, setTermsStatus, termsStatusAnimation,
        capchaStatus, setCapchaStatus, capchaStatusAnimation,
        registerStatus, setRegisterStatus, registerStatusAnimation,
        backStatus, setBackStatus, backStatusAnimation,
        TransitionOut
    };
};