import { firestore, updateLayouts } from "./CreateFirebaseEngine";
import { doc, onSnapshot } from "firebase/firestore";

// TODO: Use builder pattern and move this constant to shared file
const USERS_PATH = "users";

class LayoutEditorEngine {
    #user_id;
    #unsub;
    constructor(user_id, update_layout) {
        this.#user_id = user_id;
        this.boundCleanup = this.cleanup.bind(this);
        window.addEventListener('globalCleanup', this.boundCleanup);

        this.#unsub = onSnapshot(doc(firestore, USERS_PATH + "/" + user_id + "/customization/layouts"), (doc) => {
            if (doc.exists) {
                update_layout(doc.data());
            }
        });
    }

    async saveLayouts(layouts, selectedLayout, layoutNames) {
        try {
            await updateLayouts({
                layouts: layouts,
                selectedLayout: selectedLayout,
                layoutNames: layoutNames
            })
        } catch (error) {
            switch (error.code) {
                case "functions/invalid-argument":
                    throw new Error("invalid argument");
                default:
                    throw new Error(error.code)
            }
        }
    }

    cleanup() {
        this.#unsub()
        this.#unsub = null
        window.removeEventListener('globalCleanup', this.boundCleanup);
    }

    destructor() {
        this.cleanup();
    }
}

export default LayoutEditorEngine;