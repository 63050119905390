import { firestore, updateSkinCollection } from "./CreateFirebaseEngine";
import { doc, onSnapshot } from "firebase/firestore";

class SkinCollectionEngine {
    #user_id;
    #unsub;
    constructor(user_id, update_skins) {
        this.#user_id = user_id;

        this.#unsub = onSnapshot(doc(firestore, "users/" + user_id + "/loot/skins"), (doc) => {
            if (doc.exists) {
                update_skins(doc.data());
            }
        });
    }

    async addSkinToCollection(skin){
        try {
            await updateSkinCollection({
                skin: skin,
            })
        } catch (error) {
            switch (error.code) {
                case "functions/invalid-argument":
                    throw new Error("invalid argument");
                default:
                    throw new Error("internal")
            }
        }
    }

    destructor() {
        this.#unsub();
    }
}

export default SkinCollectionEngine;