import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import * as ScreenAnimation from './Animation';

import '../../utils/Common.css';
import CwgButton from '../../utils/CwgButton';
import { getAuth, sendEmailVerification } from 'firebase/auth';

interface Props {
    setAlertMessage: (alert: string) => void;
    BackToLogin: () => void;
}

const Verification: React.FC<Props> = ({ setAlertMessage, BackToLogin }) => {
    const animation = ScreenAnimation.useAnimationStatus();
    const [processing, setProcessing] = useState<boolean>(false)
    const [cooldown, setCooldown] = useState<number>(0)

    useEffect(() => {
        if (cooldown === 0) {
            return
        }
        const exitTimer = setTimeout(() => {
            setCooldown(cooldown - 1)
            clearTimeout(exitTimer)
        }, 1000);
    }, [cooldown])

    function sendVerificationEmail() {
        const user = getAuth().currentUser;
        setProcessing(true)
      
        if (user) {
            sendEmailVerification(user)
            .then(() => {
                setProcessing(false)
                setCooldown(31)
            })
            .catch((error) => {
                setAlertMessage('Failed to send verification email.')
                setProcessing(false)
                setCooldown(0)
            });
        } else {
            setAlertMessage('Invalid credentials, unable to send verification email.')
            setProcessing(false)
            setCooldown(0)
        }
    }

    function ToLogin() {
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            BackToLogin()
            clearTimeout(exitTimer)
        }, 250);
    }

    const allowClick = !processing && cooldown === 0 && animation.titleStatus !== 'exit'

    return (
        <motion.div className = "Screen">
            <motion.div className = "HeadTitle" 
                        initial='initial' 
                        animate={animation.titleStatus} 
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') { animation.setTitleStatus('idle') }
                        }}>
                Verify Email
            </motion.div>
            <motion.div className = "NormalText" 
                        initial='initial' 
                        animate={animation.descriptionStatus} 
                        variants={animation.descriptionAnimation}
                        onAnimationComplete={()=>{
                            if (animation.descriptionStatus === 'enter') { animation.setDescriptionStatus('idle') }
                        }}>
                Email verification required. We use email for recovery purposes.
            </motion.div>

            <CwgButton 
                initial='initial' 
                animate={animation.joinButtonStatus} 
                variants={animation.joinButtonAnimation}
                setAnimation={animation.setJoinButtonStatus}
                hasIdle={true}
                style={{marginTop: '7.5vh'}}
                text={`Send Verification${cooldown > 0 ? ` (${cooldown - 1})` : ''}`} 
                recoverDuration={2000}
                updateOnChange={false}
                onClick={() => { 
                    sendVerificationEmail()
                }} 
                allowClick={allowClick}/>

            <CwgButton 
                initial='initial' 
                animate={animation.backStatus} 
                variants={animation.backStatusAnimation}
                setAnimation={animation.setBackStatus}
                hasIdle={true}
                text={'Login'} 
                recoverDuration={1000}
                onClick={() => { 
                    ToLogin()
                }} 
                allowClick={true}
                isBack={true}/>
        </motion.div>
    );
}

export default Verification;