import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Game.css';
import GameEngine from '../../backend/GameEngine';
import { useUIOverlayAnimationStatus } from './Animation';
import sounds from '../../sounds';
import { Colors } from '../../utils/Enums';

interface Props {
    overlayUI: 'none' | 'surrender' | 'draw' | 'settings';
    setOverlayUI: (newOverlay: 'none' | 'surrender' | 'draw') => void;
    gameEngine: GameEngine | null;
    changeMode: () => void;
    setGameOverStatus: (status: {winner: Colors, condition: string}) => void;
}

const Settings: React.FC<Props> = ({ overlayUI, setOverlayUI, gameEngine, changeMode, setGameOverStatus }) => {
    const [active, setActive] = useState<boolean>(false)
    const [canInteract, setCanInteract] = useState<boolean>(false)
    const animation = useUIOverlayAnimationStatus()

    const [volume, setVolume] = useState<number>(1); // Initial volume (0.0 to 1.0)

    const handleVolumeChange = (event: any) => {
        const newVolume = parseFloat(event.target.value);
        setVolume(newVolume);
        Howler.volume(newVolume); // Sets the global volume
    };

    useEffect(() => {
        if (overlayUI === 'settings' && !active) {
            animation.setPanelStatus('enter')
            animation.setBackgroundStatus('enter')
            setActive(true)
            setCanInteract(true)
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overlayUI, active])

    useEffect(() => {
        animation.setPanelStatus('enter')
        animation.setBackgroundStatus('enter')
        setActive(true)
        setCanInteract(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <motion.div className="DarkBackground"
                        style={{pointerEvents: 'none'}}
                        initial='initial' 
                        animate={animation.backgroundStatus} 
                        variants={animation.backgroundStatusAnimation}/>
            <motion.div className='Settings'
                        initial='initial' 
                        animate={animation.panelStatus} 
                        variants={animation.panelStatusAnimation}>
                <motion.div className='InfoText' style={{marginTop: '4vh'}}>
                    Master Volume
                </motion.div>
                <motion.input className='VolumnSlider'
                    type="range" 
                    min="0" 
                    max="1" 
                    step="0.01" 
                    value={volume} 
                    onChange={handleVolumeChange} 
                />
                <motion.div className="GameOverButton" 
                        style={{marginTop: '2.5vh', marginBottom: '5vh'}}
                        onClick={()=>{
                            if (!canInteract) return
                            sounds['select'].play()
                            animation.TransitionOut()
                            setCanInteract(false)
                            const timer = setTimeout(() => {
                                if (gameEngine) {
                                    gameEngine.surrenderBackend()
                                }
                                setGameOverStatus({winner: Colors.NONE, condition: 'quit'})
                                setActive(false)
                                setOverlayUI('none')
                                changeMode()
                                return () => {
                                    clearTimeout(timer)
                                };
                            }, 200);
                        }}
                        onMouseEnter={()=>{
                            if (!canInteract) return
                            sounds['hover'].play()
                        }}
                        whileHover={{ scale: 1.1 }}> 
                    Quit Game
                </motion.div>
                <motion.div className="GameOverButton" 
                        onClick={()=>{
                            if (!canInteract) return
                            sounds['select'].play()
                            animation.TransitionOut()
                            setCanInteract(false)
                            const timer = setTimeout(() => {
                                setActive(false)
                                setOverlayUI('none')
                                return () => {
                                    clearTimeout(timer)
                                };
                            }, 200);
                        }}
                        onMouseEnter={()=>{
                            if (!canInteract) return
                            sounds['hover'].play()
                        }}
                        whileHover={{ scale: 1.1 }}> 
                    Close
                </motion.div>
            </motion.div>
            <motion.div className="BigTitle" 
                        style={{marginBottom: '31.5vh'}}
                        initial='initial' 
                        animate={animation.panelStatus} 
                        variants={animation.panelStatusAnimation}>
                Settings
            </motion.div>
        </>
    )
}

/*
Standard:
* master volumn
* mute piece hover sound
* disable particles
* disable screen shake
* quit
* will result in an immediate loss

Classic:
* master volumn
* auto end turn
* mute piece hover sound
* disable particles
* disable screen shake
* quit
* will result in an immediate loss

Bot:
* master volumn
* mute piece hover sound
* disable particles
* disable screen shake
* exit

Dummy:
* master volumn
* mute piece hover sound
* disable particles
* disable screen shake
* exit
*/

export default Settings;