import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import * as ScreenAnimation from './Animation';

import '../../utils/Common.css';
import CwgButton from '../../utils/CwgButton';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import sounds from '../../sounds';

interface Props {
    setAlertMessage: (alert: string) => void;
    BackToLogin: () => void;
}

const Recover: React.FC<Props> = ({ setAlertMessage, BackToLogin }) => {
    const animation = ScreenAnimation.useAnimationStatus();
    const [email, setEmail] = useState<string>('')
    const [processing, setProcessing] = useState<boolean>(false)
    const [cooldown, setCooldown] = useState<number>(0)

    useEffect(() => {
        if (cooldown === 0) {
            return
        }
        const exitTimer = setTimeout(() => {
            setCooldown(cooldown - 1)
            clearTimeout(exitTimer)
        }, 1000);
    }, [cooldown])

    function sendPasswordReset(email: string) {
        setProcessing(true)
        
        sendPasswordResetEmail(getAuth(), email)
        .then(() => {
            setProcessing(false)
            setCooldown(31)
        })
        .catch((error) => {
            setAlertMessage('Failed to send reset instructions. Please double check email.')
            setProcessing(false)
            setCooldown(0)
        });
    }

    function ToLogin() {
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            BackToLogin()
            clearTimeout(exitTimer)
        }, 250);
    }

    const allowClick = !processing && cooldown === 0 && animation.titleStatus !== 'exit'

    return (
        <motion.div className = "Screen">
            <motion.div className = "HeadTitle" 
                        initial='initial' 
                        animate={animation.titleStatus} 
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') { animation.setTitleStatus('idle') }
                        }}>
                Password Recovery
            </motion.div>
            <motion.div className = "NormalText" 
                        initial='initial' 
                        animate={animation.descriptionStatus} 
                        variants={animation.descriptionAnimation}
                        onAnimationComplete={()=>{
                            if (animation.descriptionStatus === 'enter') { animation.setDescriptionStatus('idle') }
                        }}>
                Please follow the password reset instruction sent to your email.
            </motion.div>

            <form autoComplete="off">
            <motion.input className='InputField' 
                initial='initial' 
                style={{marginTop: '2.25vh'}}
                animate={animation.inputStatus} 
                variants={animation.InputStatusAnimation}
                onAnimationComplete={()=>{
                    if (animation.inputStatus === 'enter') { animation.setInputStatus('idle') }
                }}
                spellCheck={false} 
                draggable={false} 
                whileHover={{ scale: !allowClick ? 1 : 1.05 }} 
                type={"text"}
                onChange={e => {
                    if (!allowClick) return
                    setEmail(e.currentTarget.value)
                }} 
                placeholder={"Email"} 
                onMouseEnter={()=>{
                    if (!allowClick) return
                    sounds['hover'].play()}
                }
                disabled={!allowClick}
                required/>
            </form>

            <CwgButton 
                initial='initial' 
                animate={animation.joinButtonStatus} 
                variants={animation.joinButtonAnimation}
                setAnimation={animation.setJoinButtonStatus}
                hasIdle={true}
                style={{marginTop: '7.5vh'}}
                text={`Send Instruction${cooldown > 0 ? ` (${cooldown - 1})` : ''}`} 
                recoverDuration={2000}
                updateOnChange={false}
                onClick={() => { 
                    sendPasswordReset(email)
                }} 
                allowClick={allowClick && email !== ''}/>

            <CwgButton 
                initial='initial' 
                animate={animation.backStatus} 
                variants={animation.backStatusAnimation}
                setAnimation={animation.setBackStatus}
                hasIdle={true}
                text={'Login'} 
                recoverDuration={1000}
                onClick={() => { 
                    ToLogin()
                }} 
                allowClick={true}
                isBack={true}/>
        </motion.div>
    );
}

export default Recover;