import { useState } from 'react';
import createFadeAnimation, { createDownAnimation, createFloatAnimation, createFloatAnimationNoOpacity, createUpAnimation, createUpAnimationNoOpacity } from '../../utils/Animator';

// adjust register screen animations in this file
export const useAnimationStatus = () => {
    const [fadeStatus, setFadeStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [titleStatus, setTitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [subtitleStatus, setSubtitleStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [profileStatus, setProfileStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [startButtonStatus, setStartButtonStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [gachaButtonStatus, setGachaButtonStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [customizeButtonStatus, setCustomizeButtonStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [logoutButtonStatus, setLogoutButtonStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');
    const [volumeStatus, setVolumeStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('enter');

    const [selectorStatus, setSelectorStatus] = useState<'initial' | 'enter' | 'exit' | 'idle'>('initial');

    const fadeStatusAnimation = createFadeAnimation(0)
    const titleStatusAnimation = createUpAnimation(0)
    const subtitleStatusAnimation = createUpAnimation(0.05)
    subtitleStatusAnimation.idle = {
        rotate: [0, 2, -2, 2, -2, 0],
        x: 0,
        opacity: 1, 
        transition: {
            duration: 2,     
            ease: "easeInOut", 
            repeat: Infinity,
            repeatDelay: 0.5  
        }
    };
    const profileStatusAnimation = createUpAnimation(0.10)
    const startButtonStatusAnimation = createUpAnimation(0.15)
    const customizeButtonStatusAnimation = createUpAnimation(0.20)
    const gachaButtonStatusAnimation = createUpAnimation(0.25)
    const logoutButtonStatusAnimation = createDownAnimation(0)
    const volumeAnimation = createUpAnimationNoOpacity(0)

    const selectorAnimation = createUpAnimation(0.1)

    titleStatusAnimation.idle = createFloatAnimation(0)
    subtitleStatusAnimation.idle = createFloatAnimation(0.2)
    profileStatusAnimation.idle = createFloatAnimation(0.4)
    startButtonStatusAnimation.idle = createFloatAnimation(0.5)
    customizeButtonStatusAnimation.idle = createFloatAnimation(0.6)
    gachaButtonStatusAnimation.idle = createFloatAnimation(0.7)
    logoutButtonStatusAnimation.idle = createFloatAnimation(1)

    selectorAnimation.idle = createFloatAnimation(1)
    volumeAnimation.idle = createFloatAnimationNoOpacity(0.1)

    function TransitionOut() {
        setFadeStatus('exit')
        setTitleStatus('exit')
        setSubtitleStatus('exit')
        setProfileStatus('exit')
        setStartButtonStatus('exit')
        setGachaButtonStatus('exit')
        setCustomizeButtonStatus('exit')
        setLogoutButtonStatus('exit')
        setVolumeStatus('exit')
    }

    return {
        fadeStatus, setFadeStatus, fadeStatusAnimation,
        titleStatus, setTitleStatus, titleStatusAnimation,
        subtitleStatus, setSubtitleStatus, subtitleStatusAnimation,
        profileStatus, setProfileStatus, profileStatusAnimation,
        startButtonStatus, setStartButtonStatus, startButtonStatusAnimation,
        gachaButtonStatus, setGachaButtonStatus, gachaButtonStatusAnimation,
        customizeButtonStatus, setCustomizeButtonStatus, customizeButtonStatusAnimation,
        logoutButtonStatus, setLogoutButtonStatus, logoutButtonStatusAnimation,
        selectorStatus, setSelectorStatus, selectorAnimation, 
        volumeStatus, setVolumeStatus, volumeAnimation,
        TransitionOut
    };
};