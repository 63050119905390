import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { registerUser } from "./CreateFirebaseEngine";

function CreateAuthEngine() {
    return {
        LogInWithEmailAndPassword: async (email, password) => {
            try {
                await setPersistence(getAuth(), browserSessionPersistence);
                const userCredential = await signInWithEmailAndPassword(getAuth(), email, password);
                return userCredential.user.uid;
            } catch (error) {
                throw error
            }
        },
        
        // registers the user, logs them in, and sends an email verification email.
        // If the registration succeeds but the login fails (for some reason),
        // the function asynchronously returns undefined.
        // We send the email verification email on the client side since the admin SDK
        // doesn't support it
        RegisterWithEmailPasswordAndUsername: async (email, password, username, subscribe, token) => {
            try {
                const result = await registerUser({
                    email: email,
                    password: password,
                    username: username,
                    subscribe: subscribe,
                    token: token,
                });
                if (result.data.success) {
                    await signInWithEmailAndPassword(getAuth(), email, password);
                    return "";
                } else {
                    return result.data.message;  
                }
            } catch (error) {
                return "An error occurred during registration. Please try again."; 
            }
        },
    };
}

export default CreateAuthEngine;