import { pull } from "./CreateFirebaseEngine";

class GachaEngine {
    #user_id;
    #unsub;

    constructor(user_id) {
        this.#user_id = user_id;
    }

    async getPull(bannerType, amount, setRewards) {
        await pull({
            bannerType: bannerType,
            amount: amount,
        }).then((result) => {
            setRewards(result.data.data.rewards)
        }).catch((error) => {
            console.error('Error:', error); 
        });
    }

    destructor() {}
}

export default GachaEngine;