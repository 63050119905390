import React from 'react';
import { motion } from 'framer-motion';

import images from '../../images';
import './Game.css';

import { Colors } from '../../utils/Enums'
import skins from '../../utils/SkinMapper'

interface Props {
    skinName: string; 
    color: Colors;
    canMovePos: boolean; 
    onClick: () => void; 
    onHover: () => void; 
}

const Cell: React.FC<Props> = ({ skinName, color, canMovePos, onClick, onHover }) => {
    let cellStyle = { backgroundColor: color === Colors.WHITE ? '#D9D9D9' : '#CBCBCB'}
    
    // if we want cell hovering
    // let cellStyle = { backgroundColor: color === Colors.WHITE ? '#D9D9D9' : '#CBCBCB', 
    //     outline: canHover && pos === hoveredPos ? '3px solid #BFBFBF' : '', 
    //     borderRadius: canHover && pos === hoveredPos ? '1vh' : '0',
    //     zIndex: canHover && pos === hoveredPos ? 1 : 0 }

    return (
        <motion.div className="Cell" style={cellStyle} onClick = { onClick } onMouseEnter = { onHover }>
            {skinName !== 'Default Tile' && (
                <img className='CellSkin' 
                    src={color === Colors.WHITE ? skins[skinName].whiteImage : skins[skinName].blackImage} 
                    alt='cell skin' draggable="false"/>
            )}
            <img className = "CanMove" src={images.predict} alt='hint' style={{ opacity: canMovePos ? 1 : 0 }}/>
        </motion.div>
    )
}

export default Cell;