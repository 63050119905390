import { firestore, queueForMatch, cancelQueue, readyInQueue } from "./CreateFirebaseEngine";
import { doc, onSnapshot } from "firebase/firestore";

class MatchMakingEngine {
    #user_id;
    #unsub;
    #gamemode;
    #isCustom;
    inQueue;
    setMatchId;

    constructor(user_id, gamemode, setOpponent, setOpponentUsername, setOpponentCharacter, setReady, setOpponentReady, setMatchId, setLastQueueUpdateTime) {
        this.#user_id = user_id;
        this.#gamemode = gamemode;
        this.setMatchId = setMatchId;
        this.inQueue = false;

        this.#unsub = onSnapshot(doc(firestore, "users/" + this.#user_id), (doc) => {
            if (!this.inQueue) return
            if (doc.exists) {
                const data = doc.data();
                const status = data['status']
                const matchMaking = data['matchMaking']
                const matchId = matchMaking['matchId']
                const opponentId = matchMaking['opponentId']
                const ready = matchMaking['ready']
                const opponentUsername = matchMaking['opponentUsername']
                const opponentCharacter = matchMaking['opponentAvatar']
                const opponentReady = matchMaking['opponentReady']
                
                if (status === 'game') {
                    setMatchId(matchId)
                } 
                if (opponentId === '') {
                    setOpponent('')
                    setReady(false)
                    setOpponentReady(false)
                } else {
                    setOpponent(opponentId)
                    setOpponentUsername(opponentUsername)
                    setOpponentCharacter(opponentCharacter)
                    setReady(ready)
                    setOpponentReady(opponentReady)
                    if (matchMaking['queueUpdateTime']) {
                        setLastQueueUpdateTime(matchMaking['queueUpdateTime'].toDate())
                    }
                }
            }
        });
    }

    async enterQueue() {
        if (this.#isCustom) return
        // we can overwrite the gamemode incase we want to switch mode
        try {
            await queueForMatch({
                gamemode: this.#gamemode,
            });
        } catch (error) {
            throw error
        }
    }

    async readyUp() {
        if (this.#isCustom) return
        try {
            await readyInQueue();
        } catch (error) {
            throw error
        }
    }

    async exitQueue() {
        try {
            await cancelQueue();
        } catch (error) {
            throw new Error(error.code)
        }
    }

    destructor() {
        this.#unsub();
    }
}

export default MatchMakingEngine;