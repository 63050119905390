import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import '../../utils/Common.css';
import * as ScreenAnimation from './Animation';
import sounds from '../../sounds';

import './Host.css';
import CharacterHandler from '../../utils/CharacterHandler';
import { getAuth } from 'firebase/auth';
import { Characters } from '../../utils/Enums';
import CwgButton from '../../utils/CwgButton';
import CustomLobbyEngine from '../../backend/CustomLobbyEngine';
import images from '../../images';
import ImageButton from '../../utils/ImageButton';

interface Props {
    setAlertMessage: (alert: string) => void;
    BackToCasual: () => void;
    StartGame: (matchId: string, mode: string) => void;
    myCharacter: Characters;
    isHost: boolean;
}

const Host: React.FC<Props> = ({ setAlertMessage, BackToCasual, myCharacter, isHost, StartGame }) => {
    const animation = ScreenAnimation.useAnimationStatus();

    const currentUser = getAuth().currentUser
    const [characterExpression, setCharacterExpression] = useState<string>('idle')
    const [opponentExpression, setOpponentExpression] = useState<string>('idle')
    const [opponent, setOpponent] = useState<string>('');
    const [opponentUsername, setOpponentUsername] = useState<string>('');
    const [opponentCharacter, setOpponentCharacter] = useState<Characters | ''>('');
    const [status, setStatus] = useState<string>('');
    const [matchId, setMatchId] = useState<string>('');
    const [idHidden, setIdHidden] = useState<boolean>(true);
    const [canHost, setCanHost] = useState<boolean>(isHost ? true : false); // make sure only allow one hosting per lobby

    const [selectedColor, setSelecatedColor] = useState<string>('random')
    const [enableEquipments, setEnableEquipments] = useState<string>('enable')
    const [previousSetting, setPreviousSetting] = useState<string[]>(['', '']);
    const canUpdate = previousSetting[0] !== selectedColor || previousSetting[1] !== enableEquipments
    
    const [ matchMakingEngine, setMatchMakingEngine ] = useState<CustomLobbyEngine | null>(null);

    const mounted = useRef<boolean>(false);

    useEffect(() => {
        if (status !== 'game' || canHost || enableEquipments === 'random') {
            return
        }
        const mode = enableEquipments === 'enable' ? 'standard' : 'classic'
        animation.TransitionOut()
        StartGame(matchId, mode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, canHost])

    function ToCasual() {
        mounted.current = false
        animation.TransitionOut()
        const exitTimer = setTimeout(() => {
            BackToCasual()
            clearTimeout(exitTimer)
        }, 550);
    }

    async function copyTextToClipboard(text: string): Promise<void> {
        try {
          await navigator.clipboard.writeText(text);
        } catch (err) {
            setAlertMessage("Failed to copy text to clipboard!");
        }
    }

    useEffect(() => {
        const initiateEngine = async () => {
            try {
                setMatchMakingEngine(new CustomLobbyEngine(
                    getAuth().currentUser?.uid, 
                    setOpponent, 
                    setOpponentUsername, 
                    setOpponentCharacter, 
                    setMatchId,
                    setEnableEquipments,
                    setSelecatedColor,
                    setStatus,
                    isHost
                ))
            } catch (error) {
                console.error("Error match making", error);
            }
        };

        initiateEngine();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

    useEffect(() => {
        if (mounted.current && matchMakingEngine) {
            matchMakingEngine.inQueue = true
        }
    }, [matchMakingEngine])

    useEffect(() => {
        const hostTimer = setTimeout(() => {
            animation.setHostPanelStatus('enter')
            clearTimeout(hostTimer)
        }, 450);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!mounted.current) {
            return
        }
        if (opponent === '') {
            if (!isHost) {
                BackToCasual()
                setAlertMessage("Host has left or you were kicked!");
            } else {
                animation.OpponentLeaves()
            }
        } else {
            animation.OpponentFound()
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [opponent, isHost])

    // update mount for initial render
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        } 
    }, [])

    return (
        <motion.div className = "Screen">
            <motion.div className = "HeadTitle" 
                        initial='initial' 
                        animate={animation.titleStatus} 
                        variants={animation.titleStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.titleStatus === 'enter') { animation.setTitleStatus('idle') }
                        }}>
                Custom Lobby
            </motion.div>

            <motion.div className = "NormalText" 
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '6vh'}}
                        initial='initial' 
                        animate={animation.idStatus} 
                        variants={animation.idStatusAnimation}
                        onAnimationComplete={()=>{
                            if (animation.idStatus === 'enter') { animation.setIdStatus('idle') }
                        }}>
                Lobby ID: {matchId === '' ? '???' : (idHidden ? '**********' : matchId)}
                {
                    matchId !== '' && isHost &&
                    <ImageButton onImg={images['eye_open']} offImg={images['eye_close']} recoverDuration={0}
                        style={{position: 'absolute', width: '5.5vh', height: '5.5vh', marginLeft: '42.5vh'}}
                        allowClick={true}
                        onCondition={!idHidden}
                        onClick={()=>{
                            setIdHidden(!idHidden)
                        }}/>
                }
                {
                    matchId !== '' && isHost &&
                    <ImageButton onImg={images['copy_ui']} recoverDuration={250}
                        style={{position: 'absolute', width: '5vh', height: '5vh', marginLeft: '55vh'}}
                        allowClick={true}
                        onClick={()=>{
                            copyTextToClipboard(matchId)
                        }}/>
                }
            </motion.div>

            <motion.div className='PanelContainer'>
                <motion.div className='HostPanel'
                        initial='initial' 
                        animate={animation.hostPanelStatus} 
                        variants={animation.hostPanelAnimation}
                        onAnimationComplete={()=>{
                            if (animation.hostPanelStatus === 'enter') { animation.setHostPanelStatus('idle') }
                        }}>
                    {isHost &&
                        <motion.div className = "Profile">
                            <CharacterHandler name={myCharacter} expression={characterExpression} setExpression={setCharacterExpression} allowAnnoy={true} smallBorder={false}/>
                            <motion.div className = "LargeText" style={{marginTop: '0.5vh'}}>{currentUser ? currentUser.displayName : "??????????????"}</motion.div>
                        </motion.div>
                    }
                    {!isHost &&
                        <motion.div className = "Profile">
                            {opponentCharacter && 
                                <CharacterHandler name={opponentCharacter} expression={opponentExpression} 
                                    setExpression={setOpponentExpression} allowAnnoy={true} smallBorder={false}/>
                            }
                            {opponentUsername && 
                                <motion.div className = "LargeText" style={{marginTop: '0.5vh'}}>{currentUser ? opponentUsername : "??????????????"}</motion.div>
                            }
                        </motion.div>
                    }
                </motion.div>
                <motion.div className='InfoPanel'
                        initial='initial' 
                        animate={animation.infoPanelStatus} 
                        variants={animation.infoPanelAnimation}
                        onAnimationComplete={()=>{
                            if (animation.infoPanelStatus === 'enter') { animation.setInfoPanelStatus('idle') }
                        }}>
                    <motion.div style={{position: 'relative', display:'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', opacity: isHost ? '1' : '0.5'}}>
                    <motion.div className='LargeText'>
                        My Color
                    </motion.div>
                    <motion.div className='SelectionPanel' style={{marginBottom: '1vh'}}>
                        <motion.div className='SelectionCell' style={{marginBottom: '1vh'}}>
                            <motion.div className='NormalText' style={{marginBottom: '1vh'}}>
                                White
                            </motion.div>
                            <motion.div className='Circle' style={{backgroundColor: selectedColor === 'white' ? '#6B6B6B' : '#D9D9D9'}}
                                onClick={() => {
                                    if (selectedColor === 'white' || !isHost) return
                                    sounds['select'].play()
                                    setSelecatedColor('white')
                                }}
                                onMouseEnter={()=>{ 
                                    if (selectedColor === 'white' || !isHost) return 
                                    sounds['hover'].play()}}
                                whileHover={{ scale: selectedColor === 'white' || !isHost ? 1 : 1.15 }}/>
                        </motion.div>
                        <motion.div className='SelectionCell' style={{marginBottom: '1vh'}}>
                            <motion.div className='NormalText' style={{marginBottom: '1vh'}}>
                                Black
                            </motion.div>
                            <motion.div className='Circle' style={{backgroundColor: selectedColor === 'black' ? '#6B6B6B' : '#D9D9D9'}}
                                onClick={() => {
                                    if (selectedColor === 'black' || !isHost) return
                                    sounds['select'].play()
                                    setSelecatedColor('black')
                                }}
                                onMouseEnter={()=>{ 
                                    if (selectedColor === 'black' || !isHost) return 
                                    sounds['hover'].play()}}
                                whileHover={{ scale: selectedColor === 'black' || !isHost ? 1 : 1.15 }}/>
                        </motion.div>
                        <motion.div className='SelectionCell' style={{marginBottom: '1vh'}}>
                            <motion.div className='NormalText' style={{marginBottom: '1vh'}}>
                                Random
                            </motion.div>
                            <motion.div className='Circle' style={{backgroundColor: selectedColor === 'random' ? '#6B6B6B' : '#D9D9D9'}}
                                onClick={() => {
                                    if (selectedColor === 'random' || !isHost) return
                                    sounds['select'].play()
                                    setSelecatedColor('random')
                                }}
                                onMouseEnter={()=>{ 
                                    if (selectedColor === 'random' || !isHost) return 
                                    sounds['hover'].play()}}
                                whileHover={{ scale: selectedColor === 'random' || !isHost ? 1 : 1.15 }}/>
                        </motion.div>
                    </motion.div>
                    
                    <motion.div className='LargeText'>
                        Equipments
                    </motion.div>
                    <motion.div className='SelectionPanel' style={{marginBottom: '1vh'}}>
                        <motion.div className='SelectionCell' style={{marginBottom: '1vh'}}>
                            <motion.div className='NormalText' style={{marginBottom: '1vh'}}>
                                Enable
                            </motion.div>
                            <motion.div className='Circle' style={{backgroundColor: enableEquipments === 'enable' ? '#6B6B6B' : '#D9D9D9'}}
                                onClick={() => {
                                    if (enableEquipments === 'enable' || !isHost) return
                                    sounds['select'].play()
                                    setEnableEquipments('enable')
                                }}
                                onMouseEnter={()=>{ 
                                    if (enableEquipments === 'enable' || !isHost) return 
                                    sounds['hover'].play()}}
                                whileHover={{ scale: enableEquipments === 'enable' || !isHost ? 1 : 1.15 }}/>
                        </motion.div>
                        <motion.div className='SelectionCell' style={{marginBottom: '1vh'}}>
                            <motion.div className='NormalText' style={{marginBottom: '1vh'}}>
                                Disable
                            </motion.div>
                            <motion.div className='Circle' style={{backgroundColor: enableEquipments === 'disable' ? '#6B6B6B' : '#D9D9D9'}}
                                onClick={() => {
                                    if (enableEquipments === 'disable' || !isHost) return
                                    sounds['select'].play()
                                    setEnableEquipments('disable')
                                }}
                                onMouseEnter={()=>{ 
                                    if (enableEquipments === 'disable' || !isHost) return 
                                    sounds['hover'].play()}}
                                whileHover={{ scale: enableEquipments === 'disable' || !isHost ? 1 : 1.15 }}/>
                        </motion.div>
                        <motion.div className='SelectionCell' style={{marginBottom: '1vh'}}>
                            <motion.div className='NormalText' style={{marginBottom: '1vh'}}>
                                Random
                            </motion.div>
                            <motion.div className='Circle' style={{backgroundColor: enableEquipments === 'random' ? '#6B6B6B' : '#D9D9D9'}}
                                onClick={() => {
                                    if (enableEquipments === 'random' || !isHost) return
                                    sounds['select'].play()
                                    setEnableEquipments('random')
                                }}
                                onMouseEnter={()=>{ 
                                    if (enableEquipments === 'random' || !isHost) return 
                                    sounds['hover'].play()}}
                                whileHover={{ scale: enableEquipments === 'random' || !isHost ? 1 : 1.15 }}/>
                        </motion.div>
                    </motion.div>
                    </motion.div>

                    <CwgButton 
                        style={{marginTop: '4vh'}}
                        text={isHost ? (canHost ? 'Host' : 'Update') : 'Waiting for Host'} 
                        recoverDuration={3000}
                        onClick={() => { 
                            if (canHost) {
                                setCanHost(false)
                                matchMakingEngine?.hostLobby(selectedColor, enableEquipments)
                                .then(() => {
                                    setPreviousSetting([selectedColor, enableEquipments])
                                })
                                .catch(() => {
                                    setCanHost(true)
                                    setAlertMessage("Error hosting lobby! Try again in a few seconds");
                                });
                            } else if (canUpdate) {
                                matchMakingEngine?.updateLobby(selectedColor, enableEquipments)
                                .then(() => {
                                    setPreviousSetting([selectedColor, enableEquipments])
                                })
                                .catch(() => {
                                    setCanHost(true)
                                    setAlertMessage("Error updating lobby! Try again in a few seconds");
                                });
                            }
                        }} 
                        allowClick={isHost && (canHost || canUpdate)}/>
                </motion.div>
                <motion.div className='HostPanel' 
                        initial='initial' 
                        animate={animation.opponentPanelStatus} 
                        variants={animation.opponentPanelAnimation}
                        onAnimationComplete={()=>{
                            if (animation.opponentPanelStatus === 'enter') { animation.setOpponentPanelStatus('idle') }
                        }}>
                        {isHost &&
                            <motion.div className = "Profile">
                                {opponentCharacter && 
                                    <CharacterHandler name={opponentCharacter} expression={opponentExpression} 
                                        setExpression={setOpponentExpression} allowAnnoy={true} smallBorder={false}/>
                                }
                                {opponentUsername && 
                                    <motion.div className = "LargeText" style={{marginTop: '0.5vh'}}>{currentUser ? opponentUsername : "??????????????"}</motion.div>
                                }
                                <CwgButton 
                                    style={{position: 'absolute', marginTop: '47.5vh'}}
                                    text={'Kick'} 
                                    recoverDuration={3000}
                                    onClick={() => { 
                                        matchMakingEngine?.kickOpponent()
                                            .catch(() => {
                                                setCanHost(true)
                                                setAlertMessage("Error kicking opponent! Try again in a few seconds");
                                            });
                                    }} 
                                    allowClick={opponent !== ''}/>
                            </motion.div>
                        }
                        {!isHost &&
                            <motion.div className = "Profile">
                                <CharacterHandler name={myCharacter} expression={characterExpression} setExpression={setCharacterExpression} allowAnnoy={true} smallBorder={false}/>
                                <motion.div className = "LargeText" style={{marginTop: '0.5vh'}}>{currentUser ? currentUser.displayName : "??????????????"}</motion.div>
                            </motion.div>
                        }
                </motion.div>
            </motion.div>

            {isHost && 
                <CwgButton 
                    initial='initial' 
                    animate={animation.startStatus} 
                    variants={animation.startStatusAnimation}
                    setAnimation={animation.setStartStatus}
                    hasIdle={true}
                    style={{position: 'absolute', bottom: '10vh', fontSize: '5vh'}}
                    text={'Start'} 
                    recoverDuration={3000}
                    onClick={() => { 
                        matchMakingEngine?.startCustomMatch()
                            .catch(() => {
                                setAlertMessage("Unable to start match!");
                            });
                    }} 
                    allowClick={isHost && opponent !== ''}/>
            }

            <CwgButton 
                initial='initial' 
                animate={animation.backStatus} 
                variants={animation.backStatusAnimation}
                setAnimation={animation.setBackStatus}
                hasIdle={true}
                text={'Leave'} 
                recoverDuration={2000}
                onClick={() => { 
                    ToCasual() 
                    if (matchMakingEngine && !canHost) {
                        matchMakingEngine.exitQueue()
                        .catch(() => {});
                    }
                }} 
                allowClick={true}
                isBack={true}/>
        </motion.div>
    );
}

export default Host;