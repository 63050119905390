import React from 'react';
import { motion } from 'framer-motion';

import '../../utils/Common.css';
import './Mode.css';

interface Props {
    flip: boolean;
}

const CardBack: React.FC<Props> = ({ flip }) => {
    return (
        <motion.div
            initial={{ rotateY: 180 }}
            animate={{ rotateY: flip ? 180 : 0 }}
            transition={{ duration: 0.4 }}
            className="back"
        >
            <div className='CardBorder'/>
            <div className="ConfirmText">
                CONFIRM
            </div>
        </motion.div>
    );
}

export default CardBack