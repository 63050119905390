import { getAuth } from 'firebase/auth';
import { useState, useEffect } from 'react';

import GameEngine from '../../backend/GameEngine';
import { Characters, Colors, Phases } from '../../utils/Enums';
import { PieceInfo } from './Piece';
import { initializePieceCache, initializePieces, PieceCache } from './GameSetup'
import { getDefaultLoadout } from '../layout/LoadoutManager';
import { ShakeData } from './Game';
import LayoutEditorEngine from '../../backend/LayoutEditorEngine';
import { getValidatedSkinName } from '../../utils/SkinMapper';
import { CoordToPos, PosToCoord, isCheckmate, stabPiece, updateShiver } from './GameLogic';
import sounds from '../../sounds';

export interface Loadout {
    [key: string]: string;
}

type AnyObject = {
    [key: string]: any;
};

const randomDummyNames = [
    'Jef', 'Bil', 'Rik', 'Steev', 'Gorge', 'Temothy', 'Eddword', 'Alun', 'Shep', 'Json', 
    'Nicoal', 'Sindy', 'Mishell', 'Emmah', 'Ahmenda', 'Clothy',
]

export const useGameState = (matchId: string, gameMode: string, debug: boolean = false) => {
    const [ gameEngine, setGameEngine ] = useState<GameEngine | null>(null);

    // global states, when initialized is true, we no longer need to read base information
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ pieces, setPieces ] = useState<PieceInfo[]>(initializePieces());
    const [ pieceCache, setPieceCache ] = useState<PieceCache[]>(initializePieceCache())
    const [ turn, setTurn ] = useState<{ color: Colors, phase: Phases }>({color: Colors.WHITE, phase: Phases.MOVE});
    const [ lastUpdateTime, setLastUpdateTime ] = useState<Date | undefined>(undefined)

    // my states
    const [ myUsername, setMyUsername ] = useState<string>('Unknown')
    const [ myCredits, setMyCredits ] = useState<number>(0)
    const [ myColor, setMyColor ] = useState<Colors>(Colors.WHITE)
    const [ myLayout, setMyLayout ] = useState<Loadout>({})
    const [ myIncome, setMyIncome ] = useState<number>(0)
    const [ hoveredPos, setHoveredPos ] = useState<string>('')
    const [ selectedPos, setSelectedPos ] = useState<string>('')

    // opponent states
    const [ opponentUsername, setOpponentUsername ] = useState<string>('Unknown')
    const [ opponentCredits, setOpponentCredits ] = useState<number>(0)
    const [ opponentLayout, setOpponentLayout ] = useState<Loadout>({})

    // screen shakes
    const [ boardShakeInfo, setBoardShakeInfo ] = useState<ShakeData>({angle: 0, force: 0})
    const [ screenShakeInfo, setScreenShakeInfo ] = useState<ShakeData>({angle: 0, force: 0})
    const [ shootAngle, setShootAngle ] = useState<number>(0);
    const [ opponentShootAngle, setOpponentShootAngle ] = useState<number>(0);
    const [ opponentCharacter, setOpponentCharacter ] = useState<Characters>(Characters.AARON);

    // speech
    const [myDrawRequest, setMyDrawRequest] = useState<boolean>(false);
    const [opponentDrawRequest, setOpponentDrawRequest] = useState<boolean>(false);
    const [mySpeech, setMySpeech] = useState<string>('');
    const [opponentSpeech, setOpponentSpeech] = useState<string>('');
    const [opponentExpression, setOpponentExpression] = useState<string>('idle')

    // game over string for indicating the game over condition
    const [gameOverStatus, setGameOverStatus] = useState<{winner: Colors, condition: string}>({winner: Colors.WHITE, condition: ''});
    // bot
    const isBotMode = gameMode.includes('bot')
    const isClassicMode = gameMode.includes('classic')
    // used for incrementing the cwg agent turn steps, so that useEffect can retrigger
    const [updateCount, setUpdateCount] = useState(0);

    useEffect(() => {
        const fetchDummyState = async () => {
            setPieces(initializePieces())
            setMyLayout(getDefaultLoadout())
            setOpponentLayout(getDefaultLoadout())
            setGameEngine(null)

            setOpponentUsername(`Dummy ${randomDummyNames[Math.floor(Math.random() * randomDummyNames.length)]}`)
            setOpponentCredits(0)

            // starting amount
            setMyIncome(9999)

            setIsLoading(true);
            try {
                // initializing the skin collection engine
                new LayoutEditorEngine(getAuth().currentUser?.uid, (layouts: any) => {
                    const fetchedLayout = layouts[`layout${layouts['selectedLayout']}`];
                    // client side validation for each skin piece to make sure that they exist as an actual skin
                    for (let pieceAlias in fetchedLayout) {
                        fetchedLayout[pieceAlias] = getValidatedSkinName(pieceAlias, fetchedLayout[pieceAlias])
                    }
                    setMyLayout(fetchedLayout);
                    // setMyLayoutName(layouts['layoutNames'][layouts['selectedLayout'] - 1])
                    if (getAuth().currentUser) {
                        setMyUsername(getAuth().currentUser?.displayName + '')
                    } else {
                        setMyUsername('You')
                    }
                })
            } catch (error) {
                console.error("Error fetching layout", error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchGameState = async (gameMode: string) => {
            setIsLoading(true);
            try {
                const isBot = gameMode.includes('bot')
                const isClassic = gameMode.includes('classic')
                if (isBot) {
                    setOpponentLayout(getDefaultLoadout())
                    setPieceCache(initializePieceCache())
                    setPieces(initializePieces())
                    setMyLayout(getDefaultLoadout())
                    // setMyColor(bot_mode_player_color)
                    setTurn({color: Colors.WHITE, phase: Phases.MOVE})
                    setOpponentUsername(isClassic ? 'Well Trained Monkey' : 'Maniac Monkey')
                    new LayoutEditorEngine(getAuth().currentUser?.uid, (layouts: any) => {
                        const fetchedLayout = layouts[`layout${layouts['selectedLayout']}`];
                        // client side validation for each skin piece to make sure that they exist as an actual skin
                        for (let pieceAlias in fetchedLayout) {
                            fetchedLayout[pieceAlias] = getValidatedSkinName(pieceAlias, fetchedLayout[pieceAlias])
                        }
                        setMyLayout(fetchedLayout);
                        // setMyLayoutName(layouts['layoutNames'][layouts['selectedLayout'] - 1])
                        if (getAuth().currentUser) {
                            setMyUsername(getAuth().currentUser?.displayName + '')
                        } else {
                            setMyUsername('You')
                        }
                    })
                }
                // initializing the skin collection engine
                setGameEngine(new GameEngine(
                    getAuth().currentUser?.uid, matchId, pieces, setPieces, setTurn, setMyColor, 
                    setMyUsername, setMyCredits, setMyLayout, setMyIncome,
                    setOpponentUsername, setOpponentCredits, setOpponentLayout,
                    pieceCache, setPieceCache, setBoardShakeInfo, setScreenShakeInfo, 
                    setOpponentShootAngle, setSelectedPos, gameOverStatus, setGameOverStatus,
                    setOpponentDrawRequest, setOpponentSpeech, opponentCharacter, setOpponentCharacter, 
                    setOpponentExpression, setLastUpdateTime, 
                    gameMode, Colors.WHITE)
                );
            } catch (error) {
                console.error("Error fetching game state", error);
            } finally {
                setIsLoading(false);
            }
        };
        
        if (debug) {
            fetchDummyState()
        } else {
            fetchGameState(gameMode)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []); 

    const updatePieces = (newPieces: PieceInfo[]) => {
        setPieces(newPieces);
        // Increment update count to trigger useEffect
        setUpdateCount(count => count + 1);
    };

    useEffect(() => {
        if (!gameEngine || !isBotMode || isClassicMode || gameEngine.botUpdateSteps.length === 0) {
            return
        }
        const actions = gameEngine.botUpdateSteps
        const i: number = gameEngine.botStepsUpdated
        const isWhite = myColor === Colors.WHITE
        const updateCache: AnyObject = gameEngine.botUpdateCache
        if (i === actions.length) {
            gameEngine.botStepsUpdated = 0
            gameEngine.botUpdateSteps = []
            gameEngine.botUpdateCache = {}
            // after every action finishes
            const timer = setTimeout(() => {
                setMyIncome(isWhite ? updateCache['whiteIncome'] : updateCache['blackIncome'])
                setTurn({color: updateCache['turn'], phase: updateCache['phase']})
                return () => {clearTimeout(timer)};
            }, 400);
            return
        }
        const timeout = actions[i].type === 'buy' ? 150 : 400
        const stepTimer = setTimeout(() => {
            if (actions[i].type === 'buy') {
                const pieceIdx = actions[i].selectedPieceIdx
                const equipment = actions[i].equipment
                if (equipment === 'shield') {
                    pieces[pieceIdx].vest = true
                } else {
                    pieces[pieceIdx].weapon = equipment
                }
                sounds['purchaseEquipment'].play()
                updatePieces(pieces)
            } else if (actions[i].type === 'promote') {
                const pieceIdx = actions[i].selectedPieceIdx
                const promoteTo = actions[i].promoteTo
                pieces[pieceIdx].type = promoteTo
                updatePieces(pieces)
            } else if (actions[i].type === 'move') {
                const pieceIdx = actions[i].selectedPieceIdx
                const destination = actions[i].to
                pieces[pieceIdx].position = destination
                if (isCheckmate(pieces, pieces[pieceIdx].color, gameMode.includes('classic'), setGameOverStatus, setSelectedPos)) {
                    updatePieces(pieces)
                    return
                }
                if (pieces[pieceIdx].weapon !== 'knife') {
                    updateShiver(pieces, pieceIdx, pieceCache, setPieceCache, Phases.FIRE, true)
                }
                updatePieces(pieces)
            } else if (actions[i].type === 'attack') {
                const pieceIdx = actions[i].selectedPieceIdx
                const fromPos = actions[i].from
                const destination = actions[i].to
                const attackedIdx = actions[i].attackedIdx
                pieces[pieceIdx].position = destination
                // only the stabbed piece need to be updated in animation
                stabPiece(pieces, updatePieces, pieceCache, setPieceCache, attackedIdx, destination, fromPos, myColor, 0, (temp)=>{}, setBoardShakeInfo, setGameOverStatus, false)
                if (isCheckmate(pieces, pieces[pieceIdx].color, gameMode.includes('classic'), setGameOverStatus, setSelectedPos)) {
                    updatePieces(pieces)
                    return
                }
                updatePieces(pieces)
            } else if (actions[i].type === 'castle') {
                const kingIdx = actions[i].kingIdx
                const rookIdx = actions[i].rookIdx
                const [kingX, kingY] = PosToCoord(pieces[kingIdx].position, myColor)
                if (rookIdx === 0) {
                    const newKingPos = CoordToPos(kingX - 2, kingY, myColor)
                    const newRookPos = CoordToPos(kingX - 1, kingY, myColor)
                    pieces[kingIdx].position = newKingPos
                    pieces[rookIdx].position = newRookPos
                } else {
                    const newKingPos = CoordToPos(kingX + 2, kingY, myColor)
                    const newRookPos = CoordToPos(kingX + 1, kingY, myColor)
                    pieces[kingIdx].position = newKingPos
                    pieces[rookIdx].position = newRookPos
                }
                updatePieces(pieces)
            } else if (actions[i].type === 'fire') {
                const hitIdxes = actions[i].hitIdxes
                const selectedPieceIdx = actions[i].selectedPieceIdx
                if (actions[i].shootAngle === 999) {
                    updatePieces(pieces)
                    return
                }
                let shootAngle = actions[i].shootAngle + Math.PI
                if (shootAngle > Math.PI) {
                    shootAngle -= Math.PI * 2
                }
                if (hitIdxes.length > 0) {
                    let totalAngle = 0
                    // calculate the average of the hit angles
                    for (const hitIdx of hitIdxes) {
                        const firstHitPiece = pieces[hitIdx]
                        // recalculate shoot angle to accomodate the offset
                        const [ originX, originY ] = PosToCoord(pieces[selectedPieceIdx].position, myColor)
                        const [ targetX, targetY ] = PosToCoord(firstHitPiece.position, myColor)
                        totalAngle += Math.atan2(targetY - originY, (targetX + 0.5) - (originX + 0.5));
                    }
                    setOpponentShootAngle(totalAngle / hitIdxes.length)
                } else {
                    setOpponentShootAngle(shootAngle)
                }
                setSelectedPos(pieces[selectedPieceIdx].position)

                const shootTimer = setTimeout(() => {
                    for (const hitIdx of hitIdxes) {
                        if (pieces[hitIdx].vest) {
                            pieces[hitIdx].vest = false
                        } else {
                            pieces[hitIdx].active = false
                        }
                    }
                    gameEngine.processFireAction(pieces, isWhite, actions[i], pieceCache, setPieceCache, setGameOverStatus, setScreenShakeInfo, null, true)
                    updatePieces(pieces)
                    return () => {clearTimeout(shootTimer)};
                }, 600);
            }
            return () => {clearTimeout(stepTimer)};
        }, timeout);

        if (i + 1 < actions.length && actions[i + 1].type === 'fire') {
            setTurn({color: turn.color, phase: Phases.FIRE})
        }
        gameEngine.botStepsUpdated++
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [pieces, updateCount, gameEngine]);

    return { 
        isLoading, pieces, setPieces, turn, setTurn, 
        myUsername, myCredits, myColor, myLayout, 
        myIncome, setMyIncome, 
        opponentUsername, opponentCredits, opponentLayout,
        pieceCache, setPieceCache, 
        selectedPos, setSelectedPos, 
        hoveredPos, setHoveredPos, 
        boardShakeInfo, setBoardShakeInfo, 
        screenShakeInfo, setScreenShakeInfo, 
        shootAngle, setShootAngle,
        opponentShootAngle, setOpponentShootAngle, 
        gameOverStatus, setGameOverStatus,
        myDrawRequest, setMyDrawRequest,
        opponentDrawRequest, setOpponentDrawRequest,
        mySpeech, setMySpeech,
        opponentSpeech, setOpponentSpeech,
        opponentExpression, setOpponentExpression, 
        opponentCharacter, 
        lastUpdateTime, 
        gameEngine
    };
};