// src/contexts/AudioContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// Define a type for the context state
export type VolumeSettings = {
    masterVolume: number;
    musicVolume: number;
    soundEffectVolume: number;
};

// Define a type for the context value
type AudioContextType = {
    volumeSettings: VolumeSettings;
    updateVolume: (type: keyof VolumeSettings, volume: number) => void;
};

// Define the default values for the context
const defaultVolumeSettings: VolumeSettings = {
    masterVolume: 1,
    musicVolume: 0.2,
    soundEffectVolume: 1,
};

const AudioContext = createContext<AudioContextType>({
    volumeSettings: defaultVolumeSettings,
    updateVolume: () => {}
});

// Custom hook to use the audio context
export const useAudio = () => useContext(AudioContext);

// Define props for AudioProvider for type safety
type AudioProviderProps = {
    children: ReactNode;
};

export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
    const [volumeSettings, setVolumeSettings] = useState<VolumeSettings>(defaultVolumeSettings);

    // Load initial settings from localStorage
    useEffect(() => {
        const savedSettings = localStorage.getItem('volumeSettings');
        if (savedSettings) {
            setVolumeSettings(JSON.parse(savedSettings));
        }
    }, []);
    
    useEffect(() => {
        const savedSettings = localStorage.getItem('volumeSettings');
        if (savedSettings) {
            const parsedSettings = JSON.parse(savedSettings);
            setVolumeSettings(parsedSettings);
        }
    }, []);

    // Save settings to localStorage whenever they change
    useEffect(() => {
        localStorage.setItem('volumeSettings', JSON.stringify(volumeSettings));
    }, [volumeSettings]);

    const updateVolume = (type: keyof VolumeSettings, volume: number) => {
        setVolumeSettings(prevSettings => ({
            ...prevSettings,
            [type]: volume
        }));
    };

    return (
        <AudioContext.Provider value={{ volumeSettings, updateVolume }}>
            {children}
        </AudioContext.Provider>
    );
};